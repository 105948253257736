import React, { useContext } from 'react'
import { SiteContext } from '../App.tsx'

import { v4 as uuidv4 } from 'uuid'
import '../admin/classes/css/app.css'

export default function More({person, mode}) {

    const {profileElements} = useContext(SiteContext)

    // console.log(person,'person', profileElements)
    return (
        <div>
            {mode !== "notes" && <div className="recipe__row">
                <div className="recipe__value recipe__value--indented">
                    <div className="ingredient-grid2">
                        <span className="bold">Period</span><span className="">{person.period}</span>
                        <span className="bold">Student ID</span><span className="">{person.studentId}</span>
                        <span className="bold">Grade</span><span className="">{person.grade}</span>
                        <span className="bold">Email</span><span className="">{person.email}</span>
                        <span className="bold">Pronounce</span><span className="">{person.namePronunciation}</span>
                        <span className="bold">Attendance</span><span className="">{person.attendanceName}</span>
                    </div>
                    <br/>
                    <div className="ingredient-grid2">
                        <span className="bold">Group</span><span className="">{person.group}</span>
                        <span className="bold">Class Role</span><span className="">{person.classRole}</span>
                        <span className="bold">Phone Taken</span><span className="">{person.phoneTaken}</span>
                        <span className="bold">Home Phone</span><span className="">{person.homePhone} ({person.homePhoneRelation})</span>
                        <span className="bold">Birthday</span><span className="">{person.birthday}</span>
                        {profileElements.map(element => {
                            if(!person[element.property]) return
                            return <ProfileElement key={element.property} element={element} person={person} />
                        })}
                    </div>
                </div>
            </div>}
            <br/>
            <p style={{fontWeight:"bold"}}>Notes:</p>
            {mode !== "record" && <div className="recipe__row">
                <div className="recipe__value recipe__value--indented">
                    <div className="ingredient-grid2">
                    {person.notes.map(entry => {
                        return (
                            <span key={uuidv4()}>{entry.type} <br/> <span className="bold">{entry.entry}</span> </span>
                        )
                    })}
                    </div>
                </div>
            </div>} 
        </div>
    )
}

function ProfileElement({element, person}) {

    return (
    <>
    <span className="bold">{element.adminText}</span>
    <div className="d-flex flex-wrap">
        {person[element.property] && person[element.property].map(entry => {
            return <div key={entry.keyValue} className="badge bg-light m-1 ms-0 text-wrap">{entry.keyValue}</div>
        })}
    </div>
    </>
    )
}