import React, { useState, useEffect } from "react";

const GoogleDataViewer = ({ baseUrl }) => {
    const [documentId, setDocumentId] = useState("");
    const [spreadsheetId, setSpreadsheetId] = useState("");
    const [presentationId, setPresentationId] = useState("");
    const [data, setData] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dayOfVisit, setDayOfVisit] = useState("");
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [copyClicked, setCopyClicked] = useState(false);
    const [scheduleData, setScheduleData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    // ✅ Set startDate to today's local date when component loads
    useEffect(() => {
        const today = new Date();
        const localDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())
            .toISOString()
            .split("T")[0]; // Ensures local time is used
        setStartDate(localDate);
    }, []);


    // ✅ Ensures fetch buttons are disabled if required fields are empty
    const isFetchDisabled = !documentId && !spreadsheetId && !presentationId;

    // ✅ Ensures endDate cannot be before startDate
    const handleEndDateChange = (e) => {
        const selectedEndDate = e.target.value;
        
        if (!selectedEndDate) {
            setEndDate(""); // Allow clearing of the end date
            setDayOfVisit(""); // Clear dayOfVisit if End Date is cleared
            return;
        }
    
        if (selectedEndDate < startDate) {
            alert("⚠️ End date cannot be before start date.");
            setEndDate(startDate);
            setDayOfVisit(startDate); // ✅ Also update Day of Visit
        } else {
            setEndDate(selectedEndDate);
    
            if (startDate === selectedEndDate) {
                setDayOfVisit(selectedEndDate); // ✅ Auto-set Day of Visit if Start and End Date are the same
            }
        }
    };

    // ✅ Copy data along with selected metadata
    const copyToClipboard = () => {
        const finalData = {
            metadata: {
                selectedDateRange: startDate && endDate ? `${formatDateForExport(startDate)} to ${formatDateForExport(endDate)}` : formatDateForExport(startDate),
                dayOfVisit: startDate && endDate ? formatDateForExport(dayOfVisit) : formatDateForExport(startDate),
            },
            documentData: data ? { type: "Google Doc", content: data } : null,
            scheduleData: scheduleData ? { type: "Google Sheet", content: scheduleData } : null,
        };
    
        // ✅ Remove null values (if either section is empty)
        const filteredData = Object.fromEntries(
            Object.entries(finalData).filter(([_, v]) => v !== null)
        );
    
        navigator.clipboard.writeText(JSON.stringify(filteredData, null, 2));
    
        // ✅ Update button UI to show that it's been copied
        setCopyClicked(true);
    
        // ✅ Reset button styling after 3 seconds
        setTimeout(() => {
            setCopyClicked(false);
        }, 3000);
    };
    
    // ✅ Formats dates as MM/DD/YYYY
    const formatDateForExport = (dateString) => {
        if (!dateString) return null;
        const [year, month, day] = dateString.split("-");
        return `${month}/${day}/${year}`;
    };

    const fetchData = async (type) => {
        let id;
        let url;
      
        // Ensure that fetching does not proceed without an ID
        if (type === "doc" && !documentId) return;
        if (type === "sheet" && !spreadsheetId) return;
        if (type === "slide" && !presentationId) return;

        setIsLoading(true); // ✅ Show Loading before fetch

        // Detect if input is a full URL or just the ID
        if (type === "doc") {
            id = documentId.includes("https://") ? new URL(documentId).pathname.split("/")[3] : documentId;
            url = `${baseUrl}/google/read-doc?documentId=${id}`;
        } else if (type === "sheet") {
            id = spreadsheetId.includes("https://") ? new URL(spreadsheetId).pathname.split("/")[3] : spreadsheetId;
            url = `${baseUrl}/google/read-sheet?spreadsheetId=${id}`;
        } else if (type === "slide") {
            id = presentationId.includes("https://") ? new URL(presentationId).pathname.split("/")[3] : presentationId;
            url = `${baseUrl}/google/read-slide?presentationId=${id}`;
        }

        // Append date filtering parameters if provided
        if (startDate) {
            url += `&startDate=${startDate}`;
        }
        if (endDate) {
            url += `&endDate=${endDate}`;
        }
        if (startDate && endDate && dayOfVisit) {
            url += `&dayOfVisit=${dayOfVisit}`;
        }

        try {
            const response = await fetch(url);
            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Invalid JSON response from server.");
            }

            const result = await response.json();
            setData(result);
            setShowCopyButton(true);
        } catch (error) {
            console.error("Error fetching data", error);
            setData({ error: "Failed to fetch valid JSON. Check the API response." });
        } finally {
            setIsLoading(false); // ✅ Hide Loading after fetch
        }

    };

    // ✅ Fetch Schedule Data from Google Sheets API
    const fetchSchedule = async () => {
        if (!spreadsheetId) {
            setError("Please provide a valid Google Sheets URL.");
            return;
        }
        setError(null);
        setIsLoading(true); // ✅ Show Loading before fetch

        try {
            const response = await fetch(`${baseUrl}/google/read-schedule?spreadsheetId=${spreadsheetId}`);
            const result = await response.json();
            if (response.ok) {
                setScheduleData(result);
            } else {
                setError(result.error || "Failed to fetch schedule data.");
            }
        } catch (err) {
            setError("Error fetching schedule data.");
            console.error("❌ Error fetching Google Sheet:", err);
        } finally {
            setIsLoading(false); // ✅ Hide Loading after fetch
        }
    };

    // ✅ Extract Spreadsheet ID from URL
    const extractSpreadsheetId = (url) => {
        try {
            const urlObj = new URL(url);
            const pathSegments = urlObj.pathname.split("/");
            return pathSegments[3] || "";
        } catch (err) {
            return "";
        }
    };

    const GoogleSheet = ({}) => {

        return (
            <div style={{ paddingBottom: "20px", paddingTop:"20px" }}>
                <h3>📊 Google Sheets Schedule</h3>
                <input
                    type="text"
                    placeholder="Google Sheets URL"
                    onChange={(e) => setSpreadsheetId(extractSpreadsheetId(e.target.value))}
                />
                <button className="ms-2" onClick={fetchSchedule} disabled={!spreadsheetId}>
                    Fetch Google Sheet
                </button>
            </div>
        );
    };

    async function fetchAll() {
        await fetchData("doc")
        await fetchSchedule()
    }

    return (
        <div style={{ padding: "30px" }}>
            <h1 className="fw-bold">GSuite Parser</h1>

            <div style={{ paddingBottom: "20px" }}>
                {/* Date Picker for filtering */}
                <div className="mt-3">
                    <h3>📅 Select Date or Range:</h3>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <label>Start Date:</label>
                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />

                        <label>End Date:</label>
                        <input type="date" value={endDate} onChange={handleEndDateChange} placeholder="End Date (optional)" />
                    </div>
                </div>

                {/* Day of Visit Picker (ONLY if startDate and endDate are selected) */}
                {startDate && endDate && (
                    <div className="mt-3">
                        <h3>📍 Select Day of Visit</h3>
                        <input type="date" value={dayOfVisit} onChange={(e) => setDayOfVisit(e.target.value)} min={startDate} max={endDate} />
                    </div>
                )}

                {/* Google Docs, Sheets, and Slides Fetch Buttons */}
                <div style={{ paddingTop: "20px" }}>
                    <h3>🔵 Google Docs:</h3>
                    <input type="text" placeholder="Google Doc URL" value={documentId} onChange={(e) => setDocumentId(e.target.value)} />
                    <button className="ms-2" onClick={() => fetchData("doc")} disabled={!documentId}>Fetch Google Doc</button>

                    {/* <h3 className="mt-3">🟢 Google Sheets:</h3>
                    <input type="text" placeholder="Google Sheet URL" value={spreadsheetId} onChange={(e) => setSpreadsheetId(e.target.value)} />
                    <button className="ms-2" onClick={() => fetchData("sheet")} disabled={!spreadsheetId}>Fetch Google Sheet</button>

                    <h3 className="mt-3">🟠 Google Slides:</h3>
                    <input type="text" placeholder="Google Slide URL" value={presentationId} onChange={(e) => setPresentationId(e.target.value)} />
                    <button className="ms-2" onClick={() => fetchData("slide")} disabled={!presentationId}>Fetch Google Slide</button> */}
                </div>
                <GoogleSheet baseUrl={baseUrl}/>
                {documentId && spreadsheetId && <button className="" onClick={fetchAll} disabled={!documentId || !spreadsheetId}>
                    Fetch All
                </button>}
            </div>

            {/* Copy Button with Click Effect */}
            {!isLoading && showCopyButton && (
                <button
                    style={{
                        float: "right",
                        padding: "15px 30px",
                        fontSize: "18px",
                        backgroundColor: copyClicked ? "#28a745" : "#D3D3D3",
                        color: copyClicked ? "white" : "black",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease"
                    }}
                    onClick={copyToClipboard}
                >
                    {copyClicked ? "✅ Copied!" : "Copy Full Response"}
                </button>
            )}
            <div style={{ marginTop: "20px" }}>
            {/* {isLoading && <p style={{ color: "", fontWeight: "bold" }}>🔄 Loading...</p>} */}
            {isLoading && <LoadingButton/>}
            </div>
            {!isLoading && <>
            {data && <div style={{marginTop:"30px"}}>
                <h1>💾 Metadata:</h1>
                <pre>{JSON.stringify({
                    selectedDateRange: startDate && endDate ? `${formatDateForExport(startDate)} to ${formatDateForExport(endDate)}` : formatDateForExport(startDate),
                    dayOfVisit: startDate && endDate ? formatDateForExport(dayOfVisit) : formatDateForExport(startDate),
                }, null, 2)}</pre>
            </div>}
            {scheduleData && <div style={{marginTop:"30px"}}>
                <h1>📅 Schedule Output:</h1>
                <pre>{JSON.stringify(scheduleData, null, 2)}</pre>
             </div>}
            {data && <div style={{marginTop:"30px"}}>
                <h1>📄 Docs Output:</h1>
                <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>}
            </>}
        </div>
    );
};

function LoadingButton() {
    return (
        <button
        style={{
            // float: "right",
            padding: "15px 30px",
            fontSize: "18px",
            backgroundColor: true ? "grey" : "#D3D3D3",
            color: true ? "white" : "black",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s ease"
        }}
    >
        🔄 Loading...
    </button>
    )
}
export default GoogleDataViewer;











