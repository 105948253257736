import React, { useContext, useState, useEffect } from 'react'

import { SiteContext } from '../../../App.tsx'
import { StudentContext } from '../../../pages/StudentContainer'

import Timer from '../../../other/utils/TimerCountUp'
import { useTenMinRule } from '../../TenMinRule'
import { useTimeRemaining } from '../../../other/utils/useTimeRemaining'

export default function HallPassMenuStatus() {

    const {classInformation, student} = useContext(SiteContext)
    const {activePass} = useContext(StudentContext)

    const tenMinRule = useTenMinRule()
    // console.log(tenMinRule,'tenMinRule')
    const hallPassTime = useTimeRemaining(activePass)

    // console.log(hallPassTime,'hallPassTime')

    if(tenMinRule) return {
        reason: '10 Minute Rule', 
        time: tenMinRule
    }
    if(activePass) {
        return {
            reason: 'Hall Pass In Use',
            time: hallPassTime
        }
    }

    return {
        reason: 'Create Hall Pass',
        time: false
    }

}

