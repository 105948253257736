import React, {useContext} from 'react'
import { SiteContext } from '../../../App.tsx'
import { AdminContext } from '../../../pages/AdminContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import ShowMore from '../../../other/utils/ShowMore';

export default function Entry({entry, colorCurrent, expand}) {

  const {colorMatch, focusAgreements} = useContext(SiteContext)
  const {handleOverride, handleResetModal} = useContext(AdminContext)

  const initial = colorMatch.filter(entry => entry.color === colorCurrent)[0]

  function ColorMatchIcon({level}) {
    if(!level) return
    var match = colorMatch.filter(entry => entry.level === level)[0]
    return (
      <FontAwesomeIcon style={{color: match.hex, marginLeft:"3px", marginRight:"3px"}} icon={faSquare} className="fa-lg" />
      // <span>{match.string}</span>
    )
  }

  function handleReset(e, mode) {
    e.preventDefault()
    handleResetModal('dayReview', entry, mode)
  }

  if(!entry.dayReview) return
  
  var ratingBg = !entry.dayReview.module.progress ? 'secondary' : 
      entry.dayReview.module.progress > 6.5 ? 'success' :
      entry.dayReview.module.progress > 4.5 ? 'secondary' :
      entry.dayReview.module.progress > 2.5 ? 'warning' : 'danger'

    return (
      <tr className={`${""}`}>
        <td className="" style={{verticalAlign:"top"}}>
              <div className="box">
                    <span className="text">{entry.name}</span>
                </div>
        </td>
        <td className="text-center" style={{verticalAlign:"top"}}>
          {initial.level && entry.dayReview.rating.self && <ColorMatchIcon level={initial.level}/>}
          {!entry.dayReview.rating.self && 
          <div className={`mb-2 me-2 badge bg-${!focusAgreements.dayReview ? 'secondary' : entry.dayReview.display === 1 ? 'flag' : 'info'}`}>Step {entry.dayReview.display}</div>}
        </td>
        <td className="text-center" style={{verticalAlign:"top"}}>
          <ColorMatchIcon level={entry.dayReview.rating.self}/>
        </td>
        <td className="text-center" style={{verticalAlign:"top"}}>
          <ColorMatchIcon level={entry.dayReview.rating.group}/>
        </td>
        <td className="text-center" style={{verticalAlign:"top"}}>
          <ColorMatchIcon level={entry.dayReview.rating.class}/>
        </td>
        {expand && <td className="" style={{verticalAlign:"top"}}>
            <div className="box">
                <span className="text">
                  <ShowMore textString={entry.dayReview.questions.success} />
                </span>
            </div>
        </td>}
        {expand && <td className="" style={{verticalAlign:"top"}}>
            <div className="box">
                <span className="text">
                  <ShowMore textString={entry.dayReview.questions.improve} />
                </span>
            </div>
        </td>}
        <td className="" style={{verticalAlign:"top"}}>
            <div className={`badge bg-${ratingBg}`}>{entry.dayReview.module.progress}</div>
            {/* { > 0 && `${entry.dayReview.module.progress}/10`} */}
        </td>
        {expand && <td className="" style={{verticalAlign:"top"}}>
          <div role="button" onClick={e => handleOverride(e, entry, !entry.modalOverride)} className={`mb-2 me-2 badge bg-${!focusAgreements.dayReview ? 'secondary' : entry.modalOverride ? 'danger' : 'success'}`}>{(entry.modalOverride || !focusAgreements.dayReview) ? 'Hidden' : 'Showing'}</div>
        </td>}
        {expand && <td className="" style={{verticalAlign:"top"}}>
          <div role="button" onClick={e => handleReset(e, 'reset')} className={`mb-2 me-2 badge bg-secondary`}>Reset</div>
        </td>}
        {expand && <td className="" style={{verticalAlign:"top"}}>
          <div role="button" onClick={e => handleReset(e, 'reopen')} className={`mb-2 me-2 badge bg-secondary`}>Reopen</div>
        </td>}
      </tr>
    )
}

 
