import React, { useContext, useState, useEffect, createContext } from 'react'
import AuctionEntry from './AuctionEntry'

import { SiteContext, baseUrl } from '../../../App.tsx'

export default function Auction( { handleChange, filteredPersons }) {

    const { 
      classInformation, 
      studentDataFunctions: {
      }
    } = useContext(SiteContext)

    return (
        <section className="" style={{margin:"10px"}}>
            <div className="" style={{display:"inline", float: "right"}}>
            <input 
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
                type = "search" 
                placeholder = "Search Students" 
                onChange = {handleChange}
            />
            </div>
            <br/><br/>
            <div className="table-responsive">
                <table className="align-middle text-truncate mb-0 table table-borderless table-hover">
                    <thead>
                    <tr>
                        <th className="text-center">Name</th>
                        <th className="text-center">Balance</th>
                        <th className="text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    { filteredPersons.map(item => {
                        if(item.period === 'Dropped') return
                        return <AuctionEntry item={item} key={item._id}/>
                    })}
                    </tbody>
                </table>
            </div>
      </section>
    )
}
