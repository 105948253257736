import React, {useContext, useState, useEffect} from 'react'

import { SiteContext } from '../../../App.tsx'
import { v4 as uuidv4 } from 'uuid'
import '../inventory.css'

import ItemCard from './ItemCard'
import Checkouts from './Checkouts'

export default function SInventory({}) {

  const { 
    modules, student, dailyFunction, badgesFunction, moduleField, moduleItems,
    studentDataFunctions: {setModuleItems} 
  } = useContext(SiteContext)

  const [searchField, setSearchField] = useState("");

  const filteredItems = moduleItems.filter(
    person => {
      return (
        person.item.toLowerCase().includes(searchField.toLowerCase())  
        ||
        (person.displayTitle && person.displayTitle.toLowerCase().includes(searchField.toLowerCase()))  
      );
    }
  )

  // console.log(moduleItems,'moduleItems')

  const handleChange = e => {
    e.preventDefault()
    setSearchField(e.target.value);
  };

  var count = 0
  const inventoryFunctions = {moduleItems, setModuleItems}

  var activeCheckout = false
  for(let i = 0; i < moduleItems.length; i++) {
    if(moduleItems[i].checkedOut) {
      activeCheckout = true
      break
    }
  }

  filteredItems.sort(function(a, b) {
    var keyA = a.item,
      keyB = b.item;
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });


  return (
    // <div className='notranslate'>
    <div className=''>
      <div className="d-flex"> 
        <div className={`flex-fill`}>
          <div className={`badgeEvents sticky-top`}>
            <div className="card">
              <div className="card-body d-flex justify-content-between align-items-center">
              <input 
                className="form-control w-25 d-inline" type = "search" value={searchField} 
                onChange = {handleChange} placeholder = "Search For Item" 
              />
              <div >
                <div className="ms-3 d-block">{student.module}</div>
                <div className="ms-3 d-block text-right fw-bold">Results: {filteredItems.length}</div>
              </div>
              {/* <div className="ms-3" style={{fontWeight:"bold"}}>{student.module} - Results: {filteredItems.length}</div> */}
              </div>
            </div> 
          </div>
          <div className={`mt-3`}>
            {moduleItems.length === 0 && `Loading...`}
            {moduleItems.length !== 0 && filteredItems.length === 0 && `No Matches`}
            {filteredItems.map(entry => {
              count++
                return (
                    <ItemCard key={uuidv4()} entry={entry} inventoryFunctions={inventoryFunctions} />
                )
            })}
          </div>
        </div>
        {activeCheckout && <div className={`ms-3`}>
          <Checkouts filteredItems={moduleItems} moduleField={moduleField} />
        </div>}
      </div>
    </div>
  );
}
