import React, { useContext, useEffect, useState } from 'react';
import Search from './Search';

import { SiteContext } from "../../../App.tsx";
import { AdminContext } from '../../../pages/AdminContainer';

import { ErrorBoundary } from '../../../other/utils/ErrorBoundary';

export default function HallPass() {

  const {
    studentDataFunctions: {
        getMongoList
    },
    studentList
  } = useContext(SiteContext)

  const {hpEvents} = useContext(AdminContext)
  const hallPasses = []

  // console.log(hpEvents,'hpEvents')

  hpEvents.map(entry => { 
    entry.events.map(event => {
      if(!event.approved) return
      hallPasses.push(event)  
    }) 
  })

  if(!studentList) return
  studentList.map(entry => {
    if(!entry.hallPassEvents) return
    entry.hallPassEvents.map(event => {
      if(!event.approved) return
      hallPasses.push(event)  
    })
  })


  if(!hallPasses) return
  var sortedHallPasses = hallPasses.sort( compare );

  return (
    <ErrorBoundary>
      <Search hallPasses={sortedHallPasses} />
    </ErrorBoundary>
  );
}

function compare( a, b ) {
    if ( a.timeStamp > b.timeStamp){
      return -1;
    }
    if ( a.timeStamp < b.timeStamp ){
      return 1;
    }
    return 0;
}