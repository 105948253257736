import React, { useContext, useState, useEffect } from 'react';
import BalanceCard from './balance/BalanceCard';
import Orders from './Orders';
import ClassBalance from './ClassBalance';
import FeelingsWheel from '../../other/feelingsWheel/FeelingsWheel';
import StoreItemsNew from './storeItems/StoreItems';

import { ErrorBoundary } from '../../other/utils/ErrorBoundary';
import { SiteContext } from "../../App.tsx";
import { AdminContext } from '../../pages/AdminContainer';
import StoreItems from './StoreItems';

export const DStoreContext = React.createContext()

export default function AStore() {

  const {
    socket,
    room,
    studentList, classInformationArray,
    studentDataFunctions: {
      setStudentList, updateMongo, deleteMongo,
    },
  } = useContext(SiteContext)

  const { dailyList, setDailyList, orders, setOrders, handleAddChipsManual, handleUpdateOrders } = useContext(AdminContext)

  return (
    <DStoreContext.Provider value={{handleUpdateOrders, handleAddChipsManual}}>
      <ErrorBoundary>
      <div className="row">
        <div className="col-12 col-md-7">
          <div className="row">
          <Orders title={'Orders'} sizeClass={'col-sm-12'} orders={orders} />
          <StoreItemsNew/>
          {classInformationArray.map(entry => {
            return <ClassBalance key={entry.period} period={entry.period} sizeClass={'col-sm-4'} colorValue="primary" />
          })}
          </div>
        </div>
        <ErrorBoundary>
          <div className="col-12 col-md-5">
            <BalanceCard title={'Balance'} colorValue={'primary'} sizeClass={'col-sm-12'} orders={orders} handleUpdateOrders={handleUpdateOrders} />
          </div>
        </ErrorBoundary>
        {/* <FeelingsWheel title={'Feelings Wheel'} sizeClass={'col-sm-12'} /> */}
      </div>
      </ErrorBoundary>
    </DStoreContext.Provider>
  );
}