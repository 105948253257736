import React, {useState} from 'react'
import { SiteContext } from '../../App.tsx'

import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Item from './Item'
import { useContext } from 'react'

export default function Modules() {

    const { modules, focusAgreements, classInformation, socket, room, studentList, storeItems, classInformationArray,
        studentDataFunctions: { getMongoList, createMongo, deleteMongo, updateMongo, setFocusAgreements, setStoreItems }
    } = useContext(SiteContext)

    async function handleSave(e, selectedEntry, form) {
        e.preventDefault()

        const editFocusAgreements = {...focusAgreements}

        const editModules = [...editFocusAgreements.modules]
        var index = editModules.findIndex(item => item.itemId === selectedEntry.itemId)
        if(index === -1) return
        // editModules[index] = {...editModules[index], ...form}
        
        delete form.checklists
        delete form._id

        editFocusAgreements.modules[index] = {...editFocusAgreements.modules[index], ...form}

        console.log({modules: editFocusAgreements.modules}, '{modules: editFocusAgreements.modules}', classInformation.focusAgreementId)
        classInformationArray.map(classEntry => {
            updateMongo({modules: editFocusAgreements.modules}, classEntry.focusAgreementId, 'focusAgreements')
        })

        var index = modules.findIndex(entry => entry.itemId === editFocusAgreements.modules[index].itemId)
        // console.log(modules[index],'index')

        const updateArray = {...editFocusAgreements.modules[index]}
            delete updateArray.checklist
            delete updateArray.checkoutItems
            delete updateArray._id
        
        updateMongo({...updateArray}, modules[index]._id, 'modules')
    }

    const handleFunctions = {
        handleSave,
    }

    var count = 0

    storeItems.sort( compare );

    if(!focusAgreements || !focusAgreements.modules) return

    return (
    <div className="mb-3">
        <div className="card">
            <div className="card-header">
                <div className="card-header-title font-size-lg text-capitalize fw-normal">
                    Modules
                </div>
            </div>
            <div className="card-body">
            {focusAgreements.modules.map(entry => {
            count++
            return <Item key={entry.itemId} entry={entry} count={count} handleFunctions={handleFunctions} length={storeItems.length} />
        })}
            </div>
        </div>

    </div>
    )
}


function newItem(storeItems) {
    return {
        item: "", 
        price: 1, 
        quantity: 0,
        oufOfStock: 'No',
        itemId: uuidv4(),
        index: storeItems.length
    }
}

function compare( a, b ) {
    if ( a.index < b.index ){
      return -1;
    }
    if ( a.index > b.index ){
      return 1;
    }
    return 0;
}