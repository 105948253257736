import React, {useState} from 'react'
import { SiteContext } from '../../App.tsx'

import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';

import Item from './Item'
import Calendar from './Calendar'
import { useContext } from 'react'

export default function Schedules() {

    const { schedules, colorMatch, focusAgreements,
        studentDataFunctions: { getMongoList, createMongo, deleteMongo, updateMongo, setSchedules, setStoreItems }
    } = useContext(SiteContext)

    const [activeSchedule, setActiveSchedule] = useState(false)
    const [showSchedule, setShowSchedule] = useState(true)

    async function handleSave(e, selectedEntry, form) {
        e.preventDefault()

        const editSchedules = [...schedules]
        var index = editSchedules.findIndex(item => item._id === selectedEntry._id)
        if(index === -1) return
        editSchedules[index] = {...editSchedules[index], ...form}
        
        updateVariables(editSchedules, editSchedules[index])
    }

    async function handleEdit(e, property, itemId) {
        e.preventDefault()

        const editSchedules = [...schedules]
        var index = editSchedules.findIndex(entry => entry.itemId === itemId)
        editSchedules[index][property] = e.target.value
        
        updateVariables(editSchedules, editSchedules[index])
    }

    function handleChangeIndex(action, selectedEntry, currentIndex) {

        const editSchedules = [...schedules]
        var index = editSchedules.findIndex(entry => entry.itemId === selectedEntry.itemId)
        if(index === -1) return

        var change = action === 'raise' ? -1 : 1
        var otherIndex = index + change

        editSchedules[index].index = otherIndex
        editSchedules[otherIndex].index = index

        updateVariables(editSchedules, editSchedules[index])
        updateVariables(editSchedules, editSchedules[otherIndex])
    }
    
    async function handleAddDelete(e, type, selectedEntry) {
        e.preventDefault()

        const obj = type === 'add' ? newItem(schedules) : selectedEntry
        var editSchedules = [...schedules]

        if(type === 'add') {
            editSchedules.push(obj)
            createMongo(obj,'schedules')
        } else if(type === 'delete') {
            editSchedules = editSchedules.filter(item => item._id !== obj._id)
            deleteMongo(obj._id, 'schedules')
        }

        updateVariables(editSchedules)
    }

    async function handleAddDeletePeriod(e, type, selectedPeriod) {
        e.preventDefault()

        var editActiveSchedule = {...activeSchedule}
        const obj = type === 'add' ? newPeriod(editActiveSchedule.periods) : selectedPeriod
        
        if(type === 'add') {
            editActiveSchedule.periods.push(obj)
        } else if(type === 'delete') {
            editActiveSchedule.periods = editActiveSchedule.periods.filter(item => item.itemId !== obj.itemId)
        }

        const editSchedules = [...schedules]
        var index = editSchedules.findIndex(entry => entry.itemId === editActiveSchedule.itemId)
        if(index === -1) return

        editSchedules[index].periods = editActiveSchedule.periods
        updateVariables(editSchedules, editSchedules[index])
    }

    async function updateVariables(updatedItems, updatedItem) {
        setSchedules(updatedItems)

        if(updatedItem) {
            var mongoId = updatedItem._id ? updatedItem._id : await getUId(updatedItem.itemId)
            if(updatedItem._id) {delete updatedItem._id}
            if(!mongoId) return
            updateMongo({...updatedItem}, mongoId, 'schedules')
        }
    }

    async function getUId(itemId) {
        const items = await getMongoList('schedules')
        var index = items.findIndex(entry => entry.itemId === itemId)
        return index === -1 ? false : items[index]._id
    }

    function handleActiveSchedule(e) {
        e.preventDefault()

        var index = schedules.findIndex(entry => entry.itemId === e.target.value)
        if(index === -1) return
        setActiveSchedule(schedules[index])
    }

    function handleShowSchedule() {
        setShowSchedule(prev => {return !prev})
    }

    function moveChecklist(e) {
        e.preventDefault()

        focusAgreements.modules.map(entry => {
            const obj = {title: entry.title, fullTitle: entry.fullTitle, checklist: entry.checklist, variant: entry.variant, checkoutItems: [], groups: []}
            console.log(obj,'obj')
            createMongo(obj, 'modules')
        })
    }

    const handleFunctions = {
        handleSave, handleAddDelete, handleChangeIndex, handleAddDeletePeriod, activeSchedule, updateVariables, schedules
    }

    var count = 0
    // var variantCount = 2
    // storeItems.sort( compare );

    return (
    <>
    {/* <div className="card">
        
    </div> */}
    <div className="mb-3">
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div className="card-header-title font-size-lg text-capitalize fw-normal">
                    Schedules & Calendar
                </div>
                {/* <div onClick={e => moveChecklist(e)}>Click</div> */}
                <div className="d-flex align-items-center">
                    <span className="card-header-title font-size-md text-capitalize fw-normal me-3">Displaying: {showSchedule ? 'Schedule' : 'Calendar'}</span>
                    <div className="badge bg-light" onClick={handleShowSchedule} role="button">{showSchedule ? 'Show Calendar' : 'Show Schedule'}</div>
                </div>
            </div>
            {showSchedule ? <div>
            <div className="card-header d-flex justify-content-between align-items-center h-auto">
                <div className="w-100 d-flex align-items-center mt-1 mb-3 flex-wrap">
                    {schedules.map(entry => {
                    //   variantCount++
                      return <div key={entry.itemId} className={`d-inline me-3 mt-2 d-flex align-items-center flex-wrap`}>  
                        <input type="text" className={`badge bg-${colorMatch[2 + entry.index].variant} d-inline flex-shrink-1 border-0`}
                        value={entry.item} onChange={(e) => handleEdit(e, 'item', entry.itemId)}/>
                        <FontAwesomeIcon className="d-inline ms-1" icon={faX} onClick={e => handleAddDelete(e, 'delete', entry)} role="button" />
                      </div>
                    })}

                    {/* <div className={`badge bg-light`} onClick={e => handleAddDelete(e, 'add')}>+ New Schedule</div> */}
                </div>
                <span className="card-header-title font-size-md text-capitalize fw-normal flex-fill text-nowrap" role="button" onClick={e => handleAddDelete(e, 'add')}>
                        <FontAwesomeIcon className="d-inline ms-1 me-1" icon={faPlus}  />
                        New Schedule
                </span>
            </div>
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                    <span className="card-header-title font-size-lg text-capitalize fw-normal w-100">{activeSchedule.item}</span>
                    <select className="form-control" onChange={e => handleActiveSchedule(e)} role="button" style={{maxWidth:"200px"}}>
                        <option value={'none'}>Select Schedule to Edit</option>
                        {schedules.map(entry => {
                            return <option key={entry.itemId} value={entry.itemId}>{entry.item}</option>
                        })}
                    </select>
                </div>
                {activeSchedule && <div className="mt-3">
                {activeSchedule.periods.map(entry => {
                  count++
                  return <Item key={entry.itemId} entry={entry} count={count} handleFunctions={handleFunctions} length={schedules.length} />
                })}
                <div className="mt-3">
                    <div className="card shadow-none bg-light" onClick={e => handleAddDeletePeriod(e, 'add')} role="button">
                        <div className="card-body d-flex justify-content-center align-items-center flex-wrap">
                          <FontAwesomeIcon className="m-1 d-inline-block" icon={faPlus} />
                          Add Period
                        </div>
                    </div>
                </div>
                </div>}
            </div>
            </div> :
            <Calendar/>}
        </div>

    </div>
    </>
    )
}


function newItem(schedules) {
    return {
        item: `Schedule (${schedules.length})`, 
        periods: [],
        itemId: uuidv4(),
        index: schedules.length
    }
}

function newPeriod(periods) {
    return {
        item: `Period ${periods.length + 1}`, 
        class: false,
        itemId: uuidv4(),
        startTime: false,
        endTime: false,
        index: periods.length
    }
}


function compare( a, b ) {
    if ( a.index < b.index ){
      return -1;
    }
    if ( a.index > b.index ){
      return 1;
    }
    return 0;
}