import React, {useContext, useEffect} from 'react'

import { SiteContext } from '../../App.tsx'

export function useMongo({setNamesList}) {

    const { classInformation, loginState, studentDataFunctions: { getMongoAllByParam} } = useContext(SiteContext)

    useEffect(() => {
        if(loginState.admin) return
        getMongoAllByParam(classInformation.period, 'dailyList', 'period').then(entries => {
            const list = []

            entries.map(entry => {
                list.push({
                    name: entry.name,
                    studentId: entry.studentId,
                    period: entry.period,
                    chips: entry.chips,
                    attendance: entry.attendance
                })
            })

            setNamesList(list)
        })

    }, [])
}
