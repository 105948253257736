// import React from "react";
// import ReactDOM from 'react-dom';
// import App from './App.tsx';
// import { BrowserRouter } from "react-router-dom";

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );


import React from "react";
import App from "./App.tsx";

import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';

import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'

import { ErrorBoundary } from "./other/utils/ErrorBoundary";

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
      autocapture: {
        event_allowlist: ['click'], // DOM events from this list ['click', 'change', 'submit']
        // url_allowlist: ['posthog.com./docs/.*'], // strings or RegExps
        // element_allowlist: ['button'], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
        // css_selector_allowlist: ['[ph-autocapture]'], // List of CSS selectors
      }
    }
);


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
      <ErrorBoundary>
      <PostHogProvider client={posthog}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </PostHogProvider>
      </ErrorBoundary>
    </React.StrictMode>,
);

