import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import SubmitButton from "./SubmitButton";
import Question from "./Question";
import Card from "./Card";
import Toggle from "./Toggle";
import RatingComponent from './Rating'
import Preview from "../../../student/preview/Preview";

import { SiteContext } from "../../../App.tsx";
import { StudentContext } from "../../../pages/StudentContainer";

export default function GoalSetting({show, type}) {

 const {
   student, colorMatch, room, socket, goalQuestions, focusAgreements, loginState,
   studentDataFunctions: {
    updateMongo, setStudent, handleClose, handleShow
   }
 } = useContext(SiteContext)

 const [showPreview, setShowPreview] = useState(false)
 
 if(!student.goalSetting || !student.goalSetting.review) return

 const form = student.goalSetting
 const step = handleStep()
 const review = handleReview()

 function handleStep() {
  if(student.goalSetting.review.review && student.goalSetting.review.reviewComplete && student.goalSetting.timeStamp) return goalQuestions.length + 1
  if(student.goalSetting) return student.goalSetting.display
  return 5
 }

 function handleReview() {
  if(!student.goalSetting.review.reviewComplete && student.goalSetting.timeStamp) return true
  if(!student.goalSetting.timeStamp) return false
  if(student.goalSetting.review.reviewComplete && student.goalSetting.timeStamp) return false
 }

 function updateForm(category, item, value) {
    // form[category][item] = value

    const editStudent = {...student}
    editStudent.goalSetting[category][item] = value
    // editStudent.goalSetting = form
    setStudent(editStudent)
    updateMongo({goalSetting: {...editStudent.goalSetting}}, student.dailyListId, 'dailyList')
    socket.emit("send_updateModal", { goalSetting: {...editStudent.goalSetting}, studentId: student.studentId, type: 'goalSetting', room });
 }

 function handleCloseLocal(e) {
   if(e) e.preventDefault()
    console.log('closing')
    handleClose(type)
 }

 function handlePrevious(e) {
   e.preventDefault()

   updateMongo({goalSetting: {...student.goalSetting, display: step - 1}}, student.dailyListId, 'dailyList')

   const editStudent = {...student}
   editStudent.goalSetting.display = editStudent.goalSetting.display - 1
   setStudent(editStudent)
 }

 function togglePreview(e) {
   e.preventDefault()
   setShowPreview(prev => {return !prev})
 }

 if(!student.goalSetting) return
 if(!review && step > goalQuestions.length) return

return (
    <Modal show={show} dialogClassName={`${review ? "my-modal3" : "my-modal5"}`}>
      <Modal.Header style={{padding:0}}>
        <Modal.Body>
          <div style={{marginTop:"10px"}}>
          </div>
        <div>
          <p style={{fontSize:"24px", marginBottom:"0px"}}>Goal Setting</p>
        </div>
        {!review && <p style={{fontSize:"16px", marginBottom:"0px"}}>{`Step ${step} of ${goalQuestions.length}`}</p>}
        {review && <p style={{fontSize:"16px", marginBottom:"0px"}}>{`Review`}</p>}
        </Modal.Body>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <Toggle form={form} step={step} goalQuestions={goalQuestions} updateForm={updateForm} review={review} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!review && <button onClick={e => togglePreview(e)} className={`btn btn-warning me-4`}>{showPreview ? 'Hide Module Preview' : 'Show Module Preview'}</button>}
        {!review && step > 1 && <button className={`btn btn-light`} onClick={e=>handlePrevious(e)}>Previous Step</button>}
        {!review && <SubmitButton step={step} handleCloseLocal={handleCloseLocal} form={form} goalQuestions={goalQuestions} />}
        {review && form.review.review && <ReviewButtons form={form} updateForm={updateForm} handleStep={handleStep} />}
        {loginState.admin && <button className="btn btn-light" onClick={e => handleCloseLocal(e)}>Hide</button>}
      </Modal.Footer>
      {showPreview && <Modal.Body className="">
        <Preview goalSetting={true} />
      </Modal.Body>}
    </Modal>
);
}

function ReviewButtons({form}) {

  const {student, goalQuestions, socket, room, studentDataFunctions: {updateMongo, setStudent}} = useContext(SiteContext)
  const {handleShow} = useContext(StudentContext)
  
  const navigate = useNavigate();

  function handleEdit(e) {
    e.preventDefault()
    updateMongo({goalSetting: {...form, timeStamp: false}}, student.dailyListId, 'dailyList')
    const editStudent = {...student}
      editStudent.goalSetting.timeStamp = false
      setStudent(editStudent)
      socket.emit("send_updateModal", { goalSetting: {...editStudent.goalSetting}, studentId: editStudent.studentId, type: 'goalSetting', room });
  }

  function handleSubmit(e) {
    e.preventDefault()

    const editStudent = {...student}
      editStudent.goalSetting.review.review = form.review.review
      editStudent.goalSetting.review.reviewComplete = true
      setStudent(editStudent)

    updateMongo({goalSetting: {...form, display: goalQuestions.length + 1}}, student.dailyListId, 'dailyList')
    socket.emit("send_updateModal", { goalSetting: {...form, display: goalQuestions.length + 1}, studentId: student.studentId, type: 'goalSetting', room });

    navigate(`/checklistS`, { replace: true });
    // handleShow(undefined, 'paxPat')
  }

  return (
    <>
    <button className={`btn btn-warning`} onClick={e => handleEdit(e)}>Edit Goal</button>
    <button className={`btn btn-info`} onClick={e => handleSubmit(e)}>Continue With This Goal</button>
    </>
  )
}

function ModulePreviewBy({}) {
  return (
    <></>
  )
}

