import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import EditBody from "./1EditBody";

import { SiteContext } from "../../../App.tsx";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

 
export default function EditModal({handleShow, handleClose, show, type, classPeriod}) {

    const {
      student, socket, room, classInformationArray, studentList, classInformation,
      studentDataFunctions: {setClassInformation, setClassInformationArray, updateMongo, createMongo}
    } = useContext(SiteContext)

    const [modifiedPeriod, setModifiedPeriod] = useState(classPeriod)

    function handleSave() {
      const id = modifiedPeriod._id
      const mongoModifiedPeriod = {...modifiedPeriod}
      delete mongoModifiedPeriod._id
      console.log({...modifiedPeriod},'{...modifiedPeriod}', id)

      handleClose()
      updateMongo({...mongoModifiedPeriod}, id, 'classPeriods')
      if(modifiedPeriod.period !== classInformation.period) {
        updateMongo({period: modifiedPeriod.period}, modifiedPeriod.focusAgreementId, 'focusAgreements')
      }
      setClassInformation({...modifiedPeriod})
      const editArray = [...classInformationArray]
      var filtered = editArray.filter(entry => entry._id !== modifiedPeriod._id)
      const newArray = [...filtered, {...modifiedPeriod}]
      const sortedClassInformationArray = newArray.sort((a,b) => (a.period > b.period) ? 1 : ((b.period > a.period) ? -1 : 0))
      setClassInformationArray(sortedClassInformationArray)
      sendMessage_updateClassInformation(modifiedPeriod, modifiedPeriod.period)
    }
    
    const sendMessage_updateClassInformation = (classInformation, roomInput) => {
      socket.emit("send_message_updateClassInformation", { classInformation, room: roomInput });
    };

 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show} 
      // onHide={handleCloseLocal}
      dialogClassName="my-modal3"
    >
      <Modal.Header>
        <Modal.Title>Edit - {classPeriod.period}</Modal.Title>
        <div>
          <button className={`btn m-1 btn-light`} onClick={handleClose}>Discard Changes</button>
          <button className={`btn m-1 btn-primary`} onClick={handleSave}>Save Changes</button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <EditBody classPeriod={classPeriod} modifiedPeriod={modifiedPeriod} setModifiedPeriod={setModifiedPeriod} />
      </Modal.Body>
      <Modal.Footer>
          {/* <button className={`btn btn-light`} onClick={handleClose}>Discard Changes</button>
          <button className={`btn btn-primary`} onClick={handleSave}>Save Changes</button> */}
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
);
}


