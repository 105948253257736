import React, { useEffect, useContext, useState } from 'react'
import { SiteContext } from '../../App.tsx';

import S_BehaviorForm from './S_BehaviorForm';
import CustomToggle from './CustomToggle';
import { ErrorBoundary } from '../../other/utils/ErrorBoundary';
import NominateModal from './NominateModal';
import ClassPointsModal from './ClassPointsModal'

import Accordion from 'react-bootstrap/Accordion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrush } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowUp, faCircleArrowDown, faStar, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/free-regular-svg-icons';

export default function SBehavior({sizeClass}) {

      const {
        student,
        studentList,
        socket,
        studentDataFunctions: {
            setStudent
        }
      } = useContext(SiteContext)

      const [showModal, setShowModal] = useState(false);
      const [showPointsModal, setShowPointsModal] = useState(false);
      const [pointsForm, setPointsForm] = useState({change: 0});
      const [colors, setColors] = useState({currentColor: student.color.current, newColor: ''})
      const [modalForm, setModalForm] = useState({
        studentInitiatingName: '',
        category: '',
        reason: '',
        currentColor: '',
        newColor: ''
      })

      const [eventKeyVar, setEventKey] = useState(false)

      useEffect(() => {

        socket.on("receive_updateBehaviorEventsResponse", (data) => {
          console.log(data, 'receive_updateBehaviorEventsResponse', data.outcomeData)
          if(data.outcomeData.studentId !== student.studentId) return
  
          const editStudent = {...student}
  
            if(typeof data.outcomeData.currentColor === 'string') {
              editStudent.color.current = data.outcomeData.currentColor
            }
  
            if(typeof data.outcomeData.pendingColor === 'string') {
              editStudent.color.pending = data.outcomeData.pendingColor
            }

            if(data.outcomeData.behaviorEvent) {
              editStudent.behaviorEvents.push(data.outcomeData.behaviorEvent)
              console.log(editStudent.behaviorEvents,'editStudent.behaviorEvents')
            }
  
            if(data.outcomeData.eventType === 'nominate' && data.outcomeData.behaviorEvent.nominateType === 'shoutOut') {
              console.log(data.outcomeData.behaviorEvent, 'behaviorEvent')
              handleShowModal(data.outcomeData.currentColor, data.outcomeData.behaviorEvent)
            }
  
            setStudent(editStudent)
        })
    
        socket.on("receive_classPointsModal", (data) => {
          console.log(data, 'receive_classPointsModal', data.outcomeData)
          
          const editPointsForm = {...pointsForm}
          setPointsForm({...editPointsForm, change: data.change})
          setShowPointsModal(true)
        })

        socket.on("receive_message_updateWarnings", (data) => {
    
          if(data.dataSend.studentId !== student.studentId) return
    
          const studentEdit = {
            ...student,
            color: {
              ...student.color,
              warningsGiven:data.dataSend.currentWarnings
            }
          }
          setStudent(studentEdit)
        })
    
        return () => {
          socket.off('receive_updateBehaviorEventsResponse');
          socket.off('receive_message_updateWarnings');
          socket.off('receive_classPointsModal');
        };
    
      },[socket, student, studentList])

     const handleClose = () => setShowModal(false);
     const handleShow = () => setShowModal(true);

     const handleClosePoints = () => setShowPointsModal(false);
     const handleShowPoints = () => setShowPointsModal(true);
 
     function handleShowModal(newColor, behaviorEvent) {
        console.log(newColor, behaviorEvent)

        setModalForm({
          studentInitiatingName: behaviorEvent.studentInitiatingName,
          category: behaviorEvent.category,
          reason: behaviorEvent.reason,
          currentColor: student.color.current,
          newColor: newColor
        })

        handleShow()
     }

     const importVariables = {colors, setColors}
  return (
    <ErrorBoundary>
      <NominateModal handleShow={handleShow} handleClose={handleClose} showModal={showModal} modalForm={modalForm} />
      <ClassPointsModal handleShow={handleShowPoints} showModal={showPointsModal} handleClose={handleClosePoints} pointsForm={pointsForm} />
      <div className={sizeClass}>
        <div className="main-card card">
          <div className="card-header">
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faBrush} />
            <div className="notranslate">Community Color Actions</div>
          </div>
          <div className="card-body">
            <Accordion>
                <div
                  id="behaviorChange"
                  className="grid-menu"
                >        
                  <div className="g-0 row">
                    <CustomToggle eventKey="0" setEventKey={setEventKey} eventKeyVar={eventKeyVar}>Raise</CustomToggle>
                    <CustomToggle eventKey="1" setEventKey={setEventKey} eventKeyVar={eventKeyVar}>Lower</CustomToggle>
                    <CustomToggle eventKey="2" setEventKey={setEventKey} eventKeyVar={eventKeyVar}>Shout Out</CustomToggle>
                    <CustomToggle eventKey="3" setEventKey={setEventKey} eventKeyVar={eventKeyVar}>Call Out</CustomToggle>
                  </div>          
                </div>
                <Accordion.Collapse eventKey="0">
                    <S_BehaviorForm name={'hideElement'} reason={''} newColor={''} color={'success'} icon={faCircleArrowUp} formType={'raise'} importVariables={importVariables} />
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="1">
                    <S_BehaviorForm name={'hideElement'} reason={''} newColor={''} color={'danger'} icon={faCircleArrowDown} formType={'lower'} importVariables={importVariables} />
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="2">
                    <S_BehaviorForm name={''} reason={''} newColor={'hideElement'} color={'primary'} icon={faStar} formType={'nominate'} nominateType={'shoutOut'} importVariables={importVariables} />
                </Accordion.Collapse>   
                <Accordion.Collapse eventKey="3">
                    <S_BehaviorForm name={''} reason={''} newColor={'hideElement'} color={'warning'} icon={faStar} formType={'nominate'} nominateType={'callOut'} importVariables={importVariables} />
                </Accordion.Collapse>                
            </Accordion>
            
          </div>
        </div>
        <div className="card mt-3 mb-3">
        <ColorsCards colors={colors} eventKeyVar={eventKeyVar} />
        </div>
      </div>
    </ErrorBoundary>
  )
}

function ColorsCards({colors, eventKeyVar}) {
  const {colorMatch, student} = useContext(SiteContext)

  const reversed = [...colorMatch].reverse()
  // console.log(eventKeyVar,'eventKeyVar')

  const match = colorMatch.filter(item => item.color === student.color.current)
    const behaviorLevel = match[0].level
    // const behaviorString = match[0].string
  var raise1index = behaviorLevel === 9 ? colorMatch.findIndex(entry => entry.level === behaviorLevel) : colorMatch.findIndex(entry => entry.level === behaviorLevel + 1)
    const raise1 = colorMatch[raise1index]
  var lower1index = behaviorLevel === 1 ? colorMatch.findIndex(entry => entry.level === behaviorLevel) : colorMatch.findIndex(entry => entry.level === behaviorLevel - 1)
    const lower1 = colorMatch[lower1index]

    // console.log(raise1,'raise1', lower1,'lower1')
  // var pendingColor = eventKeyVar == 0 ? raise1.color : eventKeyVar == 1 ? lower1.color : false
  var pendingColor = false

  return (
    <div className="d-flex flex-fill m-2">
        {reversed.map(entry => {
          // var eventType = eventKeyVar === 0 ? 1 :  ? -1 : false
          var borderShow = entry.color === pendingColor ? true : false
          // console.log(pendingColor,'pendingColor',entry.color, entry.color === pendingColor)
            return <div key={entry.variant} className={`card bg-${entry.variant} flex-fill ${borderShow ? `border-3 border-black` : `border-0`} shadow-none m-1`} style={{minHeight:`15px`}} />
        })}
    </div>
  )
}

// 