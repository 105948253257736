import React, { useContext } from 'react'

import { SiteContext } from '../../App.tsx'

import Stopwatch from '../../other/utils/Stopwatch'
import calculateTime from '../../other/utils/calculateTime'

export default function Card( { title, subtitle, colorValue, cardType, sizeClass }) {

  const {classInformation, classInformationArray} = useContext(SiteContext)
  const {classStart, classEnd, classEndReadable} = calculateTime(classInformation.dayType, classInformation.classStartNormal, classInformation.classStartMinimum)

  // console.log(classInformation,'classInformation')
  var title = subtitle === 'Class End' ? classInformation.classEndReadable : ''
  
  // console.log(classInformation,'classInfgo')
    return (
        <div className={sizeClass}>
          <div className={`mb-3 widget-chart widget-chart2 text-start card card-shadow-${colorValue}`}>
            <div className="widget-content p-0 w-100">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left pe-2 fsize-1">
                    <div id={cardType} className={`widget-numbers mt-0 fsize-3 text-${colorValue}`}>
                        {(subtitle === 'Time Elapsed' || subtitle === 'Time Remaining') && classInformation.classStart ? 
                          <Stopwatch subtitle={subtitle} classStart={classInformation.classStart} classEnd={classInformation.classEnd} /> 
                          : 
                          title
                        }
                    </div>
                  </div>
                </div>
                <div className="widget-content-left fsize-1">
                  <div className="text-muted">{subtitle}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}