import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { SiteContext, baseUrl } from "../App.tsx";

export default function DeleteModal({person, handleCloseOriginal}) {
  const [show, setShow] = useState(false);

  const {  
    studentDataFunctions: {
      setRecords, deleteMongo, getRecords, setStudentList,
    },
    records
  } = useContext(SiteContext)

  async function deleteRecord(idRecords, idDaily) {
    await deleteMongo(idRecords, 'records')
    await deleteMongo(idDaily, 'dailyList')

    setStudentList(prev => {
      var filter = prev.filter(entry => entry._id !== idRecords)
      return filter
    })
  }
  // async function deleteRecord(id) {
  //   await fetch(`${baseUrl}/delete/records/${id}`, {
  //       method: "DELETE"
  //   });
    
  //   const newRecords = records.filter((el) => el._id !== id);
  //   setRecords(newRecords);
  // }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        Delete Student
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the record for {person.name}?</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" 
            onClick={() => {
                handleClose()
                deleteRecord(person._id, person.dailyListId)
                handleCloseOriginal()
            }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}