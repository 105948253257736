import React, { useContext, useState, useEffect, createContext } from 'react'

import RChart from './RChart'
import ColorSummary from './ColorSummary'
import EventCard from './EventCard'

import { SiteContext, baseUrl } from '../../App.tsx'
import { StudentContext } from '../../pages/StudentContainer'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary'

const calculateTimeFunction = require('../../other/utils/calculateTime.js')

export default function Reflection( { entry, sizeClass, title, variantsExport }) {
    
    const { 
      student, classInformation, colorMatch, classInformationArray, studentList,
      studentDataFunctions: {
        calculateTimeElapsed
      }
    } = useContext(SiteContext)

    // if(!entry) return
    // console.log(entry,'etru')
    const { levelByColor, added, subtracted, colorChanges, color, dateString } = entry

    // if(!levelByColor) return

    const match = colorMatch.filter(item => item.color === color)
        const variant = match[0].variant
        const grade = match[0].grade
        const gradeLetter = match[0].gradeLetter

        // console.log('running')
    // var index1 = studentList.findIndex(studentEntry => studentEntry.studentId === entry.studentId)
    //     if(index1 === -1) return
    var index2 = classInformationArray.findIndex(classEntry => classEntry.period === classInformation.period)
        if(index2 === -1) return

    // var activeClassInformation = loginState.admin 
    var attendanceBg = entry.attendance === 'Tardy' ? 'flag' : 'success'

    // console.log(entry,'entry')



    var start = new Date(entry.timeStamp)
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(1)
    var startMilli = start.getTime()

    var end = new Date(entry.timeStamp)
        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(59)
    var endMilli = end.getTime()

    var completeCount = 0
    student.moduleCompletions && student.moduleCompletions.map(item => {
        // console.log(item,'item')
        if(!item.completeEvent) return
        // console.log('hello', start.getTime(), end.getTime(), 'actual', item.completeEvent.date, entry.timeStamp)
        if(item.completeEvent.date > startMilli && item.completeEvent.date < endMilli) {
            completeCount++
        }
    })
    // console.log(student,'student')

    return (
        <ErrorBoundary>
        <div className="row">
            <div className={`${sizeClass}`}>
              <div className={`card-shadow-${'primary'} mb-3 widget-chart widget-chart2 text-start card`}>
                <div className="widget-content p-0 w-100">
                    <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                        <div className={`widget-content-left pe-2 fsize-1 text-${'primary'}`}>
                            <div className="widget-numbers mt-0 fsize-3">
                                {title}
                            </div>
                        </div>
                    </div>
                    <div className="widget-content-left fsize-1">
                        <ErrorBoundary>
                            <RChart levelByColor={levelByColor} added={added} subtracted={subtracted} entriesLength={entry.added.length + 1} />
                        </ErrorBoundary>
                    </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
        <ErrorBoundary>
        <div className="row">
            <div className="col-sm-3">
                <ColorSummary title={entry.color} subtitle={'Community Color'} colorValue={variant} sizeClass={'col-sm-12'} />
                <ColorSummary title={`${grade} (${gradeLetter})`} subtitle={'Grade'} colorValue={'secondary'} sizeClass={'col-sm-12'} />
                <ColorSummary title={entry.attendance} subtitle={'Attendance'} colorValue={attendanceBg} sizeClass={'col-sm-12'} />
                <ColorSummary title={completeCount} subtitle={'Activities Completed'} colorValue={'black'} sizeClass={'col-sm-12'} />
            </div>
            <EventCard title={'Smiley'} subtitle={'Check-In'} colorValue={'primary'} sizeClass={'col-sm-9'} colorChanges={colorChanges} variantsExport={variantsExport} />
        </div>
        </ErrorBoundary>
        </ErrorBoundary>
    )
}
