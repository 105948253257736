import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
// import { deleteLookFor } from "../../../types/api.tsx";
import { Row, Col, Button } from "react-bootstrap";
import { LookFor } from "../../../types/lookFors.ts"; // Import LookFor type

interface AddLookForFormProps {
  newLookFor: LookFor; // ✅ Explicit Typing
  setNewLookFor: React.Dispatch<React.SetStateAction<LookFor>>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleRubricChange: (index: number, field: string, value: string) => void;
  handleAddLookFor: () => void;
  handleDeleteLookFor: (id: string) => void;
  hexCodes: { secondary: string }[];
}

const AddLookForForm: React.FC<AddLookForFormProps> = ({
  newLookFor,
  setNewLookFor,
  handleInputChange,
  handleRubricChange,
  handleAddLookFor,
  handleDeleteLookFor,
  hexCodes,
  lookFors
}) => {

  const [show, setShow] = useState(false);

  function handleSetShow(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setShow(prev => !prev);
  }
  
  return (
    <div className="card p-3 mb-4">
      {/* Title & Delete Button in Flexbox */}
      <Row className="d-flex justify-content-between align-items-center mb-3">
        <Col>
          <h5 className="fw-bold">➕ Add New Look For</h5>
        </Col>
        <Col className="text-end">
          {lookFors.length > 0 && <Button variant="primary" className="text-white" onClick={(e) => handleSetShow(e)}>
            Show Dialog
          </Button>}
        </Col>
      </Row>
      {(show || lookFors.length === 0) && <div>
        <input
            type="text"
            name="topic"
            value={newLookFor.topic}
            onChange={handleInputChange}
            placeholder="Topic"
            className="form-control mb-2"
            style={{fontSize:"20px"}}
        />
        <textarea
            name="description"
            value={newLookFor.description}
            onChange={handleInputChange}
            placeholder="Description"
            className="form-control mb-2"
        />

        {/* Student/Teacher Facing Toggles */}
        <label>
            <input
            type="checkbox"
            className="me-2"
            checked={newLookFor.studentFacing}
            onChange={() => setNewLookFor({ ...newLookFor, studentFacing: !newLookFor.studentFacing })}
            />
            🍎 Student Facing
        </label>
        <label className="ms-3">
            <input
            type="checkbox"
            className="me-2"
            checked={!newLookFor.studentFacing}
            onChange={() => setNewLookFor({ ...newLookFor, studentFacing: !newLookFor.studentFacing })}
            />
            ✏️ Teacher Facing
        </label>

        {/* Rubric Inputs */}
        <div className="mt-4">
            {newLookFor.rubric.map((rubricItem, index) => (
            <div key={index} className="mb-3">
                <p className="mb-0">
                <strong>
                    {index + 1}. {rubricItem.category}
                </strong>
                </p>

                <ReactQuill
                value={rubricItem.content}
                onChange={(value) => handleRubricChange(index, "content", value)}
                modules={{
                    toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    ],
                }}
                style={{
                    backgroundColor: hexCodes.length > 0 ? hexCodes[index % hexCodes.length].secondary : "white",
                    borderRadius: "5px",
                }}
                />
            </div>
            ))}
        </div>

        {/* Add LookFor Button */}
        <button className="btn btn-primary mt-2" onClick={handleAddLookFor}>
            Add Look For
        </button>
      </div>}
  </div>
  );
};

export default AddLookForForm;
