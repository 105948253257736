import React, {useContext} from 'react'
import Button from 'react-bootstrap/Button';
import { SiteContext } from '../../../../App.tsx'

export default function ButtonCard({colorsReview, setColorsReview}) {
    const {colorMatch, student} = useContext(SiteContext)

    function handleColors(e) {
      e.preventDefault()
      setColorsReview(prev => {return !prev})
    }
  
    var match = colorMatch.filter(entry => entry.color === student.color.current)[0]

    return (
        <div className={`m-2`}>
        <Button 
        style={{border:"0px", backgroundColor:"rgba(100, 100, 100, 0)", padding:"0", width:"100%", height:"100%"}}
        onClick={e => handleColors(e)}
        >
        <div className={`card-shadow-primary mb-3 widget-chart widget-chart2 text-start card bg-${colorsReview ? 'secondary' : match.variant}`} style={{height:"100%", width:"100%"}}>
            <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1`}>
                {/* <div className="widget-numbers mt-0 fsize-3 text-white"> */}
                    <div style={{fontSize:"12px"}}>Community Color:</div>
                    <div className="widget-numbers mt-0 fsize-2">
                      {match.color}
                    </div>
                    <div style={{fontSize:"12px"}}>{colorsReview ? 'Hide Day in Review' : 'Show Day in Review'}</div>
                </div>
                </div>
                <div className="widget-content-left fsize-1">
                <div className="text-muted"></div>
                </div>
            </div>
            </div>
        </div>
      </Button>
      </div>
      )
}
