import React, { useContext, useEffect, useState } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ErrorBoundary } from '../../../../other/utils/ErrorBoundary';
import { SiteContext } from '../../../../App.tsx';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie, Doughnut, Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function AQGraph({title, sizeClass, filteredEntries, type}) {

  const [intervalEntries, setIntervalEntries] = useState(filteredEntries)

  useEffect(() => {
    let interval;

    interval = setInterval(async () => {
      setIntervalEntries(filteredEntries)
    }, 3000);

    return () => clearInterval(interval);

  }, [filteredEntries])

  const options = {
    rotation: -90,
    responsive: true,
    maintainAspectRatio: true,
    // aspectRatio: 1.1,
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
    //   x: {
    //     // max: 140,
    //     // min: 0,
    //     // ticks: {
    //     //   stepSize: 20,
    //     //   autoSkip: false,
    //     //   display: true,
    //     //   callback: function(value, index, ticks) {
    //     //     if(value > 100) return ''
    //     //     return `${value}%`;
    //     //   }
    //     // },
        grid: {
          display: false,
          drawBorder: false, 
        },
    //   },
    //   y: {
    //     // position: 'right',
    //     ticks: {
    //     //   color: function (context, value) {
    //     //     // console.log(context, 'context.dataset.label', context.tick.label)
    //     //     var selection = (context.tick.label === 'Present' || context.tick.label === 'Tardy' || context.tick.label === 'Absent') ? attendance : colorMatch
    //     //     var key = selection === attendance ? 'attendance' : 'color'
    //     //     var labelVal = (context.tick.label === 'Present' || context.tick.label === 'Tardy' || context.tick.label === 'Absent') ? context.tick.label : context.tick.label.split(' ')[1]
    //     //     var index = selection.findIndex(entry => entry[key] === labelVal)
    //     //       if(labelVal === 'White') return '#343a40'
    //     //       return selection[index].hex
    //     //   },
    //     //   crossAlign: "far",
    //     //   font: function (context) {
    //     //     var size =  type === 'colors' ? context.chart.height/28 : context.chart.height/10
    //     //     size = size < 12 ? 12 : size; // setting max limit to 12
    //     //     size = size > 16 ? 16 : size; 
    //     //     // console.log(size, 'size')
    //     //     return {
    //     //       weight: 'bold',
    //     //       size,
    //     //       color: 'red'
    //     //     }
    //     //   },
    //     },
    //     // grid: {
    //     //   display: false,
    //     //   drawBorder: false, 
    //     // },
    //   }
    },
    // responsive: true,
    plugins: {
      tooltip: {
        enabled: false // <-- this option disables tooltips
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        borderRadius: 10,
        color: 'white',
        borderColor: 'white',
        // borderWidth: 3,
        padding: 3,
        font: {
            size: "18px",
            weight: 'bold'
        }
        // padding: function (context) {
        //   var size = context.chart.height/24
        //   size = size < 4 ? 4 : size; // setting max limit to 12
        //   // console.log(context.chart.height, 'context.chart.height')
        //   return size
        // },
        // font: function (context) {
        //   var size = type === 'colors' ? context.chart.height/30 : context.chart.height/15

        //   size = size < 12 ? 12 : size; // setting max limit to 12
        //   size = size > 16 ? 16 : size;
        //   return {
        //     weight: 'bold',
        //     size,
        //   }
        // },
        // offset: function (context) {
        //   var size = type === 'colors' ? -context.chart.height/20 : -context.chart.height/10
        //   size = size < -2 ? -2 : size; // setting max limit to 12
        //   return size
        // },
    },
    },
  };
  

  const {colorMatch} = useContext(SiteContext)

  const border = []
  const bgColor = []
  const dataVal = []
  const labels = []

  var countAnswered = intervalEntries.filter(item => item.customPollResponse[type]).length

  var count = 0
    for(let i = 7; i >= 1; i--) {
        const match = colorMatch.filter(entry => entry.level === i)[0]
        const variants = ['#3ac47d','#fd7e14','#fd7e14','#fd7e14','#d92550']

        count++
        var filter = intervalEntries.filter(item => item.customPollResponse[type] === count)
        // console.log(filter,'filter', count)
        if(filter.length === 0) continue
        dataVal.push(filter.length)
        labels.push(`${type !== 'createAgreement'? `#` : ''}${count}`)
        bgColor.push(type === 'createAgreement' ? variants[i-3] : match.hex)
        // bgColor.push(match.hex === '#f7b924' ? '#6c757d' : match.hex)

        // if(i === 4) {
        //     var count = 0
        //     filteredReviewEntries.map(item4 => {
        //         const match2 = colorMatch.filter(item5 => item5.color === item4.color.current)[0]
        //         if(match2.level <= 4) {count++}
        //     })
        //     if(count === 0) continue
        //     dataVal.push(count)
        //     labels.push('Other')
        //     bgColor.push(match.hex === '#f7b924' ? '#6c757d' : match.hex)
        // } else {
        //     if(filter.length === 0) continue
        //     dataVal.push(filter.length)
        //     labels.push(match.color)
        //     bgColor.push(match.hex === '#f7b924' ? '#6c757d' : match.hex)
        // }
    }

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: dataVal,
        borderColor: '#ffffff',
        borderWidth: 3,
        backgroundColor: bgColor,
        datalabels: {
          align: 'start',
          anchor: 'end',
          formatter: function(value, context) {
            return [context.chart.data.labels[context.dataIndex], `${Math.round(value/countAnswered*100)}%`];
          }
        },
      },
    ],
  };

  return (
    <ErrorBoundary>
      <div className={`${sizeClass}`}>
        <div className={`mb-3 mt-2 widget-chart widget-chart2 text-start card shadow-none`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1 text-secondary`}>
                  {/* <div className="widget-numbers mt-0 fsize-3">
                      {title}
                  </div> */}
                </div>
              </div>
              <div className="widget-content-left fsize-1 text-muted">
                <Pie options={options} data={data} plugins={[ChartDataLabels]} />
              </div>
              <div className="d-flex justify-content-between">
                  <div className="mt-2 fsize-2 fw-bold">
                      {Math.round(countAnswered/intervalEntries.length*100)}%
                  </div>
                  <div className="mt-2 fsize-2 fw-bold">
                      {countAnswered}/{intervalEntries.length}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
  
}
