import React, { useState, useEffect, useContext } from 'react';

import SeatingChart from '../seatingChart/SeatingChart';
import Scroll from './Scroll';
import SearchList from './SearchList';
import Calendar from './Calendar';

import { SiteContext, baseUrl } from '../../App.tsx';
import { ErrorBoundary } from '../../other/utils/ErrorBoundary';

const modes = [
  {type: 'Grades'}, {type: 'Attendance'}, {type: 'Completion'},
]

const grades = [
  {activities: 3, score: 100, variant: 'success'},
  {activities: 2, score: 85, variant: 'success'},
  {activities: 1, score: 70, variant: 'warning'},
  {activities: 0, score: 40, variant: 'danger'},
]

const absences = [
  {absences: 5, score: 100, variant: 'danger'},
  {absences: 4, score: 85, variant: 'flag'},
  {absences: 3, score: 70, variant: 'warning'},
  {absences: 2, score: 40, variant: 'secondary'},
  {absences: 1, score: 40, variant: 'light'},
  // {absences: 0, score: 40, variant: 'light'},
]

export default function Grades({}) {

  const { 
    studentList, classInformationArray, focusAgreements,
    studentDataFunctions: {
        getMongoAllByParam, getMongoByUniqueId, calculateCompletion
    }
  } = useContext(SiteContext)

  const blankBg = []
  classInformationArray.map(entry => {
    blankBg.push({period: entry.period, bg: 'light'})
  })

  const [periodField, setPeriodField] = useState('');
  const [focusAgreementsLocal, setFocusAgreementsLocal] = useState(focusAgreements)
  const [mode, setMode] = useState(modes[0])
  const [onlyAbsent, setOnlyAbsent] = useState(false)
  const [periodBg, setPeriodBg] = useState(blankBg)
  const [month, setMonth] = useState(() => {
    const date = new Date()
      return date.getMonth() + 1
  })
  const [day, setDay] = useState(() => {
    const date = new Date()
      return date.getDate()
  })
  const [year, setYear] = useState(() => {
    const date = new Date()
      return date.getFullYear()
  })
  const [entries, setEntries] = useState([])
  const [tableCopied, setTableCopied] = useState(false)
  const [exclude7th, setExclude7th] = useState(true)
  const [gradeBreaks, setGradeBreaks] = useState(grades)

  const [firstDate, setFirstDate] = useState(false)
  const [secondDate, setSecondDate] = useState(false)

  const [completionArray, setCompletionArray] = useState([])
  const [localFocusAgreements, setLocalFocusAgreements] = useState([])

  console.log(secondDate,'secondDate')

  useEffect(() => {

    async function getDateRecords() {
      // console.log('hello1', month, day, focusAgreements, mode)
      if(!month || !day || !focusAgreements || !focusAgreements.firstDayMilli || mode.type == 'Completion') return
      // console.log('hello')
      const filtered1 = await getMongoAllByParam(`${month}slash${day}`, 'dailyRecord', 'loginDateString')
      const filtered = filtered1.filter(entry => entry.submitTimeStamp > focusAgreements.firstDayMilli)

      var filteredYear = filtered

      studentList.map(entry => {
        var index = filteredYear.findIndex(item => item.studentId === entry.studentId)
        if(index === -1) return
        filteredYear[index].period = entry.period
        filteredYear[index].attendanceName = entry.attendanceName
      })

      var filteredDropped = filtered.filter(entry => entry.period !== 'Dropped')

      var alphaRecords = filteredDropped.sort((a, b) => a.name.localeCompare(b.name))
      // console.log(alphaRecords,'alphaRecords')
      setEntries(alphaRecords)
    }

    setEntries([])
    getDateRecords()

  }, [month, day, mode, focusAgreements.firstDayMilli])

  function compare( a, b ) {
    if ( a.milli < b.milli ){
      return -1;
    }
    if ( a.milli > b.milli ){
      return 1;
    }
    return 0;
}

  async function getAttendance() {
    if(!firstDate || !secondDate || mode.type !== 'Completion' || !focusAgreements || !focusAgreements.firstDayMilli) return
    // console.log(firstDate,'firstDate', secondDate)

    var firstMilli = firstDate.getTime()
    var lastMilli = secondDate.getTime()

    const attendanceDates = []
    for (let i = 0; i < 100; i++) {
      var localDate = new Date(firstMilli + 86400000*i)
      var localMilli = localDate.getTime()
      // console.log(localMilli,'localMilli', lastMilli, localMilli - lastMilli)
      if(localMilli > lastMilli) break
      var monthLocal = localDate.getMonth() + 1
      var dateLocal = localDate.getDate()
      attendanceDates.push({month: monthLocal, day: dateLocal, milli: localMilli, date: `${monthLocal}/${dateLocal}`})
    }

    const dateArrays = []
    const promises = attendanceDates.map(async (entry) => {
      const current = await getMongoAllByParam(`${entry.month}slash${entry.day}`, 'dailyRecord', 'loginDateString')
      const filtered = current.filter(entry => entry.submitTimeStamp > focusAgreements.firstDayMilli)
      dateArrays.push({data: filtered, milli: entry.milli, date: entry.date})
    })
    await Promise.all(promises)

    dateArrays.sort( compare );

    setCompletionArray(dateArrays)
    // return dateArrays
  }

  useEffect(() => {
    getAttendance()
  }, [firstDate, secondDate, mode, focusAgreements.firstDayMilli])

  useEffect(() => {
    async function getFocusAgreements() {
      if(mode.type !== 'Completion') return

      const arrays = []
      const promises = classInformationArray.map(async(entry) => {
        const currentEntry = await getMongoByUniqueId(entry.focusAgreementId, 'focusAgreements')
        arrays.push({period: entry.period, focusAgreements: currentEntry})
      })
      await Promise.all(promises)

      setLocalFocusAgreements(arrays)
    }
    getFocusAgreements()

  }, [firstDate, secondDate, mode])

  useEffect(() => {

    async function updatePeriod() {
      // const editPeriodBg = [...periodBg]
      // editPeriodBg.map(entry => {
      //   entry.bg = 'light'
      // })
      // var index = editPeriodBg.findIndex(entry => entry.period === periodField)
      //   if(index === -1) return
      //   editPeriodBg[index].bg = 'primary'
      //   setPeriodBg(editPeriodBg)
      
      var match = classInformationArray.filter(entry => entry.period === periodField)[0]
      if(!match || !match.focusAgreementId) return
      const update = await getMongoByUniqueId(match.focusAgreementId, 'focusAgreements')
      setFocusAgreementsLocal(update)
    }

    updatePeriod()

  }, [periodField])

  // console.log(studentList,'stueList')
  const entriesArray = mode.type === 'Completion' ? studentList : entries
  const filteredPersons = entriesArray.filter(
    person => {
      if(!person.period) return
      if(periodField.length === 0) return true
      return (
        person.period.toLowerCase().includes(periodField.toLowerCase())
      );
    }
  );

  // console.log(entries,'entries', periodField, 'periodField', filteredPersons)

  var alphaPersons = mode.type === 'Attendance' ? filteredPersons.sort((a, b) => a.attendanceName && a.attendanceName.localeCompare(b.attendanceName)) : filteredPersons.sort((a, b) => a.name.localeCompare(b.name))

  var excludedPersons = alphaPersons.filter(entry => exclude7th ? entry.period !== 'Period 7' : true)
  // alphaPersons = alphaPersons.filter((value, index, self) =>
  // index === self.findIndex((t) => (
  //   t.name === value.name
  // ))
  // )
  const handleChangePeriod = (e, newPeriod) => {
    console.log(newPeriod,'newPeriod')
    setPeriodField(newPeriod);
    setTableCopied(false)
    if(newPeriod === 'Period 7') {
      setExclude7th(false)
    }
  }

  function handleMode(e, mode) {
    e.preventDefault()
    setMode(mode)
    setTableCopied(false)
  }

  function handleAbsent(e) {
    e.preventDefault()
    setOnlyAbsent(prev => {return !prev})
  }

  function copyTable(e, tableId) {
    e.preventDefault()
    const elTable = document.querySelector(tableId);
    
    let range, sel;
    
    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
    
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      console.log(range,'range', sel, 'sel')
    
      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }
    
      document.execCommand('copy');
    }
    
    sel.removeAllRanges();
    setTableCopied(true)
    // console.log('Element Copied! Paste it in a file')
}

function handleExclude7th(e) {
  e.preventDefault()
  setExclude7th(prev => {return !prev})
}

function handleChangeScore(e, item) {
  e.preventDefault()
  const editBreaks = [...gradeBreaks]
  var index = editBreaks.findIndex(entry => entry.activities === item.activities)
  editBreaks[index].score = e.target.value
  console.log(editBreaks[index],'editBreaks[index]')
  setGradeBreaks(editBreaks)
  setTableCopied(false)
}

function findChecklist(person, gradesHeader) {

  var count = 0
  
  if(person.moduleCompletions && typeof person.moduleCompletions === 'object') {
    person.moduleCompletions.map(entry => {
      if(firstDate && secondDate && entry.completeEvent && (entry.completeEvent.date > firstDate.getTime()) && (entry.completeEvent.date < secondDate.getTime())) {
        count++
      }
    })
  }
  if(person.firstName === 'Maria') {
    // console.log(count,'count', gradeBreaks.length)
  }
  
  var index = gradeBreaks.findIndex(entry => entry.activities === count)
  // console.log(gradeBreaks[index],'index', count, person.firstName)
  // var grade = count > 0 ? 100 : 40 
  var grade = count > gradeBreaks.length - 1 ? gradeBreaks[0].score : gradeBreaks[index].score
  // gradeBreaks[gradeBreaks.length - 1]
  // count > 2 ? 100 : count > 1 ? 85 : count > 0 ? 70 : 40 
  return gradesHeader ? count : grade
}

  const date = new Date()
    date.setMonth(month - 1)
    date.setDate(day)

  const weekDayNum = date.getDay()
    const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday', 'Saturday']
    const weekDay = weekDays[weekDayNum]

  const exportFunctions = {month, day, mode, onlyAbsent, periodField, focusAgreementsLocal, firstDate, secondDate, gradeBreaks, completionArray, localFocusAgreements, findChecklist, setTableCopied}
  const calendarExport = {firstDate, setFirstDate, secondDate, setSecondDate}

  var count = 0
  var count2 = 0
  
  return (
    <ErrorBoundary>
    <section className="m-3">
      <div className="card p-4">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mb-0">
          {modes.map(entry => {
            count++
            // if(entry.type !== mode.type) return
            return <div key={entry.type + count} role="button" className={`badge bg-${entry.type === mode.type ? 'secondary' : 'light'} me-2`} onClick={e => handleMode(e, entry)}>{entry.type}</div>
          })}
          {/* {modes.map(entry => {
            if(entry.type === mode.type) return
            return <div key={entry.type} className="badge bg-light me-1" onClick={e => handleMode(e, entry)}>{entry.type}</div>
          })} */}
          {/* {completion ? <div className="badge bg-secondary"></div>grades ? "Grades" : "Attendance"} */}
        </h3>
        <div className="badge bg-secondary fsize-1 p-2">Results: {excludedPersons.length}</div>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3">
      <div className="d-inline me-3">
        {classInformationArray.map(entry => {
          count2++
          if(entry.archived) return
          return (
            <div key={entry._id + count2}
              className={`me-2 badge ${entry.period === periodField ? 'bg-primary border border-primary border-2' : 'bg-light border border-light border-2'}`}
              onClick={e => handleChangePeriod(e,`${entry.period}`)} role="button"
           >{entry.informationStrings ? entry.informationStrings.periodAbbreviation : entry.period}</div>
           
          )
        })}
        <div className={`me-2 badge ${periodField.length === 0 ? 'bg-primary border-primary' : 'bg-light border-light'} border border-2`} role="button" onClick={e => handleChangePeriod(e,``)}>All</div>
        <div role="button" className={`me-2 badge ${!exclude7th ? 'bg-primary border-primary' : 'bg-light border-light'} border border-2`} onClick={e => handleExclude7th(e)}>{exclude7th ? '7th Excluded' : '7th Included'}</div>
        <span className="ms-2">
          {(mode.type === 'Completion' || entries.length > 0) && <div className={`me-2 badge ${tableCopied ? 'bg-primary' : 'bg-light text-primary'} border border-primary border-2`} 
          onClick={e => copyTable(e, 'table')} role="button">
            {tableCopied ? 'Table Copied' : 'Copy Table'}
          </div>}
          {mode.type === 'Attendance' && <div 
            className={`mb-2 me-2 badge bg-info border border-info border-2`}
            onClick={e => handleAbsent(e)}
          >{onlyAbsent ? 'Show All Student' : 'Show Only Absent'}</div>}
        </span>
      </div>
      {mode.type !== 'Completion' && <div className="d-inline d-flex justify-content-between align-items-center bg-light ps-2 pe-2 rounded" style={{minWidth:"450px"}}>
        <div>
        <span className="ms-2 me-1">Month:</span>
        <input 
          type="number" name="month" min="1" max="12" className="rounded border border-light border-1 me-2 mt-2 mb-2"
          value={month}
          onChange={e => {
            setMonth(e.target.value)
            setTableCopied(false)
          }}
          // value={}
        />
        <span className="me-1">Day:</span>
        <input 
          type="number" name="day" min="1" max="31" className="rounded border border-light border-1 mt-2 mb-2"
          value={day}
          onChange={e => {
            setDay(e.target.value)
            setTableCopied(false)
          }}
          // value={}
        />
        <span className="me-1 ms-1">Year:</span>
        <input 
          type="number" name="day" min="1" max="31" className="rounded border border-light border-1 mt-2 mb-2"
          value={year}
          style={{minWidth:"60px"}}
          onChange={e => {
            setYear(e.target.value)
            setTableCopied(false)
          }}
          // value={}
        />
        </div>
        <span className="ms-3 me-2 fw-bold">{weekDay} {month}/{day}</span>
      </div>}        
      </div>
      </div>
      <div className="d-flex">
        <div className="flex-grow-1">
          <div className="card mt-3 p-3">
          {/* <Scroll> */}
              {entries.length === 0 && mode.type !== 'Completion' && <Warning/>}
              {(entries.length > 0 || mode.type === 'Completion') && <SearchList filteredPersons={excludedPersons} exportFunctions={exportFunctions} />}
          {/* </Scroll> */}
          </div>
        </div>
        {mode.type === 'Completion' && <div>
        <div className="ms-3">
          <div className="card mt-3 p-3">
            <span className="m-1 fw-bold">Choose Date Range:</span>
            <Calendar calendarExport={calendarExport} />
          </div>
        </div>
        <div className="ms-3">
          <div className="card mt-3 p-3">
            <span className="m-1 fw-bold">Choose Grade Values:</span>
            {gradeBreaks.map(entry => {
              var total = 0
              var size = 0
              // console.log(entries,'entry')
              excludedPersons.map(item => {
                // if(!item.period.includes(periodField) || item.module.length === 0) return
                // if(item.period === 'Period 7') return
                // if(item.period === 'Dropped') return
                // console.log(item.module, item.name)
                var count = findChecklist(item, true)
                size++
                if(count === entry.activities) total++
              })
              var percent = Math.round(total/size*100)
              return (
              <div key={entry.activities} className="d-flex justify-content-between align-items-center m-1">
                <span>Activities: <div className={`badge bg-${entry.variant} ms-1`}>{entry.activities}</div>
                <span className="ms-2">{percent}%</span>
                <span className="ms-2">{total}</span>
                </span>
                <input type="number" className={`d-inline`} min={0} max={100} 
                value={entry.score} onChange={e => handleChangeScore(e, entry)}/>
              </div>
              )
            })}
            
          </div>
        </div>
        <div className="ms-3">
          <div className="card mt-3 p-3">
            <span className="m-1 fw-bold">Border Key:</span>
            {absences.map(entry => {
              return (
              <div key={entry.absences} className="d-flex justify-content-between align-items-center m-1">
                <span>Absences: <div className={`badge bg-${entry.variant} ms-1`}>{entry.absences}</div></span>
                {/* <input type="number" className={`d-inline`} min={0} max={100} 
                value={entry.score} onChange={e => handleChangeScore(e, entry)}/> */}
              </div>
              )
            })}
          </div>
        </div>
        </div>}
      </div>
    </section>
    </ErrorBoundary>
  );
}

function Warning() {
  return (
    <div>
      <div>Grades data loading...</div>
      <div></div>
      <div>(This may take a few moments)</div>
    </div>
  )
}

// {entries.length > 0 && mode.type === 'Completion' && firstDate && secondDate && <div className="flex-grow-1 ms-3">
// <div className="card mt-3 p-3">
// {/* <Scroll> */}
//     <GradesInformation filteredPersons={excludedPersons} exportFunctions={exportFunctions} />
// {/* </Scroll> */}
// </div>
// </div>}