import React, { useState } from 'react';
import Staff from './staff/Staff.tsx';
import Cycles from './schoolLookFors/Cycles.tsx';
import { baseUrl } from "../../App.tsx";
import axios from "axios";
import { School } from "../../types/school"; // Adjust the path to match where `school.ts` is located

interface SchoolCardProps {
    school: School;
    onUpdate: (updatedSchool: School) => void;
}

export const SchoolCard: React.FC<SchoolCardProps> = ({ school, onUpdate }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [allowEdits, setAllowEdits] = useState(false);
    
    // Local state for editable fields
    const [editedSchool, setEditedSchool] = useState<School>({
        ...school, // Spread the full School object
        metadata: {
          ...school.metadata, // Ensure metadata is properly initialized
        },
      });
    
    const admin = school.staff.filter(entry => 
        entry.role.some(role => ["Principal", "Administrator", "AP"].includes(role))
    );

    const handleSaveEdits = async () => {
        try {
            await updateSchool(school._id, editedSchool);
            setAllowEdits(false);
        } catch (error) {
            console.error("Error saving edits:", error);
        }
    };

    const updateSchool = async (schoolId, updatedData) => {
        try {
            const response = await axios.put(`${baseUrl}/api/schools/${schoolId}`, updatedData);
            return response.data;
        } catch (error) {
            console.error("Error updating school:", error);
        }
    };

    // Cancel edits and reset fields
    const handleCancelEdits = () => {
        setEditedSchool({
            metadata: {
                schoolNickname: school.metadata.schoolNickname,
                address: school.metadata.address,
                districtName: school.metadata.districtName,
                emoji: school.metadata.emoji
            }
        });
        setAllowEdits(false);
    };

    const metadataFields = [
        { key: "schoolNickname", label: "Nickname" },
        { key: "address", label: "Address" },
        { key: "districtName", label: "District" },
        { key: "emoji", label: "Emoji" }
    ];

    if (!school || !school.metadata) {
        return <p>Loading school data...</p>; // Fallback in case data isn't available
    }
    
    return (
        <div className="col-md-12">
            <div className="card mb-3 p-2">
                <div className="card-body">
                    <h2 className="">{school.metadata.emoji} {school.schoolNumber}</h2>
                    

                    {allowEdits ? (
                        metadataFields.map(({ key, label }) => (
                            <div key={key} className="mb-2">
                                <strong>{label}: </strong>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={editedSchool.metadata[key as keyof typeof editedSchool.metadata]}
                                    onChange={(e) => setEditedSchool({ 
                                        ...editedSchool, 
                                        metadata: { 
                                            ...editedSchool.metadata, 
                                            [key]: e.target.value 
                                        } 
                                    })}
                                />
                            </div>
                        ))
                    ) : (
                        metadataFields.map(({ key, label }) => (
                            <p key={key} className="card-text">
                                <strong>{label}:</strong> {school.metadata[key as keyof typeof school.metadata]}
                            </p>
                        ))
                    )}
  
                    <p className="card-text"><strong># Teachers:</strong> {school.staff.length - admin.length}</p>
                    <p className="card-text"><strong># Admin:</strong> {admin.length}</p>

                    {/* Edit Controls */}
                    <div className='d-flex justify-content-between'> 
                        <button className="btn btn-sm btn-outline-secondary" onClick={() => setShowDetails(!showDetails)}>
                            {showDetails ? "Hide Details" : "View Details"}
                        </button>
                        <div>
                            {!allowEdits && (
                                <button className="btn btn-sm btn-outline-secondary" onClick={() => setAllowEdits(true)}>
                                    Edit
                                </button>
                            )}
                            {allowEdits && (
                                <>
                                    <button className="btn btn-sm btn-success me-2" onClick={handleSaveEdits}>
                                        Save Edits
                                    </button>
                                    <button className="btn btn-sm btn-danger" onClick={handleCancelEdits}>
                                        Discard Edits
                                    </button>
                                </>
                            )}
                        </div>
                    </div>

                    {showDetails && (
                        <>
                            <Staff schoolId={school._id} staffImport={school.staff || []}/>
                            <Cycles schoolId={school._id} cycles={school.cycles || []} staff={school.staff || []} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}



// import React, { useState } from 'react';
// import Staff from './staff/Staff.tsx'
// import Cycles from './schoolLookFors/Cycles.tsx';

// export default function SchoolCard({ school }) {
//     const [showDetails, setShowDetails] = useState(false);
//     const [allowEdits, setAllowEdits] = useState(false);

//     const admin = school.staff.filter(entry => 
//         entry.role.some(role => ["Principal", "Administrator", "AP"].includes(role))
//     );
    
//     return (
//         <div className="col-md-12">
//             <div className="card mb-3">
//                 <div className="card-body">
//                     <h2 className="">{school.schoolNumber}</h2>
//                     <p className="card-text"><strong>Nickname:</strong> {school.metadata.schoolNickname}</p>
//                     <p className="card-text"><strong>Address:</strong> {school.metadata.address}</p>
//                     <p className="card-text"><strong>District:</strong> {school.metadata.districtName}</p>
//                     <p className="card-text"><strong>Emoji:</strong> {school.metadata.emoji}</p>
//                     <p className="card-text"><strong># Teachers:</strong> {school.staff.length - admin.length}</p>
//                     <p className="card-text"><strong># Admin:</strong> {admin.length}</p>
//                     <div className='d-flex justifty-content-between'> 
//                         <button className="btn btn-sm btn-outline-secondary" onClick={() => setShowDetails(!showDetails)}>
//                             {showDetails ? "Hide Details" : "View Details"}
//                         </button>
//                         <div>
//                             {!allowEdits && <button className="btn btn-sm btn-outline-secondary" onClick={() => setAllowEdits(!allowEdits)}>
//                                 Allow Edits
//                             </button>}
//                             {allowEdits && <button className="btn btn-sm btn-outline-success" onClick={() => handleSaveEdits()}>
//                                 Save Edits
//                             </button>}
//                             {allowEdits && <button className="btn btn-sm btn-outline-danger" onClick={() => handleCancelEdits()}>
//                                 Cancel Edits
//                             </button>}
//                         </div>

//                     </div>
//                     {showDetails && 
//                     <>
//                         <Staff schoolId={school._id} staffImport={school.staff || []}/>
//                         <Cycles schoolId={school._id} cycles={school.cycles || []} staff={school.staff || []} />
//                     </>
//                     }
//                 </div>
//             </div>
//         </div>
//     );
// }
