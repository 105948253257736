import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from 'uuid'

import Modal from 'react-bootstrap/Modal';
import SeatingChart from './SeatingChart';

import { SiteContext } from "../../App.tsx";
import { AdminContext } from '../../pages/AdminContainer';

import { ErrorBoundary } from "../../other/utils/ErrorBoundary";
 
export default function SeatModal({handleShow, handleClose, show, type, payload}) {

    const {mode, title, modalMode, handleActive} = payload

    const {
        studentList, initialLoad, classInformation, socket,
        studentDataFunctions: {
          getMongoList, updateMongo, setStudentList
        }
    } = useContext(SiteContext)

    const {handleAttendance} = useContext(AdminContext)

    const [modeVal, setModeVal] = useState(payload && payload.mode ? payload.mode : 'attendance')
    const [newAttendance, setNewAttendance] = useState([])
    const [showComplete, setShowComplete] = useState(true)

    useEffect(() => {
        setModeVal(mode ? mode : 'attendance')
    }, [classInformation.period, payload])

    // console.log(modeVal,'modeVal')

    async function handleCloseLocal() {
      handleClose(type)
        
      if(modeVal !== 'attendance') return
      const entries = await getMongoList('dailyList')

      newAttendance.map(entry => {
        var index = entries.findIndex(item => item.studentId === entry.studentId)
        if(index === -1) return
        updateMongo({attendance: entry.attendance, color: {...entry.color}}, entries[index]._id, 'dailyList')
      })
  
      setNewAttendance([])
    }

    function handleEdit(e, newMode, modalMode) {
        e.preventDefault()
        setModeVal(newMode)
        if(newMode === 'modal' || newMode === 'checkouts') {
            e.target.value = modalMode
            handleActive(e)
        }
        // const editModeVal = modeVal
        // if(editModeVal === '') {setModeVal('editSeating')} else {setModeVal('')}
    }

    function handleResetGroups(e) {
        e.preventDefault()

        const editStudentList = [...studentList]
        editStudentList.map(entry => {
            if(entry.period !== classInformation.period) return
            var index = editStudentList.findIndex(item => item.studentId === entry.studentId)
            editStudentList[index].module = 'No Group'

            updateMongo({module: 'No Group'}, entry._id, 'records')
            updateMongo({module: 'No Group'}, entry.dailyListId, 'dailyList')
        })

        setStudentList(editStudentList)
    }

    function ModeButton({variant, text, mode, modalMode}) {
      if(modeVal === mode) return
      var textColor = text === 'Chips' ? 'text-white' : ''

        return (
        <button
            className={`btn btn-${variant} ${textColor}`}
            onClick={e => handleEdit(e, mode, modalMode)}
            // style={{float:"right"}}
        >
            {text}
        </button>
        )
    }
  
 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show} 
      onHide={handleCloseLocal}
      dialogClassName="my-modal"
    >
      <Modal.Header>
        <Modal.Title>{payload && title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="">
        <SeatingChart
          modeVal={modeVal}
          modalMode={modalMode}
          setNewAttendance={setNewAttendance}
          showComplete={showComplete}
        />
      </div>
      </Modal.Body>
      <Modal.Footer>
        {(modeVal === 'modal' || modeVal === 'checkouts') && <CheckoutsCompletion modeVal={modeVal} showComplete={showComplete} setShowComplete={setShowComplete} ModeButton={ModeButton} />}
        {modeVal !== 'modal' && modeVal !== 'checkouts' && 
          <>
            {initialLoad && modeVal === 'editSeating' &&
              <button onClick={e => handleResetGroups(e)} className="btn btn-secondary me-auto">
                Reset Groups
              </button>}
            {initialLoad && <ModeButton variant={'primary'} text={'Checkouts'} mode={'checkouts'} />}
            {initialLoad && <ModeButton variant={'purple'} text={'Chips'} mode={'chips'} />}
            {initialLoad && <ModeButton variant={'warning'} text={'Seating'} mode={'editSeating'} />}

            <ModeButton variant={'primary'} text={'Attendance'} mode={'attendance'} />
            {modeVal === 'attendance' && <button
              className="btn btn-success ms-4"
              onClick={e => {
                handleAttendance(classInformation, e)
                handleCloseLocal()
              }}
            >
              Submit
            </button>}
          </>}
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
);
}

function CheckoutsCompletion({modeVal, showComplete, setShowComplete, ModeButton}) {

    const {updateStudentListWithDaily} = useContext(AdminContext)
  
    return (
      <>
      {modeVal === 'modal' ?
            <>
            {/* <ModeButton variant={'primary'} text={'Checkouts'} mode={'checkouts'} /> */}
            <button
                style={{float:"right", margin:"5px"}}
                className={`btn-icon btn-wide btn-outline-2x btn btn-${showComplete ? 'info' : 'primary'} btn-sm manualButton`}
                onClick={e => {
                  e.preventDefault()
                  setShowComplete(prev => {return !prev})
                }}
            >{showComplete ? 'Hide Complete' : 'Show Complete'}</button>
            <button
                style={{float:"right", margin:"5px"}}
                className="btn-icon btn-wide btn-outline-2x btn btn-secondary btn-sm manualButton"
                onClick={updateStudentListWithDaily}
            >Update</button>
            </>
            :
            <></>
            // <ModeButton variant={'primary'} text={'Modal'} mode={'modal'} />
      }
      </>
    )
  }