import React, { useContext, useState, useEffect, createContext } from 'react'

import { SiteContext, baseUrl } from '../../App.tsx'
import { v4 as uuidv4 } from 'uuid'

import MessageEntry from './MessageEntry'

export default function MessageBox( { messages }) {
  
  const { 
    classInformation, 
    studentDataFunctions: {
    }
  } = useContext(SiteContext)

    var index = messages.findIndex(entry => entry.period === classInformation.period)
    var sortedMessages = messages[index].messages.sort( compare );
      if(sortedMessages.length > 15) {
        for(let i = 14; i < sortedMessages.length; i++) {
          if(sortedMessages[i].viewed) {
            sortedMessages.length = i + 1
            return
          }
        }
      }

  function compare( a, b ) {
    if ( a.timeStamp > b.timeStamp){
      return -1;
    }
    if ( a.timeStamp < b.timeStamp ){
      return 1;
    }
    return 0;
  }

    return (
      <div style={{overflowY: 'scroll', height:'', margin:"10px"}}>
        <div className="table-responsive" style={{paddingLeft:"15px",paddingRight:"15px", height: "25em"}}>
          <table className="align-middle text-truncate mb-0 table table-borderless table-hover">
            <thead>
              <tr>
                <th className="text-center">Name</th>
                <th className="">Message</th>
              </tr>
            </thead>
            <tbody>
                {sortedMessages.map(entry => {
                    return (
                        <MessageEntry entry={entry} key={uuidv4()}/>
                    )      
                })}
            </tbody>
          </table>
        </div>
		  </div>	
    )
}
