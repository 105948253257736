import { useState, useEffect } from "react";
import axios from "axios";

function AudioUploader({ baseUrl }) {
    const [audio, setAudio] = useState(null);
    const [transcription, setTranscription] = useState("");
    const [isCopying, setIsCopying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let interval;
        if (loading) {
            setTimer(0); // Reset timer when loading starts
            interval = setInterval(() => {
                setTimer(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [loading]);

    const handleUpload = async () => {
        if (!audio) return alert("Please select an audio file.");

        setLoading(true);

        const formData = new FormData();
        formData.append("audio", audio);

        try {
            const response = await axios.post(baseUrl + "/transcribe", formData, {
                headers: { "Accept": "application/json" }
            });

            setTranscription(response.data.transcription);
        } catch (error) {
            console.error("Error:", error);
            alert("Transcription failed.");
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(transcription)
            .then(() => {
                setIsCopying(true);
                setTimeout(() => setIsCopying(false), 10000);
            })
            .catch(err => console.error("Copy failed", err));
    };

    return (
        <div style={{ maxWidth: "80%", margin: "auto", textAlign: "center" }}>
            <h1>Upload an Audio File</h1>
            <input 
                type="file" 
                accept="audio/*" 
                onChange={(e) => setAudio(e.target.files[0])} 
                style={{ marginBottom: "20px" }}
            />
            {audio && !loading && (
                <button 
                    onClick={handleUpload} 
                    style={{
                        display: "block",
                        margin: "auto",
                        padding: "15px 25px",
                        fontSize: "18px",
                        backgroundColor: "#007bff",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                >
                    Transcribe
                </button>
            )}
            {loading && (
                <button 
                    style={{
                        display: "block",
                        margin: "auto",
                        padding: "15px 25px",
                        fontSize: "18px",
                        backgroundColor: "grey",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                >
                    Loading... {timer}s
                </button>
            )}
            {transcription && (
                <div style={{ marginTop: "40px" }}>
                    <h2>Transcript</h2>
                    <button 
                        onClick={handleCopy} 
                        disabled={isCopying}
                        style={{
                            marginTop: "0px",
                            marginBottom: "10px",
                            padding: "10px 20px",
                            fontSize: "16px",
                            backgroundColor: isCopying ? "#6c757d" : "#28a745",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: isCopying ? "not-allowed" : "pointer",
                            transition: "background-color 0.3s ease"
                        }}
                    >
                        {isCopying ? "Copied!" : "Copy Transcript"}
                    </button>
                    <textarea
                        value={transcription}
                        onChange={(e) => setTranscription(e.target.value)}
                        rows="10"
                        style={{ 
                            width: "80%", 
                            height: "400px", 
                            fontSize: "16px", 
                            padding: "10px", 
                            display: "block", 
                            margin: "auto",
                            resize: "vertical"
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default AudioUploader;

// import { useState } from "react";
// import axios from "axios";

// function AudioUploader({baseUrl}) {
//     const [audio, setAudio] = useState(null);
//     const [transcription, setTranscription] = useState("");
//     const [isCopying, setIsCopying] = useState(false);
//     const [loading, setLoading] = useState(false);

//     const handleUpload = async () => {
//         if (!audio) return alert("Please select an audio file.");

//         setLoading(true); // Show loading state

//         const formData = new FormData();
//         formData.append("audio", audio);

//         try {
//             const response = await axios.post(baseUrl + "/transcribe", formData, {
//                 headers: { "Accept": "application/json" }
//             });

//             setTranscription(response.data.transcription);
//         } catch (error) {
//             console.error("Error:", error);
//             alert("Transcription failed.");
//         } finally {
//             setLoading(false); // Hide loading state
//         }
//     };

//     const handleCopy = () => {
//         navigator.clipboard.writeText(transcription)
//             .then(() => {
//                 setIsCopying(true); // Change button to grey
//                 setTimeout(() => setIsCopying(false), 10000); // Reset after 10 seconds
//             })
//             .catch(err => console.error("Copy failed", err));
//     };

//     return (
//         <div style={{ maxWidth: "80%", margin: "auto", textAlign: "center" }}>
//             <h1>Upload an Audio File</h1>
//             <input 
//                 type="file" 
//                 accept="audio/*" 
//                 onChange={(e) => setAudio(e.target.files[0])} 
//                 style={{ marginBottom: "20px" }}
//             />
//             {audio && !loading && (
//                 <button 
//                     onClick={handleUpload} 
//                     style={{
//                         display: "block",
//                         margin: "auto",
//                         padding: "15px 25px",
//                         fontSize: "18px",
//                         backgroundColor: "#007bff",
//                         color: "white",
//                         border: "none",
//                         borderRadius: "5px",
//                         cursor: "pointer"
//                     }}
//                 >
//                     Transcribe
//                 </button>
//             )}
//             {loading &&
//                 <button 
//                     style={{
//                         display: "block",
//                         margin: "auto",
//                         padding: "15px 25px",
//                         fontSize: "18px",
//                         backgroundColor: "grey",
//                         color: "white",
//                         border: "none",
//                         borderRadius: "5px",
//                         cursor: "pointer"
//                     }}
//                 >
//                     Loading...
//                 </button>}
//             {transcription && (
//                 <div style={{ marginTop: "40px" }}>
//                     <h2>Transcript</h2>
//                     <button 
//                         onClick={handleCopy} 
//                         disabled={isCopying}
//                         style={{
//                             marginTop: "0px",
//                             marginBottom: "10px",
//                             padding: "10px 20px",
//                             fontSize: "16px",
//                             backgroundColor: isCopying ? "#6c757d" : "#28a745",
//                             color: "white",
//                             border: "none",
//                             borderRadius: "5px",
//                             cursor: isCopying ? "not-allowed" : "pointer",
//                             transition: "background-color 0.3s ease"
//                         }}
//                     >
//                         {isCopying ? "Copied!" : "Copy Transcript"}
//                     </button>
//                     <textarea
//                         value={transcription}
//                         onChange={(e) => setTranscription(e.target.value)}
//                         rows="10" // Increased to expand viewable area
//                         style={{ 
//                             width: "80%", 
//                             height: "400px", // Explicitly setting height to make the box visually larger
//                             fontSize: "16px", 
//                             padding: "10px", 
//                             display: "block", 
//                             margin: "auto",
//                             resize: "vertical" // Allows manual resizing
//                         }}
//                     />
//                 </div>
//             )}
//         </div>
//     );
// }

// export default AudioUploader;
