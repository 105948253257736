import React, { useContext, useState, useEffect } from 'react'
import { SiteContext, baseUrl } from '../../App.tsx';
import { useCompare } from '../../other/utils/compare';

export function useMongo({dailyRecord, setDailyRecord, listUpdate,  
    setDailyList, addDaily, setAddDaily, setAddChips, newAddChips, newAddDaily}) {

    const { studentListLoad, studentList, classInformation, loginState, initialLoad, classInformationArray, socket, room, profileElements,
        studentDataFunctions: {
            setStudentList, createMongo, getMongoList, getMongoById, updateMongo, deleteMongo, setUpdateFocusAgreements,
            getMongoAllById, setClassInformation, setRoom, getMongoByUniqueId, handleResetOtherLists, checkCalendar
        } 
    } = useContext(SiteContext)

    useEffect(() => {

        if(!studentListLoad) return

        getMongoList('dailyList').then((dailyListMongo) => {
            setDailyList(dailyListMongo)
            updateDailyList(dailyListMongo)
            updateStudentList(dailyListMongo)
        })
        
    }, [listUpdate, studentListLoad])
    
    function updateDailyList(dailyListMongo) {
        if(!dailyListMongo) return
        studentList.map(entry => {
            var filter = dailyListMongo.filter(item => item.studentId === entry.studentId)
            if(filter.length === 0) {
                const cleanStudent = dailyListCleanStudent(entry)
                dailyListMongo.push(cleanStudent)
            }
        })
        
        setDailyList(dailyListMongo)
        updateStudentList(dailyListMongo)
    }
    
    function updateStudentList(dailyListMongo) {
        const editStudentList = [...studentList]
        const newStudentList = []
        
        if(!dailyListMongo) return
        editStudentList.map(entry => {
            var index = dailyListMongo.findIndex(item => item.studentId === entry.studentId)
    
            if(!entry.loggedIn && dailyListMongo[index].loggedIn) {

                entry = {...entry,
                    loggedIn: true,
                    checkIn: {...dailyListMongo[index].checkIn},
                    loginTimeStamp: dailyListMongo[index].loginTimeStamp,
                    attendance: "Present"
                }
                newStudentList.push(entry)
            } else {
                entry = { ...dailyListMongo[index], ...entry}
                newStudentList.push(entry)
            }
        })
        
        setStudentList(newStudentList)
    }

    async function updateStudentListWithDaily() {
        const dailyList = await getMongoList('dailyList')
        const editStudentList = [...studentList]

        dailyList.map(entry => {
            var index = editStudentList.findIndex(item => item.studentId === entry.studentId)
            if(index === -1) return
            if(entry._id) {delete entry._id}
            if(entry.id) {delete entry.id}
            editStudentList[index] = {...editStudentList[index], ...entry}
        })

        setStudentList(editStudentList)
        console.log('updateStudentListWithDaily COMPLETE----')
    }

    // function updateDailyListMongo(studentId, updateArray, behaviorEvent) {

    //     getMongoById(studentId, 'dailyList').then((recordMongo) => {
    //         const id = recordMongo._id
    //         delete recordMongo._id

    //         var newArray = {...recordMongo}
    //         for (var key in updateArray) {
    //             if (updateArray.hasOwnProperty(key) && typeof updateArray[key] === 'object') {
    //                 // console.log(updateArray[key],'updateArray[key] before')
    //                 if(key !== 'addBehaviorEvent') {
    //                     newArray[key] = {...newArray[key], ...updateArray[key]}
    //                 } else {
    //                     newArray.behaviorEvents.push(updateArray[key])
    //                 }
    //             } else {
    //                 newArray[key] = updateArray[key]
    //             }
    //         }

    //         // console.log(newArray,'newArray',updateArray)
    //         updateMongo(newArray, id, 'dailyList')
    //     })
    // }

    async function handleAddDailyRecord(dateStringImport, allClasses) {

        const entries = await getMongoList('dailyList')
        // const records = await getMongoList('dailyRecordRoster')
        const hallPassEvents = await getMongoList('hallPassEvents')
        
        const promises = entries.map(async(entry) => {
            
            if(!allClasses && entry.period !== classInformation.period) return
            const entryDate = new Date(entry.loginTimeStamp ? entry.loginTimeStamp : Date.now())
                var date = entryDate.getDate()
                var month = entryDate.getMonth() + 1
                var dateString = dateStringImport ? dateStringImport : `${month}/${date}`
            
            const studentObj = {
                name: entry.name,
                studentId: entry.studentId,
                attendance: entry.attendance,
                color: entry.color.current,
                loginTimeStamp: entry.loginTimeStamp,
                submitTimeStamp: Date.now(),
                loggedIn: entry.loggedIn,
                checkIn: {...entry.checkIn},
                loginDateString: dateString,
                behaviorEvents: entry.behaviorEvents,
                attendanceTimeStamp: entry.attendanceTimeStamp
            }

            await createMongo(studentObj, 'dailyRecord') 

            const matchingHallPasses = hallPassEvents.filter(item => item.studentId === entry.studentId)

            var index = studentList.findIndex(studentItem => studentItem.studentId === entry.studentId)
            if(index === -1) return
            const dailyRecordEntry = await getMongoByUniqueId(studentList[index].dailyRecordRosterId, 'dailyRecordRoster')
            // console.log(dailyRecordEntry,'dailyRecordEntry')
            if(!dailyRecordEntry) {
                await createMongo({
                    name: entry.name,
                    studentId: entry.studentId,
                    days: [{...studentObj}],
                    hallPasses: [...matchingHallPasses]
                }, 'dailyRecordRoster') 
                await updateMongo({days: [{...studentObj}], hallPasses: [...matchingHallPasses]}, entry._id, 'dailyList')
                entry = {...entry, days: [{...studentObj}], hallPasses: [...matchingHallPasses]}
            } else {
                const newArray = {
                    // days: dailyRecordEntry.days ? [...dailyRecordEntry.days] : [{...studentObj}], 
                    days: dailyRecordEntry.days ? [{...studentObj}, ...dailyRecordEntry.days] : [{...studentObj}], 
                    hallPasses: dailyRecordEntry.hallPasses ? [...dailyRecordEntry.hallPasses, ...matchingHallPasses] : [...matchingHallPasses],
                }

                const arrayDailyList = {...newArray}
                    arrayDailyList.days = arrayDailyList.days.slice(0, 10)
                // console.log(arrayDailyList, 'arrayDailyList----', entry.name)
                await updateMongo({...newArray}, dailyRecordEntry._id, 'dailyRecordRoster')
                await updateMongo({...arrayDailyList}, entry._id, 'dailyList')
                entry = {...entry, ...arrayDailyList}
            }

            return entry
        })

        const entriesAfter = await Promise.all(promises)

        return entriesAfter
    }

    async function createCleanStudent(entry, newEntry) {
        const cleanStudent = dailyListCleanStudent(entry)
        console.log(cleanStudent,'entry createClean')
        var cleanStudentNoID = {...cleanStudent}
        delete cleanStudentNoID._id
        if(newEntry) {
            await createMongo(cleanStudent, 'dailyList')
        } else {
            await updateMongo({...cleanStudentNoID}, cleanStudent.dailyListId, 'dailyList')
        }
        
        // await createMongo(cleanStudent, 'dailyList')
    }

    async function clearDailyList(importDailyList) {
        
        const dailyListMongo = importDailyList ? importDailyList : await getMongoList('dailyList')
        const editStudentList = [...studentList]

        // console.log(dailyListMongo,'dailyListMongo')

        // dailyListMongo.map(async (entry) => {
        //     var index = editStudentList.findIndex(item => item.studentId === entry.studentId)
        //     editStudentList[index] = {...editStudentList[index], ...entry}
        //     // console.log(index,'iondex')
        //     await deleteMongo(entry._id, 'dailyList')
        // })

        // const activeList = importDailyList ? importDailyList : editStudentList

        const promises = dailyListMongo.map( async(entry) => {
            await createCleanStudent(entry)
        })
        await Promise.all(promises)

    }

    function handlePeriodSelect(period, initialEvent) {

        var index = classInformationArray.findIndex(entry => entry.period === period)
        if(index === -1) return

        var newClassInformation = classInformationArray[index]
        newClassInformation.dayType = checkCalendar()
        if(initialEvent) {
            socket.emit("send_adminStudentDashboard", {data: classInformationArray[index], room: 'admin'});
        }
        setClassInformation(classInformationArray[index])
        setRoom(classInformationArray[index].period)   
    }

    function dailyListCleanStudent(entry) {

        var retainGoal = (entry.goalSetting && entry.goalSetting.goal) ? {...entry.goalSetting.goal} : {}
        var retainProgress = (entry.dayReview && entry.dayReview.module.progress) ? {...entry.dayReview.module} : {}
    
        // console.log(entry,'entry inside clean')
        const cleanEntry = {
            ...entry,
            name: entry.name,
            studentId: entry.studentId,
            period: entry.period,
            email: entry.email,
            firstName: entry.firstName,
            attendance: "Present",
            checkIn: {color:'', complete: false, emoji:'', note:''},
            color: {current: "Green", pending: "", warningsGiven: 0},
            loggedIn: false,
            loginTimeStamp: "",
            chips: entry.chips && entry.chips.balance ? {...entry.chips} : {balance: 0, total: 0},
            chipsToday: {added: 0, subtracted: 0},
            behaviorEvents: [],
            module: entry.module ? entry.module : '',
            groupNumber: entry.groupNumber ? entry.groupNumber : 1,
            readingZoneDate: entry.readingZoneDate ? entry.readingZoneDate : false,
            readingZoneMin: entry.readingZoneMin ? entry.readingZoneMin : false,
            days: entry.days ? entry.days : [],
            hallPasses: entry.hallPasses ? [...entry.hallPasses] : [],
            dayReview: {
                display: 1,
                timeStamp: false,
                rating: { self: false, group: false, class: false},
                questions: {success: false, improve: false},
                module: { progress: false, ...retainProgress }
            },
            goalSetting: {
                display: 1,
                timeStamp: (entry.goalSetting && entry.goalSetting.timeStamp) ? entry.goalSetting.timeStamp : false,
                goal: { goal: false, matters: false, thing1: false, thing2: false, thing3: false, accomplish: false, ...retainGoal },
                review: {review: false}
            },

            modalOverride: false,
            customOverride: false,
            customPollResponse: {attendanceQuestion: false, dailyLinks: false, createAgreement: false},
            customTextResponse: {attendanceQuestion: false, dailyLinks: false, createAgreement: false},
            auctionChips: entry.auctionChips ? entry.auctionChips : 200,
            pending: entry.pending ? entry.pending : false,
            phoneWarning: 0,
            auctionBalance: entry.auctionBalance ? entry.auctionBalance : 200,
            assignments: entry.assignments ? entry.assignments : []
        }

        profileElements.map(element => {
            cleanEntry[element.property] = entry[element.property] ? entry[element.property] : []
        })

        return cleanEntry
    }

    return { 
        handleAddDailyRecord, clearDailyList, createCleanStudent, updateStudentListWithDaily, handlePeriodSelect
    }
}









