import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../App.tsx'

// import './d_behavior.css'

export default function ListingEntry({editCart,item}) {

    const { 
        colorMatch,
        studentList,
        studentDataFunctions: {
        }
    } = useContext(SiteContext)

    return (
        <tr>
            <td className="">
                <div className="box">
                    {item.quantity > 0 && <div className={`mb-2 me-2 badge bg-primary`}>{item.quantity}</div>}
                    <span className="text">{item.item}</span>
                </div>
            </td>
            <td className="text-center">
                <div className="box">
                    <span className="text">{item.price}€</span>
                </div>
            </td>
            <td className="text-center">
                <div role="group" className="btn-group-sm btn-group">
                    <button 
                      value={-1}
                      onClick={(e) => editCart(e, -1, item.item, item.price)} 
                      className={`btn-shadow btn btn-light`}>
                    -
                    </button>
                    <button 
                      value={1}
                      onClick={(e) => editCart(e, 1, item.item, item.price)} 
                      className={`btn-shadow btn btn-secondary`}>
                    +
                    </button>

                </div>
            </td>
        </tr>
    )
}
