import React, { useContext, useState, useEffect, createContext } from 'react'

import { SiteContext, baseUrl } from '../../App.tsx'
import AdminContainer, { AdminContext } from '../../pages/AdminContainer'

import D_BehaviorForm from './D_BehaviorForm'
import D_BehaviorEntry from './D_BehaviorEntry'
import D_OutEntry from './D_OutEntry'
import AgreementsModal from './focus/AgreementsModal'
import CustomSelect from './focus/CustomSelect'
import ColorTitle from '../../other/utils/ColorTitle'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpLong, faCircleUp, faCircleDown } from '@fortawesome/free-solid-svg-icons';

export default function D_Behavior( { behaviorEvents, handleUpdateBehaviorEvents, type }) {

    const { 
      classInformation, studentList, focusAgreements, room, socket,
      studentDataFunctions: {
        setBehaviorEvents, updateFocus, updateFocusSelection, updateMongo,
      }
    } = useContext(SiteContext)

    const {handleUpdateList, updateBEvents, setUpdateBEvents, deleteBehaviorEvent, filterEvents} = useContext(AdminContext)

    var activelyPending = type === 'raise' ? filterEvents('raise') : filterEvents('lower')

    var count = 0

    useEffect(() => {
      behaviorEvents.map(entry => {
        // console.log(entry,'entry delete events')
        var studentId = entry.type === 'nominate' ? entry.studentNominatedId : entry.studentInitiatingId
        var index = studentList.findIndex(item => item.studentId === studentId)
        if(index === -1) return

        // console.log(entry,'entry')
        // delete if current student is on black or the pending color matches their current color
        if((studentList[index].color.current === 'Black' && entry.levelChange > 0) || (entry.type !== 'nominate' && studentList[index].color.current === entry.pendingColor)) {
          // console.log('deleting', studentList[index])
          deleteBehaviorEvent(entry._id, type, studentList[index])
          if(type !== 'nominate') {
            const editStudent = {...studentList[index], color: {...studentList[index].color, pending: ''}}
            delete editStudent['_id']
            updateMongo(editStudent, studentList[index].dailyListId, 'dailyList')
            socket.emit("send_updateSingleStudent", {student: editStudent, room: studentList[index].period});
          }
        } 
      })
    }, [behaviorEvents])

    var focusOptions = []

    if(!focusAgreements || !focusAgreements.categories) return
    focusAgreements.categories.map(entry => {
      focusOptions.push({
        value: entry.category, isSelected: entry.adminFocus, label: entry.category, entry
      })
    })

    var badgeBg = activelyPending.length === 0 ? 'light' : 
                  activelyPending.length < 6 ? 'info' :
                  activelyPending.length < 11 ? 'primary' : 'purple text-white'

    if(activelyPending.length === 0) return

    var variant = type === 'raise' ? 'black' : 'danger'
                  
    return (
        <div className="col-12">  
          <div className="main-card mb-3 card">
            <div className="card-header h-auto mt-2">
                <h5 className="card-title">
                  <ColorTitle icon={type === 'raise' ? faCircleUp : faCircleDown} text={type === 'raise' ? 'Raise' : 'Lower'} variant={variant} />
                </h5>
            </div>
            {activelyPending.length === 0 && <div className="card-body">
              <p>No Active Events</p>
            </div>}
            {activelyPending.length > 0 && <div className="m-2 table-responsive">
              <table className="align-middle text-truncate mb-0 table table-borderless">
                <thead>
                  <tr>
                    <th className="text-center">Name</th>                    
                    <th className="text-center">Colors</th>
                    <th className="text-center">Reason</th>
                    <th className="text-center"></th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  { activelyPending.map(event => {
                      count++
                      if(count > 6) {return}
                      if(event.type !== 'nominate') {
                      return (
                        <D_BehaviorEntry 
                        key={event.id} 
                        event={event} 
                        variant={variant}
                        handleUpdateBehaviorEvents={handleUpdateBehaviorEvents}
                        />
                      )} else {
                      return(
                        <D_OutEntry 
                        key={event.id} 
                        event={event} 
                        typeMode={type}
                        handleUpdateBehaviorEvents={handleUpdateBehaviorEvents}
                      />
                      )}
                  })}
                </tbody>
              </table>
            </div>}
            {count > 6 && <div className="d-block text-center card-footer" style={{minHeight:"30px"}}>
              {count > 6 ? `+${parseInt(count) - 6} More` : ' '}
            </div>}
          </div>
        </div>
    )
}


function compare( a, b ) {
  if ( a.timeStamp > b.timeStamp){
    return -1;
  }
  if ( a.timeStamp < b.timeStamp ){
    return 1;
  }
  return 0;
}