import React, {useContext, useState, useEffect} from 'react'
import { SiteContext } from '../../App.tsx'

import Item from './Item';
import SeatGrid from './SeatGrid';
import '../../modules/checklist/checklist.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLeft, faCheck, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

export default function Preview({goalSetting, modules}) {

    const {student, focusAgreements, socket, room, loginState,
        studentDataFunctions: {setFocusAgreements, updateMongo, moduleFind}
    } = useContext(SiteContext)

    const [moduleCurrent, setModuleCurrent] = useState(focusAgreements.auctionModule ? focusAgreements.auctionModule : student.module ? student.module : '')

    // console.log(focusAgreements.auctionModule,'focusAgreements.auctionModule', student)
    useEffect(() => {
        if(!focusAgreements.auctionModule) return
        setModuleCurrent(focusAgreements.auctionModule)
    }, [focusAgreements.auctionModule])

    function updateModule(e, title) {
        e.preventDefault()
        setModuleCurrent(title ? title : e.target.value)
    }
    
    if(!modules && (!focusAgreements || !focusAgreements.modules)) return
    if(modules && modules.length === 0) return
    var modulesActive = modules ? modules : focusAgreements.modules

    function compare( a, b ) {
        if ( a.index < b.index ){
          return -1;
        }
        if ( a.index > b.index ){
          return 1;
        }
        return 0;
    }

    const sorted = modulesActive.sort( compare );

    var checklistExists = true
    var index = sorted.findIndex(item => item.title === moduleCurrent)
    if(index === -1) {
        console.log('return 1')
        checklistExists = false
        // return
    } else {
        var index1 = sorted[index].checklists.findIndex(item => item.groupNumber === 1)
        if(index1 === -1) {
            console.log('return 2')
            checklistExists = false
            // return
        }
    }

    var activeModule = index === -1 ? false : sorted[index]
    var checklist = checklistExists ? sorted[index].checklists[index1].checklist : false

    var fullTitle = moduleFind(moduleCurrent, 'title', 'fullTitle') ? toTitleCase( moduleFind(moduleCurrent, 'title', 'fullTitle') ) : ''
    console.log(fullTitle,'full')

    function toTitleCase(str) {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
    }

    function handleClick(e, title) {
        e.preventDefault()
        console.log(title,'title')

        updateModule(e, title)
    }

    var title = goalSetting ? 'Module Preview' : 'Module Previews'

    return (
    <div className="row">
        <div className="col-8">
            <div className="col-12 card">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <h1 className={`text-secondary mb-0`}>{!loginState.loggedIn ? 'Module Activities' : title}</h1>
                            {/* <div className="badge bg-secondary ms-3 d-flex flex-wrap align-items-center" style={{maxWidth:"120px"}}>
                                <div className="d-block">Auction Balance</div>
                                <div className="d-block">${student.auctionChips}</div>
                            </div> */}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items center mt-2">
                        {/* {moduleCurrent.length > 0 && <div className="">{fullTitle}</div>} */}
                        {loginState.loggedIn && <div className="badge bg-secondary d-flex align-items-center">Auction Money: ${student.auctionBalance ? student.auctionBalance : 200}</div>}
                    </div>
                    
                </div>
            </div>
            <ChecklistCard checklist={checklist} moduleCurrent={moduleCurrent} activeModule={activeModule} />
            {/* {checklist.map(entry => {
                if(entry.type === 'Design Brief' && entry.designBriefTask === 'Overview') {
                    count++
                    designBriefCount = count
                } else if(entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview') {
                    taskCount++
                } else {
                    count++
                }
                return <Item key={entry.itemId} entry={entry} count={count} handleFunctions={handleFunctions} activeModule={activeModule} />
            })} */}
        </div>
        <InfoCards moduleCurrent={moduleCurrent} updateModule={updateModule} activeModule={activeModule} goalSetting={goalSetting} modulesActive={modulesActive} />
    </div>
    )
}

function ChecklistCard({checklist, activeModule}) {

    const [showTasks, setShowTasks] = useState(false)

    var count = 0
    var designBriefCount = false
    var taskCount = 0

    var complete = 0
    var pending = 0

    const handleFunctions = {
        showTasks, setShowTasks
    }

    return (
        <>
        {checklist && checklist.map(entry => {
            if(entry.type === 'Design Brief' && entry.designBriefTask === 'Overview') {
                count++
                designBriefCount = count
            } else if(entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview') {
                taskCount++
            } else {
                count++
            }
            return <Item key={entry.itemId} entry={entry} count={count} handleFunctions={handleFunctions} activeModule={activeModule} />
        })}
        </>
    )
}

function InfoCards({moduleCurrent, updateModule, activeModule, goalSetting, modulesActive}) {

    const {student, focusAgreements, socket, room, loginState,
        studentDataFunctions: {setFocusAgreements, updateMongo}
    } = useContext(SiteContext)

    function handleClick(e, title) {
        e.preventDefault()
        console.log(title,'title')

        updateModule(e, title)
    }

    // console.log(activeModule, 'activeModule')
    var count = 0
    return (
    <div className="col-4">
        {!goalSetting && <div className="card mb-3">
          <div className="card-body d-flex flex-wrap">
            <div className="mb-2">Select Module:</div>
            <select value={moduleCurrent} 
            className="badge text-white bg-secondary" style={{fontSize:"16px"}} onChange={(e) => updateModule(e)} role="button"> 
                <option key={''} value={'--Select Module--'}></option>
                {modulesActive.map(entry => {
                    return <option key={entry.title} value={entry.title}>{entry.emoji ? `${entry.emoji} ` : ``}{entry.fullTitle}</option>
                })}
            </select>
          </div>
        </div>}
        <div className="">
        </div>
        {activeModule && (activeModule.imageURL || activeModule.description) && <div className="card">
          <div className="card-body">
            {activeModule.description && <div className="mb-3">{activeModule.description}</div>}
            {activeModule.imageURL && 
            <img src={activeModule.imageURL} 
            style={{objectFit:"contain", height:"100%", width: "100%", margin:"auto", verticalAlign:"middle", display:"block"}}
            />}
          </div>
        </div>}
        {activeModule && <div className="card mt-3">
          <div className="card-body">
            <div className="mb-2">Careers:</div>
            <div className="d-flex flex-wrap">
                {activeModule.jobs && activeModule.jobs.map(jobEntry => {
                    count++
                    return <div key={count} className="badge bg-secondary m-1 ms-0 text-wrap">{jobEntry.label}</div>
                })}
            </div>
          </div>
        </div>}
        {loginState.loggedIn && <div className="card p-2 mt-3">
            <div className="d-flex justify-content-start">
                <div>🚪 Back Door</div>
            </div>
            <div className="d-flex justify-content-start">
                <div>🖥 Promethean Board</div>
            </div>
            <SeatGrid modules={modulesActive} isResizable={false} isDraggable={false} handleClick={handleClick} layout={focusAgreements && focusAgreements.layout} rowHeight={1} moduleCurrent={moduleCurrent} />
            <div className="d-flex justify-content-end">
                <div>🚪 Front Door</div>
            </div>
        </div>}
    </div>
    )
}