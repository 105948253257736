import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../../App.tsx'
import { AdminContext } from '../../../pages/AdminContainer'
import { ErrorBoundary } from '../../../other/utils/ErrorBoundary'

import CompleteCard from './ACompleteCard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faRotate, faRightLeft, faRightFromBracket, faX } from '@fortawesome/free-solid-svg-icons';

// import './d_behavior.css'

export default function DashEntry({item, variant, name, studentId, handleAction, exportFunctions}) {

    const { 
        classInformation, socket, room, focusAgreements, student, classInformationArray, studentList, periodBg,
        studentDataFunctions: {moduleFind, updateMongo, handleUpdateCheckout, setFocusAgreements, getMongoByUniqueId, setStudentList}
    } = useContext(SiteContext)

    const {
        checkoutItems, setCheckoutItems
    } = useContext(AdminContext)

    const {show, setShow, handleClose2, handleShow, activeModal, setActiveModal} = exportFunctions

    var timeStamp = new Date(item.timestamp)
        var dayEntry = timeStamp.getDate().toString()
        var monthEntry = (timeStamp.getMonth() + 1).toString()
        var weekDayEntry = timeStamp.getDay().toString()
            const weekDays = ['Sunday','Mon','Tue','Wed','Thu','Fri', 'Sat']
            const weekDay = weekDays[weekDayEntry]

    var typeBg = item.type === 'Test' ? 'bg-black' :
                 item.type === 'Activity' ? 'bg-purple' :
                 item.type === 'Design Brief' ? 'bg-primary' : 'bg-secondary'

    var today = new Date()
    var timestampDate = new Date(item.timestamp)
    // console.log(today.getDate(), 'today.getDate()', timestampDate.getDate(), 'timestampDate.getDate()')
    var dateString = (today.getMonth() === timestampDate.getMonth() && today.getDate() === timestampDate.getDate()) ? 
        `Today ${prettyDate(item.timestamp, false)}` : prettyDate(item.timestamp, true)

    function handleShowLocal(e) {
        e.preventDefault()
        setActiveModal(item)
        handleShow()
    }
    // console.log(item,'item', item.name)
    return (
        <ErrorBoundary>
                <tr>
                    <td className="" style={{verticalAlign:"top"}}>
                        {dateString.includes('Today') ? 
                        <div className={`badge bg-${typeBg.split('-')[1]}`}>{dateString}</div>
                        :
                        <div className="badge bg-secondary">{dateString}</div>}
                        {/* <div className="text">{item.module}</div> */}
                        {/* <button onClick={e => updateFocusAg(e)}>Update Focus Agr</button> */}
                    </td>
                    <td className="" style={{verticalAlign:"top"}}>
                        {/* <div className="badge bg-danger">
                            {item.title}
                        </div> */}
                        <div className={`badge bg-${item.completeAdmin || item.returned ? 'light text-info' : 'info'}`} onClick={e => handleShowLocal(e)} role="button">{item.title}</div> 
                        {/* <CompleteCard activeAssignment={item} name={item.name} variant={variant} studentId={item.studentId} context={'dash'} handleAction={handleAction} /> */}
                    </td>
                    <td className="" style={{verticalAlign:"top"}}>
                        <span className="text">
                            {item.name}
                            {/* {item.item.substring(0, 30)} */}
                        </span>      
                    </td>
                    <td className="" style={{verticalAlign:"top"}}
                    // style={{width:"90px"}}
                    >
                        <div role="group" className="btn-group-sm btn-group">
                          {item.completeAdmin || item.returned ? <button disabled
                            className={`btn-shadow btn btn-light text-${variant}`} style={{fontSize:"10px"}}>
                            {item.returned ? <FontAwesomeIcon icon={faX} /> :
                            <FontAwesomeIcon icon={faCheck} />}
                          </button>
                          : <><button 
                            onClick={e => handleAction(e, true, item)} 
                            className={`btn-shadow btn btn-${variant} text-white`}>
                            <FontAwesomeIcon icon={faCheck} />
                          </button>
                          <button 
                            onClick={e => handleAction(e, 'returned', item)} 
                            className={`btn-shadow btn btn-secondary text-white`}>
                            <FontAwesomeIcon icon={faX} />
                          </button></>}
                        </div>
                        {/* <div role="group" className="btn-group-sm btn-group"
                        // style={{minWidth:"100px", maxWidth:"100px"}}
                        >
                            <button 
                            value="approved"
                            onClick={e => updateChecklist(e, item.itemId, true)} 
                            className={`btn-shadow btn btn-${variant} text-white`}>
                            <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button 
                            value="approved"
                            onClick={e => updateChecklist(e, item.itemId, true, true)} 
                            className={`btn-shadow btn btn-light text-purple`}>
                            <FontAwesomeIcon icon={faRotate} />
                            </button>
                            <button 
                            value="approved"
                            onClick={e => {
                                // handleUpdateCheckout(item, {}, true)
                                updateChecklist(e, item.itemId, false)
                            }} 
                            className={`btn-shadow btn btn-secondary text-white`}>
                            <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>} */}
                        {/* {!item.complete && <div role="group" className="btn-group-sm btn-group">
                            <FontAwesomeIcon style={{ marginRight: 5}} icon={faRightLeft} />
                        </div>}
                        {!item.checkedOut && !item.complete && <div role="group" className="btn-group-sm btn-group">
                            <FontAwesomeIcon style={{ marginRight: 5}} icon={faX} />
                        </div>} */}
                    </td>
                </tr>
                </ErrorBoundary>
    )

}




function prettyDate(dateInput, fullString) {
    var date = new Date(dateInput)
    // console.log(date, 'inside---')
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var hours = ((date.getHours() + 11) % 12 + 1);
    var minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    if(fullString) {
        return `${months[date.getMonth()]} ${date.getDate()} ${hours}:${minutes}`
    } else {
        return `${hours}:${minutes}`
    }

}

