import React, {useState, useContext, useEffect} from 'react'
import { SiteContext } from '../../../../App.tsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid'
import useKeyPress from '../../../../other/utils/useKeyPress';

export default function LinkBody({customOption, setVariables}) {

//   const {modifiedPeriod, setModifiedPeriod, editHeading, setEditHeading, arrays} = dataFunctions

  const { classInformation, focusAgreements, socket, room, studentDataFunctions: { updateMongo, getMongoList, } } 
  = useContext(SiteContext)
  
  function handleChange(e, type, category, categoryEntry, entryType) {
    e.preventDefault()

    const modifyHeading = {...customOption}
    if(type === 'categoryEntry') {
        var entryIndex = modifyHeading.bullets.findIndex(entry => entry.id === categoryEntry)
        console.log()
        modifyHeading.bullets[entryIndex] = {...modifyHeading.bullets[entryIndex], [entryType]: e.target.value}
    } else if(type === 'category') {
        modifyHeading.text = e.target.value
    }
    setVariables(modifyHeading)
    socket.emit("send_modalDisplay", { data: {[customOption.type]: {...modifyHeading}}, room });
  }

  function handleCreate() {
    var modifyHeading = {...customOption}
    modifyHeading.bullets.push(newCategoryEntry())

    setVariables(modifyHeading)
    socket.emit("send_modalDisplay", { data: {[customOption.type]: {...modifyHeading}}, room });
  }

  function handleDelete(e, entry){
    var modifyHeading = {...customOption}
    modifyHeading.bullets = modifyHeading.bullets.filter(item => item.id !== entry.id)
    setVariables(modifyHeading)
    socket.emit("send_modalDisplay", { data: {[customOption.type]: {...modifyHeading}}, room });
  }

  const onKeyPress = (event) => {
    // console.log(`key pressed: ${event.key}`);
    if(event.shiftKey === true && event.ctrlKey === true) {
        if(event.key === 'N') handleCreate()
    }
  };

  useKeyPress(['N'], onKeyPress);

    var editFunctions = { handleChange, handleDelete, handleCreate }
    var indexCount = -1

    return <Category key={indexCount} item={customOption} editFunctions={editFunctions} />
}

function Category({item, editFunctions, indexCount, length}) {
    
    const {handleChange, handleDelete, handleCreate} = editFunctions
    const {classInformation} = useContext(SiteContext)

      return (
        <div key={item.id} className="">
            {item.bullets && item.bullets.map(element => {
            return <CategoryEntry key={element.id} element={element} item={item} editFunctions={editFunctions} />
            })}
            <div className="d-flex justify-content-end">
              <FontAwesomeIcon className="m-2" style={{}} icon={faPlus} role="button" onClick={e => handleCreate(e, 'categoryEntry', item.category)} /> 
            </div>
        </div>
      )
  }

  function CategoryEntry({element, item, editFunctions}) {
    const {handleChange, handleDelete} = editFunctions

    console.log(element,'element')
    return (
      <>    
      <div className="d-flex align-items-center">
        <div className="d-inline-block flex-fill">
          <div className="m-1 d-flex align-items-center">
            <span className="me-2">Text</span>
            <input key={element.id} type="text" className="form-control d-inline"
            value={element.entry} onChange={(e) => handleChange(e, 'categoryEntry', item.category, element.id, 'entry')} />
          </div>
        </div>
        <div className="d-inline-block flex-fill">
          <div className="m-1 d-flex align-items-center">
            {element.link ? <a href={element.link} target="_blank">
              <span className="me-2">Link</span>
            </a> : <span className="me-2">Link</span>}
            <input key={element.id+'link'}  type="text" className="form-control bg-light d-inline" 
            value={element.link} onChange={(e) => handleChange(e, 'categoryEntry', item.category, element.id, 'link')} />
          </div>
        </div>
        <FontAwesomeIcon key={element.id + 'fa'} className="m-2 mt-1 mb-2" role="button" onClick={e => handleDelete(e, element)} icon={faX} />
      </div>
      </>
    )
  }

  function newCategoryEntry () {
      return {
        id: uuidv4(),
        entry: '',
        link: ''
      }
  }
