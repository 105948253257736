import React, { useState, useContext, useEffect } from 'react'

import { SiteContext } from '../../../App.tsx';
import { useNavigate } from "react-router-dom";
import { StudentContext } from '../../../pages/StudentContainer';

import ColorToolip from './ColorTooltip';
import ReviewModal from '../../../weekReview/ReviewModal';

import { ErrorBoundary } from '../../../other/utils/ErrorBoundary';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';

export default function ColorCard({sizeClass}) {
  const { 
    student, focusAgreements, colorMatch, socket, studentList, classInformation, classInformationArray, currentEntry,
    studentDataFunctions: {
      setStudent, createReviewEntry, handleDateString
    }
  } = useContext(SiteContext)

  const {} = useContext(StudentContext)

  const [showReviewModal, setShowReviewModal] = useState(false);

  const navigate = useNavigate();

  const {
    color: {
      current: currentColor,
      warningsGiven,
      pending: pendingColor
    },
    studentId
  } = student

  useEffect(() => {

    socket.on("receive_message_updateWarnings", (data) => {

      if(data.dataSend.studentId !== student.studentId) {return}

      const studentEdit = {
        ...student,
        color: {
          ...student.color,
          warningsGiven: data.dataSend.currentWarnings
        }
      }
      setStudent(studentEdit)
    })

    return () => {
      socket.off('receive_message_updateWarnings');
    };

  },[socket, student, studentList])

  const match = colorMatch.filter(item => item.color === currentColor)
  const variant = match ? match[0].variant : ''

  function handleClick(e, location) {
    e.preventDefault()
    navigate(`/${location}`);
  }

  function handleReviewModal(e, type) {
    e.preventDefault()

    if(type === 'open') {
      if(student.attendance === 'Absent') return
      handleReviewShow()
      // setCurrentEntry(entry)
    } else if(type === 'close') {
        handleReviewClose()
    }

  }

  const handleReviewClose = () => setShowReviewModal(false);
  const handleReviewShow = () => setShowReviewModal(true);

  var variantNew = ''
  
  if(pendingColor.length !== 0) {
    const matchNew = colorMatch.filter(item => item.color === pendingColor)
    variantNew = matchNew[0].variant
  }

  var warnings = [faCircleCheck, faCircleCheck, faCircleCheck]

  for(let i = 0; i < warningsGiven ; i++) {
    warnings[i] = faCircleXmark
  }
  
  var textColor = currentColor !== 'White' ? 'white' : 'secondary'
  
  // console.log(student.auctionBalance, 'auctionBalance',parseInt(student.auctionBalance))
    return (
      <ErrorBoundary>
      <ReviewModal showReviewModal={showReviewModal} handleReviewModal={handleReviewModal} handleReviewClose={handleReviewClose} currentEntry={currentEntry} today={true} />
      <div className={sizeClass}>
        <div className={`card-shadow-secondary mb-3 widget-chart widget-chart2 text-start card bg-${variant}`} style={{maxHeight:"125px"}}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="d-flex justify-content-between align-items-start widget-content-wrapper">
                <div className="widget-content-left pe-2 fsize-1 w-100">
                  <div className={`widget-numbers mt-0 fsize-3 text-${textColor}`}>
                    <div className="notranslate">
                      <div>
                      <span className="me-3">{currentColor}</span>
                      <FontAwesomeIcon color={textColor} icon={warnings[2]} style={{ paddingRight: 2 }} />
                      <FontAwesomeIcon color={textColor} icon={warnings[1]} style={{ paddingRight: 2 }} />
                      <FontAwesomeIcon color={textColor} icon={warnings[0]} style={{ paddingRight: 2 }} />
                      </div>
                    </div>
                  </div> 
                     
                </div>
                <div className="badge rounded-pill bg-light text-secondary m-1 text-center" style={{minWidth:"60px"}} onClick={e => handleReviewModal(e, 'open')} role="button">Review</div>  
              </div>
              <div className="widget-content-left fsize-1">
                <div className={`text-${textColor} d-flex justify-content-between align-items-center`}>
                  <div>
                    <div className="notranslate">Community Color</div>
                  </div>
                  <div className="d-flex align-items-center flex-wrap justify-content-end">
                      {pendingColor.length !== 0 ? 
                        <ColorToolip variantNew={variantNew} pendingColor={pendingColor} />
                        :
                        <>
                        <div className={`badge rounded-pill bg-light text-secondary m-1`}>
                          <ClassPointsTooltip text={`$${typeof student.auctionBalance !== 'undefined' ? parseInt(student.auctionBalance) : 200}`} tooltip={`$${typeof student.auctionBalance !== 'undefined' ? parseInt(student.auctionBalance) : 200} Auction Money`} />
                        </div>
                        {/* <div className={`badge rounded-pill bg-light text-secondary m-1`} role="button" onClick={e => handleClick(e, 'store')}>
                          <ClassPointsTooltip text={`${student.chips.balance}€`} tooltip={`${student.chips.balance}€ Class Cash`} />
                        </div> */}
                        <div className={`badge rounded-pill bg-light m-1 text-secondary`}>
                          {focusAgreements.classPoints && <ClassPointsTooltip text={`${focusAgreements.classPoints} CP`} tooltip={`${focusAgreements.classPoints} Class Points`} />}
                        </div>
                        </>
                      }
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </ErrorBoundary>
    )
}

function ClassPointsTooltip({text, tooltip }) {
  return (
    <OverlayTrigger
      overlay={<Tooltip style={{fontSize:"10px"}}>{tooltip}</Tooltip>}
      placement="top"
      delayShow={300}
      delayHide={150}
    >
      <span>{text}</span>
    </OverlayTrigger>
  );
}