import React, {useContext, useState} from 'react'

import { SiteContext } from '../../App.tsx'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary'
import { useNavigate } from "react-router-dom";

export default function InProgress() {

    const {focusAgreements, student} = useContext(SiteContext)
    const navigate = useNavigate();

    if(!focusAgreements || !focusAgreements.modules) return

    var index = focusAgreements.modules.findIndex(item => item.title === student.module)
        if(index === -1) return
    var index1 = focusAgreements.modules[index].checklists.findIndex(item => item.groupNumber === student.groupNumber)
        if(index1 === -1) return

    var checklist = focusAgreements.modules[index].checklists[index1].checklist

    var inProgressItem = false
    for(let i = 0; i < checklist.length; i++) {
        var item = checklist[i]
  
        if(!item.inProgress || item.complete) continue
        inProgressItem = item
        break
    }

    function handleClick() {
        navigate("/checklistS");
    }

    // console.log(inProgressItem, 'inProgressItem')

    if(!inProgressItem) return

    var typeBg = inProgressItem.type === 'Test' ? 'black' :
                 inProgressItem.type === 'Activity' ? 'purple' :
                 inProgressItem.type === 'Design Brief' ? 'primary' : 'secondary'

    return (
        <ErrorBoundary>
          {/* <div className={`col-12`}> */}
            <div className={`card-shadow-secondary card bg-secondary text-white ms-3`} onClick={handleClick} role="button">
              <div className="card-body d-flex align-items-center fw-bold" style={{border: "0px", padding:"6px"}}>
                  {/* <span>In Progress:</span> */}
                  {/* <div className="badge bg-white text-secondary border border-2 border-white me-1">In Progress</div> */}
                  <div className="p-0 d-flex align-items-center text-wrap">
                    <div className={`badge bg-${typeBg} text-wrap text-white border border-2 border-white flex-grow-1`}>{inProgressItem.type}</div>
                    <div className={`ms-2 badge bg-white text-${typeBg} text-wrap border border-2 border-${typeBg}`}>{inProgressItem.day} - {inProgressItem.item}</div>
                  </div> 
              </div>
            </div>
          {/* </div> */}
        </ErrorBoundary>
    )
}
