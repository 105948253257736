import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';

import StatusMenu from "../../gridMenu/elements/MenuItems";

import { SiteContext } from "../../../App.tsx";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

 
export default function MenuModal({handleShow, handleClose, show, setShow, type, student}) {

    // const {
    //   student, socket, room, goalQuestions,
    //   studentDataFunctions: {createMongo}
    // } = useContext(SiteContext)

    function handleCloseLocal() {
      setShow(false)
    }

 // This following section will display the form that takes input from the user to update the data.
 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show} 
      onHide={handleCloseLocal}
      dialogClassName="my-modal5"
    >
      {/* <Modal.Header>
        <Modal.Title>
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="p-0">
        <StatusMenu student={student} />
      </Modal.Body>
      {/* <Modal.Footer>
      </Modal.Footer> */}
    </Modal>
    </div>
  </ErrorBoundary>
  
);
}


