import React, {useContext} from 'react'

import { SiteContext } from '../../App.tsx';
import { StudentContext } from '../../pages/StudentContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';

export default function PendingItems({entry}) {

    const { 
        student, socket, room,
        studentDataFunctions: {
          createMongo, getMongoList, getMongoById, updateMongo, deleteMongo
        }
     } = useContext(SiteContext)
     const {orders, setOrders, setActiveChips, updateChips, setUpdateChips } = useContext(StudentContext)


    const priceCalc = entry.price * entry.quantity

    function handleDeleteOrder() {
        socket.emit("send_deleteOrder", { order: entry, room });
        deleteMongo(entry._id, 'orders')
        const filtered = orders.filter(item => item.timeStamp !== entry.timeStamp)
            setOrders(filtered)
            setUpdateChips(!updateChips)
            // setActiveChips(-1)
    }

    return (
        <>
            <span className="">{entry.price}€ x {entry.quantity} = {priceCalc}€</span>
            <span className="">
                {entry.item} 
            </span>
            <div className={`mb-2 me-2 badge bg-info`} style={{marginLeft:"10px"}} onClick={handleDeleteOrder}>
                X
            </div>
        </>
    )
}
