import React, {useState, useContext} from 'react';
import { SiteContext } from '../../App.tsx';
import { v4 as uuidv4 } from 'uuid'


function SearchList({filteredList, exportItems}) {

  const {activeItems, setActiveItems, editMode} = exportItems

  // const items = [
  //   {text: 'Attendance Name', prop: 'attendanceName'},
  //   {text: 'Name', prop: 'name'},
  //   {text: 'First Name', prop: 'firstName'},
  //   {text: 'Period', prop: 'period'},
  //   {text: 'Email', prop: 'email'},
  // ]

  return (
    <div>
      <table className="align-middle text-truncate mb-0 table table-borderless table-hover">
        <thead>
          <tr>
            {activeItems.map(entry => {
              if(!entry.active) return
              return <th key={entry.prop} className="">{entry.text}</th>
            })}
            
            {/* <th className="">First Name</th>
            <th className="">Period</th>
            <th className="">Email</th> */}
          </tr>
        </thead>
        <tbody>
          {filteredList.map(entry =>  
            <Entry key={entry._id} entry={entry} exportItems={exportItems}  />
          )}
        </tbody>
      </table>
    </div>
  );
}

function Entry({entry, exportItems}) {

    const {activeItems, setActiveItems, editMode} = exportItems

    const {studentList, socket, studentDataFunctions: {updateMongo, setStudentList}} = useContext(SiteContext)
    const [form, setForm] = useState({})

    function handleSave(e) {
      e.preventDefault()

      updateMongo({...form}, entry._id, 'records')

      const editStudentList = [...studentList]
      var index = editStudentList.findIndex(student => student.studentId === entry.studentId)
      if(index === -1) return

      if(form.period && form.period !== editStudentList[index].period) {
        const newModuleHistory = [
          ...editStudentList[index].moduleHistory, 
          {
            module: editStudentList[index].module, 
            date: Date.now(), 
            groupNumber: editStudentList[index].groupNumber, 
            period: form.period
          }
        ]

        form.moduleHistory = newModuleHistory
        editStudentList[index].moduleHistory = newModuleHistory
      }

      editStudentList[index] = {...editStudentList[index], ...form}
  
      updateMongo({...form}, entry.dailyListId, 'dailyList')

      setStudentList(editStudentList)

      socket.emit("send_updateSingleStudent", { student: {...editStudentList[index], ...form}, room: 'admin' });
    }

    function updateForm(e, prop) {
      const editForm = {...form, [prop]: e.target.value}
      setForm({...editForm})
    }

    // console.log(entry,'entry')

    return (
      <tr className={`text-wrap`}>
        {activeItems.map(entryItem => {
          if(!entryItem.active) return
          // console.log(entryItem,'entryItem')
          if(entryItem.array) {
            if(!entry[entryItem.prop]) return <td/>
            return (
            <td key={entryItem.text} className={``} style={{wordWrap:"break-word", maxWidth:"300px"}}> 
              <div className="d-flex flex-wrap">
            {entry[entryItem.prop].map(propEntry => {
              // console.log(propEntry,'propEntry')
              return <div key={uuidv4()}className="text-wrap m-1 bg-secondary text-white rounded p-1">{propEntry.keyValue}</div>
            })}
            </div>
            </td>
          )}

          return (
          <td key={entryItem.text} className={``} style={{wordWrap:"break-word"}}>
            {editMode ? 
            <input type="text" className={`form-control ${form[entryItem.prop] && form[entryItem.prop] !== entry[entryItem.prop] ? 'bg-secondary text-white' : ''}`} value={form[entryItem.prop] ? form[entryItem.prop] : entry[entryItem.prop]} onChange ={e => updateForm(e, entryItem.prop)}/>
            : <span className="text-wrap">{entry[entryItem.prop]}</span>}
          </td>
          )
        })}
        {editMode && <td className="">
          <div className="btn bg-primary text-white" onClick={e => handleSave(e)}>Save</div>
        </td>}
      </tr>
    )
}

export default SearchList;
