import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../../App.tsx'
import { AdminContext } from '../../../pages/AdminContainer'
import { ErrorBoundary } from '../../../other/utils/ErrorBoundary'
import { v4 as uuidv4 } from 'uuid'

import ChecklistEntry from './DashEntry'
import ColorTitle from '../../../other/utils/ColorTitle'
import ACompleteCard from './ACompleteCard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLeft, faCheck } from '@fortawesome/free-solid-svg-icons';

export default function DashAssignments( { title, variant, sizeClass }) {

    const { 
      classInformation, initialLoad, focusAgreements,
      studentList, socket,
      studentDataFunctions: {
        updateMongo, setStudentList, checkToday
      }
    } = useContext(SiteContext)

    const {orders, checkoutItems} = useContext(AdminContext)

    const [activeModal, setActiveModal] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if(!focusAgreements || !focusAgreements.activeAssignment) return

    const completionsPending = []
    const completionsComplete = []
    
    studentList.map(entry => {
        if(entry.period !== classInformation.period) return
        var index = entry.assignments ? entry.assignments.findIndex(item => item.title === focusAgreements.activeAssignment) : -1
        
        if(index === -1) return
        const current = {...entry.assignments[index], name: entry.name, studentId: entry.studentId, dailyListId: entry.dailyListId}
        if(!current.complete) return

        if(current.complete && !current.completeAdmin) {
            completionsPending.push(current)
        } else if(current.completeAdmin) {
            completionsComplete.push(current)
        }
        
    })

    const filteredComplete = completionsComplete.filter(entry => checkToday(entry.timestamp))

    // console.log(completionsComplete,'completionsComplete')
    const filteredCompletions = [...completionsPending, ...filteredComplete]
    // if(filteredCompletions.length === 0) return
    // console.log(filteredCompletions,'filteredCompletions')
    // var count = 0

    // var variant = 'purple'

    function handleAction(e, approved, item) {
      e.preventDefault()

      const editStudentList = [...studentList]
      var index = editStudentList.findIndex(entry => entry.studentId === item.studentId)
      // console.log('1')
      if(index === -1) return
      // console.log('2')
      var student = editStudentList[index]
      var index2 = student.assignments.findIndex(entry => entry.itemId === item.itemId)
      if(index2 === -1) return

      // console.log('3', approved)
      if(approved === 'returned') {
          student.assignments[index2].returned = true
          student.assignments[index2].completeAdmin = false
          // console.log('here')
      } else {
          student.assignments[index2].completeAdmin = approved
          student.assignments[index2].returned = false
      }

      console.log(editStudentList[index],'editStudentList[index]')
      setStudentList(editStudentList)
      updateMongo({assignments: student.assignments}, student.dailyListId, 'dailyList')
      socket.emit("send_updateSingleStudent", {student: {...student}, room: student.period});
  }

    var count = 0

    if(filteredCompletions.length === 0) return

    const exportFunctions = {show, setShow, handleClose, handleShow, activeModal, setActiveModal}

    function getStudent(returnItem) {
      const editStudentList = [...studentList]
      var index = editStudentList.findIndex(entry => entry.studentId === activeModal.studentId)
      if(index === -1) return false
  
      var student = editStudentList[index]
      var index2 = student.assignments.findIndex(entry => entry.itemId === activeModal.itemId)
      if(index2 === -1) return false

      if(returnItem === 'student') return editStudentList[index]
      else if(returnItem === 'assignment') return student.assignments[index2]
      else return false
    }

    var student = getStudent('student')
    var assignment = getStudent('assignment')

    return (
        <ErrorBoundary>
        {activeModal && <ACompleteCard exportFunctions={exportFunctions} activeAssignment={activeModal} name={student.name} context={'Modal'} handleAction={handleAction} />}
        <div className={sizeClass}>  
          <div className="main-card mb-3 card">
            <div className="card-header h-auto mt-2">
              <h5 className="card-title">
                <ColorTitle icon={faCheck} text={title} variant={variant} />
              </h5>
              <div className="btn-actions-pane-right">
              </div>
            </div>
            {filteredCompletions.length === 0 && <div className="card-body">
              <p>No Completions</p>
            </div>}
            {filteredCompletions.length > 0 && <div className="m-2 table-responsive">
              <table className="align-middle text-truncate mb-3 table table-borderless table-hover">
                <thead>
                  <tr>
                    <th className="">Date</th>
                    <th className="">Assignment</th>
                    <th className="">Student</th>
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCompletions.map(entry => {
                    count++
                    if(count > 8) return
                    return <ChecklistEntry key={entry.itemId + uuidv4()} item={entry} variant={variant} handleAction={handleAction} exportFunctions={exportFunctions} />
                  })}
                </tbody>
              </table>
            </div>}
            {filteredCompletions.length > 8 && <div className="d-block text-center card-footer" style={{minHeight:"30px"}}>
              {count > 8 ? `+${parseInt(count) - 8} More` : ' '}
            </div>}
          </div>
        </div>
        </ErrorBoundary>
    )
}
