import React, { useContext } from 'react'
import { SiteContext } from '../../App.tsx';
import { ErrorBoundary } from '../../other/utils/ErrorBoundary';
import { useNavigate } from "react-router-dom";

import CheckInToolTip from './CheckInToolTip';

export default function StudentCard({ title, subtitle, colorValue, sizeClass }) {

    const { student } = useContext(SiteContext)
    const navigate = useNavigate();

    function handleClick() {
      if(subtitle === 'Progress Checklist') {
        navigate("/checklistS");
      }
    }

    return (
    <ErrorBoundary>
      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`} role={subtitle === 'Progress Checklist' ? `button` : ``} onClick={handleClick}>
          <div className="widget-content p-0">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
                {/* <div className="widget-numbers mt-0 fsize-3 text-white"> */}
                  <div className="widget-numbers mt-0 fsize-3 d-flex text-wrap pe-auto">
                      {subtitle !== 'Check-In' ? title : <CheckInToolTip checkIn={student.checkIn} />}
                  </div>
                </div>
              </div>
              <div className="widget-content-left fsize-1">
                <div className="text-muted">{subtitle}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
    )
}
