import React, { useState, useContext, useEffect } from 'react'

import { SiteContext } from '../../App.tsx';
import { ErrorBoundary } from '../../other/utils/ErrorBoundary';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';

export default function ColorCard({title, subtitle, colorValue, sizeClass}) {
  const { 
    student,
    colorMatch,
    socket,
    studentList,
    studentDataFunctions: {
      setStudent
    }
  } = useContext(SiteContext)

  const {
    color: {
      current: currentColor,
      warningsGiven,
      pending: pendingColor
    },
    studentId
  } = student
  
  const match = colorMatch.filter(item => item.color === currentColor)
    const variant = match[0].variant
    
    return (
      <ErrorBoundary>
      <div className={sizeClass}>
        <div className={`card-shadow-secondary mb-3 widget-chart widget-chart2 text-start card bg-${colorValue}`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className="widget-content-left pe-2 fsize-1">
                  <div className="widget-numbers mt-0 fsize-3 text-white">
                    {title}
                  </div>      
                </div>
              </div>
              <div className="widget-content-left fsize-1">
                <div className="text-white">
                  {subtitle}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </ErrorBoundary>
    )
}
