import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';

import { SiteContext } from "../../App.tsx";
import { StudentContext } from "../../pages/StudentContainer";
import { ErrorBoundary } from "../../other/utils/ErrorBoundary";

 
export default function ShoutoutModal({handleShow, handleClose, show, type}) {

    const {
      student, socket, room,
      studentDataFunctions: {createMongo}
    } = useContext(SiteContext)

    const {nominatedName} = useContext(StudentContext)

    function handleCloseLocal() {
      handleClose(type)
    }

    function handleButton(e, open) {
        e.preventDefault()
        handleClose(type)
    }
  
 // This following section will display the form that takes input from the user to update the data.
 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show} 
      onHide={handleCloseLocal}
    >
      <Modal.Header>
        <Modal.Title>🎉 Thanks for shouting out {nominatedName}! 🎉</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div style={{margin:"10px"}}>
        <span style={{fontWeight:"bold", marginRight:"5px", display:"block"}}>Bad news:</span><span className="">We can't raise {nominatedName}'s color.</span>
      </div>
      <div style={{margin:"10px"}}>
        <span style={{fontWeight:"bold", marginRight:"5px", display:"block"}}>Good news:</span><span className="">It's because {nominatedName} is already on ⬛ Black.</span>
      </div>
      <div style={{margin:"10px"}}>
        <span style={{fontWeight:"bold", marginRight:"5px", display:"block"}}>Even better news?</span><span className="">Maybe this gives you a chance to help a whole new person reach black?</span>
      </div>
        {/* <div className="recipe__row">
            <div className="recipe__value recipe__value--indented">
                <div className="ingredient-grid2">
                    <span className="bold">Bad news:</span><span className=""></span>
                    <span className="bold">Even better news?</span><span className=""></span>
                </div>
            </div>
        </div>  
           <div style={{margin:"5px"}}>Thanks again for shouting out good things you see.
           <div className="">Good news:   But maybe shoutout someone else you see helping class?</div>
           <div style={{margin:"5px"}}>Thanks again for shouting out good things you see.</div> */}
      </Modal.Body>
      <Modal.Footer>
          <button onClick={e=>handleButton(e, true)} className={`btn btn-info`}>Close</button>
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
);
}





// import React, { useState, useEffect, useContext } from "react";
// import Modal from 'react-bootstrap/Modal';
// import { SiteContext } from "../../App.tsx";
// import { StudentContext } from "../../pages/StudentContainer";
 
// export default function ShoutoutModal({handleShow, handleClose, showModal}) {

//  const {nominatedName} = useContext(StudentContext)

//  return (

//     <Modal 
//       show={showModal} 
//       onHide={handleClose}
//     >
//       <Modal.Header closeButton>
//         <Modal.Title>Shoutout</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <h4 style={{marginBottom:"20px"}}>Thanks for sending a shoutout!</h4>
//         <span className="">{nominatedName} is actually already on black, so their color can't raise.</span>
//         <span className="">But thanks again for shouting out good things you see.</span>
//       </Modal.Body>
//       <Modal.Footer>
//       </Modal.Footer>
//     </Modal>
// );
// }



