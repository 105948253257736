import React, { useState, useContext, useEffect } from 'react'

import { SiteContext } from '../../App.tsx';

import './pquiz.css'

const grades = [
  {
      grade: 'A+',
      variant: 'alt'
  },
  {
      grade: 'A',
      variant: 'primary'
  },
  {
      grade: 'B',
      variant: 'success'
  },
  {
      grade: 'C',
      variant: 'warning'
  },
  {
      grade: 'D',
      variant: 'flag'
  },
  {
      grade: 'F',
      variant: 'danger'
  },
  {
    grade: 'N',
    variant: 'white'
  },
]


export default function PQuizGroup({group, groupScores, handleChange, presentMode}) {
  const { 
    studentDataFunctions: {
    }
  } = useContext(SiteContext)

  const [currentGrade, setCurrentGrade] = useState(grades[3])

  useEffect(() => {
    setCurrentGrade(grades.filter(entry => entry.grade === groupScores[group])[0])
    return () => {
    }
  }, [groupScores])

  // console.log(groupScores,'gs', group, groupScores[group], grades.filter(entry => entry.grade === groupScores[group])[0])
  
  function handleClick(e, colorNum) {
    e.preventDefault()

    setCurrentGrade(grades[colorNum])

    handleChange(grades[colorNum].grade, group)
  }
    
  function getColor(number) {
    if(grades[number].grade === currentGrade.grade) {
      return {
        grade: grades[number].grade,
        variant: 'secondary',
      }
    }
    return {
      grade: grades[number].grade,
      variant: grades[number].variant,
    }
  }
  
    return (

      <div className="col-6 col-md-3">
        <div className={`card-shadow-secondary mb-3 widget-chart widget-chart2 text-start card bg-${currentGrade.variant}`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="">
                <div className="widget-content-left pe-2 fsize-1">
                  <div className="widget-numbers mt-0 fsize-3 text-white">
                    <div className="title-container">
                      <div  style={{float:"left"}}>
                        <span className="text-size">{group}</span>
                      </div>
                      <div className="text-right">
                        <span className="text-size">{currentGrade.grade}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!presentMode && <div className="widget-content-left fsize-1">
                <div className="text-white">
                  <button
                    className={`btn btn-sm btn-${getColor(0).variant} gradeBtn text-white`}
                    onClick={(e) => handleClick(e, 0)}
                  >A+</button>
                  <button
                    className={`btn btn-sm btn-${getColor(1).variant} gradeBtn`}
                    onClick={(e) => handleClick(e, 1)}
                  >A</button>
                  <button
                    className={`btn btn-sm btn-${getColor(2).variant} gradeBtn`}
                    onClick={(e) => handleClick(e, 2)}
                  >B</button>
                  <button
                    className={`btn btn-sm btn-${getColor(3).variant} gradeBtn`}
                    onClick={(e) => handleClick(e, 3)}
                  >C</button>
                  <button
                    className={`btn btn-sm btn-${getColor(4).variant} gradeBtn`}
                    onClick={(e) => handleClick(e, 4)}
                  >D</button>
                  <button
                    className={`btn btn-sm btn-${getColor(5).variant} gradeBtn`}
                    onClick={(e) => handleClick(e, 5)}
                  >F</button>
                  <button
                    className={`btn btn-sm bg-white btn-${getColor(6).variant} gradeBtn text-${getColor(6).variant}`}
                    onClick={(e) => handleClick(e, 6)}
                    // style={{float:"right"}}
                  >N</button>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>

    )
}