import React, { useState, useEffect } from "react";
import axios from "axios";
import { SchoolCard } from "./SchoolCard.tsx";
import { baseUrl } from "../../App.tsx";
import { School } from "../../types/school.ts"; // ✅ Import the TypeScript type
import { v4 as uuidv4 } from "uuid";
import { fetchSchools } from "../../types/api.tsx";

export default function Schools({baseUrl}) {

    const [schools, setSchools] = useState<School[]>([]); // ✅ Enforce type safety
    const [districtSchools, setDistrictSchools] = useState([])
    const [selectedDistrict, setSelectedDistrict] = useState<string>("");

    const [newSchool, setNewSchool] = useState<School>({
        schoolNumber: "",
        metadata: {
            schoolNickname: "",
            address: "",
            districtName: "",
            grades: [],
            emoji: ""
        },
        links: [],
        staff: [],
        bellSchedule: [],
        visits: [],
        cycles: [],
        _id: uuidv4()
    });
    const [dynamicDropdownData, setDynamicDropdownData] = useState({}); // Store dropdown data globally
    
    // ✅ Fetch schools when component loads
    useEffect(() => {
        async function loadSchools() {
            const data = await fetchSchools();
            setSchools(data);
        }
        loadSchools();
    }, []);

    useEffect(() => {
        if (selectedDistrict && dynamicDropdownData['districtName']) {
            const filteredSchools = dynamicDropdownData['districtName'][selectedDistrict]; 
            setDistrictSchools(filteredSchools);
        } else {
            setDistrictSchools([]);
        }
    }, [selectedDistrict, dynamicDropdownData]);

    // ✅ Fetch dropdown data
    useEffect(() => {
        async function fetchDropdownData() {
            try {
                const response = await fetch(`${baseUrl}/api/dropdownDynamic`);
                const dynamicDropdownResponse = await response.json();
                setDynamicDropdownData(dynamicDropdownResponse);
            } catch (error) {
                console.error("🔥 Fetch Error:", error);
            }
        }
        fetchDropdownData();
    }, []);

    const handleDistrictChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedDistrict = event.target.value;
    
        setSelectedDistrict(selectedDistrict);
        console.log(selectedDistrict,'selectedDistrict')

        setNewSchool(prev => ({
            ...prev,
            metadata: {
                ...prev.metadata,
                districtName: selectedDistrict,
            }
            // schoolNumber: dynamicDropdownData[selectedDistrict]?.[0] || "", // Set first school as default
        }));
    };

    const handleSchoolChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedSchool = event.target.value;
        
        setNewSchool((prev) => ({
            ...prev,
            schoolNumber: selectedSchool, // Update school number
        }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
    
        // Check if the field belongs to metadata
        if (["schoolNickname", "address", "emoji"].includes(name)) {
            setNewSchool((prevState) => ({
                ...prevState,
                metadata: {
                    ...prevState.metadata, // ✅ Preserve existing metadata values
                    [name]: value, // ✅ Update only the changed field
                },
            }));
        } else {
            setNewSchool((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const addSchool = async () => {
        try {
            console.log("📤 Sending school data:", JSON.stringify(newSchool, null, 2));
    
            const cleanedSchool = { ...newSchool };
            delete cleanedSchool._id;  // 🔹 Ensure MongoDB generates `_id`

            console.log("📤 cleanedSchool:", cleanedSchool, cleanedSchool.metadata, cleanedSchool.metadata.schoolNickname);

            const response = await axios.post(`${baseUrl}/api/schools`, cleanedSchool);
            console.log("✅ School added successfully:", response.data);
    
            fetchSchools(); // Refresh the list after adding

        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                console.error("❌ Axios Error:", error.message);
    
                if (error.response) {
                    console.error("🔍 Response Data:", error.response.data);
                    console.error("🔍 Response Status:", error.response.status);
                    console.error("🔍 Response Headers:", error.response.headers);
                } else if (error.request) {
                    console.error("📡 No Response Received:", error.request);
                } else {
                    console.error("💥 Request Setup Error:", error.message);
                }
            } else {
                console.error("⚠️ Non-Axios Error:", error);
            }
        }
    };

    return (
        <div className="container mt-4">
            <h2 className="mb-3">School Sites</h2>

            {/* Display Schools */}
            <div className="row">
                {schools.map((school) => (
                    <SchoolCard key={school._id.toString()} school={school} />
                ))}
            </div>
                        {/* Add School Form */}
            <div className="card p-4 mb-4 bg-secondary">
                <h5 className="text-white fw-bold">Add New School</h5>
                <div className="d-flex gap-2">
                    {/* District Name Dropdown */}
                    <select
                        name="districtName"
                        value={newSchool.metadata.districtName}
                        onChange={handleDistrictChange}
                        className="form-control"
                    >
                        <option key={0} value={""}>Select District</option>
                        {dynamicDropdownData.districtName && Object.keys(dynamicDropdownData.districtName).map((districtName: string) => (
                            <option key={districtName} value={districtName}>
                                {districtName}
                            </option>
                        ))}
                    </select>
                    <select
                        name="schoolNumber"
                        value={newSchool.schoolNumber}
                        onChange={handleSchoolChange}
                        className="form-control"
                        disabled={!selectedDistrict} // Disable if no district is selected
                    >
                        <option key={0} value={""}>Select School</option>
                        {districtSchools.map((schoolNumber: string) => (
                            <option key={schoolNumber} value={schoolNumber}>
                                {schoolNumber}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="d-flex gap-2 mt-2">
                    <input
                        type="text"
                        name="schoolNickname"
                        value={newSchool.metadata.schoolNickname}
                        onChange={handleInputChange}
                        placeholder="School Nickname"
                        className="form-control"
                    />
                    <input
                        type="text"
                        name="emoji"
                        value={newSchool.metadata.emoji}
                        onChange={handleInputChange}
                        placeholder="School Emoji"
                        className="form-control"
                    />
                    <input
                        type="text"
                        name="address"
                        value={newSchool.metadata.address}
                        onChange={handleInputChange}
                        placeholder="Address"
                        className="form-control"
                    />
                </div>
                <button className="btn btn-success fw-bold mt-2" onClick={addSchool}>
                        Add
                </button>
            </div>
            
        </div>
    );
}
