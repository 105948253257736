import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import Body from "./1EditBody";

import { SiteContext } from "../../../App.tsx";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

 
export default function DeleteModal({show, setShow, modalInfo, handleDeleteFinal}) {

    const {type, category, categoryEntry} = modalInfo

    function handleClose() {
        setShow(false)
    }

    function handleDeleteLocal() {
        handleDeleteFinal(type, category, categoryEntry)
        handleClose()
    }
  
 // This following section will display the form that takes input from the user to update the data.
 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show}
      onHide={handleClose}
      dialogClassName="my-modal2"
    >
      <Modal.Header>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Are you sure you want to delete?</div>
        <div className="mt-2">{type === 'categoryEntry' ? `Category Entry: ${categoryEntry}` : `Category: ${category}`}</div>
      </Modal.Body>
      <Modal.Footer>
          <button className={`btn btn-light`} onClick={handleClose}>Cancel</button>
          <button className={`btn btn-danger`} onClick={handleDeleteLocal}>Delete</button>
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
);
}


