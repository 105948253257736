import React, { useContext, useState } from 'react'

import { SiteContext } from '../../../App.tsx'
import { AdminContext } from '../../../pages/AdminContainer'

import HallPassMath from '../hallPassMath/HallPassMath'

export default function DataEntry({student}) {

const {
    studentDataFunctions: {handleDateString}
} = useContext(SiteContext)

const {calcHallPassMath} = useContext(AdminContext)

// var fortNight = 12096e5/2
// const {over, under, total, totalComplete, totalDifference, totalString, averagePass} = calcHallPassMath(studentPasses, Date.now()-(200*fortNight))

    return (
        <tr className={`${""}`}>
            <td className="">
                {student.name}
            </td>
            <td className="">
                {student.period}
            </td>
            <td className="d-flex align-items-center">
                <div className="badge bg-secondary m-1 fsize-1" style={{width:"40px"}}>{student.over + student.under}</div>
                {student.over + student.under > 0 && <div className="badge bg-danger m-1">{student.over}</div>}
                {student.over + student.under > 0 && <div className="badge bg-success m-1">{student.under}</div>}
            </td>
            <td className="">
                {student.averagePass}
            </td>
            <td className="">
                {student.totalComplete} min
            </td>
            <td className="">
                {/* <div className="badge bg-secondary m-2" role="button">Passes</div> */}
                <HallPassMath key={student.name} hallPasses={student.hallPasses} student={student} type={'menu'} trigger={['click']} />
            </td>
        </tr>
    )
}

// text-center