import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';

import { SiteContext } from "../../../App.tsx";
import { StudentContext } from "../../../pages/StudentContainer";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

import { usePhotoGallery }  from '../../../modules/inventory/admin/upload/usePhotoGallery.ts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToDot, faBook, faSheetPlastic, faX } from '@fortawesome/free-solid-svg-icons';

export default function CompleteCard({activeAssignment, questions, variantColor, updateChecklistLocal, context}) {

    var countQ = 0
    var count = 0
    
    return (
      <div className="mt-3 mb-3 d-flex align-items-stretch">
        <div className={`card flex-grow-1 border border-3 border-${variantColor}`}>
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-start">
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="">
                  <h3 className="fsize-2 fw-bold mb-0">{activeAssignment.title}</h3>
                </div>
                {activeAssignment.complete && <div className={`badge ${activeAssignment.completeAdmin ? 'bg-success' : 'border border-3 border-success text-success bg-white'} d-flex align-items-center ps-3 pe-3 pt-2 pb-2`}>
                  <h3 style={{fontSize:"18px"}} className="mb-0 fw-bold">{activeAssignment.completeAdmin ? 'Complete' : 'Marked Complete'}</h3>
                </div>}
              </div>
            </div>
          </div>
          <div className="card-body">
            {/* <div className="card bg-secondary border border-3 border-success">
              <div className="card-body"> */}
                {questions.map(item => {
                  countQ++  
                  return (
                    <div key={countQ} className={`card bg-secondary border border-3 border-${variantColor} mb-3`}>
                      <div className="card-body">
                        <div className="text-white fw-bold">{countQ}. {item.question}</div>
                        <div className="mt-2 p-1 text-white">
                          <span>{activeAssignment[item.param]}</span>
                         </div>
                      </div>
                    </div>
                  )
                })}
              {/* </div>
            </div> */}
            <div className={`card bg-black text-white mb-3 border border-3 border-${variantColor}`} role="button">
              <div className="card-body fw-bold">
                <div>Image{activeAssignment.url && activeAssignment.url.length > 1 && 's'}</div>
                <div className="d-flex align-items-top mt-2 flex-wrap">
                  {activeAssignment.url && activeAssignment.url.length > 0 && activeAssignment.url.map(item => {
                    count++
                    return <ImageModal key={count} item={item} length={activeAssignment.url.length} />
                  })}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className={`badge bg-${variantColor} p-3`} style={{fontSize:"1rem"}} 
                role="button" onClick={e => updateChecklistLocal(e, false)}>
                Edit
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function ImageModal({item, length}) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <img src={item} onClick={handleShow} className={`${length > 1 ? 'viewModalImage1' : 'w-100'} viewModalImage1 p-2 ps-0`} />
  
        <Modal show={show} onHide={handleClose} dialogClassName={`my-modal3`}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <img src={item} onClick={handleShow} className="viewModalImage2"/>
          </Modal.Body>
          {/* <Modal.Footer>
          </Modal.Footer> */}
        </Modal>
      </>
    );
  }