import React, {useState} from 'react'
import { SiteContext } from '../../../App.tsx'

import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Item from './Item'
import { useContext } from 'react'

export default function StoreItems() {

    const { modules, focusAgreements, classInformation, socket, room, studentList, storeItems,
        studentDataFunctions: { getMongoList, createMongo, deleteMongo, updateMongo, setFocusAgreements, setStoreItems }
    } = useContext(SiteContext)

    async function handleSave(e, selectedEntry, form) {
        e.preventDefault()

        const editStoreItems = [...storeItems]
        var index = editStoreItems.findIndex(item => item._id === selectedEntry._id)
        if(index === -1) return
        editStoreItems[index] = {...editStoreItems[index], ...form}
        
        updateVariables(editStoreItems, editStoreItems[index])
    }

    function handleChangeIndex(action, selectedEntry, currentIndex) {

        const editStoreItems = [...storeItems]
        var index = editStoreItems.findIndex(entry => entry.itemId === selectedEntry.itemId)
        if(index === -1) return

        var change = action === 'raise' ? -1 : 1
        var otherIndex = index + change

        editStoreItems[index].index = otherIndex
        editStoreItems[otherIndex].index = index

        updateVariables(editStoreItems, editStoreItems[index])
        updateVariables(editStoreItems, editStoreItems[otherIndex])
    }
    
    async function handleAddDelete(e, type, selectedEntry) {
        e.preventDefault()

        const obj = type === 'add' ? newItem(storeItems) : selectedEntry
        var editStoreItems = [...storeItems]

        if(type === 'add') {
            editStoreItems.push(obj)
            createMongo(obj,'storeItems')
        } else if(type === 'delete') {
            editStoreItems = editStoreItems.filter(item => item._id !== obj._id)
            deleteMongo(obj._id, 'storeItems')
        }

        updateVariables(editStoreItems)
    }

    async function updateVariables(updatedItems, updatedItem) {
        setStoreItems(updatedItems)
        socket.emit("send_updateStoreItems", { storeItems: updatedItems, room });

        if(updatedItem) {
            var mongoId = updatedItem._id ? updatedItem._id : await getUId(updatedItem.itemId)
            if(updatedItem._id) {delete updatedItem._id}
            if(!mongoId) return
            updateMongo({...updatedItem}, mongoId, 'storeItems')
        }
    }

    async function getUId(itemId) {
        const items = await getMongoList('storeItems')
        var index = items.findIndex(entry => entry.itemId === itemId)
        return index === -1 ? false : items[index]._id
    }

    const handleFunctions = {
        handleSave, handleAddDelete, handleChangeIndex,
    }

    var count = 0

    storeItems.sort( compare );

    return (
    <div className="mb-3">
        <div className="card">
            <div className="card-header">
                <div className="card-header-title font-size-lg text-capitalize fw-normal">
                    Store Items
                </div>
            </div>
            <div className="card-body">
            {storeItems.map(entry => {
            count++
            return <Item key={entry.itemId} entry={entry} count={count} handleFunctions={handleFunctions} length={storeItems.length} />
        })}
        <div className="mt-3">
            <div className="card shadow-none bg-light" onClick={e => handleAddDelete(e, 'add')}>
                <div className="card-body d-flex justify-content-center flex-wrap">
                <FontAwesomeIcon className="m-1 d-inline-block" icon={faPlus} />
                </div>
            </div>
        </div>
            </div>
        </div>

    </div>
    )
}


function newItem(storeItems) {
    return {
        item: "", 
        price: 1, 
        quantity: 0,
        oufOfStock: 'No',
        itemId: uuidv4(),
        index: storeItems.length
    }
}

function compare( a, b ) {
    if ( a.index < b.index ){
      return -1;
    }
    if ( a.index > b.index ){
      return 1;
    }
    return 0;
}