import React, { useContext, useState, useEffect } from 'react'

import { SiteContext } from '../App.tsx';
import { StudentContext } from './StudentContainer';
import { ErrorBoundary } from '../other/utils/ErrorBoundary';

import StoreCard from '../store/student/StoreCard';
import Balance from '../store/student/Balance';
import Cart from '../store/student/Cart';
import PendingOrders from '../store/student/PendingOrders';
import Explain from '../store/student/Explain';

export default function Store() {

    const {
        student,
        socket,
        loginState,
        studentList,
        cart,
        studentDataFunctions: {
            setStudent,
            setCart, getMongoList,
        }
    } = useContext(SiteContext)

    const {namesList} = useContext(StudentContext)

    const { orders, setOrders, activeChips, setActiveChips, updateChips, setUpdateChips } = useContext(StudentContext)
    const [cartCount, setCartCount] = useState(student.chips.balance)
    const [classCount, setClassCount] = useState(0)

    useEffect(() => {
        if(!namesList) return
        var classChipTotal = 0
        namesList.map(entry => {
            if(!entry || entry.period !== student.period) return

            classChipTotal = entry.chips.total + classChipTotal
            // console.log(classChipTotal,'classChipTotal')
        })
        setClassCount(classChipTotal)

    }, [namesList])

    useEffect(() => {

        socket.on("receive_orderComplete", (data) => {
          console.log(data, 'receive_orderComplete')
          
          if(student.studentId !== data.student.studentId) {return}
          
          const editStudent = {...student}
            editStudent.chips = data.student.chips
            editStudent.chipsToday = data.student.chipsToday

            setStudent(editStudent)
            // setChipCount(data.student.chips.balance)
        })
    
        return () => {
          socket.off('receive_orderComplete');
        };

    }, [socket])

    function editCart(e, action, item, price) {
        e.preventDefault()

        console.log(action, item, price,'action, name, price', cart)
        const editCart = [...cart]
        var editActiveChips = activeChips

        var index = editCart.findIndex(entry => entry.item === item)
        const originalQuantity = editCart[index].quantity
        editCart[index].quantity = editCart[index].quantity + parseInt(action)
        if(editCart[index].quantity < 0) {
            editCart[index].quantity = 0
            return
        }

        editActiveChips = editActiveChips - price * parseInt(action)
        // console.log(editActiveChips,'editActiveChips')
        if(editActiveChips < 0) {
            editCart[index].quantity = originalQuantity
            return
        }

        // console.log(editCart,'editCart')
        setCart(editCart)
        setUpdateChips(!updateChips)
        // setActiveChips(-1)
    }


    var showCart = false
    cart.map(entry => {
        if(entry.quantity > 0) {showCart = true}
    })

    var showPending = false
    orders.map(entry => {
        if(entry.quantity > 0) {showPending = true}
    })

    var classChipTotal = 0

    // getMongoList('dailyList').then((students) => {
    //     console.log('students',students)
    //     students.map(entry => {
    //         if(!entry || entry.period !== student.period) return

    //         classChipTotal = entry.chips.total + classChipTotal
    //     })
    // })
    // studentList.map(entry => {
    //     if(entry && entry.period === student.period) {
    //         classChipTotal = entry.chips.total + classChipTotal
    //     }  
    // })    

    return (
    <ErrorBoundary>
        <div className="row">
            <div className="col-sm-8">
                <div className="row">
                    <Balance title={activeChips} subtitle={'Your Total'} colorValue={'primary'} sizeClass={'col-sm-6'}/>
                    <Balance title={classCount} subtitle={'Class Total'} colorValue={'info'} sizeClass={'col-sm-6'}/>
                </div>
                <StoreCard cart={cart} setCart={setCart} editCart={editCart} />
            </div>
            <div className="col-sm-4">
                <Explain title={345} subtitle={'Class Total'} colorValue={'primary'} sizeClass={'col-sm-12'} />
                {showCart && <Cart title={'Your Cart'} colorValue={'info'} sizeClass={'col-sm-12'} cart={cart} setCart={setCart} editCart={editCart} />}
                {showPending && <PendingOrders title={'Pending Orders'} colorValue={'info'} sizeClass={'col-sm-12'} orders={orders} setOrders={setOrders} />}
            </div>
        </div>                         
    </ErrorBoundary>   
    )
}