import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../../App.tsx'
import { AdminContext } from '../../../pages/AdminContainer';

import { useStopwatchLocal } from '../../Stopwatch';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faArrowDown, faArrowUp, faCircle, faCircleHalfStroke, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

import HallPassMath from '../hallPassMath/HallPassMath';

export default function HallPassEntry({entry}) {

    const { 
        studentList, socket, room,
        studentDataFunctions: {
            updateMongo, getMongoAllById, deleteMongo
        }
    } = useContext(SiteContext)

    const {updateExistingEvent, convertTimeString, calcHallPassMath} = useContext(AdminContext)

    const [running, setRunning] = useState(false);
    const [hallPasses, setHallPasses] = useState(entry.hallPasses ? [...entry.hallPasses] : [])
    const [time, setTime] = useState(() => {return entry.time})

    var index = studentList.findIndex(item => item.studentId === entry.studentId)
      if(index !== -1) {
        var hallPassEvents = studentList[index].hallPasses
    }

    useEffect(() => {
        
        async function getPasses() {
            const hallPassesNew = []

            const entries = await getMongoAllById(entry.studentId, 'hallPassEvents')
            entries.map(entry => {  
                if(!entry.approved) return 
                hallPassesNew.push(entry)  
            })
            if(index === -1) return
            // console.log(hallPassEvents,'hallPassEvents')
            setHallPasses([...hallPassEvents, ...hallPassesNew])
        }

        // console.log(entry.hallPassEvents,'entry.hallPassEvents---')
        getPasses()
        
    }, [entry.studentId, time])

    function updateTime(value) {
        updateMongo({time: value}, entry._id, 'hallPassEvents')
        socket.emit("send_hallPass_update", { updatedObj: entry, type: 'update', changeValue: {time: value}, room });
        return setTime((parseInt(value)));
    }

    // console.log(entry,'entry')
    return (
        <tr className={``}>
            <td className="text-center" style={{verticalAlign:"top"}}> 
                <div className="d-flex flex-wrap justify-content-center w-100">
                    <div style={{whiteSpace:"initial"}}>{entry.name}</div>
                    <HallPassMath key={entry.name} hallPasses={hallPasses} student={entry} />
                </div> 
            </td>
            <td className="text-center" style={{verticalAlign:"top"}}>
                <div className="d-flex align-items-center justify-content-center w-100">
                {!entry.approved && <>
                <input
                  type="number" className={`form-control bg-white completedWatch2 me-1`} min={0}  max={60}
                  value={time} style={{width:"50px", textAlign:"center"}}
                  onChange={e => {updateTime(e.target.value)}}
                />
                </>} 
                {entry.approved ? 
                <CompletedButtons entry={entry} /> : 
                <div className="ms-1 text-wrap text-left">
                    <div className="badge bg-secondary me-2">{entry.purpose}</div>
                    {entry.explain.length > 0 && `${entry.explain}`}
                </div>
                }
                </div> 
            </td>
            <td className="text-center" style={{verticalAlign:"top"}} style={{minWidth:"200px"}}> 
                {entry.active && !entry.approved ? 
                    <Action entry={entry} time={time} /> : 
                    <ActiveStopwatch entry={entry} running={running} setRunning={setRunning} />}
            </td>
        </tr>
    )
}


function ActiveStopwatch ({entry, running, setRunning}) {

    const { 
        classInformation, socket, room,
        studentDataFunctions: {updateMongo}
    } = useContext(SiteContext)
    const {updateExistingEvent} = useContext(AdminContext)

    useEffect(() => {
        if(entry.active && entry.approved) {
            setRunning(true)
        } else {
            setRunning(false)
        }
    }, [])
    
    // console.log(entry,'entry')
    const {minutes, seconds} = useStopwatchLocal(entry.approvedTimeStamp, running)

    function endHallPassEvent(e) {
        e.preventDefault()

        const id = entry._id
        const updatedObj = {...entry, active: false, timeComplete: `${minutes}:${seconds}`}
        socket.emit("send_hallPass_update", { updatedObj, type: 'update', changeValue: {active: false, timeComplete: `${minutes}:${seconds}`}, room });
            delete updatedObj["_id"];

        updateMongo(updatedObj, id, 'hallPassEvents')
        updateExistingEvent(updatedObj, 'update', {active: false, timeComplete: `${minutes}:${seconds}`})
        setRunning(false)
        socket.emit("send_hallPass_update", { updatedObj, type: '', room });
    }

    var minutesInt = parseInt(minutes)

    if(entry.active && entry.approved) {

        if(minutesInt > entry.time + 1) {
            var bgBadge = 'danger'
        }else if(minutesInt > entry.time) {
            var bgBadge = 'flag'
        } else if(minutesInt == entry.time) {
            var bgBadge = 'warning'
        } else { var bgBadge = 'secondary'}

        return (
            <>
            <div className={`me-2 badge bg-${bgBadge}`} style={{marginTop:"5px"}}>
                <div className="numbers">
                    <span>{minutes}:</span>
                    <span>{seconds}</span>
                </div>
            </div>
            <div 
              onClick={e => endHallPassEvent(e)} role="button"
              className={`mb-2 me-2 badge bg-info`} style={{marginTop:"5px"}}
            >
                <span>End</span>
            </div>
            </>
        )
    } else if(!entry.active && entry.approved) {
        var bgBadge = parseInt(entry.timeComplete.split(':')[0]) <= entry.time ? 'secondary' : 'flag'
        return <CompletedStopwatch/>
    }

    function CompletedStopwatch() {

        const {updateHallPassEvent} = useContext(AdminContext)
        const {studentDataFunctions: {deleteMongo}} = useContext(SiteContext)

        const [time, setTime] = useState({
            minutes: entry.timeComplete.split(':')[0],
            seconds: entry.timeComplete.split(':')[1]
        })

        // const [time, setTime] = useState(entry.timeComplete)

        function updateTime(value) {
            const timePrevious = {...time}
            setTime({ ...timePrevious, ...value })
            // setTime((prev) => {
            //   return { ...timePrevious, ...value };
            // });
            updateEvent(false, { ...timePrevious, ...value })
        }

        function updateEvent(e, inputTime) {
            if(e) e.preventDefault()
    
            var timeInside = inputTime ? inputTime : time
            const id = entry._id
            var secondsOut = parseInt(timeInside.seconds)
    
            if(secondsOut < 10) {secondsOut = '0' + secondsOut}
            const updatedObj = {...entry, timeComplete: `${timeInside.minutes}:${secondsOut}`}
                delete updatedObj["_id"];
            updateMongo(updatedObj, id, 'hallPassEvents')
            updateExistingEvent(updatedObj, 'update', {timeComplete: `${timeInside.minutes}:${secondsOut}`})
            socket.emit("send_hallPass_update", { updatedObj, type: '', room });
        }

        // console.log(time.minutes,'time.minutes', entry.time + 1)
        if(time.minutes > parseInt(entry.time) + 1) {
            var bgColor = 'danger'
        }else if(time.minutes > parseInt(entry.time)) {
            var bgColor = 'flag'
        } else if(time.minutes == parseInt(entry.time)) {
            var bgColor = 'warning'
        } else { var bgColor = 'secondary'}

        return (
        // <span style={{whiteSpace:""}} className="">
            <div role="group" className="d-flex flex-wrap align-items-start justify-content-center w-100" style={{marginLeft:"5px", display:"inline"}}> 
                <input
                type="number" className={`form-control bg-${bgColor} text-white completedWatch d-inline fw-bold`}  min={0}  max={60} style={{maxWidth:"60px", fontSize:"12px"}}
                value={time.minutes}
                onChange={e => updateTime({ minutes: e.target.value })}
                />
                <span className="m-1">:</span>
                <input
                type="number" className={`form-control bg-${bgColor} text-white completedWatch  d-inline fw-bold`} min={0}  max={60} style={{maxWidth:"60px", fontSize:"12px"}}
                value={time.seconds}
                onChange={e => updateTime({ seconds: e.target.value })}
                />  
                {/* <button 
                onClick={(e) => updateEvent(e, time, entry)}
                className={`btn-shadow btn btn-secondary ms-2 p-1 ps-2 pe-2 completedWatch ${``}`}>
                <FontAwesomeIcon className="" icon={faPen} style={{fontSize:"12px"}} />
                </button> */}
            </div>  
        ) 
    }

}

function CompletedButtons({entry}) {

    const {updateExistingEvent} = useContext(AdminContext)
    const {room, socket, 
        studentDataFunctions:{updateMongo, deleteMongo}} 
    = useContext(SiteContext)

    const [time, setTime] = useState(entry.timeComplete ? {
        minutes: entry.timeComplete.split(':')[0],
        seconds: entry.timeComplete.split(':')[1]
    } : '00:00')

    function updateExcused(e, excuseType) {
        e.preventDefault()
        
        const id = entry._id
        const updatedObj = {...entry, excused: excuseType}
            delete updatedObj["_id"];
        updateMongo(updatedObj, id, 'hallPassEvents')
        updateExistingEvent(updatedObj, 'update', {excused: excuseType})
        socket.emit("send_hallPass_update", { updatedObj, type: '', room });
    }

    function deleteHallPassEvent(e) {
        e.preventDefault()

        deleteMongo(entry._id, 'hallPassEvents')
        updateExistingEvent(entry, 'remove', {})
        socket.emit("send_hallPass_update", { updatedObj: entry, type: 'remove', room });
    }

    return (
    <div>
        <div className="text-wrap mb-2" style={{maxWidth:"250px"}}>
          <div className={`me-2 badge bg-light`}>{entry.time} min</div><div className="badge bg-secondary">{entry.purpose}</div>
          {entry.explain.length > 0 && <span className={`me-2 ms-2`}>{entry.explain}</span>}
        </div>
        {entry.timeComplete && <div className="d-flex justify-content-center">
        <div role="group" className="btn-group btn-group-sm d-flex align-items-start" style={{marginLeft:"5px", display:"inline", maxWidth:"180px"}}>   
            {/* <button 
            onClick={(e) => updateEvent(e, time, entry)}
            className={`btn-shadow btn btn-light ${``}`}>
            <FontAwesomeIcon className="me-1" icon={faPen} style={{fontSize:"12px"}} />
            </button> */}
            <button 
            onClick={(e) => updateExcused(e, 'partial')} 
            className={`btn-shadow btn btn-${entry.excused === 'partial' ? 'secondary' : 'light'}`}>
            <FontAwesomeIcon className="" icon={faCircleHalfStroke} style={{fontSize:"12px"}} />
            </button>
            <button 
            onClick={(e) => updateExcused(e, 'excused')}
            className={`btn-shadow btn btn-${entry.excused === 'excused' ? 'secondary' : 'light'}`}>
            <FontAwesomeIcon className="" icon={faCircle} style={{fontSize:"12px"}} />
            </button>
            <button 
            onClick={(e) => deleteHallPassEvent(e)}
            className={`btn-shadow btn btn-light`}>
            <FontAwesomeIcon className="" icon={faTrash} style={{fontSize:"12px"}} />
            </button>
        </div></div>} 
    </div>  
    )
}

function Action({entry, time}) {

    const { 
        classInformation, socket, room,
        studentDataFunctions: {updateMongo, deleteMongo}
    } = useContext(SiteContext)
    const {updateExistingEvent} = useContext(AdminContext)

    const [currentEntry, setCurrentEntry] = useState(entry)

    function updateHallPassEvent(e) {
        e.preventDefault()

        const id = entry._id
        
        const updatedObj = {...currentEntry, approved: true, approvedTimeStamp: Date.now(), time}
        socket.emit("send_hallPass_update", { updatedObj, type: 'update', changeValue: {approved: true, approvedTimeStamp: Date.now(), time}, room });
            delete updatedObj["_id"];

        updateMongo(updatedObj, id, 'hallPassEvents')
        updateExistingEvent(updatedObj, 'update', {approved: true, approvedTimeStamp: Date.now(), time})
        socket.emit("send_hallPass_update", { updatedObj, type: '', room });
    }

    function rejectHallPassEvent(e) {
        e.preventDefault()

        deleteMongo(entry._id, 'hallPassEvents')
        updateExistingEvent(currentEntry, 'remove', {})
        socket.emit("send_hallPass_update", { updatedObj: currentEntry, type: 'remove', room });
    }

    function editEntry(e, value, param) {
        e.preventDefault()
        const editEntry = {...currentEntry}
        editEntry[param] = value
        setCurrentEntry(editEntry)
    }

    return (
    <>
        <div role="group" className="btn-group-sm btn-group me-3">
            <button 
            // onClick={(e) => rejectHallPassEvent(e)} 
            onClick={e => editEntry(e, !currentEntry.allowSimultaneous, 'allowSimultaneous')}
            className={`btn-shadow btn btn-${currentEntry.allowSimultaneous ? 'purple' : 'light'}`}>
            {currentEntry.allowSimultaneous ? '>1' : '=1'}
            </button>
            {/* <button 
                onClick={(e) => deleteHallPass(e, entry, deleteMongo)}
                className={`btn-shadow btn btn-black text-white`}>
                <FontAwesomeIcon className="me-1" icon={faTrash} style={{fontSize:"12px"}} />
            </button> */}
        </div>
        <div role="group" className="btn-group-sm btn-group">

            <button 
            onClick={(e) => updateHallPassEvent(e)} 
            className={`btn-shadow btn btn-secondary ${``}`}>
            <FontAwesomeIcon icon={faCheck} />
            </button>
            <button 
            onClick={(e) => rejectHallPassEvent(e)} 
            className={`btn-shadow btn btn-light ${``}`}>
            <FontAwesomeIcon icon={faXmark} />
            </button>
        </div>

    </>
    )
}
