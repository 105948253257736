import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from "react-router";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { SiteContext, baseUrl } from "../../../App.tsx";

import '../../../records/modal.css'

import More from "../../../records/More";

export default function ModalTitle({person, title, edit, variant, type}) {
  const [show, setShow] = useState(false);
 
  // console.log(person, 'person in modal')
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  return (
    <>
      <div
        onClick={handleShow}
        className="showModal"
        style={{display:"inline"}}
      >
        {/* {person.name} */}
        <h5 className="menu-header-title" style={{display:"inline", margin:"10px"}}>{person.name}</h5>
      </div>

      <Modal 
        show={show} 
        onHide={handleClose}
        // style={{width:"80%"}}
        // dialogClassName="modal-height"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <More
                person={person}
            />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
}