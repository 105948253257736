import React, { useContext, useState, useEffect } from 'react'
import { SiteContext } from '../../App.tsx'

const LOCAL_STORAGE_KEY_ORDERS = 'classWebsite.orders'

export function useStore() {

    const {
        student, cart,
        studentDataFunctions: {
            useCreateStorageState, 
            useSaveLocalStorage, createMongo, getMongoList, getMongoById, updateMongo, deleteMongo
        }
    } = useContext(SiteContext)

    const [orders, setOrders] = useState(useCreateStorageState(LOCAL_STORAGE_KEY_ORDERS, []))
    const [activeChips, setActiveChips] = useState(student && student.chips && student.chips.balance)
    const [pendingChips, setPendingChips] = useState(0)
    const [updateChips, setUpdateChips] = useState(false)

    useEffect(() => {

        if(!student || !student.chips || !student.chips.balance) return
        getMongoList('orders').then(orders => {
            setOrders(orders)

            var pendingTotal = 0
            orders.map(entry => {
                if(entry.studentId !== student.studentId) {return}
                pendingTotal += parseInt(entry.quantity)*parseInt(entry.price)
            })  

            var cartTotal = 0
            cart.map(entry => {
                if(entry.quantity === 0) {return}
                cartTotal += entry.quantity*entry.price
            })

            setActiveChips(student.chips.balance - pendingTotal - cartTotal)
            setPendingChips(pendingTotal)
        })
        
    }, [orders.length, activeChips, updateChips, student && student.chips])
    
    return {
        orders, setOrders, activeChips, setActiveChips, pendingChips,
        updateChips, setUpdateChips
    }
}

