import React, { useContext } from 'react'
import { SiteContext } from '../../App.tsx';

export default function ClassBalance({ subtitle, colorValue, sizeClass, period }) {

    const { student, studentList, classInformationArray } = useContext(SiteContext)

    var classChipTotal = 0
    var max = 0
    var maxStudents = 0

    const chips = []
    classInformationArray.map(entry => {
      chips.push({period: entry.period, count: 0, students: 0, countAdjusted: 0})
    })

    studentList.map(entry => {
      var index = chips.findIndex(item => item.period === entry.period)
      if(index === -1) return
      if(entry.chips) {
        chips[index].count = chips[index].count + entry.chips.total
      }
      chips[index].students = chips[index].students + 1
    })    

    const indexCurrent = chips.findIndex(entry => entry.period === period)
    
    var maxStudents = Math.max(...chips.map(o => o.students))

    chips.map(entry => {
      entry.countAdjusted = Math.round(maxStudents/entry.students*entry.count)
      // console.log(entry.students/maxStudents)
    })

    var max = Math.max(...chips.map(o => o.countAdjusted))
    
    // console.log(chips,'chips',max, maxStudents)
    var percent = chips[indexCurrent].countAdjusted/max*100
    var percentPer = percent + '%'

    return (

      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 text-${colorValue} w-100`}>
                  <div className="widget-numbers mt-0 mb-0" style={{fontSize:"4vw"}}>
                    {chips[indexCurrent].count}€
                  </div>
                  <div className="progress-bar-xs progress mt-1">
                      <div
                        className={`progress-bar bg-${colorValue}`} 
                        role="progressbar"
                        aria-valuenow={percent}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: percentPer }}
                      ></div>
                  </div>
                  <div className="mt-3 mb-0 widget-numbers d-flex justify-content-between" style={{fontSize:"1.5vw"}}>
                    P{period.split(' ')[1]} 
                    <span></span>
                    <span style={{float:"right"}}>({chips[indexCurrent].students}, {chips[indexCurrent].countAdjusted})</span>
                  </div>
                </div>
                {/* <div className={`widget-content-right pe-2 fsize-1 text-${colorValue}`}>
                  <div className="widget-numbers mt-0 fsize-3">P{period.split(' ')[1]}</div>
                  <div className="">{chips[indexCurrent].students} {chips[indexCurrent].countAdjusted}</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

    )
}