import React, {useState, useEffect, useContext} from 'react'
import { SiteContext } from '../../../App.tsx'

import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

import AItem from './AItem'
import '../checklist.css'

const types = [
    {type: 'Test', variant: 'primary'},
    {type: 'Activity', variant: 'flag'},
    {type: 'Design Brief', variant: 'warning'},
]

export default function AChecklist() {

    const { modules, focusAgreements, classInformation, socket, room, studentList, classInformationArray,
        studentDataFunctions: { getMongoList, createMongo, updateMongo, getMongoByUniqueId, setFocusAgreements, setModules }
    } = useContext(SiteContext)

    const [module, setModule] = useState('')
    const [reset, setReset] = useState(false)
    const [restore, setRestore] = useState(false)

    const [month, setMonth] = useState(() => {
        const date = new Date()
          return date.getMonth() + 1
      })
    const [day, setDay] = useState(() => {
    const date = new Date()
        return date.getDate()
    })
    const [year, setYear] = useState(() => {
    const date = new Date()
        return date.getFullYear()
    })

    // const [checklistImport, setChecklistImport] = useState([])

    var moduleExists = module.length === 0 ? false : true

    function matchModule(entryInput) {
        var index = entryInput.findIndex(item => item.title === module)
        if(index === -1) return
        return index
    }

    async function handleSave(e, selectedEntry, form) {
        e.preventDefault()
        delete form.index

        function handleSaveChange(checklistInput) {

            const editChecklistInput = [...checklistInput]
            // console.log(selectedEntry,'selectedEntry', editChecklistInput)
            var index2 = editChecklistInput.findIndex(item => item.itemId === selectedEntry.itemId)
            console.log(checklistInput,'checklistInput')
            if(index2 === -1) {
                console.log('fail')
                editChecklistInput.push({...selectedEntry, ...form})
                console.log(editChecklistInput,'editChecklistInput', selectedEntry)
                return {modifiedChecklist: editChecklistInput}
                // return {modifiedChecklist: editChecklistInput}
            }

            editChecklistInput[index2] = {...editChecklistInput[index2], ...form}

            // editChecklistInput.map(checkEntry => {
            //     console.log(checkEntry,'checkEntry')
            //     checkEntry.complete = false
            //     checkEntry.completeAdmin = false
            //     checkEntry.excused = false
            // })

            return {modifiedChecklist: editChecklistInput}
        }

        const editModules = [...modules]
        var index3 = matchModule(editModules)
        // const {modifiedChecklist} = handleSaveChange(editModules[index3].checklist)
        editModules[index3].checklists.map(entry => {
            const {modifiedChecklist} = handleSaveChange(entry.checklist)
            entry.checklist = modifiedChecklist
        })
        // editModules[index3].checklist = modifiedChecklist
        updateModalMongo(editModules[index3])

        classInformationArray.map(async(classEntry) => {

            const entry = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
            const editEntry = {...entry}

            var index = matchModule(editEntry.modules)
            editEntry.modules[index].checklists.map(checklistEntry => {
                const {modifiedChecklist} = handleSaveChange(checklistEntry.checklist)
                checklistEntry.checklist = modifiedChecklist
            })

            updateVariables({modules: editEntry.modules}, editEntry._id)
        })
    
    }

    function handleChangeIndex(action, selectedEntry) {

        function handleChangeIndexChange(checklistInput) {
            const editChecklist = [...checklistInput]
    
            var index = editChecklist.findIndex(entry => entry.itemId === selectedEntry.itemId)
            var currentIndex = editChecklist[index].index
            console.log(editChecklist[index].index,'editChecklist[index].index', editChecklist[index], 'editChecklist[index]')
            var change = action === 'raise' ? -1 : 1
            var flipIndex = currentIndex + change

            var index2 = editChecklist.findIndex(entry => entry.index === flipIndex)

            editChecklist[index].index = flipIndex
            editChecklist[index2].index = currentIndex

            return {modifiedChecklist: editChecklist}
        }

        const editModules = [...modules]
        var index = matchModule(modules)
        // const {modifiedChecklist} = handleChangeIndexChange(modules[index].checklist)
        editModules[index].checklists.map(entry => {
            const {modifiedChecklist} = handleChangeIndexChange(entry.checklist)
            entry.checklist = modifiedChecklist
        })
        // editModules[index].checklist = modifiedChecklist

        updateModalMongo(editModules[index])

        classInformationArray.map(async(classEntry) => {
            const entry = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
            const editEntry = {...entry}

            var index = matchModule(editEntry.modules)
            editEntry.modules[index].checklists.map(checklistEntry => {
                const {modifiedChecklist} = handleChangeIndexChange(checklistEntry.checklist)
                checklistEntry.checklist = modifiedChecklist
            })

            updateVariables({modules: editEntry.modules}, editEntry._id)
            console.log(editEntry.modules[index].checklists[0].checklist, 'focusAg',editModules[index].checklists[0].checklist)
        })
    }

    async function handleAddDelete(e, type, selectedEntry) {

        e.preventDefault()

        var index = focusAgreements.modules.findIndex(entry => entry.title === module)
        const newItemObj = newItem(focusAgreements.modules[index].checklists[0].checklist.length)

        function handleAddDeleteChange(checklistInput) {
            var editChecklist = [...checklistInput]

            if(type === 'add') {
                editChecklist.push(newItemObj)
            } else if(type === 'delete') {
                editChecklist = editChecklist.filter(item => item.itemId !== selectedEntry.itemId)
            }

            return {modifiedChecklist: editChecklist}
        }

        const editModules = [...modules]
        var index = matchModule(editModules)
        editModules[index].checklists.map(entry => {
            const {modifiedChecklist} = handleAddDeleteChange(entry.checklist)
            entry.checklist = modifiedChecklist
        })
        // const {modifiedChecklist} = handleAddDeleteChange(modules[index].checklist)
        // editModules[index].checklist = modifiedChecklist
        updateModalMongo(editModules[index])

        const promises = classInformationArray.map(async(classEntry) => {

            const entry = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
            var editEntry = {...entry}

            var index = matchModule(editEntry.modules)
            editEntry.modules[index].checklists.map(checklistEntry => {
                const {modifiedChecklist} = handleAddDeleteChange(checklistEntry.checklist)
                // console.log(modifiedChecklist,'modifiedChecklist')
                checklistEntry.checklist = modifiedChecklist
            })

            updateVariables({modules: editEntry.modules}, editEntry._id)
        })
        await Promise.all(promises)

        // if(type === 'add') {window.location.reload()}
    }

    function updateVariables(newObj, id) {

        if(newObj._id) {delete newObj._id}
        //   console.log(newObj,'newObj')
          updateMongo({...newObj}, id, 'focusAgreements')
        if(newObj.period === classInformation.period) {
            const editFocusAgreements = {...focusAgreements, ...newObj}
            setFocusAgreements(editFocusAgreements)
        }
        socket.emit("send_updateProgressChecklist", { focusAgreements: {...newObj}, admin: true, room: classInformation.period });
    }

    function updateModalMongo(modifiedModule) {
        const editModified = {...modifiedModule}
        // console.log(modifiedModule,'modifiedModule')

        const editModules = [...modules]
        var index = editModules.findIndex(entry => entry.itemId === editModified.itemId)
        if(index === -1) {
            console.log('failed')
            return
        }
        editModules[index] = {...editModules[index], ...editModified}
        setModules(editModules)

        const id = editModified._id
        delete editModified._id

        // console.log('saving', editModified)
        updateMongo({...editModified}, editModules[index]._id, 'modules')
    }

    function handleModule(e) {
        e.preventDefault()
        setModule(e.target.value)
    }

    async function handleReset(e, period, saveOnly) {
        e.preventDefault()

        classInformationArray.map(async(classEntry) => {

            if(period && classEntry.period !== period) return
            const entry = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
            var editEntry = {...entry}

            editEntry.modules.map(groupEntry => {
                // const {modifiedModules, modulesIndex} = handleAddDeleteChange(groupEntry.modules)
                // groupEntry.modules = modifiedModules

                const submitEntry = {...groupEntry, submitTimeStamp: Date.now(), period: classEntry.period}
                createMongo({...submitEntry} ,'checklistRecords')

                if(saveOnly) return

                groupEntry.checklists.map(moduleEntry => {
                    moduleEntry.checklist.map(checkEntry => {
                        checkEntry.complete = false
                        checkEntry.completeAdmin = false
                        checkEntry.excused = false
                        checkEntry.completeEvent = false
                    })
                })
            })

            if(saveOnly) return
            updateVariables(editEntry, entry._id)
        })
        setReset(false)
    }

    async function restoreChecklist(e, periodImport) {
        e.preventDefault()

        const date = new Date()
            date.setMonth(month - 1)
            date.setDate(day)
            date.setHours(0)
            date.setMinutes(0)
            date.setSeconds(0)
            date.setFullYear(year)

        const checklists = await getMongoList('checklistRecords')
        // console.log(checklists,'checklists')

        checklists.sort( compare2 );

        const filter = checklists.filter(entry => entry.submitTimeStamp > date.getTime())
        console.log(filter,'filter',date.getTime())

        classInformationArray.map(async(classEntry) => {

            if(periodImport && classEntry.period !== periodImport) return
            const entry = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
            var editEntry = {...entry}

            const newModules = []

            // const newModules = filter.filter(item => item.period === entry.period)
            editEntry.modules.map(groupEntry => {
                var filtered = filter.filter(item => item.itemId === groupEntry.itemId && item.period === groupEntry.period)
                if(filtered === 0) return
                console.log(filtered[0],'filtered[0]')
                newModules.push(filtered[0])
                // console.log(groupEntry,'groupEntry')
            })

            editEntry.modules = newModules
            // console.log(editEntry,'editEntry')
            updateVariables(editEntry, entry._id)
        })
        setRestore(false)
        addChecklistGroups()
        // console.log(focusAgreements.modules)
    }

    function HandleReset() {
        return (
            <div>
                <button className="btn btn-warning m-2" onClick={e => handleReset(e)}>Reset All Checklists</button>
                <button className="btn btn-flag text-white m-2" onClick={e => handleReset(e, classInformation.period)}>Reset {classInformation.period} Checklists</button>
                <button className="btn btn-danger m-2" onClick={e => handleToggleReset(e)}>Cancel</button>
            </div>
        )
    }

    function HandleRestore() {

        const exportFunction = {month, setMonth, day, setDay, year, setYear}
        return (
            <div className="d-flex justify-content-end flex-wrap">
                <button className="btn btn-primary m-2" onClick={e => restoreChecklist(e)}>Restore All Checklists</button>
                <button className="btn btn-purple text-white m-2" onClick={e => restoreChecklist(e, classInformation.period)}>Restore {classInformation.period} Checklists</button>
                <button className="btn btn-danger m-2" onClick={e => handleToggleRestore(e)}>Cancel</button>
                {/* <div className="d-flex justify-content-end m-2 flex-wrap"> */}
                    <DateElement exportFunction={exportFunction}/>
                {/* </div> */}
            </div>
        )
    }

    function handleToggleReset(e) {
        e.preventDefault()
        setReset(prev => {return !prev})
    }

    function handleToggleRestore(e) {
        e.preventDefault()
        setRestore(prev => {return !prev})
    }

    async function forcePush(e) {
        e.preventDefault()

        const promises = classInformationArray.map(async(classEntry) => {
            const entryBefore = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
            handleClass(entryBefore)
            console.log('class complete',classEntry.period)
        })

        await Promise.all(promises)

        window.location.reload();
        
        function handleClass(focusAgreementsLocal) {

            focusAgreementsLocal.modules.map(moduleEntry => {

                moduleEntry.checklists.map(checklistEntry => {
                  checklistEntry.checklist.map(entry => {
                    console.log(entry.completeEvent,'entry in all checklist', moduleEntry.fullTitle)
                    updateStudents(entry.completeEvent, entry)
                  })
                })
            })
        }

        function updateStudents(preservedEvent, item) {
            studentList.map(entry => {
                // if(entry.period !== classInformation.period) return

                // console.log(item.completeEvent, 'item.completeEvent.matchingStudents.')
                var filter = preservedEvent && preservedEvent.matchingStudents && preservedEvent.matchingStudents ? preservedEvent.matchingStudents.filter(entry2 => entry2 == entry.studentId) : []
                if(filter.length === 0) return {oneModule: false, output: false}
                console.log(filter, 'filter', preservedEvent.matchingStudents)

                if(entry.moduleCompletions) {
                    entry.moduleCompletions = entry.moduleCompletions.filter(item2 => item2.itemId !== item.itemId)
                }
                
                entry.moduleCompletions = entry.moduleCompletions && typeof entry.moduleCompletions === 'object' ? [...entry.moduleCompletions, item] : [item]
                console.log(entry.moduleCompletions,'entry.moduleCompletions')

                updateMongo({moduleCompletions: entry.moduleCompletions}, entry.dailyListId, 'dailyList')
            })
        }

    }

    const handleFunctions = {
        handleSave, handleAddDelete, handleChangeIndex,
    }

    var count = 0
    var designBriefCount = false
    var taskCount = 0
    var indexNum = 0

    if(!focusAgreements || !focusAgreements.modules || modules.length === 0) return
    var index = modules.findIndex(item => item.title === module)
    var checklist = module.length === 0 || index === -1 ? [] : modules[index].checklists[0].checklist
    // var checklist = []

    checklist.sort( compare );

    // console.log(checklist,'checklist')

    const modulesAbc = sortByKey([...modules],'title')

    // modulesAbc.sort(function(a, b) {
    //     var keyA = new Date(a.title),
    //       keyB = new Date(b.title);
    //     // Compare the 2 dates
    //     if (keyA < keyB) return -1;
    //     if (keyA > keyB) return 1;
    //     return 0;
    // });

    function sortByKey(array, key) {
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }


    return (
        <div className="mb-3">
            <div className="col-12 card shadow-none">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <h1>Module Progress Checklist</h1>
                        <span>
                            {(!reset && !restore) ? 
                            <>
                            <div className="btn btn-success text-white m-2" role="button" onClick={e => handleReset(e, false, true)}>Backup Checklists</div>
                            <div className="btn btn-purple text-white m-2" role="button" onClick={e => addChecklistGroups(e)}>Force Checklists Update</div>
                            <div className="btn btn-info text-white m-2" role="button" onClick={e => forcePush(e)}>Push Current Completions to Student</div>
                            <button className="btn btn-warning m-2" onClick={e => handleToggleReset(e)}>Reset Checklists</button>
                            <button className="btn btn-primary m-2" onClick={e => handleToggleRestore(e)}>Restore Checklists</button>
                            <select
                                className="btn btn-secondary m-2"
                                onChange={e => handleModule(e)}
                                value={module}
                            >
                                <option value={''}>-- Select Module --</option>
                                {modulesAbc.map(entry => {
                                    return <option key={entry.title} value={entry.title}>{entry.title}</option>
                                })}
                            </select>
                            </>
                            : reset ? <HandleReset/> : restore ? <HandleRestore/> : <></>}
                        </span>
                    </div>
                    <span>{module}</span>
                </div>
            </div>
        {/* </div> */}
        {checklist.map(entry => {
            if(entry.type === 'Design Brief' && entry.designBriefTask === 'Overview') {
                count++
                designBriefCount = count
            } else if(entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview') {
                taskCount++
                count++
            } else {
                count++
            }
            return <AItem key={entry.itemId} entry={entry} count={count} designBriefCount={designBriefCount} taskCount={taskCount} handleFunctions={handleFunctions} types={types} length={checklist.length} />
        })}
       {moduleExists && <div className="row mt-3 mb-3">
            <div className="col-11"></div>
            <div className={`col-1 d-flex align-items-stretch`}>
                 <div className="card w-100 shadow-none">
                    <div className="card-body d-flex justify-content-center flex-wrap">
                    <FontAwesomeIcon className="m-1 d-inline-block" icon={faPlus} onClick={e => handleAddDelete(e, 'add')} role="button" />
                    </div>
                </div>
            </div>
        </div>}

    </div>
    )


    async function addChecklistGroups(e) {
        if(e) e.preventDefault()
    
            classInformationArray.map(async(classEntry) => {
    
                const entry = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
    
                const editEntry = {...entry}

                editEntry.modules.map(moduleEntry => {

                    var index0 = originalModules.findIndex(item => item.title === moduleEntry.title)
                    console.log(originalModules[index0], index0, 'index0')

                    moduleEntry.index = index0

                    var index = modules.findIndex(entry => entry.title === moduleEntry.title)
                    // console.log(modules[index], 'index map')
                    if(index === -1) return
                    moduleEntry.checklists.map(checklistEntry => {
                        checklistEntry.checklist.map(itemEntry => {
                            // console.log(itemEntry,'itemEntry')
                            var index2 = modules[index].checklists[0].checklist.findIndex(entry => entry.itemId === itemEntry.itemId)
                            // console.log(modules[index].checklists[0].checklist[index2],'modules[index].checklists[0].checklist[index2]')
                            itemEntry.index = modules[index].checklists[0].checklist[index2].index
                            itemEntry.link = modules[index].checklists[0].checklist[index2].link
                            itemEntry.day = modules[index].checklists[0].checklist[index2].day
                            itemEntry.item = modules[index].checklists[0].checklist[index2].item
                            itemEntry.type = modules[index].checklists[0].checklist[index2].type
                            itemEntry.itemId = modules[index].checklists[0].checklist[index2].itemId
                            itemEntry.action = modules[index].checklists[0].checklist[index2].action
                            // console.log(itemEntry,'itemEntry')
                        })
                    })
                })
                const id = editEntry._id
                delete editEntry._id

                console.log(editEntry, 'editEntry')
                updateMongo({...editEntry}, id, 'focusAgreements')
                // window.location.reload();
            })
        }
}

function DateElement({exportFunction}) {

    const {month, setMonth, day, setDay, year, setYear} = exportFunction

    const date = new Date()
        date.setMonth(month - 1)
        date.setDate(day)

    const weekDayNum = date.getDay()
        const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday', 'Saturday']
        const weekDay = weekDays[weekDayNum]

    return (
    <div style={{width:"450px"}} className="m-2">
      <div className="d-flex justify-content-between align-items-center bg-light ps-2 pe-2 rounded">
        <span className="ms-2 me-1">Month:</span>
        <input 
          type="number" name="month" min="1" max="12" className="rounded border border-light border-1 me-2 mt-2 mb-2"
          value={month}
          onChange={e => {
            setMonth(e.target.value)
            // setTableCopied(false)
          }}
          // value={}
        />
        <span className="me-1">Day:</span>
        <input 
          type="number" name="day" min="1" max="31" className="rounded border border-light border-1 mt-2 mb-2"
          value={day}
          onChange={e => {
            setDay(e.target.value)
            // setTableCopied(false)
          }}
          // value={}
        />
        <span className="me-1 ms-1">Year:</span>
        <input 
          type="number" name="day" min="1" max="31" className="rounded border border-light border-1 mt-2 mb-2"
          style={{minWidth:"60px"}}
          value={year}
          onChange={e => {
            setYear(e.target.value)
            // setTableCopied(false)
          }}
          // value={}
        />
      </div>
      <div className="d-block mt-2">
        <span className="fw-bold">Restore to Most Recent Checklist After:</span>
        <span className="ms-2 me-2">BOD {weekDay} {month}/{day}</span>
      </div>
    </div>
    )
}
function newItem(index) {
    return {
        itemId: uuidv4(), 
        item: "", 
        link: "",
        day: "", 
        type: "", 
        complete: false, 
        adminComplete: false,
        index,
    }
}

function compare( a, b ) {
    if ( a.index < b.index ){
      return -1;
    }
    if ( a.index > b.index ){
      return 1;
    }
    return 0;
}

function compare2( a, b ) {
    if ( a.submitTimeStamp > b.submitTimeStamp ){
      return -1;
    }
    if ( a.submitTimeStamp < b.submitTimeStamp ){
      return 1;
    }
    return 0;
}

const originalModules = [ 
    {title: 'Culinary', variant: 'purple', fullTitle: 'INTRO TO CULINARY ARTS', cleanupItems: []}, 
    {title: 'Alternative Energy', variant: 'maroon', fullTitle: 'ALTERNATIVE ENERGY'}, 
    {title: 'Structural Eng', variant: 'purple', fullTitle: 'STRUCTURAL ENGINEERING'},
    {title: 'Material Sci', variant: 'indigo', fullTitle: 'MATERIALS PROCESSING & DESIGN'}, 
    {title: 'Home Maintenance', variant: 'indigo', fullTitle: 'HOME MAINTENANCE FUNDAMENTALS'}, 
    {title: 'Energy & Power', variant: 'indigo', fullTitle: 'ENERGY & POWER'}, 
    {title: 'Video Prod', variant: 'primary', fullTitle: 'VIDEO PRODUCTION'}, 
    {title: 'Robotics', variant: 'primary', fullTitle: 'ROBOTICS'}, 
    {title: 'Flight & Drone', variant: 'info', fullTitle: 'FLIGHT & DRONE TECHNOLOGY'}, 
    {title: 'Criminalistics', variant: 'info', fullTitle: 'CRIMINALISTICS'}, 
    {title: 'Audio Prod', variant: 'info', fullTitle: 'DIGITAL AUDIO PRODUCTION'}, 
    {title: 'Health Sci', variant: 'pink', fullTitle: 'INTRO TO HEALTH SCIENCE CAREERS'}, 
    {title: 'Child Dev', variant: 'pink', fullTitle: 'INTRO TO CHILD DEVELOPMENT'}, 
    {title: 'Design & Mark', variant: 'pink', fullTitle: 'DESIGN & MARKETING'}, 
    {title: 'Fire & Emer', variant: 'maroon', fullTitle: 'EMERGENCY & FIRE MANAGEMENT SERVICES'}, 
    {title: 'Game & Graphics', variant: 'warning', fullTitle: 'COMPUTER GRAPHICS & GAME DEVELOPMENT'}, 
    {title: 'Electricity', variant: 'purple', fullTitle: 'ELECTRICITY & ELECTRONICS'}, 
    {title: 'Digital Manu', variant: 'warning', fullTitle: 'DIGITAL MANUFACTURING'}, 
]