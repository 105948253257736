import React, { useState, useEffect, useContext } from "react";

import { SiteContext } from "../../../App.tsx";

export default function SubmitButton({step, setStep, handleCloseLocal, form, goalQuestions}) {

    const {socket, student, room, studentDataFunctions: {updateMongo, setStudent}} = useContext(SiteContext)
    const [warning, setWarning] = useState(false)

    var filter = 0
    goalQuestions[step-1].questions.map(entry => {
      // console.log(form[entry.category][entry.item],'')
      if(!form[entry.category][entry.item] || form[entry.category][entry.item].length === 0) {filter++}
    })
    // console.log(filter,'filter', goalQuestions.length)
    
    var buttonText = step === goalQuestions.length ? `Submit` : `Continue to Step ${step + 1}`    
    var warningText = 'Please answer all questions'
    var warningTriggered = filter > 0 ? true : false
  
    function handleCheckCompletion(e) {
      e.preventDefault()

      if(warningTriggered) {
        setWarning(true)
        return
      }

      // setStep(prev => {return prev + 1})
      var newForm = {...form, display: step + 1}

      if(step === goalQuestions.length) {
        newForm = {...newForm, timeStamp: form.timeStamp ? form.timeStamp : Date.now()}
        handleCloseLocal()
      }

      console.log(student.goalSetting)

      updateMongo({goalSetting: {...student.goalSetting, ...newForm}}, student.dailyListId, 'dailyList')
      setStudent({...student, goalSetting: {...student.goalSetting, ...newForm}})
      socket.emit("send_updateModal", { goalSetting: {...student.goalSetting, ...newForm}, studentId: student.studentId, type: 'goalSetting', room });
    }

    return (
      <div style={{display:"inline", float:"right"}}>
      {(warning && warningTriggered) && <span style={{margin:"10px"}}>{warningText}</span>}
      <button className={`btn btn-${step === goalQuestions.length ? 'primary' : 'info'} ${filter > 0 ? 'incompleteButton' : ''}`} style={{marginRight:"10px"}} onClick={e => handleCheckCompletion(e)}>{buttonText}</button>
      </div>
    )
}
