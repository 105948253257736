import React, { useContext, useState, useEffect, useCallback } from 'react'

import { SiteContext, baseUrl } from '../App.tsx'
import { AdminContext } from './AdminContainer'

import DReview from '../modals/exitTicket/admin/DExitTicket';
import DGoal from '../modals/goalSetting/admin/DGoal'
import DCustomModal from '../modals/customModal/admin/dashResponses/DCustomModal';
import SeatModal from '../admin/seatingChart/SeatModal';
import AgreementsModal from '../behavior/dashboard/focus/AgreementsModal';
import students2324 from '../other/json/students2324.json'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBullseye } from '@fortawesome/free-solid-svg-icons';

import { ErrorBoundary } from '../other/utils/ErrorBoundary';
import useKeyPress from '../other/utils/useKeyPress';

export default function DModals({setExpand, expand}) {

    const { 
      classInformation, studentList, socket, room, focusAgreements, widthWindow,
      studentDataFunctions: {getMongoList, updateFocusSelection, updateMongo, setUpdateFocusAgreements, setFocusAgreements, setStudentList, createMongo}
    } = useContext(SiteContext)

    const {updateStudentListWithDaily, handleShow, modes, setModes, mode, setMode, chooseMode, createCleanStudent } = useContext(AdminContext)

    const [searchString, setSearchString] = useState('')

    async function handleDisplay(e, type) {
        if(e) {e.preventDefault()}

        var output = {}

        // console.log(modes,'modes', mode)

        modes.map(item => {
          output[item.type] = false
        })

        if(type !== 'none') { output[type] = true }
        updateMongo({...output}, classInformation.focusAgreementId, 'focusAgreements') 

        socket.emit("send_modalDisplay", { data: {...output}, room });
        setFocusAgreements(prev => {
            return {...prev, ...output}
        })
        // console.log(type,'type')
        setMode(type === 'none' ? mode : type)
        socket.emit("send_focusAgreementsUpdate", { focusAgreements: output, room: 'admin' });

        // if(type === 'goalSetting') {
        //   console.log('here')
        //   updateFocusSelection({focusCategory: 'Work Times'})
        // }
    }

    useEffect(() => {

      socket.on("receive_updateModal", (data) => {
        console.log(data, 'receive_updateModal', data.data)
        const editStudentList = [...studentList]
        
        var index = editStudentList.findIndex(entry => entry.studentId === data.studentId)
        editStudentList[index][data.type] = {...editStudentList[index][data.type], ...data[data.type]}

        setStudentList(editStudentList)
        // setUpdate(prev => {return !prev})
      })
  
      return () => {
        socket.off('receive_updateModal');
      };
  
  }, [socket, studentList])

  useEffect(() => {
    if(focusAgreements.focusCategory !== 'Reading Zone') return
    handleDisplay(false, 'customModal')
  }, [focusAgreements.focusCategory])

  const onKeyPress = (event) => {
    console.log(`key pressed: ${event.key}`, event.shiftKey, event.altKey, event);
    if(event.shiftKey === true && event.ctrlKey === true) {
      console.log('here')
      if(event.key === 'Z') {handleDisplay(false, 'customModal')}
      if(event.key === 'X') {handleDisplay(false, 'goalSetting')}
      if(event.key === 'C') {handleDisplay(false, 'dayReview')}
      if(event.key === 'V') {handleDisplay(false, 'none')}
    }
  };

  useKeyPress(['Z', 'X', 'C', 'V'], onKeyPress);

  const match = studentList.filter(entry => { 
    return entry.name.toLowerCase().includes(searchString.toLowerCase()) 
  });

  // match.map(entry => {
  //   if(searchString.length < 1) return
  //   entry.matchVariant = 'primary'
  // })

  const noMatch = studentList.filter(entry => { 
    return !entry.name.toLowerCase().includes(searchString.toLowerCase()) 
  });

  const filtered = [...match, ...noMatch]

    function updateExpand(e) {
      e.preventDefault()
      setExpand(prev => {return !prev})
    }

    function handleActive(e, mode) {
        if(e) {e.preventDefault()}
        setMode(mode ? mode : e.target.value)
    }

    function getMode(type) {
      return modes.filter(entry => entry.type === type)[0]
    }

    function updateModes(e) {
      e.preventDefault()

      var activeMode = getMode(mode)
      const editModes = [...modes]
      var index = editModes.findIndex(entry => entry.type === activeMode.type)
      editModes[index].active = e.target.value
      setModes(editModes)
    }

    function Buttons({activeModal}) {

      const payload = {
        title: `Completion: ${getMode(mode) && getMode(mode).text}`,
        mode: 'modal',
        modalMode: mode,
        handleActive
      }

      return (
      <>
        {/* { && 
        <div className="badge bg-primary d-flex align-items-center ms-2 me-2 ps-4 pe-4">Active Modal</div>} */}
        <button
            className={`btn-icon btn-wide btn-outline-2x btn ${activeModal ? `btn-outline-light` : `btn-outline-focus`} m-1 p-1 ps-2 ms-0 pe-2`}
            onClick={e => updateExpand(e)}
        >
            {expand ? 'Collapse' : 'Expand'}
        </button>
        <AgreementsModal activeModal={activeModal} />  
      </>
      )
    }

    if(!focusAgreements) return

    var activeModal = (focusAgreements.customModal || focusAgreements.dayReview || focusAgreements.goalSetting) ? true : false

    var activeModalBg = 'bg-secondary'
      if(focusAgreements.customModal) {activeModalBg = 'bg-primary'}
      if(focusAgreements.dayReview) {activeModalBg = 'bg-purple'}
      if(focusAgreements.goalSetting) {activeModalBg = 'bg-info'}

    return (
        <ErrorBoundary>
        <div className="col-sm-12">  
          <div className="main-card mb-3 card">
            <div className={`card-header h-auto ${activeModal && `${activeModalBg} text-white`}`}>
                Modals
                {/* <div onClick={e => createStudents(e)}>Click me</div> */}
              {widthWindow > 992 && <div className="btn-actions-pane-right">
                  <div className="d-grid gap-1 d-lg-flex justify-content-lg-end">
                    <Buttons activeModal={activeModal} />
                  </div>
              </div>}
            </div>
            {widthWindow < 992 && <div className={`card-header h-auto ${activeModal && `${activeModalBg} text-white`}`}>
              <div className="d-grid gap-1">
                <div className="row">
                <div className="">
                  <Buttons activeModal={activeModal} />
                </div>
              </div>
              </div>
            </div>}
            <div className="card-header h-auto d-flex justify-content-between align-items-center">
              <div className="d-xl-flex justify-content-start align-items-center flex-wrap">
                  <span className="me-2">Dashboard View:</span>
                  <select
                      className="m-1 ms-0 btn-wide btn-outline-2x btn btn-outline-focus btn-sm"
                      onChange={e => handleActive(e)} style={{height:"30px"}}
                      value={mode}
                  >
                      {modes.map(entry => {
                          return <option key={entry.type} value={entry.type}>{entry.text}</option>
                      })}
                  </select>
              </div>
              <div className="btn-actions-pane-right">
                <div className="d-flex justify-content-end align-items-center flex-wrap">
                  <span className="me-2">Student Modal:</span>
                  <select
                      className="m-1 ms-0 btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm manualButton"
                      onChange={e => handleDisplay(e, e.target.value)} style={{height:"30px"}}
                      // value={chooseMode(mode, 'dGoalExit')}
                      value={!activeModal ? 'none' : chooseMode(mode)}
                  >
                      <option value={'none'}>None</option>
                      {modes.map(entry => {
                          return <option key={entry.type} value={entry.type}>{entry.text}</option>
                      })}
                  </select>
                </div>
              </div>
            </div>
            {mode === 'dayReview' && <DReview setExpand={setExpand} expand={expand} filtered={filtered} />}
            {mode === 'goalSetting' && <DGoal setExpand={setExpand} expand={expand} filtered={filtered} />}
            {mode === 'customModal' && <DCustomModal setExpand={setExpand} expand={expand} filtered={filtered} mode={mode} />}
            <div className="d-block text-center card-footer" style={{minHeight:"30px"}}>
            </div>
          </div>
        </div>
        </ErrorBoundary>
    )
}
