import React, {useContext, useState, useEffect } from 'react'

import SubmitModal from './SubmitModal'
import ModalBox from '../../../records/ModalBox'
import AttendanceString from '../attendance/AttendanceString';
import ModalTitle from './Header';
import More from '../../../records/More';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import HallPassMath from '../../../hallPass/admin/hallPassMath/HallPassMath'

import '../studentStatus.css'

import { SiteContext, baseUrl } from '../../../App.tsx'
import { StatusContext } from '../GridMenu';
import { AdminContext } from '../../../pages/AdminContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus, faPhone, faToilet, faFaucet, faPencil, faBed, faRestroom, faArrowDown, faArrowUp, faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
import { faSquareMinus } from '@fortawesome/free-regular-svg-icons';

export default function StatusMenu({student}) {

    const {
        studentList, socket, room, colorMatch,
        studentDataFunctions: {
            setStudentList, levelChangeCalc, createMongo, updateMongo, getMongoAllById
        }
    } = useContext(SiteContext)

    const { handleAddChipsManual, handleColorClick, calcHallPassMath} = useContext(AdminContext)

    const {
        name, studentId, attendance: attendanceValue, chips, period,
        notes, color, phoneTaken, classRole, loggedIn, email, grade, birthday
    } = student

    const [note, setNote] = useState('')
    const [expand, setExpand] = useState(false)
    const hallPasses = student.hallPasses

    function handleLoginClick(e) {
        e.preventDefault()
  
        const editStudentList = [...studentList]
        const index = editStudentList.findIndex(entry => entry.studentId === studentId)
        if(index === -1) return


        const newObj = { 
          loginTimeStamp: student.loggedIn === false ? Date.now() : editStudentList[index].loginTimeStamp,
          loggedIn: !student.loggedIn,
        }

        editStudentList[index] = {...editStudentList[index], ...newObj}
        setStudentList(editStudentList)
        updateMongo({...newObj}, editStudentList[index].dailyListId, 'dailyList')
    }

    function handleUpdateItem(e, type, change) {
      e.preventDefault()
      var changeCalc = change ? change : 1
      var newValue = student[type] ? parseInt(student[type]) + changeCalc : 1
      if(type === 'auctionBalance') {
        newValue = typeof student.auctionBalance !== 'undefined' ? parseInt(student[type]) + changeCalc : 200 + changeCalc
      }
      updateMongo({[type]: newValue}, student._id, 'records')

      const editStudentList = [...studentList]
      var index = editStudentList.findIndex(entry => entry.studentId === student.studentId)
      editStudentList[index][type] = newValue
      socket.emit("send_updateSingleStudent", { student: {...editStudentList[index], [type]: newValue}, room });
      setStudentList(editStudentList)
    }

    function handleExpand() {
      setExpand(prev => {return !prev})
    }

    function handleResetRZ(e) {
      e.preventDefault()

      const editStudentList = [...studentList]
      var index = editStudentList.findIndex(entry => entry.studentId === student.studentId)
      editStudentList[index].readingZoneDate = false
      socket.emit("send_updateSingleStudent", { student: {...editStudentList[index]}, room });
      setStudentList(editStudentList)

      updateMongo({readingZoneDate: false}, editStudentList[index].dailyListId, 'dailyList')
    }

    const match = colorMatch.filter(item => item.color == color.current)
        var variant = match[0].variant   

    const counters = [
      {type: 'bathroomEmergency', variant: 'secondary', icon: faRestroom, description: 'Bathroom Emergencies'},
      {type: 'sleeping', variant: 'purple', icon: faBed, description: 'Sleeping in Class'},
      {type: 'phoneWarning', variant: 'warning', icon: faPhone, description: 'Phone Warning'},
      {type: 'auctionBalance', variant: 'primary', icon: faMoneyCheckDollar, description: 'Auction Money'},
      {type: 'otherWOrk', variant: 'info', icon: faPencil, description: "Working on Other Classes' Work"},
    ]
    
    var phoneVariant = ''
    var phoneNum = parseInt(phoneTaken)
        if(phoneNum === 0) {phoneVariant = 'success'}
        else if(phoneNum === 1) {phoneVariant = 'warning'}
        else if(phoneNum > 1) {phoneVariant = 'danger'}
    
    var bathroomVariant = (!student.bathroomEmergency || student.bathroomEmergency == 0) ? 'success' : student.bathroomEmergency < 3 ? 'warning' : student.bathroomEmergency < 4 ? 'flag' : 'danger'

    var attendanceBg = 
      student.attendance === 'Present' ? 'success' : 
      student.attendance === 'Absent' ? 'danger' : 'warning'
    
    var newDate = student.readingZoneDate ? new Date(student.readingZoneDate).getTime() : 0
      student.weeksSince = student.readingZoneMin && student.readingZoneDate ? Math.floor(datediff(newDate, Date.now())/7) : false

    function datediff(first, second) {        
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }
    return (
    <>
    <div className="dropdown-menu-header">
        <div className={`dropdown-menu-header-inner bg-${attendanceBg}`} style={{borderRadius:"5px"}}>
          <div className="menu-header-image"/>
          <div className="menu-header-content">
              <button
                  type="button"
                  style={{color:"white", fontSize:"10px", display:"inline", border:"2px solid white", textAlign:"center"}}
                  className={`btn btn-pill btn-outline-${variant} btn-${variant}`}
                  onClick={(e) => handleColorClick(e, student)}
              >
                    <span className="lower" style={{padding:"10px"}}>
                      <FontAwesomeIcon style={{fontSize: "14px"}} className="lower" icon={faCircleMinus} />
                    </span>
                    {/* <div style={{display:"inline", width: "250px", margin:"10px"}}> */}
                      <ModalTitle person={student} title={"Expand"} edit={false} variant={"primary"} type={'status'}/>
                    {/* </div> */}
                    {/* <h5 className="menu-header-title" style={{display:"inline", margin:"10px"}}>{name}</h5>&nbsp; */}
                    <span className="raise" style={{padding:"10px"}}>
                      <FontAwesomeIcon style={{fontSize: "14px"}} className="raise" icon={faCirclePlus} />
                    </span>
              </button>
          </div>
        </div>
    </div>
    <div className="card-body">
        <form className="">
            <div className="position-relative mb-3">
                <button
                  style={{color:"white", fontSize:"10px"}}
                  className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-primary active`}
                  onClick={handleExpand}
                >
                  {expand ? 'Hide' : 'Expand'}
                </button>
                {expand && <More person={student} />}
                <ModalBox person={student} title={"Edit"} edit={true} variant={"secondary"} type={'status'}/>
                <button
                  type="button"
                  style={{color:"white", fontSize:"10px"}}
                  className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-${phoneVariant} active`}
                  onClick={(e) => e.preventDefault()}
                >
                  {/* {phoneTaken} - Phone */}
                  <span style={{paddingRight:"5px"}}>
                    {phoneTaken} 
                    <FontAwesomeIcon style={{fontSize: "12px", paddingLeft:"5px"}} className="raise" icon={faPhone} />
                  </span>
                  <span className="raise" onClick={(e) => handleUpdateItem(e, 'phoneTaken')}>
                    <FontAwesomeIcon style={{fontSize: "12px"}} className="raise" icon={faCirclePlus} />
                  </span>
                </button>
                {/* {counters.map(counterEntry => {
                    return (
                    <button key={counterEntry.type}
                      type="button"
                      style={{color:"white", fontSize:"10px"}}
                      className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-${counterEntry.variant} active`}
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="raise" onClick={(e) => handleUpdateItem(e, counterEntry.type, -1)}>
                        <FontAwesomeIcon style={{fontSize: "12px"}} className="raise" icon={faCircleMinus} />
                      </span>
                      <span className="me-2 ms-2">
                        {student[counterEntry.type] ? student[counterEntry.type] : 0} 
                        <FontAwesomeIcon style={{fontSize: "12px"}} className="raise ms-1" icon={counterEntry.icon} />
                      </span>
                      <span className="raise" onClick={(e) => handleUpdateItem(e, counterEntry.type, 1)}>
                        <FontAwesomeIcon style={{fontSize: "12px"}} className="raise" icon={faCirclePlus} />
                      </span>
                    </button>
                    )
                })} */}
                {counters.map(counterEntry => {
                    return <CounterItem key={counterEntry.type} handleUpdateItem={handleUpdateItem} counterEntry={counterEntry} student={student} />
                })}
                <button
                  type="button"
                  style={{color:"white", fontSize:"10px"}}
                  className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-${student.loggedIn ? 'success' : 'danger'} active`}
                  onClick={(e) => handleLoginClick(e)}
                >
                  Login
                </button>
                <button
                  type="button"
                  style={{color:"white", fontSize:"10px"}}
                  className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-${'info'} active`}
                  onClick={(e) => e.preventDefault()}
                >
                   <span className="lower" onClick={(e) => handleAddChipsManual(e, studentId, 0, -1, 'balance')}>
                      <FontAwesomeIcon style={{fontSize: "12px"}} className="lower" icon={faCircleMinus} />
                    </span>
                    <span style={{paddingLeft:"5px", paddingRight:"5px"}}>{chips && chips.balance}€ / {chips && chips.total}€</span>
                    <span className="raise" onClick={(e) => handleAddChipsManual(e, studentId, 1, 0, 'balance')}>
                      <FontAwesomeIcon style={{fontSize: "12px"}} className="raise" icon={faCirclePlus} />
                    </span>
                </button>
                <button
                  type="button"
                  style={{color:"white", fontSize:"10px"}}
                  className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-black active`}
                >
                    {student.readingZoneMin ? <><span onClick={(e) => handleUpdateItem(e, 'readingZoneMin', -1)}>
                      <FontAwesomeIcon style={{fontSize: "12px"}} icon={faCircleMinus} />
                    </span>
                    <span className="ms-1 me-1">
                      <span>
                        {student.readingZoneMin ? (student.readingZoneMin > 20 ? `20+` : student.readingZoneMin) : 0} RZ
                      </span>
                      {student.readingZoneMin < 20 && <span>
                        {` + ${student.weeksSince ? student.weeksSince : 0}`}
                      </span>}
                    {/* {student.readingZoneMin + student.weeksSince > 20 ? ` ${20 - student.readingZoneMin}` : student.weeksSince} */}
                    </span>
                    <span className="" onClick={(e) => handleUpdateItem(e, 'readingZoneMin', 1)}>
                      <FontAwesomeIcon style={{fontSize: "12px"}} icon={faCirclePlus} />
                    </span></> : <span>No RZ Goal</span>}
                </button>
                {student.weeksSince > 0 && <button type="button" style={{color:"white", fontSize:"10px"}} className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-black active`} onClick={e => handleResetRZ(e)}>Reset RZ</button>}
                <HallPassMath key={student.name} hallPasses={hallPasses} student={student} type={'menu'} />
                {/* {attendanceWarning.length !== 0 && <button
                  type="button"
                  style={{color:"white", fontSize:"10px"}}
                  className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-${'flag'} active`}
                  onClick={(e) => e.preventDefault()}
                >
                  {attendanceWarning.split(' -- ')[3]}%
                </button>} */}
                <AttendanceString attendanceString={student.attendanceString} student={student} />
                <button type="button" style={{fontSize:"10px"}}
                  className={`mb-2 me-2 btn-pill btn btn-white btn-outline-2x btn-outline-light font-weight-bold`}
                >
                    <span className="pe-1 text-secondary font-weight-bold">{student.attendancePercent.percent ? student.attendancePercent.percent : 0}%</span>
                    <span className="ps-1 pe-1 text-flag font-weight-bold">{student.attendancePercent.tPercent ? student.attendancePercent.tPercent : 0}%</span>
                    <span className="ps-1 text-danger font-weight-bold">{student.attendancePercent.aPercent ? student.attendancePercent.aPercent : 0}%</span>
                </button>
            </div>
            <div className="position-relative mb-3">
                <input
                    placeholder="Type note here . . ."
                    type="text"
                    className="mb-2 form-control"
                    style={{width:"82%", display:"inline-block"}}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
                <SubmitModal note={note} setNote={setNote}>Submit</SubmitModal>
            </div>
            <AttendanceButton student={student} type={'Tardy'} variant={'warning'} />
            <AttendanceButton student={student} type={'Absent'} variant={'danger'} />
            <AttendanceButton student={student} type={'Present'} variant={'success'} />
        </form>
    </div>
    </>
    )
}

function CounterItem({handleUpdateItem, counterEntry, student}) {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {counterEntry.description}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <button key={counterEntry.type}
        type="button"
        style={{color:"white", fontSize:"10px"}}
        className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-${counterEntry.variant} active`}
        onClick={(e) => e.preventDefault()}
      >
        <span className="raise" onClick={(e) => handleUpdateItem(e, counterEntry.type, -1)}>
          <FontAwesomeIcon style={{fontSize: "12px"}} className="raise" icon={faCircleMinus} />
        </span>
        <span className="me-2 ms-2">
          {student[counterEntry.type] ? student[counterEntry.type] : 0} 
          <FontAwesomeIcon style={{fontSize: "12px"}} className="raise ms-1" icon={counterEntry.icon} />
        </span>
        <span className="raise" onClick={(e) => handleUpdateItem(e, counterEntry.type, 1)}>
          <FontAwesomeIcon style={{fontSize: "12px"}} className="raise" icon={faCirclePlus} />
        </span>
      </button>
    </OverlayTrigger>
  );
}

function AttendanceButton({type, student, variant}) {

  const {
    studentList, studentDataFunctions: { setStudentList }
  } = useContext(SiteContext)

  const {handleAttendanceClick} = useContext(AdminContext)

  var bgColor = student.attendance === type ? variant : 'white'
  var textColor = student.attendance === type ? 'white' : variant
  var outlineColor = student.attendance === type ? 'white' : variant

  return (
    <button
    type="button"
    className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-${outlineColor} bg-${bgColor} text-${textColor}`}
    onClick={(e) => handleAttendanceClick(e, student)}
    value={type}
  >
    {type}
  </button>
  )
}