import React, { useContext, useState, useEffect, createContext } from 'react'

import SInventory from '../../../../modules/inventory/student/SInventory'
import { SiteContext, baseUrl } from '../../../../App.tsx'
import { StudentContext } from '../../../../pages/StudentContainer'
import { ErrorBoundary } from '../../../../other/utils/ErrorBoundary'

import '../exitTicket.css'

export default function Checklist( { checkList, setCheckList, showInventory, update }) {
    
    const { 
      student, classInformation, colorMatch,
      studentDataFunctions: {
        calculateTimeElapsed, handleUpdateCheckout
      }
    } = useContext(SiteContext)
    
    function handleCheck(e, item, parent) {
        e.preventDefault()

        // console.log(item,'item',parent,'parent')

        var editList = [...checkList]

        if(parent) { 
            var index = completeFlip(editList, parent, false)
            completeFlip(editList[index].secondary, item, true)
            setCheckList(editList)
        } else {
            const index = completeFlip(editList, item, true)

            if(editList[index].secondary) {
                editList[index].secondary.map(entry => {
                    entry.complete = editList[index].complete
                })
            }
            setCheckList(editList)
        }

        function completeFlip(obj, searchTerm, flip) {
            var index = obj.findIndex(entry => entry.item === searchTerm)
            if(flip) {
                obj[index].complete = !obj[index].complete
            }
            return index
        }
        
    }  
    
    var count = 0
    var stop = checkList[0].item === 'Return to White Bin:' && checkList[0].secondary.filter(item => !item.complete).length > 0 ? true : false
    return (
    <ErrorBoundary>
        <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="align-middle text-truncate mb-0 table table-borderless table-hover text-secondary">
                    <tbody>
                        {stop ? <SecondaryRender key={0} entry={checkList[0]} count={0} /> :
                        checkList.map(entry => {
                            if(entry.secondary) {
                                count++
                                return <SecondaryRender key={count} entry={entry} count={count} />
                            }
                            return <ChecklistEntry key={entry.item + count} entry={entry} />
                        })}
                    </tbody>
                </table>
              </div>
              {showInventory && <div className={`card p-3`} style={{overflowY: 'scroll', height:'400px', margin:"10px", marginTop:"30px", border: "3px solid #6c757d"}}>
                <SInventory />
              </div>}
            </div>
        </div>
    </ErrorBoundary>
    )


    function ChecklistEntry({entry, parent}) {

        const [complete, setComplete] = useState(entry.badges[0] && entry.badges[0] === 'Turned In' ? true : false)
        var showCheck = entry.item === 'Return to White Bin:' || (parent && parent.item === 'Return to White Bin:') ? false : true
        var indent = parent ? 'indentBox' : ''
        var completeStyle = entry.complete && showCheck ? 'completeStyle' : complete ? 'completeStyle' : ''
        var boxStyle = entry.complete ? 'boxStyle' : ''

        // console.log(entry, 'entry ChecklistEntry')
        
        return (
        <tr className={``}>
            <td className=""> 
              {showCheck &&<input className={`form-check-input ${indent}`} type="checkbox"
                checked={entry.complete}
                onChange={e => {handleCheck(e, entry.item, parent ? parent.item : undefined)}}
              />}
              <span className={`text ${completeStyle}`} style={{marginLeft: !showCheck && parent ? "51px" : !showCheck ? "22px" : "10px"}}>{entry.item}</span>
              {entry.badges && entry.badges.map(item => {
                return <BadgeItem key={item} text={item} entry={entry} parent={parent} setComplete={setComplete} />
              })}
            </td>
        </tr>
        )
    }

    function BadgeItem({text, entry, parent, setComplete}) {
        var filter = badgeOptions.filter(entry => entry.text === text)[0]
        const [turnedIn, setTurnedIn] = useState(false)

        // console.log(parent,'parent')
        return (
        <div className={`badge rounded-pill cardButton bg-${turnedIn ? 'light' : filter.variant}`} role={parent && parent.item === 'Return to White Bin:' ? 'button' : ''} style={{marginLeft:"10px"}} onClick={(e) => {
            e.preventDefault()
            if(parent && parent.item === 'Return to White Bin:') {
                // console.log('triggered', entry.mongoEntry)
                if(turnedIn) return
                setTurnedIn(true)
                setComplete(true)
                handleUpdateCheckout(entry.mongoEntry, student, 'exitTicket')
                // handleCheck(e, entry.item, parent ? parent.item : undefined)
            }
        }}>
            {/* <FontAwesomeIcon style={{ marginRight: 5}} icon={icon} /> */}
            {turnedIn ? 'Turned In' : text}
        </div>
        )
    }

    function SecondaryRender({entry, count}) {

        function Secondaries({}) {
            return (
                <>
                {entry.secondary.map(entry2 => {
                count++
                return <ChecklistEntry key={entry2.item + count} entry={entry2} parent={entry} />
                })}
                </>
            )
        }
        return (
            <>
            <ChecklistEntry key={entry.item + count} entry={entry} />
            <Secondaries/>
            </>
        )
    }

}


const badgeOptions =  [
    {text: 'If Needed', variant: 'light'},
    {text: 'As You Leave', variant: 'secondary'},
    {text: 'Take your time!', variant: 'success'},
    {text: `Click To Turn In`, variant: 'flag'},
    {text: `Turned In`, variant: 'light'},
]