import React, { useContext } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ErrorBoundary } from '../other/utils/ErrorBoundary';
import { SiteContext } from '../App.tsx';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
);


export default function GradeChart({title, colorValue, sizeClass, filteredReviewEntries, type}) {

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1.5,
    // indexAxis: 'y',
    responsive: true,
    scales: {
        x: {
        //   max: 100,
        //   min: -5,
          ticks: {
            // stepSize: 1,
            // autoSkip: false,
            display: true,
            padding: 20
            // callback: function(value, index, ticks) {
            //   return `Day ${value + 1}`;
            // }
          },
          grid: {
            display: false,
            drawBorder: false, 
          },
        },
        y: {
          // position: 'right',
          max: 10,
          min: 1,
          // padding: 10,
          ticks: {
            color: function (context, value) {
                // console.log(context, 'context.dataset.label', context.tick.label)
                var labelVal = context.tick.label.split(' / ')[0].split(' ')[1]
                var index = colorMatch.findIndex(entry => entry.color === labelVal)
                  if(labelVal === 'White') return '#343a40'
                  if(index === -1) {return}
                  return colorMatch[index].hex
              },
            crossAlign: "far",
            font: function (context) {
                var size =  context.chart.height/25
                size = size < 12 ? 12 : size; // setting max limit to 12
                size = size > 16 ? 16 : size; 
                // console.log(size, 'size')
                return {
                  weight: 'bold',
                  size,
                }
            },
            callback: function(value, index, ticks) {
              var index = colorMatch.findIndex(item => item.level === value)
              if(index === -1) {return}
                var string = colorMatch[index].string
                var gradeLetter = colorMatch[index].gradeLetter
              return `${string} / ${gradeLetter}`;
            }
          },
          grid: {
            display: false,
            drawBorder: false, 
          },
        }
    },
    plugins: {
      tooltip: {
        enabled: false // <-- this option disables tooltips
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false
      },
    },
  };
  

  const {colorMatch} = useContext(SiteContext)

  const border = []
  const bgColor = []
  const dataVal = []
  const labels = []

  var count = 0
  var total = 0

  var sortedEntries = filteredReviewEntries.sort( compare );
  sortedEntries.map(entry => {
    if(entry.attendance === 'Absent') {return}
    var index = colorMatch.findIndex(item => item.color === entry.color)
      var level = colorMatch[index].level
    
      count++
      total += level

      var average = total/count
    //   console.log(average,'average', count)
      dataVal.push(average)
      labels.push(entry.dateString)

  })
//   console.log(labels,'labels')

  function createData(obj, key) {

    obj.map(entry => {

      border.push(entry.hex)
      bgColor.push(entry.hex)
    //   labels.push(entry.string)
    
    })
  }

  const dataColors = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: dataVal,
        // borderColor: border,
        // backgroundColor: bgColor,
      },
    ],
  };
  
  type = createData(colorMatch, 'color')

  const data = dataColors

  return (
    <ErrorBoundary>
      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
                {/* <div className="widget-numbers mt-0 fsize-3 text-white"> */}
                  <div className="widget-numbers mt-0 fsize-3">
                      {title}
                  </div>
                </div>
              </div>
              <div className="widget-content-left fsize-1 text-muted">
                <Line options={options} data={data} plugins={[ChartDataLabels]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
  
}


function compare( a, b ) {
    if ( a.timeStamp < b.timeStamp){
      return -1;
    }
    if ( a.timeStamp > b.timeStamp ){
      return 1;
    }
    return 0;
}