import React, {useState, useContext, useEffect} from 'react'

import { SiteContext, baseUrl } from '../../App.tsx';
import { AdminContext } from '../../pages/AdminContainer';

import MessageBox from './MessageBox';
import Dropdown from 'react-bootstrap/Dropdown';

import { ErrorBoundary } from '../../other/utils/ErrorBoundary';

import './messages.css'

export default function MessageCenter() {

    const { 
        classInformationArray,
        classInformation,
        socket,
        studentDataFunctions: {
            setClassInformationArray
        }
    } = useContext(SiteContext)

    const {orders} = useContext(AdminContext)

    const [showMenu, setShowMenu] = useState('hide')
    const [messages, setMessages] = useState(() => {
        const array = []
        classInformationArray.map(entry => {
            array.push({period: entry.period, messages: []})
        })
        return array
    })

    async function getMessages() {
        const response = await fetch(`${baseUrl}/readAll/messages`);
    
        if (!response.ok) {
            const message = `An error occurred MessageCenter getMessages: ${response.statusText}`;
            window.alert(message);
            return;
        }
    
        const messages = await response.json()
        if(!messages) return    
        return messages
    }
    
    function updateMessages(messagesMongo) {
        
        if(!messagesMongo) return
        
        const editMessages = [...messages]    
        editMessages.map(entry => {

            entry.messages.length = 0

            messagesMongo.map(item => {
                if(item.period !== entry.period) {return}
                entry.messages.push(item)
            })
                
            const read = []
            const unread = []
            
            entry.messages.map(item => {
                if(item.viewed) {read.push(item)}
                else {unread.push(item)}
            })
    
            const messagesCombined  = [ ...unread, ...read]
    
            entry.messages = messagesCombined
    
        })
    
        setMessages(editMessages)
    }
    
    async function editMessage(id, obj) {
        await fetch(`${baseUrl}/update/messages/${id}`, {
            method: "POST",
            body: JSON.stringify(obj),
            headers: {
              'Content-Type': 'application/json'
            },
        });
    }

    useEffect(() => {

        getMessages().then((messagesMongo) => {
            updateMessages(messagesMongo)
        })

    }, [])

    useEffect(() => {

        socket.on("receive_message_Center", (data) => {
    
            getMessages().then((messagesMongo) => {
                updateMessages(messagesMongo)
            })
    
        })
    
        return () => {
          socket.off('receive_message_Center');
        };
    
    },[socket])

    async function handleShowMenu(e) {

        console.log('running this')
        e.preventDefault()
        
        if(showMenu === 'hide') {
            // setShowMenu('show')
        }
        if(showMenu === 'show') {

            const editedMessages = [...messages]
            var index = editedMessages.findIndex(entry => entry.period === classInformation.period)
            editedMessages[index].messages.map((entry) => {
                if(entry.viewed === true) {return}
                entry.viewed = true
                const idVal = entry._id
                    delete entry["_id"];

                editMessage(idVal,entry)
            })

            // setShowMenu('hide')
            setMessages(editedMessages)
        }

    }

    var index = messages.findIndex(entry => entry.period === classInformation.period)
    if(index === -1) return
    const unreadOther = messages[index].messages.filter(entry => !entry.viewed && entry.reason !== 'Login').length
    const unreadLogin = messages[index].messages.filter(entry => !entry.viewed && entry.reason === 'Login').length
        if(unreadOther === 0) { var unreadBg = 'secondary'}
        else if(unreadOther > 0 && unreadOther < 4) { var unreadBg = 'warning'}
        else if(unreadOther > 0 && unreadOther > 3) { var unreadBg = 'danger'}
        if(unreadLogin === 0) {var loginBg = 'secondary'}
        else {var loginBg = 'success'}

    var cupsOrders = 0
    var otherOrders = 0
    orders.map(entry => {
        if(entry.period !== classInformation.period) return
        if(entry.item === 'Cup') {
            cupsOrders++
        } else {
            otherOrders++
        }
    })

    return (
    <ErrorBoundary>
    <Dropdown
        align="end"
        className="d-none d-md-block"
    >
        <Dropdown.Toggle 
            type="button" aria-haspopup="true" aria-expanded="false" data-bs-toggle="dropdown"
            className="m-2 dropdown-toggle btn btn-light"
        >
            <span className="m-2 me-2">
                <span>Messages</span>
                <span className={`badge rounded-pill bg-primary text-white`}>{cupsOrders}</span>
                <span className={`badge rounded-pill bg-success text-white`}>{otherOrders}</span>
            </span>   
        </Dropdown.Toggle>

        <Dropdown.Menu
            // style={{ width: "100%", maxWidth: "100%" }}
            tabIndex={-1}
            role="menu"
            aria-hidden="true"
            className="dropdown-menu-xxl dropdown-menu"
        >
                <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-secondary">
                        <div
                        className="menu-header-image"
                        style={{backgroundImage: 'url("assets/images/dropdown-header/abstract1.jpg")'}}
                        />
                            <div className="menu-header-content">
                            <h5 className="menu-header-title">Messages</h5>
                            {/* <h6 className="menu-header-subtitle">Manage all of your options</h6> */}
                        </div>
                    </div>
                </div>
                <MessageBox messages={messages} />
        </Dropdown.Menu>
    </Dropdown>
    </ErrorBoundary>
    )
}
