import React, { useState, useContext, useEffect } from 'react'

import { SiteContext } from '../../App.tsx';
import { AdminContext } from '../../pages/AdminContainer';
import StudentRow from './StudentRow';
import StudentRowEdit from './StudentRowEdit';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus, faPhone, faToilet, faFaucet, faRestroom } from '@fortawesome/free-solid-svg-icons';
import { faSquareMinus } from '@fortawesome/free-regular-svg-icons';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function Group({group, sizeClass, horizontal}) {

    const {classInformation} = useContext(SiteContext)
    const {updateDailyListMongo, handleAddChipsManual, checkoutItems} = useContext(AdminContext)

    const {variant, title, students} = group

    var horizontalVal = horizontal ? 'horizontal' : ''
    var horizontalBox = horizontal ? '' : 'horizontalBox'

    // console.log(group)
    function ChipsBox({entry}) {
      if(group.mode !== 'chips') return
      return (
      <div>
        <span className="lower" onClick={(e) => handleAddChipsManual(e, entry.studentId, 0, -1, 'balance')}>
          <FontAwesomeIcon style={{fontSize: "12px"}} className="lower" icon={faCircleMinus} />
        </span>
        <span style={{paddingLeft:"5px", paddingRight:"5px"}}>{entry.chips.balance}€</span>
        <span className="raise" onClick={(e) => handleAddChipsManual(e, entry.studentId, 1, 0, 'balance')}>
          <FontAwesomeIcon style={{fontSize: "12px"}} className="raise" icon={faCirclePlus} />
        </span>
      </div>
      )
    }
    return (
      <div className={sizeClass} onClick={e => group.handleGroupClick(e, group.title)}>
        <div className={`text-start card bg-${group.modalMode ? 'secondary' : variant} card-shadow-${variant} ${horizontalVal}`} style={{verticalAlign:"top", margin:"5px", minHeight:"90px"}}>
          <div className="text-white" style={{verticalAlign:"top", margin:"8px", fontWeight:"bold", fontSize:"12px"}}>
              {title}
          </div>
          <div className="widget-content p-0 w-100 text-white" style={{verticalAlign:"top", marginTop:0}}>
            <div style={{verticalAlign:"top", width:"100%", borderRadius:"3px", marginLeft:"4px", marginBottom:"8px"}}>
                {group.mode !== 'checkouts' && students.map(entry => {
                    var bgColor = entry.attendance === "Present" ? 'success' : 
                                  entry.attendance === "Tardy" ? 'flag' : 'danger'
                    bgColor = group.mode === 'editSeating' ? 'secondary' : 
                              group.mode === 'chips' ? 'light' : bgColor 
                    bgColor = entry.activeStudent ? 'primary' : bgColor
                    bgColor = group.modalMode ? modalBg(entry, group.modalMode, group.showComplete) : bgColor
                    var textColor = bgColor === 'light' ? '' : group.mode === 'attendance' || group.mode === 'modal' ? 'text-white' : ''

                    if(bgColor === 'return') return

                    return (
                        <button key={entry.studentId} className={`btn btn-${bgColor} attendanceBtn2 ${horizontalBox} ${textColor}`} style={{fontSize:"10px", wordBreak:"break-all", border:"2px solid white", minHeight:"30px", height:"100%"}}
                        onClick={e => group.handleClick(e, entry)}
                        > 
                        <div>
                            <span style={{fontWeight:"bold"}}>{entry.name.split(' ')[0]}</span>
                            <span>{` ${entry.name.split(' ')[1].substring(0, 1)}`}</span>
                        </div>
                        <ChipsBox entry={entry} />
                        </button>
                    )
                })}
                {group.mode === 'checkouts' && checkoutItems.map(entry => {
                    if(entry.module !== group.fullTitle) return
                    if(entry.checkoutEvent.period !== classInformation.period) return
                    return <CheckoutItem key={entry._id} entry={entry} horizontalBox={horizontalBox}/>
                })}
            </div>
          </div>
        </div>
      </div>
    )
}


function CheckoutItem({entry, horizontalBox}) {

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      {entry.checkoutEvent.name.split(' ')[0]} {entry.checkoutEvent.name.split(' ')[1].substring(0,1)}
    </Tooltip>
  );
  
  if(!entry.checkedOut && !entry.checkoutEvent.adminProcessed) return

  var variant = !entry.checkedOut && entry.checkoutEvent.adminProcessed ? 'secondary' : 
                entry.checkedOut && !entry.checkoutEvent.adminProcessed ? 'flag' :
                entry.checkedOut && entry.checkoutEvent.adminProcessed ? 'warning' : 'light'
                
  return (
    <OverlayTrigger
    placement="right"
    // delay={{ show: 250, hide: 400 }}
    overlay={renderTooltip}
    >
    <button key={entry.item} className={`btn btn-${variant} attendanceBtn2 ${horizontalBox}`} style={{fontSize:"10px", wordBreak:"break-all", border:"2px solid white", minHeight:"30px", height:"100%"}}
    // onClick={e => group.handleClick(e, entry)}
    > 
    <div>
        {entry.item.substring(0, 15)}
        {/* <span style={{fontWeight:"bold"}}>{entry.name.split(' ')[0]}</span> */}
        {/* <span>{` ${entry.name.split(' ')[1].substring(0, 1)}`}</span> */}
    </div>
    {/* <ChipsBox entry={entry} /> */}
    </button>
    </OverlayTrigger>
  )
}

function modalBg(student, modalMode, showComplete) {
  // console.log(student[modalMode],' student[modalMode]', student[modalMode].display)
    if(student.attendance === 'Absent') return 'return'
    if(student[modalMode].display !== 1 && student[modalMode].timeStamp) {
      if(!showComplete) {return 'return'}
      if(showComplete) {return 'light'}
    }
    if(student.modalOverride) return 'secondary'
    if(student[modalMode].display !== 1) return 'info'
    // if(student[modalMode].timeStamp) return 'return'
    return 'flag'
}
