import React, {useState} from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { SchoolCycleLookFor, SchoolStaff } from "../../../types/school.ts"; // ✅ Import correct types
import axios from "axios";
import { baseUrl } from "../../../App.tsx";

interface SchoolLookForsProps {
    entry: { lookFors: SchoolCycleLookFor[] };
    lookFors: { _id: string; topic: string; description: string }[];
    staff: SchoolStaff[];
    schoolId: string;
    cycleNum: number;
}
  

const SchoolLookFors: React.FC<SchoolLookForsProps> = ({ entry, lookFors, staff, schoolId, cycleNum }) => {

  const [editableLookForID, setEditableLookForID] = useState<string | null>(null);
  const [updatedLookFor, setUpdatedLookFor] = useState<Partial<SchoolCycleLookFor>>({});
  const [lookForsState, setLookForsState] = useState<SchoolCycleLookFor[]>(entry.lookFors); // ✅ State for local lookFors

  // Toggle Edit Mode
  const handleToggleEdit = (lookForID: string) => {
    setEditableLookForID(prev => (prev === lookForID ? null : lookForID));
    setUpdatedLookFor({});
  };

  // Handle Changes
  const handleChange = <K extends keyof SchoolCycleLookFor>(field: K, value: SchoolCycleLookFor[K]) => {
    setUpdatedLookFor(prev => ({ ...prev, [field]: value }));
  };

  // Save Changes
  const handleSaveChanges = async (lookForID: string) => {
    if (!updatedLookFor) return;

    try {
      await axios.put(`${baseUrl}/api/schools/${schoolId}/cycles/${cycleNum}/lookFors/${lookForID}`, updatedLookFor);
      alert("LookFor updated successfully!");
      setEditableLookForID(null);

      // Update UI immediately without requiring refresh
      setLookForsState(prevLookFors =>
            prevLookFors.map(lf => (lf.lookForID === lookForID ? { ...lf, ...updatedLookFor } : lf))
      );

    } catch (error) {
      console.error("Error updating LookFor:", error);
    }
  };

  return (
    <>
      {lookForsState && lookForsState.length > 0 && lookFors.length > 0 ? (
        <div className="mt-3 container bg-light rounded p-3">
          <h5 className="fw-bold ">🔍 Look Fors:</h5>
          {lookForsState.map((lookForEntry, index) => {
            const matchedLookFor = lookFors.find(lf => lf._id === lookForEntry.lookForID);
            const matchedList = lookForEntry.teacherIDs.map(id => staff.find(s => s.staffID === id)?.name || `Unknown (${id})`);
            const chosenByList = lookForEntry.chosenBy.map(id =>
              id === "Coach" || id === "Admin" ? id : staff.find(s => s.staffID === id)?.name || `Unknown (${id})`
            );

            return (
              <div key={index} className="border rounded p-2 mb-2 bg-white">
                {/* LookFor Content */}
                {matchedLookFor ? (
                  <div className="ps-2 pe-2 m-1">
                    <Row className="text-center d-flex align-items-stretch">
                      <Col md={1} className="rounded p-0 m-0 d-flex">
                        <div className={`m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-center text-center bg-opacity-50 bg-${matchedLookFor.studentFacing ? "danger" : "warning"}`}>
                          <h2 className="fw-bold">{matchedLookFor.studentFacing ? "🍎" : "✏️"}{index + 1}</h2>
                        </div>
                      </Col>
                      <Col md={editableLookForID === lookForEntry.lookForID ? 9 : 10} className="rounded p-0 m-0 d-flex">
                        <div className="m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-between text-start bg-secondary text-white">
                          <div className="">
                            <span className="h4 fw-bold">{matchedLookFor.topic}</span>
                            <span className="ms-4 h5">
                              {chosenByList.includes("Coach") ? "📝 " : chosenByList.includes("Admin") ? "🎓 " : "🍎 "} Metric
                            </span>
                          </div>
                          <div className="flex-grow-1 mb-1">
                            <div className="flex-grow-1 mb-1" dangerouslySetInnerHTML={{ __html: matchedLookFor.description }} />
                          </div>
                        </div>
                      </Col>
                      {editableLookForID === lookForEntry.lookForID && <Col md={1} className="rounded p-0 m-0 d-flex">
                        <Button
                          className={`m-1 pt-2 pb-1 p-2 w-100 bg-success text-white h5 fw-bold border border-0`}
                          onClick={() => handleSaveChanges(lookForEntry.lookForID)}
                        >
                          <span className="me-1">💾 </span>Save
                        </Button>
                      </Col>}
                      <Col md={1} className="rounded p-0 m-0 d-flex">
                        <Button
                          className={`m-1 pt-2 pb-1 p-2 w-100 bg-${editableLookForID === lookForEntry.lookForID ? 'danger' : 'primary'} text-white h5 fw-bold border border-0`}
                          onClick={() => handleToggleEdit(lookForEntry.lookForID)}
                        >
                          {editableLookForID === lookForEntry.lookForID ? "Cancel" : "Edit"}
                        </Button>
                      </Col>

                    </Row>
                  </div>
                ) : (
                  <p className="text-muted">Look For not found</p>
                )}
                <div className="p-2 pt-0 m-1">
                 {editableLookForID !== lookForEntry.lookForID ? 
                  <Row className="text-center d-flex align-items-stretch">
                    <Col md={6} className="rounded p-0 m-0 d-flex">
                      <div className={`m-1 rounded p-3 w-100 d-flex flex-column justify-content-start text-start bg-light ${editableLookForID === lookForEntry.lookForID && "border border-3 border-success"}`}>
                        <div className="mb-1">
                          <strong>{matchedList.length > 1 ? 'Teachers' : 'Teacher'} Using Look For:</strong>
                          {matchedList.map(item => <div key={item}>{item}</div>)}
                        </div>
                        <p className="mb-1 mt-2">
                          <strong>
                            Chosen By:
                          </strong>
                          <div>
                          {chosenByList.includes('Coach') && "📝 Coach"}
                          {chosenByList.includes('Admin') && "🎓 Admin"}
                          {!chosenByList.includes('Coach') && 
                            !chosenByList.includes('Admin') && 
                            chosenByList.length > 0 && 
                            <span>🍎 {chosenByList.join(", ")}</span>}
                          </div>
                        </p>
                        <p className="fw-bold mt-2">{lookForEntry.active ? "✅ Active" : "⏸️ Disabled"}</p>
                      </div>
                    </Col> 
                    <Col md={6} className="rounded p-0 m-0 d-flex">
                      <div className={`m-1 rounded p-3 w-100 d-flex flex-column justify-content-start text-start bg-light`}>
                        <p className="mb-1"><strong>Scores:</strong></p>
                        {lookForEntry.scores && lookForEntry.scores.length > 0 ? (
                          <div className="mt-2">
                            <h6 className="fw-bold">📊 Scores:</h6>
                            {lookForEntry.scores.map((score, scoreIndex) => (
                              <div key={scoreIndex} className="border rounded p-2 mb-1">
                                <p className="mb-1"><strong>Date:</strong> {score.date}</p>
                                <p className="mb-1"><strong>Score:</strong> {score.score}</p>
                                <p className="mb-1"><strong>Staff ID:</strong> {score.staffID}</p>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="text-muted">No scores recorded</p>
                        )}
                      </div>
                    </Col>
                  </Row>                   
                     : 
                    <div className="pt-0">
                        <Row className="text-center d-flex align-items-stretch">
                            {/* Chosen By */}
                            <Col md={5} className="rounded p-0 m-0 d-flex">
                            <div className="m-1 p-3 w-100 bg-light border border-success border-4 rounded">
                                <Form.Label className="d-flex fw-bold mb-2 ms-1 text-start">Chosen By:</Form.Label>
                                <Form.Select
                                multiple
                                value={updatedLookFor.chosenBy ?? lookForEntry.chosenBy}
                                onChange={(e) =>
                                    handleChange("chosenBy", Array.from(e.target.selectedOptions, option => option.value))
                                }
                                >
                                <option value="Coach">Coach</option>
                                <option value="Admin">Admin</option>
                                {staff.filter(member => member.role.includes("Teacher")).map(member => (
                                    <option key={member.staffID} value={member.staffID}>{member.name}</option>
                                ))}
                                </Form.Select>
                            </div>
                            </Col>

                            {/* Teacher IDs */}
                            <Col md={5} className="rounded p-0 m-0 d-flex">
                            <div className="m-1 p-3 w-100 bg-light border border-success border-4 rounded">
                                <Form.Label className="fw-bold d-flex text-start ms-1">Teacher(s):</Form.Label>
                                <Form.Select
                                multiple
                                value={updatedLookFor.teacherIDs ?? lookForEntry.teacherIDs}
                                onChange={(e) =>
                                    handleChange("teacherIDs", Array.from(e.target.selectedOptions, option => option.value))
                                }
                                >
                                {staff.filter(member => member.role.includes("Teacher")).map(member => (
                                    <option key={member.staffID} value={member.staffID}>{member.name}</option>
                                ))}
                                </Form.Select>
                            </div>
                            </Col>
                            <Col md={2} className="rounded p-0 m-0 d-flex">
                            <div className="m-1 p-3 w-100 bg-light border border-success border-4 rounded">
                                <Form.Check
                                type="checkbox"
                                label="Active"
                                checked={updatedLookFor.active ?? lookForEntry.active}
                                onChange={(e) => handleChange("active", e.target.checked)}
                                />
                            </div>
                            </Col>
                        </Row>
                      </div>
                    }
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="text-muted">No Look Fors assigned yet.</p>
      )}
    </>
  );
};


export default SchoolLookFors;
