import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';

import { v4 as uuidv4 } from 'uuid'
import { SiteContext } from "../../../App.tsx";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus } from '@fortawesome/free-solid-svg-icons';
 
export default function EditModal({handleShow, handleClose, show, type, classPeriod}) {

    const {
      student, socket, room, classInformationArray, classInformationArrayArchived,
      studentDataFunctions: {setClassInformation, setClassInformationArray, createMongo, updateMongo, deleteMongo, cleanFocusAgreement, }
    } = useContext(SiteContext)

    const [modifiedPeriod, setModifiedPeriod] = useState(classPeriod)

    const combinedArray = [...classInformationArray, ...classInformationArrayArchived]

    function createNewPeriod() {

        const creationId = uuidv4()
        const newPeriodFocus = cleanFocusAgreement({}, true)
        // newPeriodFocus._id = focusAgreementId

        createMongo({...newPeriodFocus, creationId} ,'focusAgreements')

        console.log(creationId, 'creationId')
        const newPeriod = {
            period: `New Period (${classInformationArray.length})`,
            agreements: [],
            classRoles: [],
            focusSchedule: focusSchedule,
            dailyEventSubmitted: false,
            information: [],
            informationStrings: {
              period: `New Period (${classInformationArray.length})`,
              periodAbbreviation: `(${classInformationArray.length})`,
              schoolYear: `${new Date().getFullYear()}`,
              sectionId: '',
              googleClassroomLink: false,
            },
            creationId
        }
        createMongo(newPeriod,'classPeriods')

        window.location.reload();
    }
    
    const sendMessage_updateClassInformation = (classInformation, roomInput) => {
      socket.emit("send_message_updateClassInformation", { classInformation, room: roomInput });
    };

    var count = 0

 return (
  <ErrorBoundary>    
    <div onKeyDown={e => e.stopPropagation()}>
    <Modal show={show} dialogClassName="my-modal3" onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Manage Classes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {combinedArray.map(classPeriod => {
            count++
            return <ClassCard key={classPeriod.period + classPeriod._id} classPeriod={classPeriod} />
        })}
      </Modal.Body>
      <Modal.Footer>
          <button className={`btn btn-success`} onClick={createNewPeriod}>+ New Class</button>
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
);
}


function ClassCard({classPeriod}) {

    const {
        studentDataFunctions: {updateMongo, setClassInformationArray, deleteMongo, setClassInformationArrayArchived}
    } = useContext(SiteContext)

    const [archived, setArchived] = useState(classPeriod.archived)
    const [showDelete, setShowDelete] = useState(false)

    function handleArchive(e, currentArchive) {
        e.preventDefault()
        const newClassPeriod = {...classPeriod}
        delete newClassPeriod._id
        updateMongo({...newClassPeriod, archived: currentArchive}, classPeriod._id, 'classPeriods')

        setClassInformationArray(current => {
            // var filtered = 
            // var index = current.findIndex(entry => entry._id === classPeriod._id)
            // current[index].archived = currentArchive
            if(currentArchive) return current.filter(entry => entry._id !== classPeriod._id)
            else return [...current, {...newClassPeriod, archived: currentArchive}]
        })
        setClassInformationArrayArchived(prev => {
          if(currentArchive) return [...prev, {...newClassPeriod, archived: currentArchive}]
          else return prev.filter(entry => entry._id !== classPeriod._id)
        })

        setArchived(currentArchive)
    }

    function activateDelete(e, outcome) {
        e.preventDefault()

        setShowDelete(outcome)
    }

    function handleDelete(e) {
        e.preventDefault()
        console.log(classPeriod, 'classPeriod')
        deleteMongo(classPeriod._id, 'classPeriods')
        deleteMongo(classPeriod.focusAgreementId, 'focusAgreements')

        window.location.reload();
    }

    return <div className="card mb-2 shadow-none">
        <div className="card-body d-flex justify-content-between align-items-center p-2">
            <div>
            <span>{classPeriod.period}</span>
            {classPeriod.informationStrings && <span className="ms-2">({classPeriod.informationStrings.schoolYear})</span>}
            <div className={`ms-3 badge ${archived ? 'bg-primary' : 'bg-success'}`}>{archived ? 'Archived' : 'Active'}</div>
            </div>
            <div className="btn-group" role="group">
                {!showDelete ? 
                <>
                {archived && <div type="button" className={`btn btn-outline-success`} onClick={e => handleArchive(e, false)}>Active</div>}
                {!archived && <div type="button" className={`btn btn-outline-primary`} onClick={e => handleArchive(e, true)}>Archive</div>}
                <div type="button" className={`btn btn-outline-danger`} onClick={e => activateDelete(e, true)}>
                    <FontAwesomeIcon icon={faX} />
                </div>
                </>
                :
                <>
                <div type="button" className={`btn btn-outline-danger`} onClick={e => handleDelete(e, true)}>Confirm Delete</div>
                <div type="button" className={`btn btn-outline-secondary`} onClick={e => activateDelete(e, false)}>Cancel</div>
                </>}
            </div>
        </div>
    </div>
}


function newCategoryEntry () {
  return {
    id: uuidv4(),
    entry: ''
  }
}

const focusSchedule = [
  {
    id: uuidv4(),
    category: 'Monday',
    elements: [
      newCategoryEntry()
    ]
  },
  {
    id: uuidv4(),
    category: 'Tuesday',
    elements: [
      newCategoryEntry()
    ]
  },
  {
    id: uuidv4(),
    category: 'Wednesday',
    elements: [
      newCategoryEntry()
    ]
  },
  {
    id: uuidv4(),
    category: 'Thursday',
    elements: [
      newCategoryEntry()
    ]
  },
  {
    id: uuidv4(),
    category: 'Friday',
    elements: [
      newCategoryEntry()
    ]
  },
]