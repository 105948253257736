export default function LessonPhaseTracker() {
  const phases = [
    { name: "Warm Up", time: "5 min", color: "border-blue-900", bg: "bg-blue-100", text: "text-blue-900" },
    { 
      name: "Activity 1", 
      time: "", 
      color: "border-blue-900", 
      bg: "bg-blue-900", 
      text: "text-white", 
      subphases: [
        { name: "Launch", color: "border-blue-900", bg: "bg-white", text: "text-blue-900" },
        { name: "Student Work Time", color: "border-blue-400", bg: "bg-blue-400", text: "text-white" },
        { name: "Instructional Routine", color: "border-yellow-400", bg: "bg-yellow-400", text: "text-white" },
        { name: "Synthesis", color: "border-orange-400", bg: "bg-orange-400", text: "text-white" }
      ]
    },
    { name: "Lesson Synthesis", time: "5 min", color: "border-orange-400", bg: "bg-orange-100", text: "text-orange-900" },
    { name: "Cool Down", time: "5 min", color: "border-blue-400", bg: "bg-blue-100", text: "text-blue-900" }
  ];

  return (
    <div className="flex flex-col items-start p-4 space-y-4 w-full max-w-md mx-auto">
      {phases.map((phase, index) => (
        <div key={index} className={`border-2 rounded-lg p-4 w-full ${phase.bg} ${phase.color}`}> 
          <p className={`font-bold text-lg ${phase.text}`}>{phase.name} {phase.time && <span>({phase.time})</span>}</p>
          {phase.subphases && (
            <div className="ml-4 mt-2 space-y-1">
              {phase.subphases.map((subphase, subIndex) => (
                <div key={subIndex} className={`border-2 rounded-lg p-2 ${subphase.bg} ${subphase.color} ${subphase.text}`}>
                  {subphase.name}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
