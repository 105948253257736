import React, {useState, useContext, useEffect} from 'react'
import { SiteContext } from '../../App.tsx';

import CalendarPicker from 'react-calendar';
import { differenceInCalendarDays } from 'date-fns';

import 'react-calendar/dist/Calendar.css';
import '../schedulesCalendar/schedules.css'
// const datesToAddClassTo = [];

export default function Calendar({calendarExport}) {

    const {colorMatch, schedules, calendarDays, loginState, studentDataFunctions: {setCalendarDays, createMongo, updateMongo}} = useContext(SiteContext)
    const {firstDate, setFirstDate, secondDate, setSecondDate} = calendarExport

    const [value, setValue] = useState(new Date());

    // useEffect(() => {
    //     if(firstDate && secondDate && firstDate.getTime() > secondDate.getTime()){
    //         setSecondDate(false)
    //     }
    // }, [firstDate, secondDate])

    function editSecondDate(secondDateEdit) {
      // secondDateEdit.setHours(0)
      // secondDateEdit.setMinutes(0)
      // secondDateEdit.setSeconds(0)
      return secondDateEdit
    }

    var editedSecondDate = secondDate ? editSecondDate(secondDate) : secondDate
    const activeDates = [firstDate, editedSecondDate]

    function onChange(nextValue){
        setValue(nextValue);

        if(firstDate && !secondDate) {
            if(firstDate.getTime() > nextValue.getTime()) {
                setFirstDate(nextValue)
            }
            const secondDateLate = nextValue
            secondDateLate.setMinutes(59)
            secondDateLate.setHours(23)
            secondDateLate.setSeconds(50)
            // console.log(secondDateLate,'secondDateLate')
            setSecondDate(new Date(secondDateLate.getTime()))
        } else if(firstDate && secondDate) {
            setFirstDate(nextValue)
            setSecondDate(false)
        } else {
            setFirstDate(nextValue)
        }
    }

    function tileClassName({ date, view }) {
        // Add class to tiles in month view only
        if (view === 'month') {
            // console.log(date,'date')
          if(firstDate && secondDate 
            && new Date(date).getTime() > firstDate.getTime() 
            && new Date(date).getTime() < secondDate.getTime()) 
          {
            return 'styledTile bg-secondary'
          }
          var index = activeDates.findIndex(entry => entry && entry.getTime() === new Date(date).getTime())
        //   console.log(activeDates,'activeDates')
          if(index === -1) return 'noBackground'
          if(index === 0) return 'styledTile bg-flag'
          if(index === 1) return 'styledTile bg-danger'
        }
      }

    return (
     <div className="d-flex justify-content-between align-items-start flex-wrap">
      <div className="m-2">
        <CalendarPicker
            onChange={loginState.admin ? onChange : () => {}}
            value={value}
            tileClassName={tileClassName}
            next2Label={null}
            prev2Label={null}
            maxDetail={'month'}
            maxDate={new Date(2030, 0, 1)}
            minDate={new Date(2020, 0, 1)}
        />
        {firstDate && secondDate && <div className="m-2 flex-wrap d-flex justify-content-between">
          <span className="me-2">Range:</span>
          <div className="badge bg-flag d-flex align-items-center m-1">{firstDate && firstDate.toDateString()}</div>
          to
          <div className="badge bg-secondary d-flex align-items-center m-1">{secondDate && secondDate.toDateString()}</div>
        </div>}
       </div>
      </div>
    );
}