import React, { useContext, useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { SiteContext } from "../App.tsx";
import { StudentContext } from '../pages/StudentContainer';
import { ErrorBoundary } from '../other/utils/ErrorBoundary';

import HallPassMenuStatus from '../hallPass/student/header/HallPassMenuStatus';
import Dropdown from 'react-bootstrap/Dropdown';
import HallPassModal from '../hallPass/student/modal/HallPassModal';
import DayReview from '../modals/exitTicket/student/ExitTicket';
import SAssignmentsModal from '../modals/assignments/student/SAssignmentsModal';
import GoalSetting from '../modals/goalSetting/student/GoalSetting';
import PaxPatModal from '../modals/goalSetting/student/PaxPat';
import ShoutoutModal from '../behavior/student/ShoutoutModal';
import CustomModal from '../modals/customModal/student/SCustomModal';
import GoalSettingStatic from '../modals/goalSetting/student/GoalSettingStatic'
import DAdminPages from './DAdminPages';
import InProgress from '../student/studentCards/InProgress';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './header.css'

export default function StudentHeader( { studentName, show, handleClose, handleShow, handleStudentOverride, update, type }) {

    const {
        focusAgreements, goalQuestions, initialLoad, loginState, modalShowFunction,
        studentDataFunctions: {
            handleLogout, updateRandomIndex
        },
        classInformation, student
    } = useContext(SiteContext)

    const {connected} = useContext(StudentContext)
    // const [show, setShow] = useState(false);

    function findShow(type, parameter) {
        var index = show.findIndex(entry => entry.type === type)
        return parameter ? show[index][parameter] : show[index].show 
    }

    function findOverride(type) {
        var index = show.findIndex(entry => entry.type === type)
        return show[index].show
    }

    const navigate = useNavigate();

    function handleNavigate(item) {
        navigate(`/${item}`, { replace: true });
    }

    let location = useLocation();

    var gcLink = classInformation.informationStrings && classInformation.informationStrings.googleClassroomLink ? classInformation.informationStrings.googleClassroomLink : 'https://classroom.google.com/'

    function HallPassMessage({}) {
        // const message = HallPassMenuStatus()
        return (
            <Dropdown.Item 
                onClick={e => {
                // if(message !== 'Create Hall Pass') return
                handleShow(e, 'hallPassInitial')
                }}
            >   
                Create Hall Pass
                {/* {message !== 'Create Hall Pass' ? 
                    <>
                    <span className="me-1">No Hall Passes</span>
                    <div className="badge bg-secondary">
                        <HallPassMenuStatus />
                    </div>
                    </>
                    :
                    <HallPassMenuStatus />
                }  */}
            </Dropdown.Item>
        ) 
    }

    var unhideModal = focusAgreements.dayReview ? 'dayReview' : focusAgreements.goalSetting ? 'goalSetting' : false
    var unhideText = focusAgreements.dayReview ? 'Exit Ticket' : focusAgreements.goalSetting ? 'Goal Setting' : false

    function CompleteBadge({text}) {
        return (
          <div className="badge bg-light ms-3 d-flex align-items-center">
            <span className="p-2 text-wrap">{text}</span>
            <FontAwesomeIcon className="p-2 ps-0" icon={faCheck} />
        </div>
        )
    }

    return (
    <ErrorBoundary>
      <div className='notranslate'>
        <ErrorBoundary>
        <>
            <PaxPatModal handleClose={handleClose} handleShow={handleShow} show={findShow('paxPat')} type={'paxPat'} update={update} />
            <ShoutoutModal handleClose={handleClose} handleShow={handleShow} show={findShow('shoutout')} type={'shoutout'} update={update} />
            <DayReview handleClose={handleClose} handleShow={handleShow} show={findShow('dayReview')} type={'dayReview'} update={update} />
            <GoalSetting handleClose={handleClose} handleShow={handleShow} show={findShow('goalSetting')} type={'goalSetting'} update={update} />
            <CustomModal handleClose={handleClose} handleShow={handleShow} handleStudentOverride={handleStudentOverride} show={findShow('customModal')} type={'customModal'} update={update} />
            <GoalSettingStatic handleClose={handleClose} handleShow={handleShow} show={findShow('goalSettingStatic')} type={'goalSettingStatic'} update={update} />
            <HallPassModal handleClose={handleClose} handleShow={handleShow} show={findShow('hallPassInitial')} type={'hallPassInitial'} update={update} />
            <SAssignmentsModal handleClose={handleClose} handleShow={handleShow} show={findShow('assignments')} type={'assignments'} update={update} />
        </>
        </ErrorBoundary>
        <div className="app-header header-shadow">
            <div className="app-header__content">
                <div className="app-header-left">
                        {location.pathname === '/student' && 
                        (loginState.admin ? <AdminPages/> : <span>CCR Helms - {classInformation.period}</span>)}
                        {location.pathname !== '/student' && location.pathname !== '/checkin' && 
                            <button
                              className="btn btn-primary"
                              onClick={() => handleNavigate('student')}
                            >
                            <span className='translate'>Return to Homepage</span>
                            </button>}
                        {findShow('customModal', 'studentOverride') && <button onClick={e => handleStudentOverride(e, student.customOverride, 'customModal')} className="btn btn-purple text-white ms-4">
                            Show Popup
                        </button>}
                        {student.goalSetting && student.goalSetting.display > 4 && focusAgreements.goalSetting && 
                            <CompleteBadge text={'Goal Setting'} />}
                        {student.dayReview && student.dayReview.display > 3 && focusAgreements.dayReview &&                             
                            <CompleteBadge text={'Exit Ticket'} />}
                        {student.modalOverride && loginState.admin && <button onClick={e => handleShow(e, unhideModal)} className="btn btn-light ms-4">
                            Show {unhideText}
                        </button>}
                        {type !== 'checklistS' && <InProgress />}
                </div>
                <div className="app-header-right">
                    {initialLoad && !loginState.admin && !connected && <button style={{padding:"8px", margin: "10px", width:"50%"}} className="btn btn-danger">
                            No Connection
                    </button>}
                    {/* {loginState.admin && <div className="badge bg-light" role="button" style={{fontSize:"10px"}} onClick={e => updateRandomIndex(e)} role="button">Randomize</div>} */}
                    <div className="page-title-head center-elem">
                        <Dropdown>
                            <Dropdown.Toggle variant="white" id="dropdown-basic">
                                {studentName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu size="sm">
                                {/* {loginState.admin ? <StudentHeaderPages/> : <StudentHeaderPages/>} */}
                                <Dropdown.Item onClick={() => handleNavigate('genInfo')}>
                                    General Information
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigate('inventoryS')}>
                                    Lab Inventory
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigate('checklistS')}>
                                    Progress Checklist
                                </Dropdown.Item>
                                <div className="dropdown-divider"></div>
                                {/* {location.pathname === '/student' && <HallPassMessage/>} */}
                                <HallPassMessage/>
                                {/* <Dropdown.Item onClick={() => handleNavigate('store')}>
                                    Chips Store
                                </Dropdown.Item> */}
                                <Dropdown.Item onClick={() => handleNavigate('colorReview')}>
                                    Colors Review
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigate('preview')}>
                                    Module Previews
                                </Dropdown.Item>
                                <div className="dropdown-divider"></div>
                                <Dropdown.Item href={gcLink} target="_blank">
                                    Google Classroom
                                </Dropdown.Item>
                                <Dropdown.Item href="https://lms.paxpat.com/" target="_blank">
                                    PaxPat Website
                                </Dropdown.Item>
                                <Dropdown.Item onClick={e => handleShow(e, 'assignments')}>
                                    Assignments
                                </Dropdown.Item>
                                {/* <Dropdown.Item href="https://forms.gle/1hyRwoThhbwLNumZA" target="_blank">
                                    Grade Update Reminder
                                </Dropdown.Item> */}
                                <div className="dropdown-divider"></div>
                                <Dropdown.Item href="https://www.pooltracker.com/w/tourney/pool_standings.asp?poolid=230554" target="_blank">
                                    Men's Bracket
                                </Dropdown.Item>
                                {/* <Dropdown.Item href="https://www.pooltracker.com/w/tourney/index.asp?poolid=230662" target="_blank">
                                    Women's Bracket
                                </Dropdown.Item> */}
                                <div className="dropdown-divider"></div>
                                <Dropdown.Item onClick={() => handleNavigate('profile')}>
                                    Profile
                                </Dropdown.Item>
                                <Dropdown.Item 
                                    onClick={handleLogout} 
                                >
                                    Log Out
                                </Dropdown.Item>
                                {loginState.admin && <>
                                <div className="dropdown-divider"></div>
                                <Dropdown.Item onClick={() => handleNavigate('dashboard')}>
                                    Return to Admin Dashboard
                                </Dropdown.Item></>}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </ErrorBoundary>
    )
}

function AdminPages({}) {

    const {classInformation, loginState, studentDataFunctions: {handleLogout}} = useContext(SiteContext)

    const navigate = useNavigate();
    let location = useLocation();

    function handleNavigate(item) {
        navigate(`/${item}`, { replace: true });
    }

    return (
        <Dropdown align="end">
            <Dropdown.Toggle variant="white" id="dropdown-basic">
                CCR Helms - {classInformation.period}
            </Dropdown.Toggle>

            <Dropdown.Menu size="sm">
                {/* <Dropdown.Item onClick={() => handleNavigate('genInfo')}>
                    General Information
                </Dropdown.Item> */}
                <DAdminPages/>
            </Dropdown.Menu>
        </Dropdown>
    )
}

function StudentHeaderPages({}) {

    const {classInformation, loginState, studentDataFunctions: {handleLogout}} = useContext(SiteContext)

    const navigate = useNavigate();
    let location = useLocation();

    function handleNavigate(item) {
        navigate(`/${item}`, { replace: true });
    }

    var gcLink = classInformation.informationStrings && classInformation.informationStrings.googleClassroomLink ? classInformation.informationStrings.googleClassroomLink : 'https://classroom.google.com/'

    return (
    <>
        <Dropdown.Item onClick={() => handleNavigate('genInfo')}>
            General Information
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleNavigate('inventoryS')}>
            Lab Inventory
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleNavigate('checklistS')}>
            Progress Checklist
        </Dropdown.Item>
        <div className="dropdown-divider"></div>
        {location.pathname === '/student' && <HallPassMessage/>}
        <Dropdown.Item onClick={() => handleNavigate('store')}>
            Chips Store
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleNavigate('colorReview')}>
            Colors Review
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleNavigate('preview')}>
            Module Previews
        </Dropdown.Item>
        <div className="dropdown-divider"></div>
        <Dropdown.Item href={gcLink} target="_blank">
            Google Classroom
        </Dropdown.Item>
        <Dropdown.Item href="https://lms.paxpat.com/" target="_blank">
            PaxPat Website
        </Dropdown.Item>
        <Dropdown.Item href="https://forms.gle/1hyRwoThhbwLNumZA" target="_blank">
            Grade Update Reminder
        </Dropdown.Item>
        <div className="dropdown-divider"></div>
        <Dropdown.Item onClick={() => handleNavigate('profile')}>
            Profile
        </Dropdown.Item>
        <Dropdown.Item 
            onClick={handleLogout} 
        >
            Log Out
        </Dropdown.Item>
        {loginState.admin && <Dropdown.Item onClick={() => handleNavigate('dashboard')}>
            Return to Admin Dashboard
        </Dropdown.Item>}
    </>
    )
}

