import React, { useContext, useState } from 'react'
import { SiteContext } from "../../../App.tsx";

export default function Question({entry, updateForm, form}) {

  const {colorMatch, student} = useContext(SiteContext)

  const {question, sentenceStem, sizeClass, category, item} = entry

  function localUpdate(e) {
    e.preventDefault()
    updateForm(category, item, e.target.value)
  }

  var questionMod = responsiveString(question)
  var sentenceStemMod = responsiveString(sentenceStem)

  function responsiveString(input) {
    // if(!input.contains('this module')) return
    if(!student.moduleFull) return
    return input
    return input.replace('this module', toTitleCase(student.module))
  }

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

    return (
      <>
        <div className={`${sizeClass} mb-4`}>
            <div className={`card-shadow-secondary bg-light widget-chart widget-chart2 text-start card`} style={{height:"100%", width:"100%"}}>
            <div className="widget-content p-0 w-100">
                <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                </div>
                <div className="widget-content-left fsize-1">
                    {questionMod} 
                </div>
                <textarea
                className="form-control" style={{marginTop:"10px", resize:"none", marginBottom:"10px"}}
                onChange={e => localUpdate(e)}
                cols={40}
                rows={3}
                id="textAreaSpace"
                placeholder={sentenceStem}
                // placeholder="Type here . . ."
                defaultValue={!form[category][item] ? '' : form[category][item]}
                // value={form[category][item]}
                />
                </div>
                {form[category][item].length > 0 && <span style={{fontStyle:"italic"}}>{sentenceStem}</span>}
            </div>
            </div>
        </div>
      </>
    )
}



{/* <input
type="text" style={{marginTop:"10px"}}
className="form-control"
// value={''}
// onChange={(e) => updateForm(e, { explain: e.target.value })}
/> */}








      