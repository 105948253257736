import React, { useContext, useState, useEffect, createContext } from 'react'

import { SiteContext, baseUrl } from '../../../App.tsx'
import { AdminContext } from '../../../pages/AdminContainer'

import Entry from './Entry';

import { ErrorBoundary } from '../../../other/utils/ErrorBoundary';

export default function DExitTicket({setExpand, expand, filtered, handleOverride}) {

    const { 
      classInformation, studentList, socket, 
      studentDataFunctions: {setStudentList}
    } = useContext(SiteContext)

    const {} = useContext(AdminContext)

    const [update, setUpdate] = useState(false)

    useEffect(() => {

        socket.on("receive_exitTicket", (data) => {
          console.log(data, 'receive_exitTicket')
          const editStudentList = [...studentList]

          var index = editStudentList.findIndex(entry => entry.studentId === data.studentId)
          editStudentList[index].dayReview = {...editStudentList[index].dayReview, ...data.dayReview}

          setStudentList(editStudentList)
          // setUpdate(prev => {return !prev})
        })
    
        return () => {
          socket.off('receive_exitTicket');
        };
    
    },[socket, studentList, filtered])

    function updateExpand(e) {
      e.preventDefault()
      setExpand(prev => {return !prev})
    }

    return (
      <ErrorBoundary>
          <div className="card-body table-responsive" style={{}}>
            <table className="align-middle text-truncate mb-0 table table-borderless table-hover" style={{width:"100%"}}>
              <thead>
                <tr>
                  <th className="">Name</th> 
                  <th className="text-center">Actual</th>
                  <th className="text-center">Self</th>
                  <th className="text-center">Group</th>
                  <th className="text-center">Class</th>
                  <th className="">Supported class by...</th>
                  {expand && <th className="">Better support class by...</th>}
                  <th className="">Rate</th>
                  {expand && <th className="">Override</th> }
                  {expand && <th className="">Reset</th> }
                  {expand && <th className="">Reopen</th> }
                </tr>
              </thead>
              <tbody>
                  {filtered.map(entry => {
                      if(entry.period !== classInformation.period || entry.attendance === 'Absent') return
                      return <Entry key={entry.studentId} expand={expand} entry={entry} colorCurrent={entry.color.current} handleOverride={handleOverride} />
                  })}
              </tbody>
            </table>
          </div>
      </ErrorBoundary>
    )
}


