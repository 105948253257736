import React, { useState, useEffect } from "react";
import axios from "axios";
import { fetchLookFors, createLookFor, updateLookFor, deleteLookFor, fetchHexCodes } from '../../../types/api.tsx';
import { v4 as uuidv4 } from "uuid";
import { LookFor, LookForRubric } from "../../types/lookFors.ts"; // ✅ Import the TypeScript type
import { Row, Col, Button } from "react-bootstrap";
import AddLookForForm from "./Add.tsx";

import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css"; // Import default styles

export default function LookForsWrapper({ baseUrl }) {

    const [lookFors, setLookFors] = useState<LookFor[]>([]);

    const [newLookFor, setNewLookFor] = useState<LookFor>({
        lookForID: uuidv4(),
        index: 0, // ✅ Add this line to satisfy the schema
        topic: "",
        description: "",
        studentFacing: false,
        rubric: [
          { score: 1, category: "Not Yet", content: "", hex: "" },
          { score: 2, category: "Developing", content: "", hex: "" },
          { score: 3, category: "Proficient", content: "", hex: "" },
          { score: 4, category: "Advanced", content: "", hex: "" },
        ],
    });

    const [hexCodes, setHexCodes] = useState([])
    // console.log(hexCodes,'hexCodes', lookFors, 'lookFors')

    useEffect(() => {
      const getLookFors = async () => {
        const data = await fetchLookFors();
        // console.log(data, 'data------')
        setLookFors(data);
      };
  
      getLookFors();
    }, []);

    useEffect(() => {
        const getHexCodes = async () => {
          const data = await fetchHexCodes();
          setHexCodes(data);
        };
    
        getHexCodes();
      }, []);

    // ✅ Handle Input Change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewLookFor((prev) => ({ ...prev, [name]: value }));
    };

    // ✅ Handle Add LookFor
    const handleAddLookFor = async () => {
        if (!newLookFor.topic || !newLookFor.description) {
            alert("Topic and Description are required.");
            return;
        }
    
        // Assign index dynamically based on existing lookFors
        const newIndex = lookFors.length > 0
            ? Math.max(...lookFors.map((lf: LookFor) => lf.index || 0)) + 1
            : 1;

        const lookForWithIndex: LookFor = {
            lookForID: newLookFor.lookForID, // Generate a unique ID
            index: newIndex,
            topic: newLookFor.topic,
            description: newLookFor.description,
            studentFacing: newLookFor.studentFacing || false,
            rubric: newLookFor.rubric || [],
            createdAt: new Date(),
            updatedAt: new Date()
        };
            
        const addedLookFor = await createLookFor(lookForWithIndex);
        if (addedLookFor) setLookFors((prev) => [...prev, addedLookFor]);
    };

    const confirmDeleteLookFor = (id: string) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this Look For? This action cannot be undone.");
        if (isConfirmed) {
            handleDeleteLookFor(id);
        }
    };

    // ✅ Handle Delete LookFor
    const handleDeleteLookFor = async (id) => {
        await deleteLookFor(id);
        setLookFors((prev) => prev.filter((lookFor) => lookFor._id !== id));
    };

    // ✅ Handle Update LookFor
    const handleUpdateLookFor = async (id, updatedData) => {
        const updatedLookFor = await updateLookFor(id, updatedData);
        if (updatedLookFor) {
        setLookFors((prev) =>
            prev.map((lookFor) =>
            lookFor._id === id ? updatedLookFor : lookFor
            )
        );
        }
    };

    const handleRubricChange = (index: number, field: keyof LookForRubric, value: string) => {
        setNewLookFor((prevLookFor) => {
            const updatedRubric = [...prevLookFor.rubric];
            updatedRubric[index] = { ...updatedRubric[index], [field]: value };
    
            return { ...prevLookFor, rubric: updatedRubric };
        });
    };

    return (
        <div className="container mt-4">
            <h2 className="mb-3">Look Fors</h2>

            {/* Display LookFors */}
            <div className="p-0">
                {lookFors.map((lookFor) => (
                    <div key={lookFor.lookForID} className="card p-3 mb-3">
                        {/* <h4 className="fw-bold">{lookFor.studentFacing ? "🍎" : "✏️"} {lookFor.topic}</h4>
                        <p>{lookFor.description}</p> */}
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                            <h5 className="fw-bold">{lookFor.studentFacing ? "✏️" : "🍎"} {lookFor.topic}</h5>
                            <p>{lookFor.description}</p>
                            </div>
                            
                            {/* 🗑️ Delete Button (Right-Aligned) */}
                            <Button variant="light" className="text-danger fw-bold" onClick={() => confirmDeleteLookFor(lookFor._id)}>
                            🗑️ Delete
                            </Button>
                        </div>
                        <h5 className="fw-bold mt-3">Rubric</h5>
                        <div className="p-1">
                            <Row className="text-center d-flex align-items-stretch">
                                {lookFor.rubric.map((rubricItem, index) => (
                                    <Col key={index} md={3} className="rounded p-0 m-0 d-flex">
                                        <div 
                                            className="m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-between text-start"
                                            style={{ backgroundColor: hexCodes[index % hexCodes.length]?.secondary || "#ccc", minHeight: "100%" }}
                                        >
                                            <h4 className="fw-bold">{rubricItem.category} ({rubricItem.score})</h4>
                                            <div className="flex-grow-1 mb-1">
                                                <div
                                                    className="flex-grow-1 mb-1"
                                                    dangerouslySetInnerHTML={{ __html: rubricItem.content }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                ))}
            </div>
            <AddLookForForm 
                newLookFor={newLookFor} 
                setNewLookFor={setNewLookFor} 
                handleInputChange={handleInputChange} 
                handleRubricChange={handleRubricChange} 
                handleAddLookFor={handleAddLookFor} 
                hexCodes={hexCodes} 
                lookFors={lookFors}
            />
            <BulkUploadLookFors baseUrl={baseUrl} />
        </div>
    );
}

const BulkUploadLookFors = ({baseUrl}) => {
    const [file, setFile] = useState<File | null>(null);
    const [message, setMessage] = useState("");

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        setFile(event.target.files[0]);
      }
    };
  
    const handleUpload = async () => {
      if (!file) return setMessage("Please select a file first.");
  
      const formData = new FormData();
      formData.append("file", file);
  
      try {
        const response = await axios.post(`${baseUrl}/api/lookFors/bulk-upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setMessage(`Upload successful! ${response.data.uploaded} LookFors added.`);
      } catch (error) {
        setMessage("Upload failed: " + error.response?.data?.error || error.message);
      }
    };
  
    return (
      <div>
        <h3>Bulk Upload LookFors</h3>
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <button onClick={handleUpload} disabled={!file}>Upload</button>
        {message && <p>{message}</p>}
      </div>
    );
  };