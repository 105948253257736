import React, { useContext, useState, useEffect, createContext } from 'react'

import { SiteContext, baseUrl } from '../../App.tsx'

import ModuleEntry from './ModuleEntry'

export default function ModuleCompletions({ title, subtitle, colorValue, sizeClass, selectedStudent, moduleCompletionsImport }) {

    const { 
      classInformation, focusAgreements, student, handleDateString,
      studentDataFunctions: {
      }
    } = useContext(SiteContext)

    function handleModuleCompletions() {
      const moduleCompletions= []

      if(!focusAgreements || !focusAgreements.modules || !selectedStudent) return
  
      var index = focusAgreements.modules.findIndex(item => item.title === selectedStudent.module)
          if(index === -1) return
      var index1 = focusAgreements.modules[index].checklists.findIndex(item => item.groupNumber === selectedStudent.groupNumber)
          if(index1 === -1) return
  
      var checklist = focusAgreements.modules[index].checklists[index1].checklist
  
      checklist.map(entry => {
        if(!entry.completeAdmin) return
        moduleCompletions.push(entry)
      })

      return moduleCompletions
    }

    const moduleCompletionsFinal = moduleCompletionsImport ? moduleCompletionsImport : handleModuleCompletions()

    var count = 0
    var currentDate = Date.now()
    var bgVariant = 2

    return (
      <div className={sizeClass}>
      <div className={`card-shadow-secondary mb-3 widget-chart widget-chart2 text-start ${!moduleCompletionsImport && 'card'} mt-4`}>
        <div className="widget-content p-0 w-100">
          
          <div className="widget-content-outer">
            <div className="widget-content-wrapper">
              <div className="widget-content-left pe-2 fsize-1">
                <div className="widget-numbers mt-0 fsize-3 text-info">
                  Checklist Completions
                </div>      
              </div>
            </div>
            
            <div className="widget-content-left fsize-1">
            {moduleCompletionsFinal && moduleCompletionsFinal.length === 0 && <span>No Checklist Items Completed</span>}
            {moduleCompletionsFinal && moduleCompletionsFinal.length > 0 && <div className="table-responsive">
              <table className="align-middle text-truncate mb-0 table table-borderless table-hover text-secondary">
                <thead>
                  <tr>
                    <th className="text-center">Date</th>
                    {/* <th className="text-center">Day</th> */}
                    <th className="">Type</th>
                    <th className="">Activity</th>
                    {/* <th className="text-center">Colors</th> */}
                  </tr>
                </thead>
                <tbody>
                    
                    {moduleCompletionsFinal.map(entry => {
                        count++
                        // console.log(entry,'entry')
                        var date = new Date(entry.completeEvent.date)
                          date.setHours(0)
                          date.setMinutes(0)
                          date.setSeconds(0)
                        
                        if(currentDate !== date) {
                          currentDate = date
                          bgVariant++
                        }
                        return (
                            <ModuleEntry entry={entry} key={count} bgVariant={bgVariant} />
                        )
                    })}
                </tbody>
              </table>
            </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}
