import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../App.tsx'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import TimerCountUp from '../../other/utils/TimerCountUp';

import './d_behavior.css'

export default function D_BehaviorEntry({event, handleUpdateBehaviorEvents, variant }) {

    const { 
        colorMatch,
        studentList,
        studentDataFunctions: {
            updateMongo
        }
    } = useContext(SiteContext)

    const {
        activelyPending, studentInitiatingName, studentInitiatingId,
        category, reason, type, studentNominatedName,
        result, pendingColor, currentColor, id
    } = event

    async function updateBehaviorEvent(e, result) {

        e.preventDefault();

        var editedBehaviorEvent = {
            ...event,
            activelyPending: false,
            result,
            currentColor: colorCurrent,
            pending: '',
            pendingColor: colorPending
        };
        
        if(editedBehaviorEvent._id){delete editedBehaviorEvent._id}
        // This will send a post request to update the data in the database.
        updateMongo(editedBehaviorEvent, id, 'behaviorEvents')
        handleUpdateBehaviorEvents(editedBehaviorEvent)
    }

    var index = studentList.findIndex(student => student.studentId == studentInitiatingId)
    if(index === -1) return
    const studentInitiating = studentList[index]

    if(!studentInitiating) return

    const match = colorMatch.filter(item => item.color == studentInitiating.color.current)[0]
      if(!match) return
      var colorCurrent = match.color
      var variantCurrent = match.variant

    var pendingMatch = colorMatch.filter(item => item.level == match.level + event.levelChange)[0]
      if(!pendingMatch) return
      var colorPending = pendingMatch.color
      var variantPending = pendingMatch.variant   
    
    var opacity = ''
        if(result === 'approved') { opacity = 'approved' }
        else if(result == 'rejected') { opacity = 'rejected' } 
        else { opacity = 'pending' }

    var nameTag = ''
    var nameTagVariant = 'light'
        if(type === 'nominate') {
            nameTagVariant = 'primary'
        } else { nameTag = 'Self'}

    var reasonString = category
        if(reason !== 'n/a') {reasonString = reason}
        if(reason !== 'n/a' && category === 'n/a') { reasonString = reason}

    if(studentNominatedName.length !== 0) {
        var studentNameString = studentNominatedName
        var typeBadge = studentInitiatingName
    } else {
        studentNameString = studentInitiatingName
        typeBadge = category
    }

    const categoryCount = studentInitiating.behaviorEvents.filter(entry => entry.category === typeBadge).length
    const agreementCount = studentInitiating.behaviorEvents.filter(entry => entry.reason === reason).length

    // console.log(studentInitiating,'studentInitiating', studentInitiating.color.lastUpdate, categoryCount, agreementCount,'agreementCount')

    return (
        <ErrorBoundary>
        <tr className={`${opacity}`} style={{height:"80px"}}>
            <td className="text-center" style={{verticalAlign:"top"}}>
                <div className="box">
                  <span className="text">{studentNameString}</span>
                </div>
                <div>
                  <div className={`mb-2 me-2 badge bg-light ms-1`}>Self</div> 
                </div>
            </td>
            <td className="text-center" style={{verticalAlign:"top"}}>
                <div className="ms-auto me-auto" style={{maxWidth:"75px"}}>
                    <div className={`badge rounded-pill bg-${variantCurrent} w-100 d-flex justify-content-center mb-1`}>
                        <span className="">{colorCurrent}</span>
                        <span className="ms-1">→</span>
                    </div>
                    <div className={`badge rounded-pill bg-${variantPending} w-100 d-flex justify-content-center`}>
                        <span className="me-1">→</span>
                        <span className="">{colorPending}</span>
                    </div>
                    <div className="badge mt-2 bg-info">
                        {studentInitiating.color.lastUpdate ? <TimerCountUp startTime={studentInitiating.color.lastUpdate} showBadge={true} /> : "New"} 
                    </div>
                    {/* {categoryCount > 0 && <div className={`badge mt-2 ms-1 bg-${(categoryCount > 1 || agreementCount > 0) ? 'danger' : 'flag'}`}>
                        {categoryCount} 
                    </div>} */}
                    {agreementCount > 0 ? <div className={`badge mt-2 ms-1 bg-purple`}>{agreementCount}</div> 
                    : categoryCount > 0 ? <div className={`badge mt-2 ms-1 bg-${categoryCount === 1 ? 'warning' : categoryCount === 2 ? 'flag' : 'danger'}`}>{categoryCount}</div> : <></>}
                </div>
            </td>
            <td className="" style={{verticalAlign:"top"}}>
                {/* <div style={{wordWrap:"break-word", width:"100%"}}>{reasonString}</div> */}
                <div className="d-flex flex-wrap word-wrap flex-shrink-1 justify-content-start">
                    <div className={`mb-1 me-2 badge bg-${nameTagVariant} d-inline`}>{typeBadge}</div>
                    <span className="ms-1 text d-inline">{reason.slice(0,50)}</span>
                </div>
            </td>
            <td className="text-center">
                {/* {event.levelChange && event.levelChange !== 1 && <div className={`badge rounded-pill bg-${bgLevelChange}`} style={{marginLeft:"5px"}}>
                  {event.levelChange > 0 && '+'}{event.levelChange}
                </div>} */}
            </td>
            <td className="" style={{width:"90px", verticalAlign:"top"}}>
                {/* <div className="d-flex align-items-start"> */}
                <div role="group" className="btn-group-sm btn-group d-flex align-top justify-content-center pt-auto ms-auto me-auto" 
                // style={{minWidth:"70px", maxWidth:"70px"}}
                >
                    <button 
                    value="approved"
                    onClick={(e) => updateBehaviorEvent(e, 'approved')} 
                    className={`btn-shadow btn btn-${variant} text-white`}>
                    <FontAwesomeIcon icon={faCheck} />
                    </button>
                    <button 
                    onClick={(e) => updateBehaviorEvent(e, 'rejected')} 
                    className={`btn-shadow btn btn-light ${opacity} text-${variant}`}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                {/* </div> */}
            </td>
        </tr>
        </ErrorBoundary>
    )
}
