import React, {useContext} from 'react'

import { SiteContext } from "../../App.tsx";
import { AdminContext } from "../../pages/AdminContainer";

export function useHallPass() {

    const { 
        classInformation, socket, room,
        studentDataFunctions: { deleteMongo, getMongoAllById }
    } = useContext(SiteContext)

    const {hpEvents, setHpEvents, updateExistingEvent} = useContext(AdminContext)

    var index = hpEvents.findIndex(entry => entry.period === classInformation.period)
    if(index !== -1) {
        var eventsPeriod = hpEvents[index].events
    } else {
        var eventsPeriod = []
    }

    var count = 0
    var pendingCount = 0
    var completeCount = 0

    var completeEvent = {}

    if(!classInformation || !classInformation.period || !hpEvents || hpEvents.length === 0) return {count, pendingCount, completeCount, completeEvent, eventsPeriod}
    
    for(let i = 0; i < eventsPeriod.length; i++) {
        if(eventsPeriod[i].active && eventsPeriod[i].approved) {
            if(count === 0) {var eventCurrent = eventsPeriod[i]}
            count++
        } else if(eventsPeriod[i].active && !eventsPeriod[i].approved && count === 0) {
            if(pendingCount === 0) {var eventPending = eventsPeriod[i]}
            pendingCount++
        } else if(!eventsPeriod[i].active && eventsPeriod[i].approved) {
            completeCount++
            completeEvent = eventsPeriod[i]
        }
    }

    return {count, pendingCount, completeCount, completeEvent, eventsPeriod, eventCurrent, eventPending}
}
