import React, { useState, useEffect } from "react";
import { baseUrl } from '../App.tsx';

const JSONEditorForm = () => {

    const [formData, setFormData] = useState([]);
    const [jsonInput, setJsonInput] = useState("");
    const [dynamicDropdownData, setDynamicDropdownData] = useState({}); // Store dropdown data globally

    useEffect(() => {
        async function fetchData() {
            try {
                const [formEditResponse, dynamicDropdownResponse] = await Promise.all([
                    fetch(`${baseUrl}/api/formEdit`).then(res => res.json()),
                    fetch(`${baseUrl}/api/dropdownDynamic`).then(res => res.json()),
                ]);

                console.log("📄 formEditResponse:", formEditResponse);
                console.log("📄 dynamicDropdownResponse:", dynamicDropdownResponse);
                
                setDynamicDropdownData(dynamicDropdownResponse);

                let updatedFormData = updateDependentDropdowns(formEditResponse, dynamicDropdownResponse);

                updatedFormData = updatedFormData.map(entry => 
                    entry.selector === "events" ? { ...entry, duration: findNearestDuration(entry.duration) } : entry
                );

                setFormData(updatedFormData);
            } catch (error) {
                console.error("🔥 Fetch Error:", error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (!dynamicDropdownData || formData.length === 0) return;
    
        setFormData(prevFormData => {
            return prevFormData.map(entry => {
                if (["schoolName", "solvesSpecificStrategy", "supportCycle"].includes(entry.selector)) {
                    const parentKey = entry.selector === "schoolName" ? "districtName"
                        : entry.selector === "solvesSpecificStrategy" ? "PrimaryStrategy"
                        : "implementationIndicator";
    
                    const parentEntry = prevFormData.find(e => e.selector === parentKey);
                    const parentValue = parentEntry?.value;
    
                    if (!parentValue || !dynamicDropdownData[parentKey]?.[parentValue]) {
                        return entry;
                    }
    
                    const newOptions = dynamicDropdownData[parentKey][parentValue];
    
                    return {
                        ...entry,
                        options: newOptions,
                        value: newOptions.includes(entry.value) ? entry.value : (newOptions[0] || ""),
                    };
                }
                return entry;
            });
        });
    }, [jsonInput, dynamicDropdownData]);
    
    const updateDependentDropdowns = (data, dropdownData) => {
        return data.map(entry => {
            if (["districtName", "PrimaryStrategy", "implementationIndicator"].includes(entry.selector)) {
                return {
                    ...entry,
                    options: dropdownData[entry.selector] ? Object.keys(dropdownData[entry.selector]) : entry.options,
                };
            } else if (["schoolName", "solvesSpecificStrategy", "supportCycle"].includes(entry.selector)) {
                const parentKey = entry.selector === "schoolName" ? "districtName"
                    : entry.selector === "solvesSpecificStrategy" ? "PrimaryStrategy"
                    : "implementationIndicator";

                const parentValue = data.find(e => e.selector === parentKey)?.value;
                const newOptions = dropdownData[parentKey]?.[parentValue] || [];

                return {
                    ...entry,
                    options: newOptions,
                    value: newOptions.includes(entry.value) ? entry.value : (newOptions[0] || ""),
                };
            }
            return entry;
        });
    };

    const handleFieldChange = (selector, newValue, index = null, isMultiSelect = false) => {
        setFormData(prevFormData => {
            return prevFormData.map(entry => {
                if (entry.selector === selector && (entry.i === index || index === null)) {
                    const updatedEntry = { ...entry, value: isMultiSelect ? [...newValue] : newValue };

                    let updatedFormData = [...prevFormData];

                    if (selector === "districtName") {
                        const schoolEntry = updatedFormData.find(e => e.selector === "schoolName");
                        if (schoolEntry) {
                            const newOptions = dynamicDropdownData["districtName"]?.[newValue] || [];
                            schoolEntry.options = newOptions;
                            schoolEntry.value = newOptions.includes(schoolEntry.value) ? schoolEntry.value : (newOptions[0] || "");
                        }
                    } else if (selector === "PrimaryStrategy") {
                        const strategyEntry = updatedFormData.find(e => e.selector === "solvesSpecificStrategy");
                        if (strategyEntry) {
                            const newOptions = dynamicDropdownData["PrimaryStrategy"]?.[newValue] || [];
                            strategyEntry.options = newOptions;
                            strategyEntry.value = newOptions.includes(strategyEntry.value) ? strategyEntry.value : (newOptions[0] || "");
                        }
                    } else if (selector === "implementationIndicator") {
                        const cycleEntry = updatedFormData.find(e => e.selector === "supportCycle");
                        if (cycleEntry) {
                            const newOptions = dynamicDropdownData["implementationIndicator"]?.[newValue] || [];
                            cycleEntry.options = newOptions;
                            cycleEntry.value = newOptions.includes(cycleEntry.value) ? cycleEntry.value : (newOptions[0] || "");
                        }
                    }

                    return updatedEntry;
                }
                return entry;
            });
        });
    };

    const handleJsonInputChange = (event) => {
        setJsonInput(event.target.value);
    };

    const handleJsonSubmit = () => {
        try {
            const jsonServer = JSON.parse(jsonInput);
            const updatedFormData = formData.map(entry => {
                const updatedEntry = jsonServer.find(item => item.selector === entry.selector);
                return updatedEntry ? { ...entry, value: updatedEntry.value } : entry;
            });
            console.log("🔄 Merged JSON:", updatedFormData);

            let updatedFormDataDependent = updateDependentDropdowns(updatedFormData, dynamicDropdownData);

            updatedFormDataDependent = updatedFormDataDependent.map(entry => 
                entry.selector === "events" ? { ...entry, duration: findNearestDuration(entry.duration) } : entry
            );

            setFormData(updatedFormDataDependent);
        } catch (error) {
            console.error("❌ Invalid JSON input:", error);
        }
    };

    const handleExport = async () => {
        try {
            console.log(formData,'formData')

            let finalData = formData

            finalData = finalData.map(entry => 
                entry.selector === "events" ? { ...entry, duration: findNearestDuration(entry.duration) } : entry
            );

            console.log(finalData,'finalData')

            const response = await fetch("http://localhost:8080/submit-form-data", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(finalData)
            });

            if (response.ok) {
                console.log("✅ Form data successfully sent to the server.");
            } else {
                console.error("❌ Error sending form data.");
            }
        } catch (error) {
            console.error("🔥 API Error:", error);
        }
    };

    const handleRemoveEvent = (index) => {
        setFormData(prevFormData => {
            return prevFormData.map(entry => {
                if (entry.selector === "events") {
                    return {
                        ...entry,
                        value: entry.value.filter((_, i) => i !== index) // Remove event at given index
                    };
                }
                return entry;
            });
        });
    };

    const handleEventChange = (index, key, newValue) => {
        setFormData(prevFormData => {
            return prevFormData.map(entry => {
                if (entry.selector === "events") {
                    const updatedEvents = [...entry.value];
                    updatedEvents[index] = { ...updatedEvents[index], [key]: newValue };
                    return { ...entry, value: updatedEvents };
                }
                return entry;
            });
        });
    };

    // Define section colors and titles
    const sectionColorAndTitle = [
        { color: '#eee2ff', title: 'General Information', outlineColor: '#9853ff' },
        { color: '#d4edda', title: 'Micro PL', outlineColor: '#54db74' },
        { color: '#d1ecf1', title: 'Group Coaching', outlineColor: '#28bad4' },
        { color: '#ffeacd', title: 'Admin Support', outlineColor: '#ffa836' },
        { color: '#d6d8db', title: 'NYC Solves', outlineColor: '#3772c9' },
        { color: '#ffe6b6', title: 'Contractor', outlineColor: '#ffa703' },
        { color: '#ffe2ec', title: 'Events', outlineColor: '#ff73a4' }
    ];

    // Grouping form data by section
    const groupedSections = formData.reduce((acc, entry) => {
        const { section } = entry.organization || { section: 999 }; // Default to section 999 if missing
        if (!acc[section]) acc[section] = [];
        acc[section].push(entry);
        return acc;
    }, {});
    
    // Sort sections and order entries by index
    const sortedSections = Object.entries(groupedSections)
    .sort(([a], [b]) => a - b)
    .map(([section, entries]) => {
        const firstValueIsNo = entries[0]?.value == "No";
        return {
            section,
            title: sectionColorAndTitle[section]?.title || `Section ${section}`,
            entries: entries.sort((a, b) => a.organization.index - b.organization.index),
            color: sectionColorAndTitle[section]?.color || "#ffffff",
            strikethrough: firstValueIsNo,
            // outline: firstValueIsNo ? "none" : `5px solid ${sectionColorAndTitle[section]?.outlineColor}`
            outline: firstValueIsNo ? `5px solid #d64242` : "none"
        };
    });
    

    return (
        <div style={{ padding: "20px", maxWidth: "900px", margin: "auto" }}>
            <h1>JSON Form Editor</h1>
            <p><strong>Total Selectors on Page:</strong> {formData.length}</p>
            <p><strong>Total Selectors on Page:</strong> {formData.length}</p>
            <textarea
                rows="6"
                cols="50"
                placeholder="Paste JSON here..."
                style={{ width: "100%", marginBottom: "10px" }}
                value={jsonInput}
                onChange={handleJsonInputChange}
            />
            <button onClick={handleJsonSubmit} style={{ marginBottom: "20px" }}>Load JSON</button>
            {sortedSections.map(({ section, title, entries, color, strikethrough, outline }) => (
                <div 
                    key={section} 
                    style={{ backgroundColor: color, padding: "10px", marginBottom: "15px", borderRadius: "5px", border: outline}}
                >
                    <h3 style={{ textDecoration: strikethrough ? "none" : "none" }}>{strikethrough && "❌"} {title}</h3>
                    {entries.map((field, index) => (
                        <div key={index} style={{ marginBottom: "15px" }}>
                            <label>{field.label}</label>
                            {Array.isArray(field.value) ? (
                                field.selector === "events" ? (
                                    <div>
                                    {field.value.map((event, idx) => (
                                        <div key={idx} style={{ 
                                            position: "relative",
                                            marginBottom: "10px", 
                                            padding: "10px", 
                                            border: "1px solid #ccc",
                                            backgroundColor: "#ffe2ec" 
                                        }}>
                                            <button 
                                                onClick={() => handleRemoveEvent(idx)} 
                                                style={{
                                                    position: "absolute",
                                                    top: "10px",
                                                    right: "10px",
                                                    backgroundColor: "red",
                                                    color: "white",
                                                    border: "none",
                                                    cursor: "pointer",
                                                    padding: "2px 6px",
                                                    fontSize: "12px",
                                                    borderRadius: "50%"
                                                }}
                                            >
                                                X
                                            </button>
                                            <div style={{marginBottom:"10px", fontWeight:"bold"}}>Event {idx+1}:</div>
                                            <label>Name(s):</label>
                                            <input
                                                type="text"
                                                value={event.name.join(", ")}
                                                style={{ width: "100%", marginBottom: "5px" }}
                                                onChange={(e) => handleEventChange(idx, "name", e.target.value.split(", "))}
                                            />
                                            <label>Role:</label>
                                            <input
                                                type="text"
                                                value={event.role}
                                                style={{ width: "100%", marginBottom: "5px" }}
                                                onChange={(e) => handleEventChange(idx, "role", e.target.value)}
                                            />
                                            <label>Activity:</label>
                                            <input
                                                type="text"
                                                value={event.activity}
                                                style={{ width: "100%", marginBottom: "5px" }}
                                                onChange={(e) => handleEventChange(idx, "activity", e.target.value)}
                                            />
                                            <label>Duration:</label>
                                            <select
                                                style={{ width: "100%", marginBottom: "5px" }}
                                                value={event.duration}
                                                onChange={(e) => handleEventChange(idx, "duration", e.target.value)}
                                            >
                                                {["15", "30", "45", "60", "76", "90"].map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ))}
                                </div>
                                ) : (
                                    <select multiple style={{ width: "100%", height: "100px" }} value={field.value} 
                                        onChange={(e) => {
                                        const selectedValues = Array.from(e.target.selectedOptions, option => option.value);
                                        handleFieldChange(field.selector, selectedValues, field.i, true);
                                    }}>
                                        {field.options.map(option => (
                                            <option key={option} value={option} selected={field.value.includes(option)}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                )
                            ) : field.options ? (
                                <select style={{ width: "100%" }} value={field.value} onChange={(e) => handleFieldChange(field.selector, e.target.value, field.i)}>
                                    {field.options.map(option => (
                                        <option key={option} value={option} selected={field.value === option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                // <input type="text" value={field.value} style={{ width: "100%" }} readOnly />
                                <input type="text" value={field.value} style={{ width: "100%" }}  onChange={(e) => handleFieldChange(field.selector, e.target.value, field.i)}/>
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <button onClick={handleExport} style={{ marginTop: "20px" }}>
                Export & Send GET Request
            </button>
        </div>
        
    );

}

export default JSONEditorForm;










const VALID_DURATIONS = ["15", "30", "45", "60", "76", "90"];

const findNearestDuration = (duration) => {
    const numericDuration = parseInt(duration, 10);
    if (isNaN(numericDuration)) return "30"; // Default to 30 if not a number

    console.log(numericDuration,'numericDuration')
    return VALID_DURATIONS.reduce((prev, curr) => 
        Math.abs(curr - numericDuration) < Math.abs(prev - numericDuration) ? curr : prev
    );
};




    // useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             const [formEditResponse, dynamicDropdownResponse] = await Promise.all([
    //                 fetch("http://localhost:8080/api/formEdit").then(res => res.json()),
    //                 fetch("http://localhost:8080/api/dropdownDynamic").then(res => res.json()),
    //             ]);

    //             console.log("📄 formEditResponse:", formEditResponse);
    //             console.log("📄 dynamicDropdownResponse:", dynamicDropdownResponse);
                
    //             setDynamicDropdownData(dynamicDropdownResponse); // Store in state

    //             const updatedFormData = formEditResponse.map(entry => {
    //                 if (["districtName", "PrimaryStrategy", "implementationIndicator"].includes(entry.selector)) {
    //                     return {
    //                         ...entry,
    //                         options: dynamicDropdownResponse[entry.selector]
    //                             ? Object.keys(dynamicDropdownResponse[entry.selector])
    //                             : entry.options,
    //                     };
    //                 } else if (["schoolName", "solvesSpecificStrategy", "supportCycle"].includes(entry.selector)) {
    //                     const parentKey = entry.selector === "schoolName" ? "districtName"
    //                         : entry.selector === "solvesSpecificStrategy" ? "PrimaryStrategy"
    //                         : "implementationIndicator";

    //                     const parentValue = formEditResponse.find(e => e.selector === parentKey)?.value;
    //                     const newOptions = dynamicDropdownResponse[parentKey]?.[parentValue] || [];

    //                     return {
    //                         ...entry,
    //                         options: newOptions,
    //                         value: newOptions.includes(entry.value) ? entry.value : (newOptions[0] || ""),
    //                     };
    //                 }
    //                 return entry;
    //             });

    //             const updatedFormData2 = updateDependentDropdowns(formEditResponse, dynamicDropdownResponse);

    //             console.log("📄 Updated Form Data:", updatedFormData);
    //             setFormData(updatedFormData2);
    //         } catch (error) {
    //             console.error("🔥 Fetch Error:", error);
    //         }
    //     }
    //     fetchData();
    // }, []);

    // useEffect(() => {

    //     async function fetchData() {
    //         try {
    //             const [formEditResponse, dynamicDropdownResponse] = await Promise.all([
    //                 fetch("http://localhost:8080/api/formEdit").then(res => res.json()),
    //                 fetch("http://localhost:8080/api/dropdownDynamic").then(res => res.json()),
    //             ]);
    
    //             console.log("📄 formEditResponse:", formEditResponse);
    //             console.log("📄 dynamicDropdownResponse:", dynamicDropdownResponse);
    
    //             const updatedFormData = formEditResponse.map(entry => {
    //                 if (["districtName", "PrimaryStrategy", "implementationIndicator"].includes(entry.selector)) {
    //                     // Parent selectors: Keep original value from formData, but update options
    //                     return {
    //                         ...entry,
    //                         options: dynamicDropdownResponse[entry.selector]
    //                             ? Object.keys(dynamicDropdownResponse[entry.selector])
    //                             : entry.options,
    //                     };
    //                 } else if (["schoolName", "solvesSpecificStrategy", "supportCycle"].includes(entry.selector)) {
    //                     // Dependent selectors: Update options but retain formData value
    //                     const parentKey = entry.selector === "schoolName" ? "districtName"
    //                         : entry.selector === "solvesSpecificStrategy" ? "PrimaryStrategy"
    //                         : "implementationIndicator";
    
    //                     const parentValue = formEditResponse.find(e => e.selector === parentKey)?.value;
    //                     const newOptions = dynamicDropdownResponse[parentKey]?.[parentValue] || [];
    
    //                     return {
    //                         ...entry,
    //                         options: newOptions,
    //                         value: newOptions.includes(entry.value) ? entry.value : (newOptions[0] || ""), // Keep value if valid
    //                     };
    //                 }
    //                 return entry;
    //             });
    
    //             console.log("📄 Updated Form Data:", updatedFormData);
    //             setFormData(updatedFormData);
    //         } catch (error) {
    //             console.error("🔥 Fetch Error:", error);
    //         }
    //     }
    //     fetchData();
    // }, []);