import React, { useEffect, useContext, useState } from 'react'
import { SiteContext } from '../../App.tsx'

export function useModal({show, setShow, setUpdate}) {

    const {student, focusAgreements, goalQuestions, classInformationArray, classInformation, studentList,
        studentDataFunctions: {updateMongo, setStudent, createReviewEntry, handleDateString} } 
    = useContext(SiteContext)

    // const [currentEntry, setCurrentEntry] = useState({})
    // console.log(student,'student')
    
    useEffect(() => {
        if(!student.dayReview) return
        if(student.modalOverride) { 
            handleClose('dayReview', undefined) 
            return
        }
        if((student.dayReview.display < 4) && focusAgreements.dayReview) {
            handleShow(undefined, 'dayReview')
        } else if(student.dayReview.display > 2 || !focusAgreements.dayReview) {
            handleClose('dayReview', undefined)
        }

        if(student.customOverride) {
          const editShow = [...show]
            var index = editShow.findIndex(entry => entry.type === 'customModal')
            editShow[index].studentOverride = student.customOverride
          setShow(editShow)
        }
    }, [student.dayReview && student.dayReview.display, student.modalOverride, student.customOverride, focusAgreements, student])

    useEffect(() => {
        if(!student.goalSetting) return
        if(student.modalOverride) { 
            handleClose('goalSetting', undefined) 
            return
        }  
        if(student.goalSetting.display <= goalQuestions.length && focusAgreements.goalSetting && !student.goalSetting.review.reviewComplete) {
            handleShow(undefined, 'goalSetting')
        } else if(student.goalSetting.display > 2 || !focusAgreements.goalSetting) {
            handleClose('goalSetting', undefined)
        }
    }, [student.goalSetting && student.goalSetting, student.modalOverride, focusAgreements])

    useEffect(() => {
        if(student.modalOverride) { 
            handleClose('customModal', undefined) 
            return
        }        
        if(focusAgreements.customModal) {
            handleShow(undefined, 'customModal')
        } else if(!focusAgreements.customModal) {
            handleClose('customModal', undefined)
        }
    }, [student.goalSetting && student.goalSetting, student.modalOverride, focusAgreements])

    // console.log(currentEntry,'currentEntry')

    const handleClose = (type, e) => {
        if(e) e.preventDefault()

        setShow(prev => {
            var index = prev.findIndex(entry => entry.type === type)
            prev[index].show = false
            return prev
        })
        setUpdate(prev => {return prev + 1})
    }

    const handleShow = (e, type) => {
        if(e) e.preventDefault()
        setShow(prev => {
            var index = prev.findIndex(entry => entry.type === type)
            prev[index].show = true
            return prev
        })
        setUpdate(prev => {return prev + 1})
    }

    const handleStudentOverride = (e, oldValue,type) => {
        if(e) e.preventDefault()
        const editShow = [...show]
          var index = editShow.findIndex(entry => entry.type === type)
          editShow[index].studentOverride = !oldValue
        setShow(editShow)

        // console.log({customOverride: !oldValue}, 'customOverride: !oldValue')
        updateMongo({customOverride: !oldValue}, student.dailyListId, 'dailyList')
        setStudent(prev => {
            prev.customOverride = !oldValue
            return prev
        })
        // setUpdate(prev => {return prev + 1})
    }

    return { handleClose, handleShow, handleStudentOverride }
}
