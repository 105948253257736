import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../App.tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function MessageEntry({entry}) {

    const { 
        studentList,
        studentDataFunctions: {
        }
    } = useContext(SiteContext)

    if(entry.viewed) {
        var opacity = 'viewed'
    } else {var opacity = ''}

    if(entry.reason === "Login") {
        var badgeVariant = 'success'
    } else if(entry.reason === "Login Problems") {
        var badgeVariant = 'warning'
    } else {
        var badgeVariant = 'primary'
    }

    // console.log(entry,'entry message')
    if(entry.studentId) {
        // console.log(entry,'entry w id', studentList)
        var index = studentList.findIndex(item => item.studentId === entry.studentId)
            var note = studentList[index].checkIn.note
            var color = studentList[index].checkIn.color
            var emoji = studentList[index].checkIn.emoji
    }

    return (
        <tr className={opacity}>
            <td className="text-center"> 
                <a>{entry.name}</a>
            </td>
            {/* <td className="text-center">
                <a>{entry.reason}</a>
            </td> */}
            <td className="">
                <div className="box">
                    <div className={`mb-2 me-2 badge bg-${badgeVariant}`}>{entry.reason}</div>
                    <span className="text">
                        {entry.reason === 'Login' && color !== '' &&
                            <FontAwesomeIcon  className="btn-icon-wrapper" color={color} icon={emoji} style={{marginRight:"5px"}}/>}
                        {entry.message}
                    </span>
                </div>
            </td>
        </tr>
    )
}
