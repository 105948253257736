import React, { useContext } from 'react'

import { SiteContext } from '../../../App.tsx';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ColorToolip({variantNew, pendingColor}) {

    const { 
        colorMatch,
        student,
        studentDataFunctions: {
        }
     } = useContext(SiteContext)

    var currentIndex = colorMatch.findIndex(entry => entry.color === student.color.current)
     var currentVariant = colorMatch[currentIndex].variant

    return (

        <OverlayTrigger className
            placement="bottom"
            overlay= {
                <Tooltip>
                    <div className="box me-2">
                        <div className={`badge me-2 rounded-pill bg-${currentVariant}`}>{student.color.category}</div>
                        <span className="text">{student.color.reason}</span>
                    </div>
                </Tooltip>
            }
        >
            <div className={`badge rounded-pill bg-${variantNew} ms-1 m-1`}>
            Pending: {pendingColor}
            </div>
        </OverlayTrigger>         

    )
}
