import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { SiteContext } from "../../App.tsx";
import { AdminContext } from "../../pages/AdminContainer";
import { ErrorBoundary } from "../../other/utils/ErrorBoundary";
 
export default function AddModal() {

 const {classInformation, focusAgreements} = useContext(SiteContext)
 const {showChipModal, handleChipModal, handleChipClose, addChips} = useContext(AdminContext)

 const chipsPeriod = focusAgreements.chips
//  console.log(chipsPeriod,'chipsPeriod', focusAgreements)
//  addChips.filter(entry => entry.period === classInformation.period)[0]

// console.log('Hello')
 if(!chipsPeriod || chipsPeriod.length === 0) return

//  console.log('Hello 2')
 return (

    <Modal 
      show={showChipModal} 
      onHide={handleChipClose}
      // style={{width:"80%"}}
      // dialogClassName="modal-height"
    >
      <Modal.Header closeButton>
        <Modal.Title>Chips Reminder!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorBoundary>
        <div>
            <span className={`badge rounded-pill bg-${chipsPeriod[0].variant} dailyPill`} style={{fontSize:"12px"}}>{chipsPeriod[0].timeStamp}</span>
            <span className={`badge rounded-pill bg-${chipsPeriod[1].variant} dailyPill`} style={{fontSize:"12px"}}>{chipsPeriod[1].timeStamp}</span>
            <span className={`badge rounded-pill bg-${chipsPeriod[2].variant} dailyPill`} style={{fontSize:"12px"}}>{chipsPeriod[2].timeStamp}</span>
            <span className={`badge rounded-pill bg-secondary dailyPill`} style={{fontSize:"12px"}}>{classInformation.period}</span>
        </div>
        </ErrorBoundary>
      </Modal.Body>
      <Modal.Footer>
        <ErrorBoundary>
        <button className="btn btn-primary" onClick={e => handleChipModal(e, 'reject', true)}>
            Attendance
        </button>
        <button className="btn btn-success" onClick={e => handleChipModal(e, 'add', false)}>
            Chips
        </button>
        <button className="btn btn-info" onClick={e => handleChipModal(e, 'add', true)}>
            Chips &amp; Attendance
        </button>
        <button className="btn btn-flag text-white" onClick={e => handleChipModal(e,'reject')}>
            Close
        </button>
        </ErrorBoundary>
      </Modal.Footer>
    </Modal>
);
}



