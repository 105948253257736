import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../App.tsx";
import { SchoolCycleLookFor } from "../../../types/school.ts";
import { v4 as uuidv4 } from "uuid";
import { fetchLookFors, fetchHexCodes } from '../../../types/api.tsx';
import { LookFor, LookForRubric } from "../../../types/lookFors.ts"; // ✅ Import the TypeScript type
import { Row, Col, Button, Form } from "react-bootstrap";

const PairLookFors: React.FC = ({schoolId, staff, cycleNum}) => {
  const [lookFors, setLookFors] = useState<LookFor[]>([]);
  const [hexCodes, setHexCodes] = useState([])
  const [showDetails, setShowDetails] = useState(false)
  const [selectedChosenBy, setSelectedChosenBy] = useState<string[]>([]);
  const [selectedTeacherIDs, setSelectedTeacherIDs] = useState<string[]>([]);
  const [lookForsExist, setLookForsExist] = useState(false)
  
  const handleChange = (index: number, field: keyof SchoolCycleLookFor, value: any) => {
    const updatedLookFors = [...lookFors];
    updatedLookFors[index] = { ...updatedLookFors[index], [field]: value };
    setLookFors(updatedLookFors);
  };

  useEffect(() => {
    const getLookFors = async () => {
        try {
            const allLookFors = await fetchLookFors();
            const response = await axios.get(`${baseUrl}/api/schools/${schoolId}`);
            const schoolData = response.data;

            // Find the cycle based on cycleNum
            const currentCycle = schoolData.cycles.find(cycle => cycle.cycleNum === cycleNum);
            if (!currentCycle) {
                console.warn("Cycle not found, showing all LookFors.");
                setLookFors(allLookFors);
                return;
            }

            if(currentCycle.lookFors.length > 0) {
                setLookForsExist(true)
            }
            // Extract lookForIDs from the current cycle
            const cycleLookForIDs = new Set(currentCycle.lookFors.map(lf => lf.lookForID));

            // Filter LookFors to exclude those already in the cycle
            const filteredLookFors = allLookFors.filter(lf => !cycleLookForIDs.has(lf._id));

            setLookFors(filteredLookFors);
        } catch (error) {
            console.error("Error fetching LookFors:", error);
        }
    };

    const getHexCodes = async () => {
        const data = await fetchHexCodes();
        setHexCodes(data);
    };

    getLookFors();
    getHexCodes();
  }, [schoolId, cycleNum]);


  const handleAddLookForToCycle = async (lookForID: string) => {
    try {
        console.log(lookFors,'lookFors',lookForID)
        const selectedLookFor = lookFors.find(lf => lf._id === lookForID);
        if (!selectedLookFor) {
            alert("LookFor not found!");
            return;
        }

      const newLookForEntry = {
        lookForID,
        lookForIndex: selectedLookFor.index, // Extract and pass lookForIndex
        chosenBy: selectedChosenBy,
        teacherIDs: selectedTeacherIDs,
        scores: [], 
        active: true,
        cycleNum
      };
    //   console.log("before--------", newLookForEntry)
      await axios.post(`${baseUrl}/api/schools/${schoolId}/cycles/addLookFor`, newLookForEntry);
      alert("LookFor added to cycle!");
    } catch (error) {
      console.error("Error adding LookFor to cycle:", error);
    }
  };

  const handleShowDetails = (e) => {
    e.preventDefault()
    setShowDetails(prev => {return !prev})
  }

  return (
    <>
    {lookFors.length === 0 ? <div className="container mt-4 fw-bold rounded bg-light p-3">{lookForsExist ? "All Look Fors Already Matched" : "No Global Look Fors"}</div> : 
    <div className="container mt-4 p-3 bg-secondary rounded">
    <div className={`d-flex justify-content-between align-items-center ${showDetails && 'mb-3'}`}>
        <h5 className="fw-bold text-white mb-0">Global Look Fors <span className="fst-italic fw-lighter">(Not Yet Included)</span></h5>
        <Button variant="light" className="" onClick={e => handleShowDetails(e)}>
            {showDetails ? 'Hide Details' : 'Show Details'}
        </Button>
    </div>
    {/* Display LookFors */}
    {showDetails && <div className="p-0">
        {lookFors.map((lookFor) => (
            <div key={lookFor.lookForID} className={`p-1 mb-3 container bg-white rounded p-3 border border-${lookFor.studentFacing ? 'danger' : 'warning'} border-4`}>
                {/* <h4 className="fw-bold">{lookFor.studentFacing ? "🍎" : "✏️"} {lookFor.topic}</h4>
                <p>{lookFor.description}</p> */}
                <div className="p-1">
                    <Row className="text-center d-flex align-items-stretch">
                        <Col md={1} className="rounded p-0 m-0 d-flex">
                            <div className={`m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-start text-center bg-${lookFor.studentFacing ? 'danger' : 'warning'}`}>
                             <h2 className="fw-bold">{lookFor.studentFacing ? '🍎' : '✏️'} 1</h2>
                            </div>
                        </Col>
                        <Col md={5} className="rounded p-0 m-0 d-flex">
                            <div className="m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-between text-start bg-light">
                                <h4 className="fw-bold">{lookFor.topic}</h4>
                                <div className="flex-grow-1 mb-1">
                                    <div
                                            className="flex-grow-1 mb-1"
                                            dangerouslySetInnerHTML={{ __html: lookFor.description }}
                                        />
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className="rounded p-0 m-0 d-flex ">
                            <Col md={6} className="rounded p-0 m-0 d-flex">
                                <div className="m-1 p-2 w-100 d-flex flex-column bg-light border border-secondary border-4 rounded">
                                {/* <h5 className="fw-bold text-secondary m-1 p-0">Add to Cycle</h5> */}
                                    {/* Chosen By Select */}
                                    <Form.Label className="fw-bold mb-2">Chosen By</Form.Label>
                                    <Form.Select multiple value={selectedChosenBy} onChange={(e) =>
                                        setSelectedChosenBy(Array.from(e.target.selectedOptions, (option) => option.value))
                                    }>
                                        <option value="Coach">Coach</option>
                                        <option value="Admin">Admin</option>
                                        {staff.filter(member => member.role.includes("Teacher")).map(member => (
                                        <option key={member.staffID} value={member.staffID}>{member.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} className="rounded p-0 m-0 d-flex">
                                <div className="m-1 p-2 w-100 d-flex flex-column justify-content-between bg-light border border-secondary border-4 rounded">
                                    {/* Teacher IDs Select */}
                                    <Form.Label className="fw-bold mt-2">Teacher(s)</Form.Label>
                                    <Form.Select multiple value={selectedTeacherIDs} onChange={(e) =>
                                        setSelectedTeacherIDs(Array.from(e.target.selectedOptions, (option) => option.value))
                                    }>
                                        {staff.filter(member => member.role.includes("Teacher")).map(member => (
                                        <option key={member.staffID} value={member.staffID}>{member.name}</option>
                                        ))}
                                    </Form.Select>

                                    {/* Add Button */}
                                    <Button variant="success" className="mt-2" onClick={() => handleAddLookForToCycle(lookFor._id, )}>
                                        ➕ Add Look For
                                    </Button>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="text-center d-flex align-items-stretch">
                        <Col md={12} className="rounded p-0 m-0 d-flex">
                            {lookFor.rubric.map((rubricItem, index) => (
                                <Col key={index} md={3} className="rounded p-0 m-0 d-flex">
                                    <div 
                                        className="m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-between text-start"
                                        style={{ backgroundColor: hexCodes[index % hexCodes.length]?.secondary || "#ccc", minHeight: "100%" }}
                                    >
                                        <h4 className="fw-bold">{rubricItem.category} ({rubricItem.score})</h4>
                                        <div className="flex-grow-1 mb-1">
                                            <div className="flex-grow-1 mb-1" dangerouslySetInnerHTML={{ __html: rubricItem.content }} />
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Col>
                    </Row>
                </div>
            </div>
        ))}
    </div>}
  </div>}
  </>
  );
};

export default PairLookFors;
