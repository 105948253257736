import React, { useState, useEffect } from "react";

// We use Route in order to define the different routes of our application
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import io from "socket.io-client";
 
// We import all the components we need in our app
import Login from "./pages/Login.js"
import ChoosePeriod from "./pages/ChoosePeriod.js";
import ModuleSelect from "./pages/ModuleSelect.js"
import Checkin from "./pages/Checkin.js"
import AdminContainer from "./pages/AdminContainer.js";
import StudentContainer from "./pages/StudentContainer.js";

import { useStore } from './files/app/store.js'
import { useStorage } from './files/app/storage.js' 
import { useClassInformation } from "./files/app/classInformation.js";
import { useMongoFunctions } from "./files/app/mongoFunctions.js";
import { useBehavior } from "./files/app/behavior.js";
import { useFocus } from "./files/app/focus.js";
import { useTime } from "./files/app/time.js";
import { useInventory } from "./files/app/inventory.js";
import { useReset } from "./files/app/reset.js";
import { useColorMatch } from "./other/utils/colorMatch.js";
import { useGoal } from "./files/app/goal.js";
import { useGeneral } from "./files/app/general.js";
import { useChecklist } from "./files/app/checklist.js";
import { useSchedules } from "./files/app/schedules.js";
import { useCalendar } from "./other/utils/calendar.js";
import { useModules } from "./files/app/modules.js";
import { useProfile } from "./files/app/profile.js";
import { useModal } from "./files/app/modal.js";
import GoogleDataViewer from "./googleAPI/GoogleDataViewer.js"

import birthdayFunction from "./other/utils/birthday.js";

import { ErrorBoundary } from "./other/utils/ErrorBoundary.js";
import { usePostHog } from 'posthog-js/react'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export const SiteContext = React.createContext()
export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://www.helmsmiddlemath.com'

const LOCAL_STORAGE_KEY = 'classWebsite.studentList'
const LOCAL_STORAGE_KEY2 = 'classWebsite.student'
const LOCAL_STORAGE_KEY3 = 'classWebsite.loginState'
const LOCAL_STORAGE_KEY4 = 'classWebsite.classInformation'

const socket = io.connect(baseUrl);

const App = () => {

//  GoogleDataViewer(baseUrl)
 const posthog = usePostHog()
//  const navigate = useNavigate();
 const colorMatch = useColorMatch()

 const { useCreateStorageState, useSaveLocalStorage } 
 = useStorage()

 const { createMongo, getMongoList, getMongoListPeriod, getMongoByUniqueId, getMongoById, getMongoAllById, getMongoAllByParam, updateMongo, deleteMongo } 
 = useMongoFunctions()

 const { schedules, setSchedules, calendarDays, setCalendarDays, getClassTimes } 
 = useSchedules({getMongoList})

 const { checkCalendar } 
 = useCalendar({calendarDays, schedules})

 const { checkToday, calculateTimeElapsed, stringtoJSDate, handleDateString } 
 = useTime()

 const { goalQuestions } 
 = useGoal({})

const [isConnected, setIsConnected] = useState(socket.connected);
const [wasDisconnected, setWasDisconnected] = useState(false);

 const [room, setRoom] = useState('');
 const [roomJoined, setRoomJoined] = useState(false)

 const [records, setRecords] = useState([]);
 const [studentListLoad, setStudentListLoad] = useState(false)
 const [classesUpdated, setClassesUpdated] = useState(false)
 var [student, setStudent] = useState(useCreateStorageState(LOCAL_STORAGE_KEY2, nullStudent))
 const [loginState, setLoginState] = useState(useCreateStorageState(LOCAL_STORAGE_KEY3, {}))
//  const [studentList, setStudentList] = useState(useCreateStorageState(LOCAL_STORAGE_KEY, []))
 const [studentList, setStudentList] = useState([])
 const [dailyListIndexes, setDailyListIndexes] = useState(useCreateStorageState(LOCAL_STORAGE_KEY, []))
 const [initialLoad, setInitialLoad] = useState(false)
 
 useEffect(() => {
  socket.on("disconnect", () => {
    setIsConnected(false);
    setWasDisconnected(true); // Mark that a disconnect happened
    console.warn("⚠️ Connection lost! Trying to reconnect...");
  });

  socket.on("connect", () => {
    setIsConnected(true);
    console.log("✅ Reconnected to server!");

    if (wasDisconnected && isConnected) {
      console.log("🔄 Reloading due to prior disconnection...");
      window.location.reload(); // Hard refresh **only if previously disconnected**
      setWasDisconnected(false)
    }
  });

  return () => {
    socket.off("disconnect");
    socket.off("connect");
  };
}, [wasDisconnected]); // Depend on `wasDisconnected` to track prior disconnections

 const {widthWindow} 
 = useGeneral({student, setStudent, checkToday, updateMongo, loginState})

 const { handleClose, handleShow, modalShowFunction, show, setShow } 
 = useModal({student, setStudent})


 const { classInformationArray, setClassInformationArray, classInformationArrayArchived, setClassInformationArrayArchived, classInformation, setClassInformation, periodBg } 
 = useClassInformation({getMongoList, updateMongo, student, calendarDays, useCreateStorageState, setClassesUpdated, useSaveLocalStorage, LOCAL_STORAGE_KEY4, classesUpdated, loginState, classInformationObject, checkCalendar, getClassTimes, schedules})

 const { updateFocus, checkFocus, focusAgreements, setFocusAgreements, setUpdateFocusAgreements, updateFocusSelection } 
 = useFocus({classInformation, getMongoList, getMongoByUniqueId, updateMongo, socket, room, stringtoJSDate})

 const { modules, setModules, moduleFind, moduleField, setModuleField } 
 = useModules({getMongoList, student, show})

 const { storage, dailyFunction, badgesFunction, moduleItems, setModuleItems, handleUpdateCheckout} 
 = useInventory({getMongoList, getMongoAllByParam, updateMongo, student, socket, room, classInformation, loginState, modules, moduleField, stringtoJSDate})

 const { reviewEntries, levelChangeCalc, reviewStudent, setReviewStudent, createReviewEntry, currentEntry, setRandomStudentIndex, randomStudentIndex, updateRandomIndex } 
 = useBehavior({classInformation, calculateTimeElapsed, getMongoAllById, getMongoAllByParam, getMongoById, student, handleDateString, getMongoList, studentList, loginState, checkCalendar, loginState, classInformationArray, calendarDays, getClassTimes})

 const { cart, setCart, storeItems, setStoreItems } 
 = useStore({studentList, updateMongo, setStudentList, socket, room, getMongoList})

 const { handleResetOtherLists, handleLogout, handleResetStudent, clearFocusAgreements, updateDailyRecord, newCustomModal, cleanFocusAgreement, individualAttendanceData }
 = useReset({getMongoList, getMongoByUniqueId, deleteMongo, updateMongo, setRecords, studentList, setStudentList, getRecords, socket, setInitialLoad, getMongoById, classInformation,
              setStudent, setRoomJoined, setClassInformation, classInformationObject, LOCAL_STORAGE_KEY, setFocusAgreements, modules,
              LOCAL_STORAGE_KEY2, LOCAL_STORAGE_KEY3, LOCAL_STORAGE_KEY4, setUpdateFocusAgreements, loginState, classInformationArray })

  const { calculateCompletion }
  = useChecklist()

  const { profileElements }
  = useProfile()

  // console.log(student,'student')
  const inputStudent = {...studentList[randomStudentIndex]}
    delete inputStudent.modalOverride
    delete inputStudent.customOverride

 useSaveLocalStorage(LOCAL_STORAGE_KEY, dailyListIndexes)
 useSaveLocalStorage(LOCAL_STORAGE_KEY2, student)
 useSaveLocalStorage(LOCAL_STORAGE_KEY3, loginState)

 const joinRoom = (personName, roomProvided) => {
    if (roomProvided) {
      socket.emit("join_room", {room: roomProvided, personName});
    }
 };

  async function sendMessage(messageObj) {

    messageObj.timeStamp = Date.now()
    messageObj.viewed = false

    createMongo(messageObj, 'messages')
    socket.emit("send_message_Center", { messageObj, room });
  }

  useEffect(() => {

    getRecords();
    setStudentListLoad(true)
    return;

  }, [studentList.length, classInformationArray.length]);

  async function getRecords() {
    
    // console.log(window.location.href,'window.location.href 22')
    //// stop the data-intensive Mongo calls while website is nerfed
    // return

    if (window.location.href.includes("/google")) {
      console.log("Exiting getRecords(): URL contains '/google'");
      return;
    }

    if(loginState && !loginState.admin) return
    if(classInformationArray.length === 0) return

    const records = await getMongoList('records')
    const dailyList = await getMongoList('dailyList')

    console.log('lists loaded')
    if(!records || !dailyList) return

    setRecords(records);
    updateLists(records, dailyList)
  }

  function updateLists(records, dailyList) {

    var studentListFiltered = [...studentList]

    studentList.map(entry => {
      var index = records.findIndex(item => item.studentId === entry.studentId)
      if(index !== -1) return
      studentListFiltered = studentListFiltered.filter(item => item.studentId !== entry.studentId)
    })

    matchData(dailyList, 'dailyList')
    matchData(records, 'records')

    async function matchData(list, type) {
      const promises = list.map(async (entry) => {
        var index = studentListFiltered.findIndex(item => item.studentId === entry.studentId)

        if(type === 'dailyList') {
          // console.log(index, 'index')
        }
        if(type === 'dailyList') {
          entry.dailyListId = entry._id
          delete entry._id
        }
        if(type === 'records') {
          if(entry.dailyListId) {
            delete entry.dailyListId
          }
        }
        if(type === 'records' && !entry.dailyRecordRosterId) {
          const dailyRecordEntry = await getMongoById(entry.studentId, 'dailyRecordRoster')
          // console.log(dailyRecordEntry,'dailyRecordEntry', entry)
          updateMongo({dailyRecordRosterId: dailyRecordEntry._id}, entry._id, 'records')
          entry.dailyRecordRosterId = dailyRecordEntry._id
        }

        if(index === -1) {
          studentListFiltered.push({...entry, color: {current: 'Green', pending: '', warningsGiven: 0}})
        } else {
          studentListFiltered[index] = {...studentListFiltered[index], ...entry}
          if(type === 'dailyList') {
            // console.log(studentListFiltered[index],'studentListFiltered[index]','entry',entry, 'type', type)
          }
        }

      })

      await Promise.all(promises)
    }

    setInitialLoad(true)
    updateDailyRecord(studentListFiltered)
    delete studentListFiltered['days']
    // console.log(studentListFiltered,'studentListFiltered')
    setStudentList(studentListFiltered)

    var chosenId = isMobile ? 'alexander.smith@wccusd.net - mobile' : 'alexander.smith@wccusd.net - browser'
    posthog.identify(chosenId, {
      email: 'alexander.smith@wccusd.net',
    })
    posthog.group('period', 'admin');
    
    console.log('complete -- load records and daily')
  }

  const studentDataFunctions = {

    setStudent, setRecords, setStudentList, setClassInformation, setCart, setStoreItems,
    setClassInformationArray, setClassInformationArrayArchived, setRoomJoined, setClassesUpdated, setLoginState, setRoom,
    
    handleResetOtherLists, handleLogout, handleResetStudent, setInitialLoad,
    
    joinRoom, sendMessage, checkToday, calculateTimeElapsed, levelChangeCalc, stringtoJSDate, handleDateString,
    useCreateStorageState, useSaveLocalStorage,

    createMongo, getMongoList, getMongoByUniqueId, getMongoById, getMongoAllById, getMongoAllByParam, updateMongo, deleteMongo,

    updateFocus, checkFocus, setUpdateFocusAgreements, moduleFind, setFocusAgreements, clearFocusAgreements,
    
    getRecords, setModuleItems, setModuleField, handleUpdateCheckout, updateFocusSelection,

    calculateCompletion, cleanFocusAgreement, setSchedules, setCalendarDays, checkCalendar, setModules, getClassTimes, setReviewStudent,

    handleClose, handleShow, setShow, createReviewEntry, setRandomStudentIndex, updateRandomIndex, individualAttendanceData
  }

 return (
<ErrorBoundary>
   <div>
     <SiteContext.Provider value={{ 
         classInformation, studentList, student, records, classInformationArray, classInformationArrayArchived,
         room, roomJoined, loginState, reviewEntries,
         socket, studentListLoad, cart, storeItems,
         studentDataFunctions, 
         colorMatch, focusAgreements,
         modules, storage, dailyFunction, badgesFunction,
         initialLoad, goalQuestions, adminPages,
         moduleItems, moduleField, widthWindow, newCustomModal,
         schedules, calendarDays, reviewStudent, profileElements, periodBg,
         modalShowFunction, show, currentEntry, randomStudentIndex,
    }}>

      <ErrorBoundary>
        <Routes>
            <Route path="/" exact element={<Login />} />
            {/* <Route path="/" exact element={<ModuleSelect/>} /> */}

            {/* student pages */}
            <Route path="/modules" element={<ModuleSelect/>} />
            {!loginState.admin && <Route path="/checkIn" element={<Checkin />} />}
            {studentSorted.map(entry => {
              return <Route key={entry.type} path={`/${entry.type}`} exact element={<StudentContainer type={entry.type} text={entry.text} />} />
            })}
            {loginState.loggedIn && !loginState.admin && <Route path="*" element={<Navigate replace to="/student" />} />}
            {!loginState.loggedIn && <Route path="*" element={<Navigate replace to="/"/>}/>}

            {/* admin pages */}
            {loginState.admin && <Route path="/choosePeriod" exact element={<ChoosePeriod />} />}
            {loginState.admin && <Route path="/edit/:id" element={<AdminContainer type={'editRecord'} />} />}
            {loginState.admin && <Route path="/create" element={<AdminContainer type={'createRecord'} />} />}
            {loginState.admin && adminSorted.map(entry => {
              return <Route key={entry.type} path={`/${entry.type}`} exact element={<AdminContainer type={entry.type} text={entry.text} />} />
            })}
            <Route path="/google-data" element={<GoogleDataViewer baseUrl={baseUrl}  />} />
            
        </Routes>
        {/* <GoogleDataViewer baseUrl={baseUrl} /> */}
        </ErrorBoundary>
     </SiteContext.Provider>
   </div>
  </ErrorBoundary>
 );
};

export default App;

const studentPages = [
  {type: 'checkIn', text: 'Check In'},
  {type: 'student', text: 'Student'},
  {type: 'genInfo', text: 'General Information'},
  {type: 'store', text: 'Store'},
  {type: 'reflection', text: 'Reflection'},
  {type: 'colorReview', text: 'Color Review'},
  {type: 'inventoryS', text: 'Lab Inventory'},
  {type: 'preview', text: 'Module Previews'},
  {type: 'checklistS', text: 'Progress Checklist'},
  {type: 'profile', text: 'Profile'},
]

const studentSorted = pagesSort(studentPages)

var adminPages = [
  {type: 'dashboard', text: 'Dashboard', loginPage: true, priority: 1},
  {type: 'records', text: 'Records', loginPage: true},
  // {type: 'classPage', text: 'Classes', loginPage: true},
  {type: 'pquiz', text: 'PQuiz', loginPage: true},
  // {type: 'agreements', text: 'Agreements', loginPage: true},
  // {type: 'seatingChart', text: 'Seating Chart', loginPage: false},
  // {type: 'classEdit', text: 'Class Edit', loginPage: true},
  {type: 'grades', text: 'Grades & Attendance', loginPage: true, priority: 2},
  {type: 'astore', text: 'Store', loginPage: true, priority: 2},
  {type: 'feelingsWheel', text: 'Feelings Wheel', loginPage: true},
  // {type: 'scheduleAdmin', text: 'Schedule', loginPage: true},
  {type: 'hallPass', text: 'Hall Passes', loginPage: true},
  {type: 'inventory', text: 'Inventory', loginPage: true, priority: 2},
  {type: 'auction', text: 'Auction', loginPage: true},
  {type: 'colorGrid', text: 'Color Grid', loginPage: true, priority: 1},
  {type: 'roster', text: 'Roster', loginPage: true},
  {type: 'classes', text: 'Classes', loginPage: true},
  {type: 'customModal', text: 'Custom Modal', loginPage: true},
  // {type: 'inventoryByDay', text: 'Inventory By Day', loginPage: true},
  {type: 'achecklist', text: 'Checklist', loginPage: false},
  {type: 'storeItems', text: 'Store Items', loginPage: false},
  {type: 'events', text: 'Events', loginPage: false, priority: 1},
  // {type: 'grid', text: 'Grid', loginPage: false},
  {type: 'modules', text: 'Modules', loginPage: false},
  {type: 'schedules', text: 'Schedules & Calendars', loginPage: false},
  {type: 'colorReviewAdmin', text: 'Color Review', loginPage: false},
  {type: 'audioUploader', text: 'Audio Uploader', loginPage: false},
  {type: 'jsonEditor', text: 'JSON Editor', loginPage: false},
  {type: 'recorder', text: 'Class Recorder', loginPage: false},
  {type: 'schools', text: 'Schools', loginPage: false},
  {type: 'lookFors', text: 'Look Fors', loginPage: false},
  {type: 'score', text: 'Score Entry', loginPage: false},
  {type: 'scorePage', text: 'Rubrics', loginPage: false},
]

const adminSorted = pagesSort(adminPages)

function pagesSort(obj) {
  return obj.sort(function(a, b) {
    return a.text.localeCompare(b.text);
  });
}


const nullStudent = {
  color: {
  current: 'Green',
  warningsGiven: 0,
  pending: ''
  },
  chips: {
    balance: 0,
    total: 0,
  },
  chipToday: {
    added: 0,
    subtracted: 0,
  },
  birthday: "01/01/2001"
}

const classInformationObject = {
  period: '',
  hallPassStatus: {
      message: '15 Min Rule',
      available: false
  },
  agreements: [],
  dayReview: {display: false}
}

