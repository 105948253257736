import React, { useEffect, useState, useContext } from "react";

import '../assets/base.scss'
import '../assets/style.css'

import { SiteContext } from "../App.tsx";
import Preview from "../student/preview/Preview";

export default function ChoosePeriod() {

    const {
        modules
        // studentDataFunctions: {
        //     setClassInformation, checkCalendar
        // },
        // adminPages, classInformationArray
    } = useContext(SiteContext)

    return (
        <div className="p-3">
            {/* Hello */}
            <Preview modules={modules} />
        </div>
    )
}