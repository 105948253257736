import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../App.tsx";
import { v4 as uuidv4 } from "uuid";
import { fetchAllowedRoles, fetchAllowedSubjects, fetchAllowedSpecialGroups, fetchAllowedGrades } from '../../../types/api.tsx'; // ✅ Use require()
import { SchoolStaff } from "../../types/school.ts"; // ✅ Import the TypeScript type

interface Staff {
//   _id?: string;
  name: string;
  staffID: string;
  email: string;
  grade: string[];
  specialGroups: string[];
  role: string[];
  subjects: string[];
}

interface Props {
    schoolId: string;
    staffImport: Staff[];
}

const Staff: React.FC<Props> = ({ schoolId, staffImport }) => {
  const [staff, setStaff] = useState<SchoolStaff[]>(staffImport);
  const [newStaff, setNewStaff] = useState<SchoolStaff>({
    name: "",
    staffID: uuidv4(),
    email: "",
    grade: [],
    specialGroups: [],
    role: [],
    subjects: [],
  });
  
  const [roles, setRoles] = useState<string[]>([]); // Store fetched roles
  const [subjects, setSubjects] = useState<string[]>([]);
  const [specialGroups, setSpecialGroups] = useState<string[]>([]);
  const [grades, setGrades] = useState<string[]>([]);

  useEffect(() => {
    const fetchAllEnums = async () => {
      try {
        const [fetchedRoles, fetchedSubjects, fetchedSpecialGroups, fetchedGrades] = await Promise.all([
          fetchAllowedRoles(),
          fetchAllowedSubjects(),
          fetchAllowedSpecialGroups(),
          fetchAllowedGrades(),
        ]);
  
        setRoles(fetchedRoles);
        setSubjects(fetchedSubjects);
        setSpecialGroups(fetchedSpecialGroups);
        setGrades(fetchedGrades);
  
      } catch (error) {
        console.error("❌ Failed to fetch enums:", error);
      }
    };
  
    fetchAllEnums();
  }, []);

  const handleChange = (index: number, field: keyof SchoolStaff, value: any) => {
    const updatedStaff = [...staff];
    updatedStaff[index] = { ...updatedStaff[index], [field]: value };
    setStaff(updatedStaff);
  };

//   const handleNewStaffChange = (field: keyof Staff, value: any) => {
//     console.log(value,'value')
//     setNewStaff({ ...newStaff, [field]: value });
//   };

  const handleNewStaffChange = (field: keyof SchoolStaff, value: any) => {
    console.log(value, "value");
    setNewStaff({ ...newStaff, [field]: value });
  };

  const addNewStaff = async () => {
    try {
        console.log(schoolId,'schoolId')
        const response = await axios.post(`${baseUrl}/api/schools/${schoolId}/staff`, newStaff);
        console.log("✅ Staff member added successfully:", response.data);

        // Update the staff list in state with the new staff member
        setStaff([...staff, response.data]);

        // Reset form fields after adding
        setNewStaff({ name: "", staffID: "", email: "", grade: [], specialGroups: [], role: [], subjects: [] });

    } catch (error) {
        console.error("❌ Error adding staff member:", error);
    }
  };

  const confirmDelete = (staffId: string, e) => {
    e.preventDefault();
    const isConfirmed = window.confirm("Are you sure you want to delete this staff member? This action cannot be undone.");
    if (isConfirmed) {
        deleteStaffMember(staffId, e);
    }
};

  const deleteStaffMember = async (staffId: string, e) => {

    if(e) e.preventDefault()
        
    try {
        const response = await axios.delete(`${baseUrl}/api/schools/${schoolId}/staff/${staffId}`);
        console.log("✅ Staff member deleted successfully:", response.data);

        // Update the staff list in state after deletion
        setStaff(staff.filter(member => member.staffID !== staffId));

    } catch (error) {
        console.error("❌ Error deleting staff member:", error);
    }
  };

  const saveChanges = async () => {
    try {
      const response = await axios.put(`${baseUrl}/api/schools/${schoolId}/staff`, { staff });
      console.log("✅ Staff updated successfully:", response.data);
    } catch (error) {
      console.error("❌ Error updating staff:", error);
    }
  };

  function isEven(number) {
    return number % 2 === 0;
  }

  return (
    <div className="mt-3 p-3 rounded bg-light">
    <div className="p-2">
      <h4 className="fw-bold mb-3">👔 Manage Staff</h4>
      <table className="mt-2 table bg-white rounded">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Subjects</th>
            <th>Grade(s)</th>
            <th>Populations</th>
          </tr>
        </thead>
        <tbody>
          {staff.map((s, index) => (
            <tr key={s.staffID} className={`${isEven(index) && 'bg-white'}`}>
              <td>
                <input type="text" value={s.name} onChange={(e) => handleChange(index, "name", e.target.value)} className="form-control" />
              </td>
              <td>
                <input type="email" value={s.email} onChange={(e) => handleChange(index, "email", e.target.value)} className="form-control" />
              </td>
              <td>
                <select 
                    multiple 
                    value={s.role} 
                    className="form-select"
                    onChange={(e) => {
                    const selectedRoles = Array.from(e.target.selectedOptions, (option) => option.value);
                    handleChange(index, "role", selectedRoles);
                    }}
                >
                    {roles.map((role) => (
                    <option key={role} value={role}>
                        {role}
                    </option>
                    ))}
                </select>
              </td>
                <td>
                    <select 
                        multiple 
                        value={s.subjects} 
                        className="form-select"
                        onChange={(e) => {
                        const selectedSubjects = Array.from(e.target.selectedOptions, (option) => option.value);
                        handleChange(index, "subjects", selectedSubjects);
                        }}
                    >
                        {subjects.map((subject) => (
                        <option key={subject} value={subject}>
                            {subject}
                        </option>
                        ))}
                    </select>
                </td>
                <td>
                    <select 
                        multiple 
                        value={s.grade} 
                        className="form-select"
                        onChange={(e) => {
                        const selectedGrades = Array.from(e.target.selectedOptions, (option) => option.value);
                        handleChange(index, "grade", selectedGrades);
                        }}
                    >
                        {grades.map((grade) => (
                        <option key={grade} value={grade}>
                            {grade}
                        </option>
                        ))}
                    </select>
                </td>
                <td>
                    <select 
                        multiple 
                        value={s.specialGroups} 
                        className="form-select"
                        onChange={(e) => {
                        const selectedSpecialGroups = Array.from(e.target.selectedOptions, (option) => option.value);
                        handleChange(index, "specialGroups", selectedSpecialGroups);
                        }}
                    >
                        {specialGroups.map((group) => (
                        <option key={group} value={group}>
                            {group}
                        </option>
                        ))}
                    </select>
                </td>
                <td>
                    <button className="btn btn-primary mt-3 btn-danger" onClick={e => confirmDelete(s.staffID, e)}>X</button> 
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="btn btn-primary" onClick={saveChanges}>💾 Save Changes</button>
      </div>
      <div className="row g-2 mt-4 rounded p-2" style={{backgroundColor:"#ffffff"}}>
        <h5 className="fw-bold">➕ Add New Staff</h5>
        <div className="d-flex gap-2">
            <div className="col">
            <input type="text" value={newStaff.name} onChange={(e) => handleNewStaffChange("name", e.target.value)} placeholder="Name" className="form-control" />
            </div>
            <div className="col">
            <input type="email" value={newStaff.email} onChange={(e) => handleNewStaffChange("email", e.target.value)} placeholder="Email" className="form-control" />
            </div>
        </div>    
        <div className="d-flex gap-2">
            {/* <div className="col"> */}
            {/* </div> */}
            {/* <div className="col"> */}
            {/* <input type="text" value={newStaff.subjects.join(", ")} onChange={(e) => handleNewStaffChange("subjects", e.target.value.split(", "))} placeholder="Subjects (comma-separated)" className="form-control" /> */}
            {/* </div> */}
            {/* <div className="col-auto"> */}
            <select
                multiple
                value={newStaff.role}
                className="form-select"
                onChange={(e) => {
                    const selectedRoles = Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                    );
                    handleNewStaffChange("role", selectedRoles);
                }}
            >
                {roles.map((role) => (
                    <option key={role} value={role}>
                        {role}
                    </option>
                ))}
            </select>

            {/* Subjects Dropdown */}
            <select
                multiple
                value={newStaff.subjects}
                className="form-select"
                onChange={(e) => {
                    const selectedSubjects = Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                    );
                    handleNewStaffChange("subjects", selectedSubjects);
                }}
            >
                {subjects.map((subject) => (
                    <option key={subject} value={subject}>
                        {subject}
                    </option>
                ))}
            </select>

            {/* Special Groups Dropdown */}
            <select
                multiple
                value={newStaff.specialGroups}
                className="form-select"
                onChange={(e) => {
                    const selectedSpecialGroups = Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                    );
                    handleNewStaffChange("specialGroups", selectedSpecialGroups);
                }}
            >
                {specialGroups.map((group) => (
                    <option key={group} value={group}>
                        {group}
                    </option>
                ))}
            </select>

            {/* Grades Dropdown */}
            <select
                multiple
                value={newStaff.grade}
                className="form-select"
                onChange={(e) => {
                    const selectedGrades = Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                    );
                    handleNewStaffChange("grade", selectedGrades);
                }}
            >
                {grades.map((grade) => (
                    <option key={grade} value={grade}>
                        {grade}
                    </option>
                ))}
            </select>
            <button style={{color:"#e9e9e9"}} className="btn btn-success"  onClick={addNewStaff}>➕ Add Staff</button>
            {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Staff;
