import React, {Component, useContext} from 'react'

import Rating from 'react-rating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

class RatingComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: 0,
    }
  }

  handleRatingChange = (rating) => {
    this.setState({...this.state, value: rating});
    this.props.updateForm(this.props.category, this.props.item, rating)
  }

  render() {
    var count = 0
    var count2 = 0
    return (
        <>
        <Rating initialRating={this.state.value} stop={5}
          emptySymbol={this.props.reversed.map(entry => {
            count++
            return <IconNumber count={count} color={`white`}/>
          })}
          fullSymbol={this.props.reversed.map(entry => {
            count2++
            return <IconNumber count={count2} color={`#6c757d`}/>
        })}
      />
      </>
    );
    
  }
}

function IconNumber({count, color}) {
    var textColor = color === "#6c757d" ? 'white' : "#6c757d"
    return (
        <span className="fa-layers fa-fw m-1" style={{fontSize:"30px"}}>
            <FontAwesomeIcon value={count} style={{color: color}} icon={faSquare} className="fa-lg">1</FontAwesomeIcon>
            <span className={`fa-layers-text fa-inverse fa-xs text-${textColor}`} transform="shrink-8 down-3" style={{color:"#6c757d"}}>{count}</span>
        </span>
    )
}
 
export default RatingComponent