import React, {useState, useContext, useEffect} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid'
import useKeyPress from '../../../../other/utils/useKeyPress';

import { SiteContext } from '../../../../App.tsx';

export default function Body({customOption, setVariables}) {

//   const {modifiedPeriod, setModifiedPeriod, editHeading, setEditHeading, arrays} = dataFunctions

  const { classInformation, focusAgreements, socket, room, studentDataFunctions: { updateMongo, getMongoList, } } 
  = useContext(SiteContext)
  
  function handleChange(e, type, category, categoryEntry) {
    e.preventDefault()

    const modifyHeading = {...customOption}
    if(type === 'categoryEntry') {
        var entryIndex = modifyHeading.bullets.findIndex(entry => entry.entry === categoryEntry)
        modifyHeading.bullets[entryIndex] = {...modifyHeading.bullets[entryIndex], entry: e.target.value}
    } else if(type === 'category') {
        modifyHeading.text = e.target.value
    } else if(type === 'responseType') {
        console.log(e.target.value,'e.target.value')
        modifyHeading.responseType = e.target.value
    }

    console.log(customOption,'customOption agBody', type, categoryEntry)
    setVariables(modifyHeading)
    socket.emit("send_modalDisplay", { data: {[customOption.type]: {...modifyHeading}}, room });
  }

  function handleCreate() {
    // console.log('creating')
    var modifyHeading = {...customOption}
    modifyHeading.bullets.push(newCategoryEntry())

    setVariables(modifyHeading)
    socket.emit("send_modalDisplay", { data: {[customOption.type]: {...modifyHeading}}, room });
  }

  function handleDelete(e, entry){
    var modifyHeading = {...customOption}
    modifyHeading.bullets = modifyHeading.bullets.filter(item => item.id !== entry.id)
    setVariables(modifyHeading)
    socket.emit("send_modalDisplay", { data: {[customOption.type]: {...modifyHeading}}, room });
  }

  const onKeyPress = (event) => {
    // console.log(`key pressed: ${event.key}`);
    if(event.shiftKey === true && event.ctrlKey === true) {
        if(event.key === 'N') handleCreate()
    }
  };

  useKeyPress(['N'], onKeyPress);

    var editFunctions = { handleChange, handleDelete, handleCreate, customOption }
    var indexCount = -1

    return (
      <>
      <ResponseTypes editFunctions={editFunctions} />
      <div className="mt-4">
        <div className="d-block">Prompts:</div>
        <Category key={indexCount} item={customOption} editFunctions={editFunctions} />
      </div>
      </>
    )
    
}

function Category({item, editFunctions, indexCount, length}) {
    
    const {handleChange, handleDelete, handleCreate} = editFunctions

    const {classInformation} = useContext(SiteContext)

      return (
      <div key={item.id} style={{}} className="">
        <textarea cols={40} rows={1}
        type="text" className="form-control bg-light"
        value={item.text} style={{width:"100%", display:"inline"}}
        onChange={(e) => handleChange(e, 'category', item.category)}
        />
        <div style={{marginTop:"15px"}} className="classes-edit__grid">
            {item.bullets && item.bullets.map(element => {
              // console.log(element,'element agBody')
            return <CategoryEntry key={element.id} element={element} item={item} editFunctions={editFunctions} />
            })}
          <span></span>
          <FontAwesomeIcon className="m-2" style={{}} icon={faPlus} role="button" onClick={e => handleCreate(e, 'categoryEntry', item.category)} />
        </div>
      </div>
      )
  }

  function CategoryEntry({element, item, editFunctions}) {
    const {handleChange, handleDelete} = editFunctions

    return (
      <>
        <textarea key={element.id} type="text" className="form-control" cols={40} rows={1}
          value={element.entry} onChange={(e) => handleChange(e, 'categoryEntry', item.category, element.entry)} />
        <FontAwesomeIcon key={element.id + 'fa'} role="button" className="m-2 mt-3 mb-3" onClick={e => handleDelete(e, element)} icon={faX} />
      </>
    )
  }

  function ResponseTypes({editFunctions}) {

    const {handleChange, handleDelete, handleCreate, customOption} = editFunctions

    const [currentResponseType, setCurrentResponseType] = useState(customOption.responseType)
    function updateResponseType(e) {
      e.preventDefault()

      console.log(e.target.value)
      // setCurrentResponseType(e.target.value)
      handleChange(e, 'responseType')
    }

    return (
      <div className="mb-2">
        <div className="d-block">
          Student Response Type:
        </div>
        <select
        className="form-control" role="button"
        onChange={e => updateResponseType(e)}
        value={customOption.responseType}
        >
          <option>No Response</option>
          <option>Poll Response</option>
          {/* <option>Text Response</option>
          <option>Poll Response</option>
          <option>Poll Response and Explain</option>
          <option>Full Screen, No Response</option> */}
        </select>
      </div>
    )

  }

  function newCategoryEntry () {
      return {
        id: uuidv4(),
        entry: ''
      }
  }
