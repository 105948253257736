import React, { useState, useEffect, useContext } from 'react';
import Scroll from './Scroll';
import SearchList from './SearchList';
import DataList from './DataList';

import { SiteContext, baseUrl } from '../../../App.tsx';
import { AdminContext } from '../../../pages/AdminContainer';

export default function Search({ hallPasses }) {

  const { 
    studentList, classInformationArray,
    studentDataFunctions: {
        setRecords, updateMongo, getMongoList
    }
  } = useContext(SiteContext)
  const {} = useContext(AdminContext)

  const blankBg = []
  classInformationArray.map(entry => {
    blankBg.push({period: entry.period, bg: 'light'})
  })
  blankBg.push({period: 'P', bg: 'light'})

  const [searchField, setSearchField] = useState("");
  const [periodField, setPeriodField] = useState("P");
  const [periodBg, setPeriodBg] = useState(blankBg)
  const [showPasses, setShowPasses] = useState(true)

  useEffect(() => {
    const blankBg = []
    classInformationArray.map(entry => {
      blankBg.push({period: entry.period, bg: 'light'})
    })
    blankBg.push({period: 'P', bg: 'light'})
    setPeriodBg(blankBg)
  }, [classInformationArray])

  useEffect(() => {
    // if(periodField === '') {return}
    const editPeriodBg = [...periodBg]
    editPeriodBg.map(entry => {
      entry.bg = 'light'
    })
    var index = editPeriodBg.findIndex(entry => entry.period === periodField)
      if(index === -1) return
      editPeriodBg[index].bg = 'primary'
      setPeriodBg(editPeriodBg)
  }, [periodField, classInformationArray.length])

  var oldPasses = []
  studentList.map(entry => {
    // console.log(entry.hallPasses,'hallPasses')
    if(entry.hallPasses.length === 0) return
    oldPasses = [...oldPasses, ...entry.hallPasses]
  })
  const hallPassesCombined = [...hallPasses, ...oldPasses]

  const filteredPasses = hallPassesCombined.filter(
    person => {
    //   if(periodField.length !== 0 && person.period !== periodField) {return}
      return (
        person
        .name
        .toLowerCase()
        .includes(searchField.toLowerCase()) 
        &&
        person
        .period
        .toLowerCase()
        .includes(periodField.toLowerCase())
      );
    }
  );

  const handleChange = e => {
    setSearchField(e.target.value);
  };

  const handleChangePeriod = (e, period) => {
    e.preventDefault()
    setPeriodField(period);
  }
  
  function handleToggle(e, action) {
    e.preventDefault()
    setShowPasses(action)
  }

  function PeriodSelection({entry, count}) {
    var title = entry.period === 'P' ? 'All' : entry.period
    // console.log(periodBg,'periodBg', count)
    if(!periodBg[count]) return
    return (
      <div role="button"
        className={`m-1 badge bg-${periodBg[count].bg}`}
        onClick={e => handleChangePeriod(e, entry.period)}
      >{title}</div>
    )
  }

  var count = -1
  return (
    <section className="" style={{margin:"10px"}}>
      <div className="pa2 d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex flex-wrap align-items-center">
          <input className="pa3 bb br3 grow b--none bg-lightest-blue ma3" type = "search" 
                placeholder = "Search Students" onChange = {handleChange} />
          <span className="ms-3 fw-bold">Results: {filteredPasses.length}</span>
          <div className="ms-3">
            <div className={`badge bg-${showPasses ? 'primary' : 'light'} m-1 p-1`} onClick={e => handleToggle(e, true)} role="button">Passes</div>
            <div className={`badge bg-${!showPasses ? 'primary' : 'light'} m-1 p-1`} onClick={e => handleToggle(e, false)} role="button">Data</div>
          </div>
        </div>
        <div className="d-flex flex-wrap">
          {blankBg.map(entry => {
              count++
              return <PeriodSelection key={entry.period} entry={entry} count={count} />
          })}
        </div>
      </div>
      {hallPasses && hallPasses.length === 0 && <div style={{marginTop:"20px"}}>Loading...</div>}
      {!showPasses && <AllPassesData filteredPasses={filteredPasses}/>}
      {hallPasses && hallPasses.length > 0 && <Scroll>
        {!showPasses ? 
          <DataList filteredPasses={filteredPasses} periodField={periodField} searchField={searchField} /> : periodField.length > 0 && 
          <SearchList filteredPasses={filteredPasses} />
        }
      </Scroll>}
    </section>
  );
}

function AllPassesData({filteredPasses}) {

  const {calcHallPassMath} = useContext(AdminContext)

  var fortNight = 12096e5/2
  const {over, under, total, totalComplete, totalDifference, totalString, averagePass, totalCount} = calcHallPassMath(filteredPasses, Date.now()-(200*fortNight))

  return (
    <div className="card mt-3">
      <div className="card-body d-flex align-items-center">
        <div className="fw-bold m-1">Data Summary:</div>
        <div className="badge bg-secondary m-1 fsize-1 d-flex justify-content-center m-1" style={{width:"40px"}}>{over + under}</div>
        {over + under > 0 && <div className="badge bg-danger m-1">{over}</div>}
        {over + under > 0 && <div className="badge bg-success m-1">{under}</div>}
        <div className="badge bg-light m-1">Average Pass: {averagePass}</div>
      </div>
    </div>
  )
}