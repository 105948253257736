import React, { useContext, useState, useEffect, createContext } from 'react'

import { SiteContext, baseUrl } from '../../../../App.tsx'
import { AdminContext } from '../../../../pages/AdminContainer'

import Entry from './Entry';

import { ErrorBoundary } from '../../../../other/utils/ErrorBoundary';

export default function DCustomModal({mode}) {

    const { 
      classInformation, studentList, socket, room, focusAgreements, colorMatch,
      studentDataFunctions: {getMongoList, updateMongo, setStudentList, setFocusAgreements}
    } = useContext(SiteContext)

    const {modes} = useContext(AdminContext)

    const customCategory = focusAgreements.customModalType
    const customArray = focusAgreements[customCategory]

    // console.log(focusAgreements,'focusAgreements', customArray)

    const filtered = studentList.filter(entry => entry.period === classInformation.period && entry.attendance !== "Absent")
    const importVariables = {customArray}
    var matchType = (customArray.responseType === 'Poll Response' || customArray.responseType === 'Poll Response and Explain') ? 'customPollResponse' : customArray.responseType === 'Text Response' ? 'customTextResponse' : false
    const totalCount = filtered.filter(studentEntry => studentEntry[matchType] && studentEntry[matchType][customArray.type]).length

    var bulletCount = 0
    var variantCount = 0

    if(customArray.type === 'dailyLinks') return

    const fistToFive = [1,2,3,4,5]

    var bulletArray = customArray.type === "createAgreement" ? fistToFive : customArray.bullets
    // console.log(customArray,'customArray', focusAgreements, mode)

    var index = modes.findIndex(entry => entry.type === 'customModal')
    var index2 = modes[index].options.findIndex(entry => entry.type === modes[index].active)

    const activeOption = modes[index].options[index2]

    var index = modes.findIndex(entry => entry.type === 'customModal')
    var index2 = modes[index].options.findIndex(entry => entry.type === modes[index].active)
    var customOptionTitle =  modes[index].options[index2].text

    function resetResponses(e) {
      e.preventDefault()

      const editStudentList = [...studentList]
      
      studentList.map(entry => {
        if(entry.period !== classInformation.period) return
        // console.log(entry,'entry')
        if(!entry.customPollResponse && !entry.customTextResponse) return
        if(entry.customPollResponse) {entry.customPollResponse[customArray.type] = false}
        if(entry.customTextResponse) {entry.customTextResponse[customArray.type] = false}
        
        const payload = {studentId: entry.studentId, customPollResponse: entry.customPollResponse, customTextResponse: entry.customTextResponse}
        socket.emit("send_updateSingleStudent", { student: payload, room: entry.period });
        socket.emit("send_updateSingleStudent", { student: payload, room: 'admin' });
        updateMongo({...payload}, entry.dailyListId, 'dailyList')
        var index = editStudentList.findIndex(studentEntry => studentEntry._id === entry._id)
        editStudentList[index] = {...entry}
      })

      setStudentList(editStudentList)
    }

    function handlePause(e) {
      e.preventDefault()

      const editFocusAgreements = {...focusAgreements}
      editFocusAgreements.pauseResponses = !editFocusAgreements.pauseResponses
      setFocusAgreements(editFocusAgreements)

      updateMongo({pauseResponses: editFocusAgreements.pauseResponses}, classInformation.focusAgreementId, 'focusAgreements') 

      socket.emit("send_focusAgreementsUpdate", { focusAgreements: {...focusAgreements, pauseResponses: editFocusAgreements.pauseResponses}, room: classInformation.period });
    }

    // console.log(customArray,'customArray', modes[index].options[index2])
    if(customArray.responseType === 'No Response') return

    return (
      <ErrorBoundary>
          <div className={`card-body table-responsive ${focusAgreements.pauseResponses && 'bg-light'}`} style={{}}>
            <div className="fsize-1 fw-bold d-flex justify-content-between">
              <span>{customOptionTitle}</span>
              <div>
                <div className={`fsize-1 me-2 badge border border-2 ${focusAgreements.pauseResponses ? 'border-white bg-flag' : 'border-light bg-light'}`} role="button" onClick={e => handlePause(e)}>{focusAgreements.pauseResponses ? 'Unpause' : 'Pause'}</div>
                <div className="fsize-1 me-2 badge bg-secondary border border-2 border-secondary" role="button" onClick={e => resetResponses(e)}>Reset</div>
                <div className="fsize-1 badge bg-primary border border-2 border-primary">{totalCount}/{filtered.length}</div>
              </div>
              
            </div>
            <div className={`fsize-2 d-flex align-items-center mb-2 flex-wrap`}>
              <div>
              {customArray.responseType === 'Poll Response' && bulletArray.map(bulletEntry => {
                bulletCount++
                variantCount++
                if(variantCount > 6) {variantCount = 0}
                const variants = ['','danger','flag','flag','flag','success']
                var count = filtered.filter(studentEntry => studentEntry.customPollResponse[customArray.type] && studentEntry.customPollResponse[customArray.type] === bulletCount).length
                var variant = customArray.type === "createAgreement" ? variants[variantCount] : colorMatch[variantCount + 1].variant

                return (
                <div key={bulletCount} className="flex-wrap me-4">
                  <div className={`badge bg-${variant} m-1`}>{bulletCount}</div>
                  <div className="badge bg-secondary m-1">
                    <span className="me-4">{Math.round(count/filtered.length*100)}%</span>
                    <span>{count}/{filtered.length}</span>
                  </div>
                </div>
                )
              })}
              </div>
            </div>
            <table className="align-middle text-truncate mb-0 table table-borderless table-hover" style={{width:"100%"}}>
              <thead>
                <tr>
                  {/* <th className=""></th> */}
                  <th className="">Name</th> 
                  {(customArray.responseType === 'Poll Response' || customArray.responseType === 'Poll Response and Explain') &&
                  <th className="">Vote</th>}
                  {(customArray.responseType === 'Text Response' || customArray.responseType === 'Poll Response and Explain') && 
                  <th className="">Written Response</th>}
                </tr>
              </thead>
              <tbody>
                  {filtered.map(entry => {
                      if(entry.period !== classInformation.period || entry.attendance === 'Absent') return
                      return <Entry key={entry.studentId} entry={entry} importVariables={importVariables} />
                  })}
              </tbody>
            </table>
          </div>
      </ErrorBoundary>
    )
}


