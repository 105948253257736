import React, {useState, useContext} from 'react'

import { SiteContext } from '../../../App.tsx';
import { ErrorBoundary } from '../../../other/utils/ErrorBoundary'
import '../inventory.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight, faCircleExclamation, faMagnifyingGlass, faLocationDot, faShare, faRightLeft, faCheck, faX, faWarning, faTag, faCircleArrowRight, faSquareUpRight, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid'

export default function ItemCard({entry, inventoryFunctions}) {

    const { 
      setInventoryItems, classInformation, student, socket, room,
      studentDataFunctions: { updateMongo, moduleFind, handleUpdateCheckout}
    } = useContext(SiteContext)
    
    const {moduleItems, setModuleItems} = inventoryFunctions

    // console.log(student,'student')

    const [expanded, setExpanded] = useState(false)
    const [checkedOut, setCheckedOut] = useState(entry.checkedOut)

    function handleLocationClick(e) {
        e.preventDefault()
        setExpanded(!expanded)
    }

    function localHandleUpdateCheckout(e, entry, student) {
      e.preventDefault()

      handleUpdateCheckout(entry, student)
      setCheckedOut(!checkedOut)
    }

    function CheckoutButton({}) {
      var borderVal = checkedOut ? 'outBorder' : 'availableBorder'
      return (
        <div className={`badge rounded-pill cardButton bg-${bgVal} ${borderVal} ms-3`} value={entry.checkedOut} role="button" onClick={e => localHandleUpdateCheckout(e, entry, student)}>
          <FontAwesomeIcon value={entry.checkedOut} style={{ marginRight: 5}} icon={faRightLeft} />
          {checkedOut ? 'Turn In' : 'Checkout'}
        </div>
      )
    }

    function WhereButton({}) {
      var whereBg = expanded ? 'white' : 'primary'
      var whereText = expanded ? 'primary' : 'white'
      return (
        <div className={`badge rounded-pill cardButton bg-${whereBg} text-${whereText} whereBorder`} role="button" onClick={e => handleLocationClick(e)}>
          <FontAwesomeIcon style={{ marginRight: 5}} icon={faLocationDot} />
          Where Is It?
        </div>
      )
    }

    var teacher = entry.badges.filter(entry => entry.text === 'Teacher' && entry.active )
    var showCheckout = teacher.length > 0 ? true : false

    const storages = ['storage1', 'storage2', 'storage3', 'storage4']
    var count = 0

    var bgVal = checkedOut ? 'flag' : 'success'

    return (
      <ErrorBoundary>
        <div className="d-flex align-items-stretch" style={{}}>
          <div className="main-card mb-3 card me-3" style={{minWidth:"250px", maxWidth:"250px"}}>
            {showCheckout && <span className={`position-absolute top-0 start-100 translate-middle p-2 bg-${bgVal} rounded-circle`} style={{border:"2px solid white"}}>
              <span className="visually-hidden">New alerts</span>
            </span>}
            <div className="card-body" style={{width:"250px"}}>
              <img src={entry.url} 
              style={{objectFit:"contain", height:"100%", maxWidth: "100%", margin:"auto", verticalAlign:"middle", display:"block", maxHeight:"160px"}}
              />
            </div>
          </div>
            <div className="main-card mb-3 card flex-fill">
              <div className="" style={{display:"flex", alignItems:"center", padding:"1rem", paddingBottom:"0px"}}>
                <div className="card-header-title font-size-lg text-capitalize fw-normal">
                    <div className={`fsize-3`}>
                      <div className="fsize-3" style={{fontWeight:"bold"}}>
                          {entry.displayTitle ? entry.displayTitle : entry.item}
                      </div>
                    </div>
                </div>
                <div className="btn-actions-pane-right">
                  {showCheckout && <CheckoutButton />}
                </div>
              </div>
              <div className="card-body">
                  <BadgeEntry text={entry.module} variant={`light`} icon={faTag} action={() => {}} />
                  {entry.badges && entry.badges.map(entry => {
                    if(!entry.active) return
                    return <BadgeEntry key={entry.text} text={entry.text} variant={entry.variant} icon={entry.logo} action={entry.action} />
                  })}
              </div>
              <div className="p-3 card-footer">
                <WhereButton/>
              </div>
              {expanded && <div className="p-3 card-footer">
                {storages.map(storage => {
                    if(!entry.storageArray || !entry.storageArray[storage]) return
                    count++
                    var storageEntry = (storage === 'storage1' && entry.storageArray[storage].split(' ')[0] === 'Drawer') ? `Orange Cabinets - ${entry.storageArray[storage]}` : entry.storageArray[storage]
                    return <LocationBadge key={uuidv4()} storage={storageEntry} storageLev={storage} count={count} />    
                })} 
                {showCheckout && <div className={`badge rounded-pill cardButton bg-success`}>
                  <span className="p-1">Check Out If Needed</span>
                  <FontAwesomeIcon className="me-1" style={{fontSize:"12px"}} icon={faSquareArrowUpRight} />
                </div>}
                {count === 0 && <NoLocationBadge/>}
              </div>}
            </div>
          {/* </div> */}
        </div>
        </ErrorBoundary>
      )
}

function BadgeEntry({variant, icon, text, action}) {
  return (
    <div className={`badge rounded-pill cardButton bg-${variant} text-wrap`} onClick={action}>
      <FontAwesomeIcon style={{ marginRight: 5}} icon={icon} />
      {text}
    </div>
  )
}

function LocationBadge({storage, count, storageLev}) {
  var bgVar = (storageLev === 'storage1' && storage.split(' - ')[0] === 'Orange Cabinets') ? 'flag' : count === 1 ? 'info' : count === 2 ? 'primary' : count === 3 ? 'purple' : 'pink'
  // storage = storage === 'Front of Room' || storage === 'Back Closet' ? `${storage} - Please Check Out If Needed` : storage
  var checkoutFlag = storage === 'Front of Room' || storage === 'Back Closet' ? true : false
  return (
  <>
    {storageLev !== 'storage1' && <LocationArrow key={uuidv4()} />}
    <div className={`badge rounded-pill cardButton bg-${bgVar}`}>
      {storage}
      {/* <span className="p-1">Check Out If Needed</span> */}
    </div>
  </>
  )
}

function LocationArrow({storage, bgVar}) {
  return (
    <FontAwesomeIcon style={{ marginRight: 10, fontSize:"18px", verticalAlign:"center"}} icon={faCircleArrowRight} />
  )
}

function NoLocationBadge({}) {
  return (
  <div className={`badge rounded-pill cardButton bg-secondary`}>
    Location Not Listed
  </div>
  )
}

