import React, {useContext} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { ErrorBoundary } from '../../../other/utils/ErrorBoundary'
import { SiteContext } from '../../../App.tsx';

import '../inventory.css'

export default function Checkouts({filteredItems, moduleField, updateCheckouts, type}) {

  return (
    <ErrorBoundary>
        <div className={`col-12 badgeEvents sticky-top`} >
          <div className="main-card mb-3 card">
              <div className="" style={{alignItems:"center", padding:"1rem", paddingBottom:"0px"}}>
                <div className="">
                  <div className={`badge rounded bg-secondary`} style={{overflowWrap:"break-word", whiteSpace:"normal", padding:"8px", width:"100%"}}>
                    Checkouts
                  </div>
                </div>
              </div>
              <div className="card-body pt-1" style={{paddingTop:"10px"}}>
                  {filteredItems.map(entry => {
                    if(entry.module !== moduleField || !entry.checkedOut) return
                    return <CheckoutEntry key={entry._id} entry={entry} />
                  })}
              </div>
          </div>
        </div>
      </ErrorBoundary>
    )
}

function CheckoutEntry({entry}) {

  const { 
    setInventoryItems, classInformation, student, socket, room,
    studentDataFunctions: { updateMongo, moduleFind, handleUpdateCheckout}
  } = useContext(SiteContext)

  function localHandleUpdateCheckout(e, entry, student) {
    e.preventDefault()

    handleUpdateCheckout(entry, student)
  }

  return (
    <div className="d-flex align-items-stretch">
      <div className={`badge rounded bg-light p-2 mt-2 text-wrap flex-fill d-flex align-items-center justify-content-between`} style={{whiteSpace:"initial"}} key={entry.item}>
        {entry.displayTitle ? entry.displayTitle : entry.item}
      </div>
      <div style={{minWidth:"50px", maxWidth:"50px", fontSize:"0.75em"}} className={`badge mt-2 p-2 bg-flag ms-1 text-wrap d-flex align-items-center justify-content-between`} role="button"
        onClick={e => localHandleUpdateCheckout(e, entry, student)}
      >
          Turn In
      </div>
    </div>
  )
}
