import React, { useState, useContext, useEffect } from 'react';
import { SiteContext, baseUrl } from '../../../../App.tsx';

export function uploadImageToS3 (fileName, base64) {

    // var stringLength = base64.length - 'data:image/png;base64,'.length;

    // var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
    // var sizeInKb=sizeInBytes/1000;

    // console.log(sizeInKb,'sizeInKb')

    const requestObject = {
        method:'POST' ,
        body: JSON.stringify({ 
            imageBinary: base64, fileName
        }),
        headers: {
            "Content-Type": "application/json",
        },
    }

    fetch(`${baseUrl}/awsPut`, requestObject)

}