import React, { useContext, useState } from 'react';
import Scroll from './Scroll';
import SearchList from './SearchList';
import { SiteContext } from '../App.tsx';

function Search({ students }) {

  const {classInformationArray} = useContext(SiteContext)
  const [searchField, setSearchField] = useState("");
  const [periodField, setPeriodField] = useState("");

  const filteredPersons = students.filter(
    person => {
      if(periodField.length !== 0 && person.period !== periodField) {return}
      return (
        person
        .name
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
        person
        .email
        .toLowerCase()
        .includes(searchField.toLowerCase())
      );
    }
  );

  const handleChange = e => {
    setSearchField(e.target.value);
  };

  const handleChangePeriod = e => {
    if(e.target.value === '-- Select Period --') {
      setPeriodField("");
      return
    }
    setPeriodField(e.target.value);
  }

  function searchList() {
    return (
      <Scroll>
        <SearchList filteredPersons={filteredPersons} />
      </Scroll>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div className="pa2 d-inline me-2">
            <input 
              className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
              type = "search" 
              placeholder = "Search People" 
              onChange = {handleChange}
            />
          </div>
          <div className="pa2 d-inline">
            <select className="d-inline"
              onChange = {handleChangePeriod}
            >
              <option>-- Select Period --</option>
              {classInformationArray.map(entry => {
                return <option key={entry.period + entry._id}>{entry.period}</option>
              })}
              <option>Dropped</option>
            </select>
          </div>
        </div>
        <div className="badge bg-light">Results: {filteredPersons.length}</div>
      </div>
      {searchList()}
    </>
  );
}

export default Search;