import { useState, useEffect } from "react";
import { School, SchoolCycle, SchoolStaff, SchoolCycleLookForScore } from "../../../types/school";
import axios from "axios";
import { Form, Row, Col, Button } from "react-bootstrap";
import { baseUrl } from "../../../App.tsx";
import { fetchSchools, fetchLookFors } from "../../../types/api.tsx";
import { LookFor, LookForRubric } from "../../../types/lookFors.ts"; // ✅ Import the TypeScript type
import ScoreEntry from "./ScoreEntry.tsx";

const ScoresPage: React.FC = () => {

  const [districts, setDistricts] = useState<string[]>([]);
  const [schools, setSchools] = useState<School[]>([]);
  const [teachers, setTeachers] = useState<SchoolStaff[]>([]);
  
  const [selectedDistrict, setSelectedDistrict] = useState<string>("");
  const [selectedSchool, setSelectedSchool] = useState<string>("");
  const [selectedTeachers, setSelectedTeachers] = useState<string[]>([]);

  const [lookFors, setLookFors] = useState([]);
  const [globalLookFors, setGlobalLookFors] = useState<LookFor[]>([]);

  const [selectedCycle, setSelectedCycle] = useState<string | null>(null);
  const [cycles, setCycles] = useState<number[]>([]);

  const [rubricEntries, setRubricEntries] = useState<any[]>([]);

  // Fetch Schools and Districts
  useEffect(() => {
    const fetchDistrictsAndSchools = async () => {
      try {
        const fetchedSchools = await fetchSchools();
        setSchools(fetchedSchools);

        // Extract unique district names from schools
        const uniqueDistricts = [...new Set(fetchedSchools.map((school) => school.metadata.districtName))];
        setDistricts(uniqueDistricts);

        const availableCycles = new Set<number>();
        fetchedSchools.forEach((school) => {
          school.cycles.forEach((cycle) => {
            availableCycles.add(cycle.cycleNum);
          });
        });
        setCycles(Array.from(availableCycles).sort((a, b) => a - b)); // Sort cycles numerically

      } catch (error) {
        console.error("Error fetching districts and schools:", error);
      }
    };

    fetchDistrictsAndSchools();
  }, []);

  useEffect(() => {
    const getLookFors = async () => {
        try {
            const allLookFors = await fetchLookFors();
            setGlobalLookFors(allLookFors);
        } catch (error) {
            console.error("Error fetching LookFors:", error);
        }
    };

    getLookFors();
  }, []);

  useEffect(() => {
    const getFilteredLookFors = async () => {
        if (selectedDistrict && selectedSchool && selectedCycle) {
            const filteredLookFors = await fetchFilteredLookFors(selectedDistrict, selectedSchool, selectedCycle, schools);
            console.log(filteredLookFors,'filteredLookFors')
            setLookFors(filteredLookFors);
        }
    };

    getFilteredLookFors();
}, [selectedDistrict, selectedSchool, selectedCycle]);

  // Fetch Teachers when School is Selected
  useEffect(() => {
    if (!selectedSchool) {
      setTeachers([]);
      return;
    }

    const school = schools.find((s) => s.schoolNumber === selectedSchool);
    if (school) {
      setTeachers(school.staff);
    }
  }, [selectedSchool, schools]);


//   useEffect(() => {
//     const fetchCycles = async () => {
//       try {
//         const schoolsData = await fetchSchools(schools);
//         const availableCycles = new Set<number>();
//         schoolsData.forEach((school) => {
//           school.cycles.forEach((cycle) => {
//             availableCycles.add(cycle.cycleNum);
//           });
//         });
//         setCycles(Array.from(availableCycles).sort((a, b) => a - b)); // Sort cycles numerically
//       } catch (error) {
//         console.error("Error fetching cycles:", error);
//       }
//     };
  
//     fetchCycles();
//   }, []);

 const fetchFilteredLookFors = async (selectedDistrict: string, selectedSchool: string, selectedCycle: number, schools) => {
    try {
        // const schools = await fetchSchools(); // Fetch all schools

        // Find the selected school based on district and school number
        const school = schools.find(
            (entry) =>
                entry.metadata.districtName === selectedDistrict &&
                entry.schoolNumber === selectedSchool
        );

        if (!school) {
            console.warn("No matching school found for selection.");
            return [];
        }

        console.log('here----------', selectedCycle, school.cycles, )
        // Find the selected cycle in that school
        const cycle = school.cycles.find((c) => c.cycleNum == selectedCycle);
        
        if (!cycle) {
            console.warn("No matching cycle found in school.");
            return [];
        }

        console.log(cycle,'cycle-------')

        // Return only the lookFors for the selected cycle
        return cycle.lookFors || [];
    } catch (error) {
        console.error("Error fetching filtered LookFors:", error);
        return [];
    }
};


  return (
    <div className="container mt-4">
      <Row className="mb-4 text-center">
        <Col md={3}>
          <h5 className="fw-bold">📚 District</h5>
          <Form.Select 
            value={selectedDistrict} 
            onChange={(e) => {
              setSelectedDistrict(e.target.value);
              setSelectedSchool("");
              setSelectedTeachers([]);
            }}>
            <option value="">Select District</option>
            {districts.map((district) => (
              <option key={district} value={district}>{district}</option>
            ))}
          </Form.Select>
        </Col>
        <Col md={3}>
          <h5 className="fw-bold">🏫 School</h5>
          <Form.Select 
            value={selectedSchool} 
            onChange={(e) => {
              setSelectedSchool(e.target.value);
              setSelectedTeachers([]);
            }} 
            disabled={!selectedDistrict}>
            <option value="">Select School</option>
            {schools
              .filter((s) => s.metadata.districtName === selectedDistrict)
              .map((school) => (
                <option key={school.schoolNumber} value={school.schoolNumber}>
                  {school.metadata.schoolNickname}
                </option>
              ))}
          </Form.Select>
        </Col>
        <Col md={3}>
            <h5 className="fw-bold">🔄 Cycle</h5>
            <Form.Select 
            value={selectedCycle || ""} 
            onChange={(e) => setSelectedCycle(e.target.value)} 
            disabled={!selectedSchool}
            >
            <option value="">Select Cycle</option>
            {cycles.map((cycleNum) => (
                <option key={cycleNum} value={cycleNum}>
                Cycle {cycleNum}
                </option>
            ))}
            </Form.Select>
        </Col>
        <Col md={3}>
          <h5 className="fw-bold">🍎 Teacher</h5>
          <Form.Select 
            multiple 
            value={selectedTeachers} 
            onChange={(e) =>
              setSelectedTeachers(Array.from(e.target.selectedOptions, (option) => option.value))
            } 
            disabled={!selectedCycle}>
            <option value="">Select Teacher</option> 
            {teachers.map((teacher) => (
              <option key={teacher.staffID} value={teacher.staffID}>
                {teacher.name}
              </option>
            ))}
          </Form.Select>
        </Col>

      </Row>

      <div>

      <>
  {selectedCycle && lookFors.length > 0 ? (
    lookFors
      .filter((lookFor) => lookFor.teacherIDs?.some((teacherID) => selectedTeachers.includes(teacherID)))
      .map((lookFor) => {
        // Find the matching LookFor details from globalLookFors
        const matchedLookFor = globalLookFors.find((globalLookFor) => globalLookFor._id === lookFor.lookForID);

        return (
          <ScoreEntry
            key={lookFor.lookForID}
            lookForID={lookFor.lookForID}
            lookForIndex={lookFor.lookForIndex}
            teacherIDs={lookFor.teacherIDs || []}
            chosenBy={lookFor.chosenBy || []}
            scores={lookFor.scores || []}
            active={lookFor.active ?? true}
            topic={matchedLookFor?.topic || "Unknown Topic"}
            description={matchedLookFor?.description || "No Description Available"}
            studentFacing={matchedLookFor?.studentFacing}
            rubric={matchedLookFor?.rubric || []}
            staff={schools.find((s) => s.schoolNumber === selectedSchool)?.staff || []}
          />
        );
      })
  ) : (
    <p className="text-center">No results found. Please select district, school, cycle, and teacher(s).</p>
  )}
</>

      </div>
    </div>
  );
};

export default ScoresPage;
