import React, {useContext, useState, useEffect} from 'react'
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { SiteContext } from '../../App.tsx'
import { useColorMatch } from '../../other/utils/colorMatch'

import calculateTimeFunction from '../../other/utils/calculateTime.js'

export function useBehavior({student, getMongoAllById, getMongoAllByParam, getMongoById, handleDateString, getClassTimes,
    calculateTimeElapsed, classInformation, getMongoList, studentList, checkCalendar, loginState, classInformationArray, calendarDays}) {

  let location = useLocation();

  const [reviewEntriesLocal, setReviewEntries] = useState([])
  const [reviewStudent, setReviewStudent] = useState(false)
  const [randomStudentIndex, setRandomStudentIndex] = useState(parseInt(Math.random()*studentList.length))

  useEffect(() => {

    function generateIndex() {

        // console.log(studentList[randomStudentIndex],'studentList[randomStudentIndex]')
        if(loginState.admin && (randomStudentIndex === 0 || studentList && studentList[randomStudentIndex] && studentList[randomStudentIndex].period !== classInformation.period)) {
            setRandomStudentIndex( 
                parseInt(Math.random()*studentList.length) 
            )
        }
    }

    generateIndex()

  }, [studentList.length, randomStudentIndex])

//   console.log(randomStudentIndex,'randomStudentIndex')

  const colorMatch = useColorMatch()

  function createCurrentEntry() {

    if(!student || ! student.color) return
    
    if(student && student.behaviorEvents && student.behaviorEvents[0]) {
        var firstEventStamp = student.behaviorEvents[0].timeStamp
    } else if(student && student.attendance === 'Absent') {
        var firstEventStamp = student.attendanceTimeStamp
    }

    
    var chosenTimeStamp = firstEventStamp ? firstEventStamp : student.loginTimeStamp > 0 ? student.loginTimeStamp : Date.now()
    // var chosenTimeStamp = firstEventStamp ? firstEventStamp : student.loginTimeStamp > 0 ? student.loginTimeStamp : entry.attendanceTimeStamp ? entry.attendanceTimeStamp :entry.submitTimeStamp

    const match = colorMatch.filter(item => item.color === student.color.current)
        const variant = match[0].variant

    return {...createReviewEntry(student, true), timeStamp: chosenTimeStamp, attendance: student.attendance, 
        dateString: handleDateString(chosenTimeStamp), color: student.color.current, name: student.name, studentId: student.studentId, period: student.period,
        loginTimeStamp: handleDateString(chosenTimeStamp), submitTimeStamp: student.submitTimeStamp, variant
    }
  }

  const currentEntry = createCurrentEntry()

  useEffect(() => {

    if(!student || !student.studentId || student.studentId.length === 0) return

    async function getEntries() {

        if(location.pathname !== '/colorReview' && location.pathname !== '/colorReviewAdmin') return
        // console.log(loginState,'loginState')
        var selectedStudent = (location.pathname === '/colorReview' && loginState && loginState.admin) ? studentList[randomStudentIndex] :
        loginState && loginState.admin ? reviewStudent : student
        const editReviewEntries = loginState && loginState.admin ? [] : [currentEntry]
        
        // console.log(reviewStudent,'reviewStudent')
        if(!selectedStudent || !selectedStudent.studentId) {
            // console.log(selectedStudent,'selectedStudent')
        }
        if(!selectedStudent) return
        const entriesRecord = await getMongoAllById(selectedStudent.studentId, 'dailyRecord')
        if(!entriesRecord) return

        // console.log(entriesRecord,'entriesRecord')

        entriesRecord.map(entry => {
            const createdEntry = createEntry(entry, colorMatch, editReviewEntries)
            // console.log(createdEntry,'createdEntry')
            if(createdEntry) {
                var filter = editReviewEntries.filter(item => item.dateString == createdEntry.dateString).length
                if(createdEntry.dateString === 'Fri 5/3') {
                    console.log(createdEntry,'createdEntry',editReviewEntries, filter)
                }
                if(filter > 0) return
                // console.log(createdEntry,'createdEntry', createdEntry.dateString)
                const date = new Date(createdEntry.timeStamp)
                const dayOfWeek = date.getDay()
                if(dayOfWeek === 0 || dayOfWeek === 6) return

                editReviewEntries.push(createdEntry)
            } 
        })
    
        // console.log(editReviewEntries,'editReviewEntries')
        // const entry = await getMongoById(selectedStudent.studentId, 'dailyList')

        // console.log(editReviewEntries,'editReviewEntries')
        var sortedEntries = editReviewEntries.sort( compare );
        // console.log(sortedEntries,'sortedEntries')
        var today = new Date()
        today.setHours(0)
        today.setMinutes(0)
        today.setSeconds(0)

        // console.log(sortedEntries,'sortedEntries')
        const filtered = sortedEntries.filter(item => item.timeStamp < today.getTime())
        // console.log(filtered,'filtered')
        setReviewEntries(filtered)
        // console.log(filtered,'filtered')

    }

    getEntries()
  
  }, [student.studentId, reviewStudent, location.pathname, calendarDays, studentList.length, loginState.admin && randomStudentIndex])

  const reviewEntries = [currentEntry, ...reviewEntriesLocal]
//   const reviewEntries = reviewEntriesLocal

      function createEntry(entry, colorMatch, editReviewEntries) {
        if(!entry.behaviorEvents) return
    
        if(entry.behaviorEvents[0]) {
          var firstEventStamp = entry.behaviorEvents[0].timeStamp
        } else if(entry.attendance === 'Absent') {
          firstEventStamp = entry.attendanceTimeStamp
        }
    
        function getProperTimestamp(entry) {
            var month = entry.loginDateString.split('/')[0]
            var day = entry.loginDateString.split('/')[1]
            var submitDate = new Date(entry.submitTimeStamp)
                var submitYear = submitDate.getFullYear()
            var date = new Date()
            date.setFullYear(submitYear)
            date.setMonth(month-1)
            date.setDate(day)
            // console.log(entry.loginDateString,'entry.loginDateString', day, month-1, date)
            
            return date.getTime()
        }

        var chosenTimeStamp = 
        (entry.loginDateString.length > 0 && entry.submitTimeStamp > 0) ? getProperTimestamp(entry) : 
        entry.loginTimeStamp > 0 ? entry.loginTimeStamp : entry.attendanceTimeStamp ? entry.attendanceTimeStamp : entry.submitTimeStamp

        // console.log(entry,'entry', chosenTimeStamp)
        // var chosenTimeStamp = firstEventStamp ? firstEventStamp : entry.loginTimeStamp > 0 ? entry.loginTimeStamp : entry.attendanceTimeStamp ? entry.attendanceTimeStamp : entry.submitTimeStamp
        // var index = editReviewEntries.findIndex(item => item.timeStamp === chosenTimeStamp)
        
    
        // console.log('here', entry.loginDateString)
        const match = colorMatch.filter(item => item.color === entry.color)
            const variant = match[0].variant
    
        // if(index !== -1) {
            
        // } else {
        //     // editReviewEntries.push(
        //     //     {...createReviewEntry(entry), timeStamp: chosenTimeStamp, 
        //     //         attendance: entry.attendance, dateString: handleDateString(chosenTimeStamp), color: entry.color, variant, name: entry.name, studentId: entry.studentId, period: entry.period,
        //     //         loginTimeStamp: handleDateString(chosenTimeStamp), submitTimeStamp: entry.submitTimeStamp
        //     //     }
        //     // )
        // }

        return {...createReviewEntry(entry), timeStamp: chosenTimeStamp, 
            attendance: entry.attendance, dateString: handleDateString(chosenTimeStamp), color: entry.color, variant, name: entry.name, studentId: entry.studentId, period: entry.period,
            loginTimeStamp: handleDateString(chosenTimeStamp), submitTimeStamp: entry.submitTimeStamp
        }
        // console.log(editReviewEntries[0],'editReviewEntries[0')
      }

      function createReviewEntry(entryItem, today) {

        /// account for class length
        // console.log('running 0', entryItem, studentList)

        function findPeriod() {
            if(studentList.length === 0) return {}
            var index1 = studentList.findIndex(studentEntry => studentEntry.studentId === entryItem.studentId)
            if(index1 === -1) return
            // console.log('running 1', classInformation.period)
            var index2 = classInformationArray.findIndex(classEntry => classEntry.period === classInformation.period)
            if(index2 === -1) return
            // console.log('running 2')

            return classInformationArray[index2]
        }

        var classInformationLocal = loginState.admin && location.pathname !== '/colorReview' ? findPeriod() : classInformation

        const behaviorEvents = entryItem.behaviorEvents
        const colorChanges = []
        var dayType = (entryItem.loginDateString && entryItem.loginDateString.length > 0 && entryItem.submitTimeStamp > 0) ? checkCalendar(getProperTimestamp(entryItem)) : (today && entryItem.loginTimeStamp) ? checkCalendar(entryItem.loginTimeStamp) : ''
        // var dayType = entryItem.loginDateString ? checkCalendar(getProperTimestamp(entryItem)) : (today && entryItem.loginTimeStamp) ? checkCalendar(getProperTimestamp(entryItem.loginTimeStamp)) : ''
        var classLengthVar = 0

        // console.log(classInformation,'classInformation', dayType)

        if(dayType && dayType.length > 0) {
            const classTimeOutput = getClassTimes(dayType, classInformationLocal.period)
            // if(!classTimeOutput)
            const {classLength, classStart} = classTimeOutput
            // console.log(classLength,'classLength')
            classLengthVar = classLength
        }

        // console.log(today, dayType,'dayType')
        function getProperTimestamp(entry) {
            // console.log(entry,'entry')
            var month = entry.loginDateString.split('/')[0]
            var day = entry.loginDateString.split('/')[1]
            var submitDate = new Date(entry.submitTimeStamp)
                var submitYear = submitDate.getFullYear()
            var date = new Date()
            date.setDate(day)
            date.setMonth(month-1)
            date.setFullYear(submitYear)
            
            return date.getTime()
        }

        if(!behaviorEvents) return
        behaviorEvents.map(entry => {
            if(entry.result !== 'approved') return

            dayType = checkCalendar(entry.timeStamp, true)
            const classTimeOutput = getClassTimes(dayType, classInformationLocal.period)
            const {classLength, classStart} = classTimeOutput
            // console.log(classLength,'classLength')
            classLengthVar = classLength
            // console.log(dayType,'dayType')
            // var {classStart} = calculateTimeFunction(dayType, classInformation.classStartNormal, classInformation.classStartMinimum)
            const convertedTS = changeDayMonth(entry.timeStamp)
    
            colorChanges.push({
                color: entry.pendingColor,
                oldColor: entry.currentColor,
                timeElapsed: calculateTimeElapsed(classStart ? classStart : classInformationLocal.classStart, convertedTS, 'review'),
                category: entry.category,
                reason: entry.reason,
                type: entry.type === 'self' ? 'Self' : entry.nominateType === 'shoutOut' ? 'Shout Out' : 'Call Out',
                levelChange: entry.levelChange,
                added: false,
            })
        })
    
        // console.log('here', entryItem.loginDateString)

        const added = [null]
        const subtracted = [null]
        const colorLevelArr = [5, 5]
    
        var colorLevel = 5

        var entriesLength = classLengthVar + 1
        // console.log(dayType,'dayType', entryItem, entriesLength)
        // var entriesLength = dayType === 'Normal' ? 51 : 41
        
        for(let i = 2; i < entriesLength; i++) {
            added.push(null)
            subtracted.push(null)
    
            // console.log(classInformationLocal,'classInformationLocal')
            if(classLengthVar < i) {
                colorLevelArr.push(null)
            } else {
                colorLevelArr.push(colorLevel)
            }
            
            var index = colorChanges.findIndex(entry => entry.timeElapsed <= i && !entry.added)
            if(index !== -1) {
                const colorMatched = colorMatch.filter(item => item.color === colorChanges[index].color)
                    const level = colorMatched[0].level
                
                colorChanges[index].levelChange = level - colorLevel
                var type = level - colorLevel > 0 ? added : subtracted
                type[i] = level - colorLevel
                const colorMatchedOld = colorMatch.filter(item => item.level === colorLevel)
                    const colorOld = colorMatchedOld[0].color
                colorChanges[index].oldColor = colorOld
                colorChanges[index].levelChange = level - colorLevel
                colorLevelArr[i] = level
                colorLevel = level
                colorChanges[index].added = true
            }
        }
    
        const levelByColor = {}

        // console.log(colorLevelArr,'colorLevelArr')
    
        for(let j = 1; j < 10; j++) {
            levelByColor['level' + j] = []
    
            for(let i = 0; i < entriesLength; i++) {
                colorLevelArr[i] === j ? levelByColor['level' + j].push(0.1) : levelByColor['level' + j].push(null)
            }
        }

            return {
                added, subtracted, levelByColor, colorChanges, dayType
            }
      }
    
      function levelChangeCalc(currentColor, pendingColor) {
          if(currentColor.length < 1 || pendingColor.length < 1) {return}
    
          var currentIndex = colorMatch.findIndex(entry => entry.color === currentColor)
          var currentLevel = colorMatch[currentIndex].level
    
          var pendingIndex = colorMatch.findIndex(entry => entry.color === pendingColor)
          var pendingLevel = colorMatch[pendingIndex].level
    
          return pendingLevel - currentLevel
      }

      function updateRandomIndex(e) {
        e.preventDefault()
        setRandomStudentIndex(
          parseInt(Math.random()*studentList.length)
        )
      }

    return {
        reviewEntries, levelChangeCalc, reviewStudent, setReviewStudent, createReviewEntry, currentEntry, setRandomStudentIndex, randomStudentIndex, updateRandomIndex
    }
}

function compare( a, b ) {
    if ( a.timeStamp > b.timeStamp){
      return -1;
    }
    if ( a.timeStamp < b.timeStamp ){
      return 1;
    }
    return 0;
}

function changeDayMonth(timeStamp) {

    const date = new Date()
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()

    const dateImport = new Date(timeStamp)
        dateImport.setDate(day)
        dateImport.setMonth(month)
        dateImport.setFullYear(year)
    
    return dateImport.getTime()
}