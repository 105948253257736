import React, { useContext, useState, useEffect } from 'react'

import { SiteContext } from '../../App.tsx';
import { StudentContext } from '../../pages/StudentContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToDot, faBook } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';

import { ErrorBoundary } from '../../other/utils/ErrorBoundary';

export default function ReadingZone({sizeClass, dataOnly}) {

  const {
      classInformation, focusAgreements, student, colorMatch, studentList,
      studentDataFunctions: {
        createMongo, getMongoList, getMongoById, updateMongo, deleteMongo,
        updateFocus, checkFocus
      }
  } = useContext(SiteContext)

  const {handleShow} = useContext(StudentContext)

  student.readingZoneMin = parseInt(student.readingZoneMin)

  var newDate = student.readingZoneDate ? new Date(student.readingZoneDate).getTime() : 0
  var weeksSince = student.readingZoneMin && student.readingZoneDate ? Math.floor(datediff(newDate, Date.now())/7) : false

  function datediff(first, second) {        
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  if(!focusAgreements) return

  if(dataOnly) return (<>
  <div className="card-header">
    <div>
    <FontAwesomeIcon className="me-2" icon={faBook} />
    Reading Zone Target
    </div>
  </div>
  <div className="card-body">
    {student.readingZoneMin ? <Goal/> : <NoGoal/>}
  </div>
  </>)


  return (
      <ErrorBoundary>
      <div className={sizeClass}>
        <div className={`main-card ${dataOnly ? '' : 'mb-3'} card`}>
          <div className="card-header">
            <div>
            <FontAwesomeIcon className="me-2" icon={faBook} />
             Reading Zone Target
            </div>
          </div>
          <div className="card-body">
             {student.readingZoneMin ? <Goal/> : <NoGoal/>}
          </div>
        </div>
      </div>
      </ErrorBoundary>
    )
}

function Goal({}) {

    const {student, studentDataFunctions: {updateMongo, setStudent}} = useContext(SiteContext)

    var newDate = student.readingZoneDate ? new Date(student.readingZoneDate).getTime() : 0
    var weeksSince = student.readingZoneMin && student.readingZoneDate ? Math.floor(datediff(newDate, Date.now())/7) : false

    function datediff(first, second) {        
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }


    return (
    <>
    <div className="d-flex align-items-center">
        <div className="">
            <div className="d-flex align-items-center">
                <span className="m-1 ms-0 text-nowrap d-flex align-items-center">
                    <div className="badge bg-light d-inline-block p-2 me-1 d-inline">{student.readingZoneMin}</div>
                    {`Minute${student.readingZoneMin > 1 || student.readingZoneMin === 0 ? `s` : ``} Originally`}
                </span>
                <span className="m-1">+</span>
                <span className="m-1 text-nowrap d-flex align-items-center">
                    <div className="badge bg-light d-inline-block p-2 me-1">{weeksSince ? weeksSince : 0}</div>
                    {`Week${weeksSince > 1 || weeksSince === 0 || !weeksSince ? `s` : ``} Since Starting`}
                </span>
                <span className="m-1">=</span>                    
            </div> 
        </div>
        <div className="badge bg-primary text-white ms-1">
            <p className="m-1" 
            // style={{fontSize:"14px"}}
            >
                {student.readingZoneMin + weeksSince > 20 ? 20 : student.readingZoneMin + weeksSince} min
            </p>
        </div>
    </div>
    <div className="mt-4 font-italic" style={{fontStyle:"italic"}}>Remember: Every week, we're just adding one minute.</div>
    </>
    )
}

function NoGoal() {

    const {student, socket, studentDataFunctions: {updateMongo, setStudent}} = useContext(SiteContext)

    const [minutes, setMinutes] = useState(10)

    function updateMinutes(e) {
        e.preventDefault()
        setMinutes(e.target.value)
    }

    function submitMinutes() {
        updateMongo({readingZoneMin: parseInt(minutes), readingZoneDate: Date.now()}, student.dailyListId, 'dailyList')
        setStudent(prev => {return {...prev, readingZoneMin: parseInt(minutes), readingZoneDate: Date.now()}})
        socket.emit("send_updateSingleStudent", { student: {...student, readingZoneMin: minutes, readingZoneDate: Date.now()}, room: 'admin' });
    }

    return (
        <>
            <span>No Goal Yet! Let's make one now.</span>
            <span className="d-block mt-2 mb-3">Enter how many minutes you *actually* read during the normal Reading Zone. Every week, we're just going to add 1 more minute to that number and slowly build the habit.</span>
            <div className="mt-2">
            <input type="number" className={`btn bg-light me-2`} min={1}  max={20} value={minutes} onChange={e => updateMinutes(e)} />
            <button className="btn btn-success" onClick={e => submitMinutes(e)}>Submit</button>
            </div>
            
        </>
    )
}