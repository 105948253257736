import React, { useContext, useState, useEffect, createContext } from 'react'
import EventEntries from './EventEntries'
import { SiteContext, baseUrl } from '../../App.tsx'

export default function EventCard( { title, subtitle, colorValue, sizeClass, colorChanges, variantsExport }) {

    const { 
      classInformation, 
      studentDataFunctions: {
      }
    } = useContext(SiteContext)

    var count = 0
    return (
      <div className={sizeClass}>
      <div className={`card-shadow-secondary mb-3 widget-chart widget-chart2 text-start card`}>
        <div className="widget-content p-0 w-100">
          
          <div className="widget-content-outer">
            <div className="widget-content-wrapper">
              <div className="widget-content-left pe-2 fsize-1">
                <div className="widget-numbers mt-0 fsize-3 text-info">
                  Agreements &amp; Color Changes
                </div>      
              </div>
            </div>
            
            <div className="widget-content-left fsize-1">
            {colorChanges.length === 0 && <span>No Color Changes</span>}
            {colorChanges.length > 0 && <div className="table-responsive">
              <table className="align-middle text-truncate mb-0 table table-borderless table-hover text-secondary">
                <thead>
                  <tr>
                    <th className="text-center">Time</th>
                    <th className="text-center">Colors</th>
                    <th className="text-center">Type</th>
                    <th className="">Agreement Category</th>
                    <th className="">Agreement</th>
                  </tr>
                </thead>
                <tbody>
                    
                    {colorChanges.map(entry => {
                        count++
                        // console.log(entry,'entry')
                        return (
                            <EventEntries entry={entry} key={count} variantsExport={variantsExport} />
                        )
                    })}
                </tbody>
              </table>
            </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
        // <div className={`${sizeClass}`}>
        //   <div className="main-card mb-3 card">
        //     <div className="card-header">
        //       <div className="card-header-title font-size-lg text-capitalize fw-normal">
        //         {/* Community Agreements &amp;  */}
        //         Color Changes
        //       </div>
        //     </div>
        //     <div className="table-responsive">
        //       <table className="align-middle text-truncate mb-0 table table-borderless table-hover">
        //         <thead>
        //           <tr>
        //             <th className="text-center">Time</th>
        //             <th className="text-center">Colors</th>
        //             {/* <th className="text-center">Change</th> */}
        //             <th className="text-center">Type</th>
        //             <th className="text-center">Details</th>
        //           </tr>
        //         </thead>
        //         <tbody>
        //             {colorChanges.map(entry => {
        //                 count++
        //                 return (
        //                     <EventEntries entry={entry} key={entry.timeElapsed + count}/>
        //                 )
        //             })}
        //         </tbody>
        //       </table>
        //     </div>
        //     <div className="d-block p-4 text-center card-footer">
        //       {/* <button className="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg">
        //         <span className="me-1">View More</span>
        //       </button> */}
        //     </div>
        //   </div>
        // </div>
    )
}


function compare( a, b ) {
  if ( a.timeStamp > b.timeStamp){
    return -1;
  }
  if ( a.timeStamp < b.timeStamp ){
    return 1;
  }
  return 0;
}