import React, {useContext} from 'react'
import { SiteContext } from '../../../App.tsx'
import { AdminContext } from '../../../pages/AdminContainer';

import ShowMore from '../../../other/utils/ShowMore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

import './goal.css'

export default function Entry({entry, colorCurrent, expand}) {

  const {
    colorMatch, focusAgreements, goalQuestions, studentList, room, socket,
    studentDataFunctions: {updateMongo, setStudentList}
  } = useContext(SiteContext)

  const {handleOverride, handleResetModal} = useContext(AdminContext)

  const initial = colorMatch.filter(entry => entry.color === colorCurrent)[0]

  function ColorMatchIcon({level}) {
    if(!level) return
    var match = colorMatch.filter(entry => entry.level === level)[0]
    return (
      <FontAwesomeIcon style={{color: match.hex, marginLeft:"3px", marginRight:"3px"}} icon={faSquare} className="fa-lg" />
      // <span>{match.string}</span>
    )
  }

  // console.log(entry,'entry-----')

  function handleReset(e, mode) {
    e.preventDefault()
    handleResetModal('goalSetting', entry, mode)
  }

  var stepString = entry.goalSetting && entry.goalSetting.display <= goalQuestions.length ? `Step ${entry.goalSetting.display}` : `Done`
  var stepBg = !focusAgreements.goalSetting ? 'secondary' : entry.goalSetting.display === 1 ? 'flag' : entry.goalSetting.display <= goalQuestions.length ? 'info' : 'success'
    return (
      <tr className={`${""}`}>
        <td className="" style={{verticalAlign:"top"}}>
          <div className={`mb-2 me-2 badge bg-${stepBg}`}>{stepString}</div>
        </td>
        <td className={``} style={{verticalAlign:"top"}}>
            <div className="box word-wrap">
               <span className="text">{entry.name}</span>
            </div>
        </td>
        <td className="" style={{verticalAlign:"top"}}>
            <div className="box text-break">
               <span className="text text-wrap">
                 {/* {entry.goalSetting.goal.goal} */}
                 <ShowMore textString={entry.goalSetting ? entry.goalSetting.goal.goal : ''} />
                </span>
            </div>
        </td>
        {expand && <td className="" style={{verticalAlign:"top"}}>
            <div className="box text-wrap">
               <span className="text text-break">
                 {/* {entry.goalSetting.goal.thing1} */}
                 <ShowMore textString={entry.goalSetting ? entry.goalSetting.goal.thing1 : ''} />
               </span>
            </div>
        </td>}
        {expand && <td className="" style={{verticalAlign:"top"}}>
            <div className="box text-wrap">
               <span className="text text-break">
                 {/* {entry.goalSetting.goal.thing2} */}
                 <ShowMore textString={entry.goalSetting ? entry.goalSetting.goal.thing2 : ''} />
               </span>
            </div>
        </td>}
        {expand && <td className="" style={{verticalAlign:"top"}}>
            <div className="box text-break">
               <span className="text text-wrap">
                 {/* {entry.goalSetting.goal.thing3} */}
                 <ShowMore textString={entry.goalSetting ? entry.goalSetting.goal.thing3 : ''} />
               </span>
            </div>
        </td>}
        {expand && <td className="" style={{verticalAlign:"top"}}>
            <div className="box text-break">
                <span className="text text-wrap">
                  {/* {entry.goalSetting.goal.matters} */}
                  <ShowMore textString={entry.goalSetting ? entry.goalSetting.goal.matters : ''} />
                </span>
            </div>
        </td>}
        {expand && <td className="" style={{verticalAlign:"top"}}>
            <div className="box text-break">
                <span className="text text-wrap">
                  {/* {entry.goalSetting.goal.accomplish} */}
                  <ShowMore textString={entry.goalSetting ? entry.goalSetting.goal.accomplish : ''} />
                </span>
            </div>
        </td>}
        {expand && <td className="" style={{verticalAlign:"top"}}>
          <div role="button" onClick={e => handleOverride(e, entry, !entry.modalOverride)} className={`mb-2 me-2 badge bg-${!focusAgreements.goalSetting ? 'secondary' : entry.modalOverride ? 'danger' : 'success'}`}>{(entry.modalOverride || !focusAgreements.goalSetting) ? 'Hidden' : 'Showing'}</div>
        </td>}
        {expand && <td className="" style={{verticalAlign:"top"}}>
          <div role="button" onClick={e => handleReset(e, 'reset')} className={`mb-2 me-2 badge bg-secondary`}>Reset</div>
        </td>}
        {expand && <td className="" style={{verticalAlign:"top"}}>
          <div role="button" onClick={e => handleReset(e, 'reopen')} className={`mb-2 me-2 badge bg-secondary`}>Reopen</div>
        </td>}
        {/* {expand && <td className="" style={{verticalAlign:"top"}}>
          <div onClick={e => handleReset(e)} className={`mb-2 me-2 badge bg-secondary`}</div>
        </td>} */}
      </tr>
    )
}

 
