import React, { useContext } from 'react'
import { SiteContext } from '../../../App.tsx'

export default function Form({hpForm, updateForm}) {

    const {loginState} = useContext(SiteContext)

    return (
    <form>
        <div className="form-group">
            <label htmlFor="position" style={{marginBottom:"5px"}}>Hall Pass Purpose:</label>
            <select
            className="form-control pa3 bb br3 grow b--none bg-lightest-blue ma3 d-block"
            value={hpForm.purposeOption} role="button"
            onChange={(e) => {
                if(e.target.value === '-- Select Class Role --') {return}
                updateForm(e, { purposeOption: e.target.value })
            }}
            >
            <option>-- Select Hall Pass Purpose --</option>
            <option>Bathroom</option>
            <option>Water</option>
            <option>Locker</option>
            <option>Front Office</option>
            <option>Library</option>
            <option>Counselor</option>
            <option>Talk to Adult</option>
            <option>Another Classroom</option>
            <option>Courtyard Behind Class</option>
            <option>Other</option>
            </select>

            {hpForm.purposeOption === 'Other' && <div className="form-group edit-row" style={{marginTop:"10px"}}>
                <label htmlFor="name">Other:</label>
                <input
                type="text"
                className="form-control"
                // id="name"
                value={hpForm.purpose}
                onChange={(e) => updateForm(e, { purpose: e.target.value })}
                />
            </div>} 
        </div><br/>
        <label htmlFor="position" style={{marginBottom:"5px"}}>How much time do you need?</label><br/>

        <TimeOptions hpForm={hpForm} updateForm={updateForm} />
        {hpForm.moreTime === true && <div className="form-group edit-row" style={{display:"inline"}}>
                <label htmlFor="name" style={{paddingRight:"5px"}}>Time Needed:</label>
                <input
                type="number"
                className="form-control"
                min={4}
                max={50}
                style={{width:"15%",display:"inline"}}
                // id="name"
                value={hpForm.time}
                onChange={(e) => updateForm(e, { time: parseInt(e.target.value) })}
                />
        </div>}
        {hpForm.moreTime === true && <div className="form-group" style={{marginTop:"10px"}}>
                <label htmlFor="name" style={{marginBottom:"5px", display:"block"}}>Most hall passes are 3 min or less!</label>
                <label htmlFor="name" style={{marginBottom:"5px"}}>Explain why you need {hpForm.time} minutes this time.</label>
                <input
                type="text"
                className="form-control"
                value={hpForm.explain}
                // onChange={(e) => updateFormExplain({ explain: e.target.value })}
                onChange={(e) => updateForm(e, { explain: e.target.value })}
                />
                {hpForm.explain.length < 10 && <label htmlFor="name" style={{marginBottom:"5px", float:"right"}}>10 Characters Required ({10-hpForm.explain.length} More Needed)</label>}
                {/* {hpForm.explain.length < 10 && <label htmlFor="name" style={{marginBottom:"5px"}}></label>} */}
            </div>} 
        </form>
    )
}


function TimeOptions({hpForm, updateForm}){

    const bgColors = [
      '', '', '', ''
    ]
  
    var index = hpForm.time - 1
    if(hpForm.time > 4) {
      index = 3
    }
    bgColors[index] = 'bg-secondary text-white'
  
      return (
        <>
          <div className={`mb-2 me-2 badge ${bgColors[0]}`} style={{border:"2px solid #6c757d", color:"#6c757d"}}
            onClick={e => updateForm(e, { moreTime: false, time: 1 })} role="button"
          >1 Min</div>
          <div className={`mb-2 me-2 badge ${bgColors[1]}`} style={{border:"2px solid #6c757d", color:"#6c757d"}}
            onClick={e => updateForm(e, { moreTime: false, time: 2 })} role="button"
          >2 Min</div>
            <div className={`mb-2 me-2 badge ${bgColors[2]}`} style={{border:"2px solid #6c757d", color:"#6c757d"}}
            onClick={e => updateForm(e, { moreTime: false, time: 3 })} role="button"
          >3 Min</div>
          <div className={`mb-2 me-2 badge ${bgColors[3]}`} style={{border:"2px solid #6c757d", color:"#6c757d"}}
            onClick={e => updateForm(e, { moreTime: true, time: 4 })} role="button"
          >Over 3 Min</div>
          </>
      )
  }