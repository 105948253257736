import React, { useContext, useState, useEffect } from 'react'
import { SiteContext, baseUrl } from '../App.tsx';
import { AdminContext } from './AdminContainer';

import Behavior from '../behavior/dashboard/Behavior';
import Orders from '../store/admin/Orders';
import Checkouts from '../modules/inventory/admin/checkouts/Checkouts';
import ChecklistBox from '../modules/checklist/dashboard/AChecklistDash';
import CustomSelect from '../behavior/dashboard/focus/CustomSelect'
import useStopwatch from '../other/utils/useStopwatch';
import Agreements from '../student/studentCards/Agreements';
import ActiveEvent from '../hallPass/admin/mainModal/ActiveEvent';
import ColorTitle from '../other/utils/ColorTitle';
import FocusCategories from '../behavior/dashboard/FocusCategories';
import DashAssignments from '../modals/assignments/admin/DashAssignments';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleUp, faCircleDown, faStore, faGlassWater, faRightLeft, faLeftRight, faMagnifyingGlass, faClock } from '@fortawesome/free-solid-svg-icons';

import { ErrorBoundary } from '../other/utils/ErrorBoundary';
import '../modules/inventory/inventory.css'
import '../other/utils/events.css'
import new7th from '../other/json/new7th.json'

export default function Dashboard() {

    const {
        classInformation, focusAgreements,
        socket,
        studentList,
        loginState, initialLoad,
        studentDataFunctions: {
            setStudentList, updateFocus, updateFocusSelection,
            checkToday, updateMongo, createMongo
        }
    } = useContext(SiteContext)

    const { hpMessage, orders, modes, mode, filterEvents, checkoutItems, hpEvents, handleShow, createCleanStudent } = useContext(AdminContext)
    const math = useCheckoutMath()
    const {checkoutTotal} = math

    var focusOptions = []

    if(!classInformation || !classInformation.agreements) return
    
    classInformation.agreements.map(entry => {
      focusOptions.push({
        value: entry.category, isSelected: focusAgreements.focusCategory === entry.category ? true : false, label: entry.category, entry
      })
    })

    const index = classInformation.agreements.findIndex(entry => entry.category === focusAgreements.focusCategory)
    const focusAgreementList = index === -1 ? false : classInformation.agreements[index].elements

    var index2 = hpEvents.findIndex(entry => entry.period === classInformation.period)
    if(index2 !== -1) {
        var eventsPeriod = hpEvents[index2].events
    } else {
        var eventsPeriod = []
    }

    var count = 0
    var pendingCount = 0
    var completeCount = 0

    var completeEvent = {}
    
    for(let i = 0; i < eventsPeriod.length; i++) {
        if(eventsPeriod[i].active && eventsPeriod[i].approved) {
            if(count === 0) {var eventCurrent = eventsPeriod[i]}
            count++
        } else if(eventsPeriod[i].active && !eventsPeriod[i].approved && count === 0) {
            if(pendingCount === 0) {var eventPending = eventsPeriod[i]}
            pendingCount++
        } else if(!eventsPeriod[i].active && eventsPeriod[i].approved) {
            completeCount++
            completeEvent = eventsPeriod[i]
        }
    }

    // console.log(studentList, 'studetList')
    return (
    <ErrorBoundary>
      <div className="d-flex">
        <div className="flex-grow-1">
        {count + pendingCount + completeCount > 0 && 
        <div className="col-12">  
            <div className="main-card mb-2 card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  {/* <AHallPass/> */}
                  <div className="bg-light me-2 p-2 rounded"
                  // onClick={e => handleShow(undefined, 'hallPassBoxModal', {title: "Hall Passes"})} role="button"
                  >
                    <ActiveEvent events={true} />
                  </div>
                  <StopwatchContainer/> 
                </div>
              </div>
            </div>
          </div>
          }
          {initialLoad && <FocusCategories showStopwatch={count + pendingCount + completeCount === 0 ? true : false}/>}
          {initialLoad && focusAgreementList && <div className="col-12">  
          </div>}
          <Behavior />
          {checkoutTotal > 0 && 
          <Checkouts title={'Checkouts'} sizeClass={'col-12'} />
           }
          <Orders title={'Cups'} sizeClass={'col-12'} orders={orders} type={'cups'} context={'dashboard'} />
          <ChecklistContainer/>
          <Orders title={'Orders'} sizeClass={'col-12'} orders={orders} context={'dashboard'} />
          <DashAssignments title={'Assignments'} sizeClass={'col-12'} variant={'info'} />
          <Agreements/>
        </div>
        <BadgesColumn/>
      </div>
    </ErrorBoundary>
    )
}

function BadgesColumn({}){

  const {classInformation, focusAgreements, studentList} = useContext(SiteContext)
  const { hpMessage, orders, modes, mode, filterEvents, checkoutItems, hpEvents } = useContext(AdminContext)
  // const {completionsPending} = useChecklistMath()
  
  const outcome = useCheckoutMath()
  const {completionsPending, completionsComplete} = useChecklistMath()
  const {checkouts, checkoutsActive, checkoutsComplete, checkoutTotal} = outcome

  if(!focusAgreements || !focusAgreements.activeAssignment) return

  const assignmentsPending = []
  const assignmentsComplete = []
  
  studentList.map(entry => {
      if(entry.period !== classInformation.period) return
      var index = entry.assignments ? entry.assignments.findIndex(item => item.title === focusAgreements.activeAssignment) : -1
      
      if(index === -1) return
      const current = {...entry.assignments[index], name: entry.name, studentId: entry.studentId, dailyListId: entry.dailyListId}
      if(!current.complete) return

      if(current.complete && !current.completeAdmin) {
        assignmentsPending.push(current)
      } else if(current.completeAdmin) {
        assignmentsComplete.push(current)
      }
      
  })

  // const filteredCompletions = [...completionsPending, ...completionsComplete]
  // var checkouts = 0
  // var completionsPending = 0

  var ordersPeriod = orders.filter(entry => entry.period === classInformation.period)
  var cups = ordersPeriod.filter(entry => entry.item === 'Cup')
  var otherOrders = ordersPeriod.filter(entry => entry.item !== 'Cup')
  // var activelyPending = type === 'raise' ? filterEvents('raise') : filterEvents('lower')

  const types = [
    {type: 'Raise', variant: 'black', formula: filterEvents('raise').length, icon: faCircleUp},
    {type: 'Checkouts', variant: 'flag', formula: checkouts, icon: faLeftRight},
    {type: 'Lower', variant: 'danger', formula: filterEvents('lower').length, icon: faCircleDown},
    {type: 'Cups', variant: 'primary', formula: cups.length, icon: faGlassWater},
    {type: 'Completions', variant: 'purple', formula: completionsPending, icon: faCheck},
    {type: 'Orders', variant: 'info', formula: otherOrders.length, icon: faStore},
    {type: 'Assignments', variant: 'info', formula: assignmentsPending.length, icon: faCheck},
  ]

  var showEvents = types.filter(entry => entry.formula > 0).length > 0 ? true : false

  if(!showEvents) return
  return <PendingBadges types={types} />
}

function useCheckoutMath() {

  const {classInformation} = useContext(SiteContext)
  const { hpMessage, orders, modes, mode, filterEvents, checkoutItems, hpEvents } = useContext(AdminContext)

  if(!checkoutItems) return {checkouts: 0, checkoutsActive: 0, checkoutsComplete: 0, checkoutTotal: 0}

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); 

  today = `month${mm}day${dd}`

  var checkouts = 0
  var checkoutsActive = 0
  var checkoutsComplete = 0

  checkoutItems.map(entry => {
      if(entry.checkoutTimestamp !== today && !entry.checkedOut) return {checkouts: 0, checkoutsActive: 0, checkoutsComplete: 0, checkoutTotal: 0}
      if(!entry.checkoutEvent || entry.checkoutEvent.period !== classInformation.period) return {checkouts: 0, checkoutsActive: 0, checkoutsComplete: 0, checkoutTotal: 0}
      if(entry.checkedOut && !entry.checkoutEvent.adminProcessed) {
        checkouts++
      } else if(entry.checkedOut && entry.checkoutEvent.adminProcessed) {
        checkoutsActive++
      } else if(!entry.checkedOut && entry.checkoutEvent.adminProcessed) {
        checkoutsComplete++
      }
  })

  var checkoutTotal = checkouts + checkoutsActive + checkoutsComplete

  // console.log('jere', {checkouts, checkoutsActive, checkoutsComplete, checkoutTotal})
  return {checkouts, checkoutsActive, checkoutsComplete, checkoutTotal}
}

function ChecklistContainer() {
  const {completionsPending, completionsComplete} = useChecklistMath()

  // console.log(completionsPending,'completionsPending')
  // if(completionsPending === 0) return
  return <ChecklistBox title={'Module Completions'} sizeClass={'col-12'} />
}

function useChecklistMath() {
  
  const {focusAgreements, classInformation} = useContext(SiteContext)

  var completionsPending = 0
  var completionsComplete = 0

  if(!focusAgreements || !focusAgreements.modules) return {completionsPending: 0, completionsComplete: 0}

  focusAgreements.modules.map(moduleEntry => {
    // console.log(moduleEntry,'moduleEntry')
    var todaysDate = new Date();

      moduleEntry.checklists.map(checklistEntry => {
        checklistEntry.checklist.map(entry => {
          if(!entry.completeEvent) return {completionsPending: false, completionsComplete: false}
          var inputDate = new Date(entry.completeEvent.date)
          if(!entry.complete || entry.completeEvent.period !== classInformation.period) return {completionsPending: false, completionsComplete: false}
          
          if(entry.completeAdmin && inputDate.setHours(0,0,0,0) === todaysDate.setHours(0,0,0,0)) {
            completionsComplete++
          } else if(!entry.completeAdmin) {
            completionsPending++
          }
        })
      })
  })

  return {completionsPending, completionsComplete}
}

function PendingBadges({types}) {
  return (
    <div className="mb-3 ms-2 badgeEvents sticky-top me-0"
     style={{minWidth:"90px", maxWidth:"90px"}}>
     <div className="opacity-0" style={{height:"102px"}}></div>
      <div className="card d-flex flex-wrap justify-content-center" >
        {types.map(entry => {
          if(entry.formula === 0) return
          return <div key={entry.type} className={`badge flex-fill bg-${entry.variant} text-white m-2 d-flex align-items-center justify-content-center p-2`}>
                  <p className="m-1" style={{fontSize:"14px"}}>
                    {entry.formula} 
                    {/* {entry.type} */}
                  </p>
                  <FontAwesomeIcon className="m-1 card-title d-inline text-white" icon={entry.icon} />
                </div>
        })}
      </div>
     </div>
  )
}

function StopwatchContainer({}) {

  const {classInformation} = useContext(SiteContext)
  // const {classStart, classEnd} = calculateTime(classInformation.dayType, classInformation.classStartNormal, classInformation.classStartMinimum)

  const stopwatchTime = useStopwatch('Time Remaining', classInformation.classStart, classInformation.classEnd)
  var minutes = stopwatchTime.split(':') ? stopwatchTime.split(':')[0] : false
  var bgColor = minutes && minutes < 1 ? 'bg-danger' : 
                           minutes < 5 ? 'bg-flag' : 
                           minutes < 10 ? 'bg-warning' :
                           stopwatchTime === 'Complete' ? 'bg-success' : 'bg-primary text-white'
  return (
    
    <div className={`badge p-2 ${bgColor} text-white d-flex align-items-center text-wrap`}>
        <p className="m-1 d-inline ">{stopwatchTime}{minutes > -1 ? ' Remaining' : ''}</p>
        <FontAwesomeIcon className="m-0 ms-1 me-1 card-title d-inline text-white" icon={faClock} />
    </div>
  )
}

