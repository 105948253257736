import React, { useState, useEffect, useContext } from "react";

import { SiteContext } from "../../../App.tsx";
import { StudentContext } from "../../../pages/StudentContainer";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

import Questions from "./Questions";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToDot, faBook, faSheetPlastic } from '@fortawesome/free-solid-svg-icons';

import './assignments.css'

export default function AssignmentModal({show, type}) {

 const {
   student, moduleItems, loginState, socket, room, focusAgreements,
   studentDataFunctions: {updateMongo, moduleFind, setStudent, handleClose, getMongoList}
 } = useContext(SiteContext)

 const {updateStudent} = useContext(StudentContext)

 const [assignments, setAssignments] = useState([])
 const [activeAssignment, setActiveAssignment] = useState(() => {
   if(!focusAgreements || !focusAgreements.activeAssignment || !student.assignments) return false
   var index = student.assignments.findIndex(entry => entry.title === focusAgreements.activeAssignment)
   if(index === -1) return false
   return student.assignments[index]
 })

 useEffect(() => {
  
  async function getClasses() {
    const assignmentsLoaded = await getMongoList('assignments')
    setAssignments(assignmentsLoaded)

    if(!student.assignments) {
      updateMongo({assignments: []}, student.dailyListId, 'dailyList')
      const editStudent = {...student}
      setStudent({...editStudent, assignments: []})
      socket.emit("send_updateSingleStudent", {student: {...editStudent, assignments: []}, room: editStudent.period});
      console.log('again 1')
      return
    }

    var updateMongoBoolean = false
    const editAssignments = [...student.assignments]

    // console.log(student,'student')

    assignmentsLoaded.map(entry => {
      var index = editAssignments.findIndex(item => item.itemId === entry.itemId)
      if(index !== -1) return

      const newAssignment = {
        title: entry.title,
        dateMilli: Date.now(),
        itemId: entry.itemId,
        url: [],
      }
      editAssignments.push(newAssignment)
      updateMongoBoolean = true

    })

    if(updateMongoBoolean) {
      // console.log('item')
      updateMongo({assignments: editAssignments}, student.dailyListId, 'dailyList')
      const editStudent = {...student}
      setStudent({...editStudent, assignments: editAssignments})
      // socket.emit("send_updateSingleStudent", {student: {...editStudent, assignments: editAssignments}, room: editStudent.period});
      socket.emit("send_updateSingleStudent", {student: {...editStudent, assignments: editAssignments}, room: 'admin'});
      console.log('again 2')
    }
  }

  getClasses()

 }, [student.assignments])

 useEffect(() => {

  if(!focusAgreements || !focusAgreements.activeAssignment || !student.assignments) return
  var index = student.assignments.findIndex(entry => entry.title === focusAgreements.activeAssignment)
  if(index === -1) return 
  setActiveAssignment(student.assignments[index]) 

 }, [focusAgreements.activeAssignment])

 async function handleChooseAssignment(e) {
  e.preventDefault()

  if(e.target.value === 'none') {
    setActiveAssignment(false)
    return
  } 
  var index = student.assignments.findIndex(entry => entry.itemId === e.target.value)
  if(index === -1) return
  setActiveAssignment(student.assignments[index])
 }

 return (

  <ErrorBoundary>
  <div className={"col-12"}>
    {/* <div className={`main-card card`}> */}
      <div className="card-header d-flex justify-content-between align-items-center">
        <div style={{fontSize:"20px"}}>
          <FontAwesomeIcon className="me-2" icon={faSheetPlastic} />
          Assignments
        </div>
        <div>
        <select className="form-control" role="button" value={activeAssignment.itemId} onChange={e => handleChooseAssignment(e)}>
            <option value="none">Choose Assignment</option>
            {student.assignments.map(entry => {
              return <option key={entry.itemId} value={entry.itemId}>{entry.title}</option>
            })}
        </select>
        </div>
      </div>
      <div className="card-body">
         {activeAssignment && <Questions activeAssignment={activeAssignment} setActiveAssignment={setActiveAssignment} />}
      </div>
    {/* </div> */}
  </div>
  </ErrorBoundary>
);
}