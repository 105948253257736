import React, {useContext} from 'react'

import ReflectionQs from "./ReflectionQs";
import TextQuestions from './TextQuestions'
import ButtonCard from './ButtonCard';
import Reflection from '../../../../weekReview/reflection/Reflection';

import { SiteContext } from '../../../../App.tsx'

export default function ColorsReflection({form, updateForm, setForm, colorsReview, setColorsReview}) {

    const {reviewEntries, student, studentDataFunctions: {}} = useContext(SiteContext)  

    return (
        <>
       <div className="d-flex flex-wrap">
           <ReflectionQs question={`you`} type={'q'} updateForm={updateForm} form={form} category={`rating`} item={`self`} value={form.rating.self} />
           <ReflectionQs question={`your group`} type={'q'} updateForm={updateForm} form={form} category={`rating`} item={`group`} value={form.rating.group} />
           <ReflectionQs question={`our class`} type={'q'} updateForm={updateForm} form={form} category={`rating`} item={`class`} value={form.rating.class} />
           <ReflectionQs question={``} type={'moduleDay'} updateForm={updateForm} form={form} category={`module`} item={`progress`} value={form.module.progress}/>
       </div>
       <div className="d-flex align-items-stretch" style={{paddingBottom: colorsReview ? '40px' : '0px'}}>
           <TextQuestions question={`How did you support our community today?`} updateForm={updateForm} form={form} setForm={setForm} category={`questions`} item={`success`} />
           <TextQuestions question={`How could you have better supported our community?`} updateForm={updateForm} form={form} setForm={setForm} category={`questions`} item={`improve`} />
           <ButtonCard colorsReview={colorsReview} setColorsReview={setColorsReview} />
       </div>
       <div className="row">
           {colorsReview && <Reflection entry={reviewEntries[0]} />}
       </div>
       </>
      )
}
