import React, { useContext } from 'react'
import { SiteContext } from '../../App.tsx';
import { StudentContext } from '../../pages/StudentContainer';
import { ErrorBoundary } from '../../other/utils/ErrorBoundary';

import {useTenMinRule} from '../TenMinRule'

import ActiveHP from './ActiveHP';

export default function HPCard({ event, subtitle, colorValue, sizeClass }) {

  const {activePass, updateEvents} = useContext(StudentContext)
  const {classInformation} = useContext(SiteContext)

    return (
    <ErrorBoundary>
      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 card`}>
          <div className="card-header h-auto mt-0 mb-1 pb-0" style={{border: "0px"}}>
            <div className="gap-1">
              <div className={`mb-2 me-2 badge bg-secondary`}>{subtitle}</div>
              <StatusBadge/>
            </div>
            {event.active && !event.approved && <div className="d-none d-lg-block btn-actions-pane-right">
          <PassInstructions/>
        </div>}
            <div className="btn-actions-pane-right">
              <ActiveHP event={event} key={event.timeStamp} />
            </div>
            
          </div>
        {event.active && !event.approved && <div className="d-block d-lg-none card-header h-auto pt-0">
          <PassInstructions/>
        </div>}
        </div>
      </div>
    </ErrorBoundary>
    )


    function StatusBadge() {

      const tenMinRule = useTenMinRule()

      if(event.active && !event.approved) {
          return (
            <>
              <div className={`mb-2 me-2 badge bg-info`}>Pending</div>
              {tenMinRule && <div className={`mb-2 me-2 badge bg-flag`} style={{marginTop:"5px"}}>10 Minute Rule</div>}
              {activePass && !tenMinRule && <div className={`mb-2 me-2 badge bg-flag`} style={{marginTop:"5px"}}>Hall Pass In Use</div>}
            </>
          )
      } else if(event.active && event.approved) {
          return (
            <>
              <div className={`mb-2 me-2 mt-2 badge bg-success`}>Active</div>
            </>
          )
      } else if(!event.active && !event.closed) {
          return (
              <div className={`mb-2 me-2 mt-2 badge bg-secondary`}>Complete</div>
          )
      }
  }

  function PassInstructions() {
    return (
    <>
        <div className={`mb-2 me-2 badge bg-info`}>
            Instructions
        </div>
        {/* <div className="btn-actions-pane-right"> */}
          <div className={`mb-2 me-2 badge bg-secondary`}>
              <span>1. Fill out paper hall pass</span>
          </div>
          <div className={`mb-2 me-2 badge bg-secondary`}>
              <span>2. Give to Smith</span>
          </div>
        {/* </div> */}
    </>
    )
}

}
