import React, { useContext, useEffect, useState } from 'react'

import { SiteContext, baseUrl } from '../../App.tsx';
import { AdminContext } from '../../pages/AdminContainer';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import ColorCard from './ColorCard';
import ColorChart from './ColorChart';
import Card from './Card';
import ClassCard from './ClassCard'
import Settings from './Settings'
import Podium from './Podium'
import useStopwatch from '../../other/utils/useStopwatch'
import calculateTime from '../../other/utils/calculateTime'

import { ErrorBoundary } from '../../other/utils/ErrorBoundary';


export default function ColorGrid({}) {

    const {
      classInformation, focusAgreements, socket,
      studentList, colorMatch, classInformationArray,
      studentDataFunctions: {
        getMongoList, updateMongo, getMongoByUniqueId
      }
    } = useContext(SiteContext)

    const { behaviorEvents, handleUpdateList, dailyList, setDailyList } = useContext(AdminContext)

    const [standings, setStandings] = useState(false)
    // const {classStart, classEnd, classEndReadable} = calculateTime(classInformation.dayType, classInformation.classStartNormal, classInformation.classStartMinimum)
    const [showTimer, setShowTimer] = useState(focusAgreements.timerMilli ? true : false)
    const [startTime, setStartTime] = useState(focusAgreements.timerMilli ? focusAgreements.timerMilli : false)
    const [showEmojis, setShowEmojis] = useState(false)
    const [timerLength, setTimerLength] = useState(focusAgreements.timerLength ? focusAgreements.timerLength : 5)

    const filteredList = studentList.filter(entry => entry.period === classInformation.period && entry.attendance !== 'Absent')
    const filteredListAll = studentList.filter(entry => entry.attendance !== 'Absent')

    useEffect(() => {
      setStartTime(focusAgreements.timerMilli ? focusAgreements.timerMilli : false)
      setShowTimer(focusAgreements.timerMilli ? true : false)
      setTimerLength(focusAgreements.timerLength ? focusAgreements.timerLength : 5)
    }, [focusAgreements])

    // useEffect(() => {
    //   if(focusAgreements.focusCategory !== 'Reading Zone') return
    //   setShowTimer(true)
    // }, [focusAgreements.focusCategory])

    // useEffect(() => {

    //   socket.on("receive_updateColorGrid", (data) => {
    //     const editDaily = [...dailyList]
    //     var index = editDaily.findIndex(entry => entry.studentId === data.data.studentId)
    //     editDaily[index].color = {...editDaily[index].color, ...data.data.color}
 
    //     setDailyList(editDaily)     
    //   })
  
    //   return () => {
    //       socket.off('receive_updateColorGrid');
    //   };
  
    // },[socket, dailyList])

    useEffect(() => {
      let interval;
      interval = setInterval(() => {
          window.location.reload();
      }, 600000);
    }, [])

    if(filteredList.length === 0 || filteredListAll.length === 0) return (
      <span>Loading...</span>
    ) 
    var combined = []

    function getColors() {
      colorMatch.map(entry => {
        const current = filteredList.filter(student => student.color.current === entry.color)
        combined = [...combined, ...current]
      })
    }

    getColors()

    function handleToggleTimer() {
      setShowTimer(prev => {return !prev})
    }

    function handleStart(e) {
      e.preventDefault()
      setStartTime(Date.now())
      const editFocusAgreements = {...focusAgreements}
        editFocusAgreements.timerMilli = Date.now()
        editFocusAgreements.timerLength = timerLength
      updateMongo({timerMilli: Date.now(), timerLength}, classInformation.focusAgreementId, 'focusAgreements') 
      socket.emit("send_focusAgreementsUpdate", { focusAgreements: editFocusAgreements, room: 'admin' });
    } 

    function handleReset(e) {
      e.preventDefault()
      setStartTime(false)
      const editFocusAgreements = {...focusAgreements}
        editFocusAgreements.timerMilli = false
      updateMongo({timerMilli: false}, classInformation.focusAgreementId, 'focusAgreements') 
      socket.emit("send_focusAgreementsUpdate", { focusAgreements: editFocusAgreements, room: 'admin' });
    }

    const values = []
    classInformationArray.map(entry => {
      values.push({period: entry.period, vals: []})
    })
    
    const valuesAfter = []

    function average (array) {
      var before = array.length > 0 ? array.reduce((a, b) => a + b) / array.length : []
      var tenth = Math.round(before * 100) / 100
      var half = Math.round(tenth*2)/2
      
      const match = colorMatch.filter(item => item.level === parseInt(half))[0]
      // console.log()
      return {
          level: half,
          roundTenth: tenth,
          color: match ? match.color : '',
          variant: match ? match.variant : '',
      }
      
    };

    filteredListAll.map(entry => {
        const match = colorMatch.filter(item => item.color === entry.color.current)[0]
        // console.log(values, entry.period)
        var index = values.findIndex(item => item.period === entry.period)
        if(index === -1) return
        values[index].vals.push(match.level)
    })

    values.map(entry => {
      const calc = average(entry.vals)
      entry = {...entry, level: calc.level, roundTenth: calc.roundTenth, color: calc.color, variant: calc.variant, period: entry.period}
      valuesAfter.push(entry)
    })

    valuesAfter.sort((a, b) => parseFloat(b.roundTenth) - parseFloat(a.roundTenth));

    function ColorTotalCard({color}) {
      const match = colorMatch.filter(item => item.color == color)[0]
      var colorLevel = match.level
      var count = 0

      // console.log(combined,'combined')
      combined.map(entry => {
        const match = colorMatch.filter(item => item.color == entry.color.current)[0]
        if(match.level <= colorLevel) {
          count++
        }
        if(color === 'Green' && match.level <= colorLevel - 1) {count--}
      })

      const matching = combined.filter(entry => entry.color.current === color)
      // console.log(matching.length, 'matching', color)

      var variant = color === 'Yellow' ? 'secondary' : match.variant

      // function ClassPointsTooltip({text, tooltip }) {
      //   return (
      //     <OverlayTrigger
      //       overlay={
      //         <Tooltip style={{fontSize:"10px"}}>
                
      //         </Tooltip>}
      //       placement="top"
      //       delayShow={300}
      //       delayHide={150}
      //     >
      //       <span>{text}</span>
      //     </OverlayTrigger>
      //   );
      // }

      return (
        <OverlayTrigger
          overlay={
          <Tooltip style={{fontSize:"10px"}}>
            {color === 'Green' && matching.map(entry => {
              return <div key={entry._id} className="bg-light m-1">{entry.name}</div>
            })} 
          </Tooltip>}
          placement="right"
          delay={600}
          // delayShow={1000}
          // delayHide={150}
        >
        <div className="col-6">
          <div className={`mb-2 widget-chart p-1 card bg-${variant}`} style={{padding:"5px"}} role={color === 'Green' ? 'button' : ''}>
            <div className="p-1">
              <div className="widget-numbers mt-0 fsize-1 text-white">
                <div className="title-container">
                  <div style={{float:"left"}}>
                    <span className="text-size">
                      {color === 'Yellow' ? 'Other' : color} 
                    </span>
                  </div>
                </div>
                <div className="title-container">
                  <div style={{float:"left"}}>
                    <span className="">
                    {count} {count !== 1 ? `Students` : 'Student'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </OverlayTrigger>
      )
    }

    const foci = focusAgreements.categories.filter(entry => entry.adminFocus)
    var string = focusAgreements.focusCategory ? focusAgreements.focusCategory : 'N/A'

    var count = 0

    const importFunctions = {standings, setStandings, showTimer, handleToggleTimer, timerLength, 
      setTimerLength, startTime, handleStart, handleReset, showEmojis, setShowEmojis}

    return (
      <ErrorBoundary>
      <div className="row">
          <div className={standings ? 'col-5' : 'col-7'}>
            <div className={`row`}>
              <TimeContainer/>
              {combined.map(entry => {
                  const match = colorMatch.filter(item => item.color == entry.color.current)[0]
                  if(match.level < 6 && !showEmojis) return
                  count++
                  return (
                      <ColorCard 
                          key={entry.studentId}
                          student={entry}
                          period={classInformation.period}
                          importFunctions={importFunctions}
                      />
                  )
              })}
            </div>
            {!showEmojis && <div className={`row ${count > 0 && `mt-4`}`}>
              <ColorTotalCard color={'Green'}/>
              <ColorTotalCard color={'Yellow'}/>
            </div>}
            <div className="row" style={{marginTop:"60px"}}>

            </div>
          </div>
          <div className={standings ? 'col-7' : 'col-5'}>
            <div className="row">
              <div className={`${standings ? 'col-7' : 'col-12'}`}>
                <Card title={string} subtitle={'Focus Category'} sizeValue={'col-12'} />
                <ColorChart title={'Colors Chart'} colorValue={'info'} sizeClass={'col-sm-12'} filteredReviewEntries={combined} type={'colors'}/>
                <Settings title={string} subtitle={'Focus Category'} sizeValue={'col-12'} importFunctions={importFunctions} />
              </div>
              {standings && <div className={`col-5`}>
                <RankingTitle/>
                <Podium valuesAfter={valuesAfter} />
              </div>}
            </div>
          </div>
      </div>
      </ErrorBoundary>
    )

    function TimeContainer({}) {
      
      const [localStart, setLocalStart] = useState(classInformation.classStart)
      const [localEnd, setLocalEnd] = useState(classInformation.classEnd)
      const [localEndReadable, setLocalEndReadable] = useState(classInformation.classEndReadable)

      useEffect(() => {
        setLocalStart(classInformation.classStart)
        setLocalEnd(classInformation.classEnd)
        setLocalEndReadable(classInformation.classEndReadable)
      }, [classInformation.classStart, classInformation.classEnd, classInformation.classEndReadable])

      const cleanupTime = useStopwatch('Time Til Cleanup', localStart, localEnd)
      var remainingTime = useStopwatch('Time Remaining', localStart, localEnd)
      var showRemaining = cleanupTime === 'Clean Up!' ? remainingTime : false
      
      return (
        <div className="col-12">
          <div className="d-flex align-items-stretch gap-2">
            <Card subtitle={'Time Til Cleanup'} sizeValue={`${(showTimer && showRemaining) ? 'col-6' : (showTimer || showRemaining) ? 'col-4' : `col-6`}`} time={'stopwatch'} displayTime={cleanupTime} />
            {showRemaining && <Card subtitle={'Time Remaining'} sizeValue={showTimer ? `col-6` : `col-4`} time={'stopwatch'} displayTime={remainingTime} />}
            {showTimer && <Card subtitle={'Timer'} sizeValue={showRemaining ? `col-6` : `col-4`} time={'timer'} timerLength={timerLength} startTime={startTime} />}
            <Card title={localEndReadable} subtitle={'Class End'} sizeValue={`${(showTimer && showRemaining) ? 'col-6' : (showTimer || showRemaining) ? 'col-4' : `col-6`}`} />
          </div>
        </div>
      )

    }
}

function RankingTitle() {
  return (
  <div className={`col-12`}>
    <div className={`widget-chart widget-chart2 mb-3 bg-secondary text-start card card-shadow-secondary`}>
      <div className="widget-content p-0 w-100">
        <div className="widget-content-outer">
          <div className="widget-content-wrapper">
            <div className="widget-content-left fsize-1">
                <div className={`widget-numbers mt-0 fsize-2 text-white`} style={{marginBottom: "0px"}}>
                   Daily Standings
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}