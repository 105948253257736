import React, { useState, useContext, useEffect } from 'react'
import { SiteContext } from '../../App.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ColorCard({student, importFunctions}) {

  const {showEmojis} = importFunctions

  const { 
    studentDataFunctions: {
    },
    colorMatch
  } = useContext(SiteContext)

  const {
    name,
    color: {
        current: currentColor
    }
  } = student

  var nameSplit = name ? name.split(' ') : ''
  var nameString = ''
  nameSplit.map(entry => {
    nameString = nameString + entry.charAt(0)
  })

  const match = colorMatch.filter(item => item.color === currentColor)
    const variant = showEmojis ? 'white' : match[0].variant
    var text = student.checkIn.color
  
    // console.log(student.checkIn.color,'student.checkIn.color', student.name)
    return (

      <div className="col-6 col-md-3">
        <div className={`mb-2 widget-chart card bg-${variant} p-1`}>
          <div className="p-2">
            <div className="widget-numbers mt-0 fsize-1 text-white">
                <div style={{float:"left"}}>
                  <span className={`text-size text-${showEmojis ? 'secondary' : 'white'}`} style={{fontSize:"22px"}}>{nameString}</span>
                  <span className="text-size ms-2" style={{fontSize:"22px"}}>
                  {showEmojis && <FontAwesomeIcon  className="btn-icon-wrapper" color={student.checkIn.color} icon={student.checkIn.emoji}/>}
                  </span>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
}