import React, { useEffect, useState, useContext } from "react";

import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrush } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots, faCircleArrowDown, faCircleArrowUp, faCirclePlus, faBed, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faStar, faFaceSmile, faFaceMeh, faFaceFrownOpen, faFaceAngry, faFaceGrinTongueWink } from '@fortawesome/free-regular-svg-icons';

import '../assets/base.scss'
import '../assets/style.css'

import { SiteContext } from "../App.tsx";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {

    const navigate = useNavigate();

    const {
        student,
        room,
        roomJoined,
        studentDataFunctions: {
            setStudent,
            joinRoom,
            setRoom,
            setRoomJoined,
            sendMessage
        }
    } = useContext(SiteContext)
    
    const [emoji, setEmoji] = useState(faSpinner)
    const [bgColor, setBgColor] = useState('#ff753e')

    useEffect(() => {
        setRoom(student.period)
    }, [student.name, student])

    useEffect(() => {
        joinRoom(student.name, room)
        setRoomJoined(true)
    }, [room])

    // useEffect(() => {
    //     console.log(room, 'room joined')
    // }, [roomJoined])

    function handleLoadStudentPage(e, emoji, color) {
        e.preventDefault();
    
        var studentOriginal = {...student}

        // console.log(student,'student checkin')
        
        studentOriginal.checkIn = {
          complete: true,
          emoji,
          color,
          note: e.target.textAreaSpace.value,
        }
        
        // studentOriginal.chipsToday = {added: 0, subtracted: 0}
        setStudent(studentOriginal)

        sendMessage({
            name: student.name,
            reason: 'Login',
            message: studentOriginal.checkIn.note,
            period: student.period,
            studentId: student.studentId
        })

        navigate("/student");  
    }

    function CustomToggle({ children, eventKey, type, color }) {
        const decoratedOnClick = useAccordionButton(eventKey, (e) => {
            setEmoji(type)
            setBgColor(color)
        });

        return (
            <div id={type} style={{ border: 0 }} className="col-sm-4">
            <button
                type="button"
                id={type}
                onClick={decoratedOnClick}
                className={`btn-icon-vertical btn-transition btn`}
            >
                <FontAwesomeIcon id={type} className="btn-icon-wrapper" color={color}  style={{marginLeft:"auto", marginRight:"auto"}} icon={type}/>
            </button>
            </div>
        );
    }


    return (

        <div className="app-container app-theme-white body-tabs-shadow">
            {/* <div className="app-container"> */}
                <div className="h-100 bg-plum-plate bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <div className="mx-auto app-login-box col-md-8">
                    <div className="app-logo-inverse mx-auto mb-3" />
                        <div className="modal-dialog w-100 mx-auto">
                            <div className="modal-content">
                                <div className="modal-body">
                                {/* <div className="main-card mb-3 card">    */}
                                        <div className="card-header">
                                            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faCommentDots} />
                                            Hi {student.firstName}, How Are You Feeling Today?
                                        </div>
                                        <div className="card-body">
                                            <Accordion>
                                                <div
                                                style={{ paddingBottom: 20 }}
                                                id="behaviorChange"
                                                className="grid-menu"
                                                >        
                                                    <div className="g-0 row">
                                                        <CustomToggle eventKey="0" type={faFaceSmile} color={"#66c183"}></CustomToggle>
                                                        <CustomToggle eventKey="0" type={faFaceMeh} color={"#f4b568"} ></CustomToggle>
                                                        <CustomToggle eventKey="0" type={faFaceFrownOpen} color={"#c73953"} ></CustomToggle>
                                                        <CustomToggle eventKey="0" type={faFaceAngry} color={"#1e3050"} ></CustomToggle>
                                                        <CustomToggle eventKey="0" type={faFaceGrinTongueWink} color={"#ff753e"}></CustomToggle>
                                                        <CustomToggle eventKey="0" type={faBed} color={"rgb(133, 19, 133)"} ></CustomToggle>
                                                    </div>          
                                                </div>
                                                <Accordion.Collapse eventKey="0">
                                                    <form 
                                                        onSubmit={(e) => handleLoadStudentPage(e, emoji, bgColor)}
                                                        style={{paddingTop:"20px"}}
                                                    >
                                                        <div className="position-relative mb-3">
                                                            <label htmlFor="exampleSelect" className="form-label">
                                                            Anything else you want to share? 
                                                            </label><br/>
                                                            <label htmlFor="exampleSelect" className="form-label">
                                                            No pressure to say anything!
                                                            </label>
                                                            <textarea
                                                            className="mb-2 form-control"
                                                            cols={40}
                                                            rows={2}
                                                            id="textAreaSpace"
                                                            placeholder="Type here . . ."
                                                            defaultValue={""}
                                                            />
                                                        </div>
                                                        <button type="submit" style={{ width: "100%", background: bgColor, border: "none" }} className={`mt-1 btn btn-primary`}>
                                                            <FontAwesomeIcon name="emojiElement" size="xl" style={{ marginRight: 8 }} icon={emoji} />
                                                            Submit                            
                                                        </button>
                                                    </form>
                                                </Accordion.Collapse>                         
                                            </Accordion>
                                        </div>
                                    </div>


                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            {/* </div> */}
        </div>
        
    )
}
