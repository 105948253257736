import React, { useContext } from 'react'

import { SiteContext } from '../../App.tsx'

import Stopwatch from '../../other/utils/Stopwatch'
import calculateTime from '../../other/utils/calculateTime'

import './grid.css'

export default function Podium( { valuesAfter }) {

  const {classInformation, colorMatch} = useContext(SiteContext)

    return (
        <>
        <div className="row">
            <div className={`col-4`}>
                <EntryBlank/>
                <Entry rank={2} />
                <EntryPeriod period={valuesAfter[1].period} />
            </div>
            <div className={`col-4`}>
                <Entry  rank={1} />
                {valuesAfter[0] && <EntryPeriod period={valuesAfter[0].period} />}
            </div>
            <div className={`col-4`}>
                <EntryBlank/>
                <EntryBlank/>
                <Entry  rank={3} />
                {valuesAfter[2] && <EntryPeriod period={valuesAfter[2].period} />}
            </div>
        </div>
        <div className="row">
            <ClassData valuesAfter={valuesAfter} />
        </div>
        </>
    )
}

function EntryBlank() {
    return (
        <div className={`col-12`}>
        <div className={`text-start`} style={{height: 40}}>

        </div>
      </div>
    )
}

function Entry({period, rank}) {

    var extraHeight = rank === 1 ? 80 : rank === 2 ? 40 : 0
    var bgColor = rank === 1 ? 'gold' : rank === 2 ? 'silver' : 'bronze'
    var rank = rank === 1 ? '1st' : rank === 2 ? '2nd' : '3rd'
    return (
        <div className={`col-12`}>
        <div className={`mb-2 bg-${bgColor} card`} style={{height: 70 + extraHeight, textAlign:"center"}}>
                  <div className={`text-white fsize-2`} style={{fontWeight:"bold", marginTop:"10px"}}>
                      {rank}
                  </div>
              <div className={`widget-content-left fsize-1`}>
                <div className="">
                </div>
              </div>
        </div>
      </div>
    )
}

function EntryPeriod({period, rank}) {

    const {classInformation, colorMatch} = useContext(SiteContext)

    var extraHeight = rank === 1 ? 80 : rank === 2 ? 40 : 0
    var bgColor = rank === 1 ? 'gold' : rank === 2 ? 'silver' : 'bronze'
    var rank = rank === 1 ? '1st' : rank === 2 ? '2nd' : '3rd'
    var borderVal = period === classInformation.period ? 'borderCurrent' : 'borderOther'

    return (
        <div className={`col-12`}>
        <div className={`mb-3 bg-${'secondary'} card ${borderVal}`} style={{textAlign:"center"}}>
            <div className={`text-white`} style={{fontWeight:"bold", marginTop:"10px", marginBottom:"10px", fontSize:"13px"}}>
                {`${ordinal_suffix_of(period.split(' ')[1])} Period`}
            </div>
            {/* <div className={`text-white fsize-3`} style={{fontWeight:"bold", marginBottom:"5px"}}>
                
            </div> */}
        </div>
      </div>
    )
}

function ClassData({valuesAfter}) {
    var count = 0
    return (
        <div className={'col-12'}>
        <div className={`card-shadow-secondary bg-secondary mb-3 widget-chart widget-chart2 text-start card`} style={{marginTop:"25px"}}>
          <div className="widget-content p-0 w-100">
            
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className="widget-content-left pe-2 fsize-1">
                  <div className="widget-numbers mt-0 fsize-2 text-white">
                    Class Data
                  </div>      
                </div>
              </div>
              
              <div className="widget-content-left fsize-1">
              <div className="table-responsive">
                <table className="text-truncate table table-borderless text-white">
                  <thead>
                    <tr>
                      <th className="" style={{padding:"0"}}>Place</th>
                      <th className="" style={{padding:"0"}}>Period</th>
                      <th className="" style={{padding:"0"}}>Avg</th>
                    </tr>
                  </thead>
                  <tbody>
                      {/* <DataEntry entry={valuesAfter[1]} key={valuesAfter[1].period} rank={count} variant={}/> */}
                      {valuesAfter.map(entry => {
                        count++
                        if(count === 1) {entry.variant = 'gold'} else if(count === 2) {entry.variant = 'silver'} else if(count === 3) {entry.variant = 'bronze'} else {
                            entry.variant = 'light'
                        }
                        return <DataEntry entry={entry} key={entry.period} rank={count} />
                      })}
                  </tbody>
                </table>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

function DataEntry({entry, rank}) {

    const {classInformation, colorMatch} = useContext(SiteContext)
    var bgColor = rank === 1 ? 'gold' : rank === 2 ? 'silver' : rank === 3 ? 'bronze' : 'black'
    var borderVal = entry.period === classInformation.period ? 'borderBottom' : ''
    var textVal = entry.period === classInformation.period ? 'textBottom' : ''

    return (
        <tr className={`${''}`}>
            <td className=""  style={{paddingLeft:"0"}}>
                    <div className={`badge rounded-pill bg-${bgColor} text-white ${borderVal}`} >{ordinal_suffix_of(rank)}
                    </div>
            </td>
            <td className="" style={{paddingLeft:"0"}}>
                <div className={`${textVal}`}>
                    {/* {entry.period}  */}
                    {`${ordinal_suffix_of(entry.period.split(' ')[1])} Period`}
                </div>
            </td>
            <td className={`${textVal}`} style={{paddingLeft:"0"}}>
                <div>
                    {entry.roundTenth}
                </div>
            </td>
        </tr>
    )
}

function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}