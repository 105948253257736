import React, { useState } from "react";
import Slider from "rc-slider";
import { Button, Row, Col } from "react-bootstrap";
import { SchoolCycleLookFor } from "../../../types/school";
import he from 'he'; // Install using: npm install he

import "rc-slider/assets/index.css"; // Import styles

const rubricGradient = `
  linear-gradient(to right,
    #f8b89c 0%, #f8b89c 6%, 
    #f8b89c 6%, #ffe599 25%, 
    #ffe599 25%, #ffe599 35%,
    #ffe599 35%, #b6d7a8 60%, 
    #b6d7a8 60%, #b6d7a8 70%, 
    #b6d7a8 70%, #274e13 94%, 
    #274e13 94%
  )
`;

// const title = "Key Questions"
// const description = "Ask 1-3 Key Questions"
// const studentFacing = "🍎"

interface ScoreEntryProps {
  lookForID: string;
  lookForIndex: number;
  teacherIDs: string[];
  chosenBy: string[];
  scores: {
    date?: string;
    score?: number;
    staffID?: string;
    staffScoredID?: string[];
  }[];
  active?: boolean;
  topic: string;
  description: string;
  studentFacing: boolean;
  rubric: {
    score: number;
    category: string;
    content: string;
    hex: string;
  }[];
  staff: string[];
}

const ScoreEntry: React.FC<ScoreEntryProps> = (props) => {

  const {
    lookForID,
    lookForIndex,
    teacherIDs,
    chosenBy,
    scores,
    active,
    topic,
    description,
    studentFacing,
    rubric, 
    staff
  } = props;
  const [score, setScore] = useState<number>(2.5);

  console.log(props, 'props')
  const rubricLabels = (rubric || []).map((item, index) => ({
    id: index + 1,
    title: item.category, // Dynamic category from rubric
    description: item.content, // Dynamic content from rubric
    left: ["10%", "35%", "60%", "90%"][index] || "50%", // Keep hardcoded positioning
  }));

  const chosenByList = [];
  chosenBy?.forEach((id) => {
    if (id === "Coach" || id === "Admin") {
      chosenByList.push(id);
    } else {
      const nameFind = staff?.find((s) => s.staffID === id)?.name || `Unknown (${id})`;
      chosenByList.push(nameFind);
    }
  });


  return (
    <div className="mb-0 m-2 rounded pt-2 pb-0  p-3 w-100 d-flex flex-column bg-white">
    <Row className="text-center d-flex align-items-stretch p-2">
       <Col md={1} className="rounded p-0 m-0 d-flex">
        <div className={`mb-0 m-2 rounded pt-2 pb-0 p-3 w-100 d-flex flex-column justify-content-center text-center text-white bg-danger border border border-4 border-danger`}>
          <p className="fw-bold h1">#{lookForIndex}</p>
        </div>
       </Col>
       <Col md={10} className="rounded p-0 m-0 d-flex">
        <div className="mb-0 m-2 rounded pt-2 pb-0 p-3 w-100 d-flex flex-column justify-content-between text-start bg-secondary text-white">
          <span className="h2 fw-bold">{studentFacing ? "✏️" : "🍎"} {topic}</span>
          <div className="flex-grow-1 mb-1">
            <div className="flex-grow-1 mb-2 h5 mt-1" dangerouslySetInnerHTML={{ __html: he.decode(description) }} />
          </div>
        </div>


       </Col>
       <Col md={1} className="rounded p-0 m-0 d-flex">
        <div className={`mb-0 m-2 rounded pt-2 pb-0 p-3 w-100 d-flex flex-column justify-content-center text-center bg-light border border border-4 border-secondary`}>
          <p className="fw-bold">
            <span className="h2">{chosenByList.includes("Coach") ? "📝 " : chosenByList.includes("Admin") ? "🎓 " : "🍎 "}</span>
            <span className="h6">{chosenByList.join(", ")}</span>
          </p>
        </div>
       </Col>
    </Row>
    

    <div className="mt-4" style={{ width: "100%", marginBottom: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>

      {/* Rubric Labels (Titles + Descriptions) */}
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "12px",
          fontWeight: "bold",
          width: "100%",
        }}
      >
        {rubricLabels.map((label, index) => {
          const backgroundColors = ["#f8b89c", "#ffe599", "#b6d7a8", "#274e13"];
          const textColor = index === 3 ? "white" : "black";
          const decodedDescription = he.decode(label.description);

          const cleanedDescription = he
            .decode(label.description)
            .replace(/style\s*=\s*(['"])[^'"]*color:[^'"]*\1/g, ""); // Remove inline color styles
          return (
            <div
              key={label.id}
              style={{
                flex: "1",
                padding: "5px",
                borderRadius: "5px",
                backgroundColor: backgroundColors[index], // Match solid section
                color: textColor,
                maxWidth: "25%", // Ensures equal space for each label
              }}
              className="m-2 p-2"
            >
              <div className="h4 fw-bold">{label.title}</div>
              <div
                className={`flex-grow-1 mb-2 h5 mt-1 ${index === 3 ? "text-white" : "text-black"}`}
                style={{ color: index === 3 ? "white" : "black" }} 
              >
                <span style={{ color: "inherit" }} dangerouslySetInnerHTML={{ __html: cleanedDescription }} />
              </div>
              {/* <div style={{ fontSize: "16px", fontWeight: "normal", marginTop: "2px" }}>{label.description}</div> */}
            </div>
          );
        })}
      </div>

      {/* Score Positions (1, 2, 3, 4) */}
      <div
        style={{
          position: "relative",
          width: "100%", // Match slider width
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)", // Ensure even spacing
          textAlign: "center",
          marginTop: "10px",
        }}
      >
        {["1", "2", "3", "4"].map((num, index) => (
          <div
            key={num}
            className="h3"
            style={{
              // fontSize: "12px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            {num}
          </div>
        ))}
      </div>

      {/* Gradient Background Bar */}
      <div
        style={{
          width: "75%", // Make gradient bar match slider width
          height: "30px",
          background: rubricGradient,
          borderRadius: "5px",
          marginTop: "5px",
        }}
      />

      {/* Responsive Slider */}
      <Slider
        min={1}
        max={4}
        step={0.1}
        value={score}
        onChange={(value) => setScore(value as number)}
        trackStyle={{ background: "none" }} // Transparent track
        railStyle={{ background: "none" }} // Transparent rail
        handleStyle={{
          borderColor: "black",
          height: "20px",
          width: "20px",
          backgroundColor: "white",
        }}
        style={{ width: "75%", marginTop: "-23px" }} // Make slider match gradient bar width
      />

      <h4 className="mt-4 h3">Score: <strong>{score}</strong></h4>
    </div>
    </div>
  );
};

export default ScoreEntry;
