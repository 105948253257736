import React, { useState, useEffect, useContext } from 'react';

import { SiteContext, baseUrl } from '../../../App.tsx';
import { AdminContext } from '../../../pages/AdminContainer';

import DataEntry from './DataEntry'

export default function DataList({searchField, periodField, filteredPasses}) {

    const {studentList} = useContext(SiteContext)
    const {calcHallPassMath} = useContext(AdminContext)

    const filteredStudent = studentList.filter(
        person => {
        //   if(periodField.length !== 0 && person.period !== periodField) {return}
          return (
            person
            .name
            .toLowerCase()
            .includes(searchField.toLowerCase()) 
            &&
            person
            .period
            .toLowerCase()
            .includes(periodField.toLowerCase())
          );
        }
    );

    const hpStudentList = []
    filteredStudent.map(student => {
        if(student.period === 'Dropped') return
        var studentPasses = filteredPasses.filter(entry => entry.studentId === student.studentId)
        
        var fortNight = 12096e5/2
        // console.log(studentPasses,'studentPasses')
        const {over, under, total, totalComplete, totalDifference, totalString, averagePass, totalCount} = calcHallPassMath(studentPasses, Date.now()-(200*fortNight))

        // console.log(totalCount ? totalCount : 0,'totalCount')
        const newStudent = {...student, over, under, total, totalComplete, totalDifference, totalString, averagePass, totalCount: totalCount ? totalCount : 0, hallPasses: studentPasses}
        hpStudentList.push(newStudent)

    })

    hpStudentList.sort( compare );

    function compare( a, b ) {
        if ( a.totalCount > b.totalCount ){
          return -1;
        }
        if ( a.totalCount < b.totalCount ){
          return 1;
        }
        return 0;
    }

    const filtered = hpStudentList.map( student =>  {
        if(student.period === 'Dropped') return
        // console.log(filteredPasses, 'filteredPasses')
        // var studentPasses = filteredPasses.filter(entry => entry.studentId === student.studentId)
        return <DataEntry key={student._id} student={student} />
    }); 

    return (
        <div>
          <table className="align-middle text-truncate mb-0 table table-borderless table-hover">
            <thead>
              <tr>
                <th className="">Name</th>
                <th className="">Period</th>
                <th className="">Total Passes</th>
                <th className="">Average Pass</th>
                <th className="">Total Time</th>
                <th className="">Past Two Weeks & More</th>
                {/* <th className="">Passes Under</th> */}
              </tr>
            </thead>
            <tbody>
              {hpStudentList.length === 0 ? <span className="mt-3">No Matches</span> : filtered}
            </tbody>
          </table>
        </div>
    );
}
