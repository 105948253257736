import React, {useContext} from 'react'
import { SiteContext } from '../../App.tsx'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import ColorTitle from '../../other/utils/ColorTitle'
import useStopwatch from '../../other/utils/useStopwatch';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleUp, faCircleDown, faStore, faGlassWater, faRightLeft, faLeftRight, faMagnifyingGlass, faClock } from '@fortawesome/free-solid-svg-icons';

export default function FocusCategories({showStopwatch}) {

    const {
        classInformation, focusAgreements,
        socket,
        studentList,
        loginState, initialLoad,
        studentDataFunctions: {
            setStudentList, updateFocus, updateFocusSelection,
            checkToday, updateMongo, createMongo
        }
    } = useContext(SiteContext)

    function handleFocusAgreement(e, entry) {
        e.preventDefault()
        // console.log(entry,'entry')
        var selected = entry.value === focusAgreements.focusCategory ? false : entry
        console.log(selected,'selected')
        updateFocusSelection(selected)
    }

    var focusOptions = []

    if(!classInformation || !classInformation.agreements) return
    
    classInformation.agreements.map(entry => {
      focusOptions.push({
        value: entry.category, isSelected: focusAgreements.focusCategory === entry.category ? true : false, label: entry.category, entry
      })
    })

    const index = classInformation.agreements.findIndex(entry => entry.category === focusAgreements.focusCategory)
    const focusAgreementList = index === -1 ? false : classInformation.agreements[index].elements

    return (
      <div className="col-12">  
        <div className="main-card mb-3 card">
          <div className="card-header d-flex justify-content-between">
            <ColorTitle icon={faMagnifyingGlass} text={'Focus Agreement'} variant={'black'} />
            {showStopwatch && <StopwatchContainer/>}
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div className="" style={{maxWidth: showStopwatch ? '500px' : ''}}>
                {focusOptions.map(entry => {
                  if(entry.isSelected) return <AgreementsTooltip key={entry.value} entry={entry} focusAgreementList={focusAgreementList} handleFocusAgreement={handleFocusAgreement} />
                  return <div key={entry.value} className={`badge bg-${entry.isSelected ? 'secondary' : 'light'} m-1`} onClick={e => handleFocusAgreement(e, entry)} role="button">{entry.value}</div>
                })}
                {/* <CustomSelect focusOptions={focusOptions} updateFocus={updateFocus} updateFocusSelection={updateFocusSelection} /> */}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    )
}

function AgreementsTooltip({entry, focusAgreementList, handleFocusAgreement}) {

    const {focusAgreements} = useContext(SiteContext)
  
    function ListElement({}) {
  
      var count = 0
      return (
      // <div className="main-card mb-3 card">
        <div className="d-flex w-100 flex-wrap">
            {/* <span className={`badge bg-secondary m-1`}>{focusAgreements.focusCategory}</span> */}
            {focusAgreementList.map(entry => {
                count++
                return (
                  <div key={entry.id} className={`listBadge badge bg-light m-1 text-wrap w-100 d-flex justify-items-start align-items-center`}>
                    <div className="me-2 badge bg-black p-1" style={{fontSize:"12px"}}>{count}</div>
                    <div className="">{entry.entry}</div>
                  </div>)
            })}
        </div>
      // </div>
      )
    }
    return (
      <OverlayTrigger
        overlay={<Tooltip className="mytooltip"><ListElement/></Tooltip>}
        placement="bottom"
        delay={500}
        delayShow={1000}
        delayHide={1000}
      >
        <div key={entry.value} className={`badge bg-${entry.isSelected ? 'black' : 'light'} m-1`} onClick={e => handleFocusAgreement(e, entry)} role="button">{entry.value}</div>
      </OverlayTrigger>
    );
}

function StopwatchContainer({}) {

    const {classInformation} = useContext(SiteContext)
    // const {classStart, classEnd} = calculateTime(classInformation.dayType, classInformation.classStartNormal, classInformation.classStartMinimum)
  
    const stopwatchTime = useStopwatch('Time Remaining', classInformation.classStart, classInformation.classEnd)
    var minutes = stopwatchTime.split(':') ? stopwatchTime.split(':')[0] : false
    var bgColor = minutes && minutes < 1 ? 'bg-danger' : 
                             minutes < 5 ? 'bg-flag' : 
                             minutes < 10 ? 'bg-warning' :
                             stopwatchTime === 'Complete' ? 'bg-success' : 'bg-primary text-white'
    return (
      
      <div className={`badge p-2 ${bgColor} text-white d-flex align-items-center text-wrap`}>
          <p className="m-1 d-inline ">{stopwatchTime}{minutes > -1 ? ' Remaining' : ''}</p>
          <FontAwesomeIcon className="m-0 ms-1 me-1 card-title d-inline text-white" icon={faClock} />
      </div>
    )
  }