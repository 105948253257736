import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../../App.tsx'
import { AdminContext } from '../../../pages/AdminContainer'
import { ErrorBoundary } from '../../../other/utils/ErrorBoundary'

import CompleteEvent from './CompleteEvent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faRotate, faRightLeft, faRightFromBracket, faX, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

// import './d_behavior.css'

export default function ChecklistEntry({item, variant, exportFunctions}) {

    const { 
        classInformation, socket, room, focusAgreements, student, classInformationArray, studentList, periodBg,
        studentDataFunctions: {moduleFind, updateMongo, handleUpdateCheckout, setFocusAgreements, getMongoByUniqueId}
    } = useContext(SiteContext)

    const { checkoutItems, setCheckoutItems, handleDate } = useContext(AdminContext)

    const {handleShow, handleClose, activeEntry, setActiveEntry, updating, setUpdating, lastUpdate, 
        setLastUpdate, localFocusAgreements, setLocalFocusAgreements} = exportFunctions
    // console.log(item,'item---')

    // const [lastUpdate, setLastUpdate] = useState(false)
    // const [updating, setUpdating] = useState(false)
    // const [localFocusAgreements, setLocalFocusAgreements] = useState(false)

    // useEffect(() => {

    //     let interval;
    //     interval = setInterval(async () => {
    //         // console.log(lastUpdate,'lastUpdate',localFocusAgreements,'localFocusAgreements')
    //         if(!lastUpdate) return
    //         if(!localFocusAgreements || !localFocusAgreements.modules || localFocusAgreements.modules.length === 0) return
    //         if(Date.now() - lastUpdate < 5000) return
    //         setUpdating(true)
    //         await updateMongo({modules: [...localFocusAgreements.modules]}, localFocusAgreements._id, 'focusAgreements')
    //         setUpdating(false)
    //         setLastUpdate(false)
    //         setFocusAgreements(prev => {return {...prev, modules: localFocusAgreements.modules}})
    //         console.log('updated 1')
    //     }, 5000);
    //     return () => clearInterval(interval)
  
    // }, [localFocusAgreements, lastUpdate])
    // const [lastUpdate, setLastUpdate] = useState(false)
    // const [localFocusAgreements, setLocalFocusAgreements] = useState({...focusAgreements})
 
    async function updateChecklist(e, itemId, approved, setPending) {

        try{
        e.preventDefault()
        if(updating) return
        const preservedEvent = item.completeEvent
        const {output: initialOutput} = updateChecklistActions(e, itemId, approved, setPending, {...focusAgreements}, true, preservedEvent)
            setFocusAgreements(prev => {return {...prev, ...initialOutput}})
        console.log(lastUpdate, (Date.now() - lastUpdate < 5000), 'booleans')
        const mongoEntry = (lastUpdate && (Date.now() - lastUpdate < 5000) || updating) ? localFocusAgreements : await getMongoByUniqueId(classInformation.focusAgreementId, 'focusAgreements')
        // const mongoEntry = await getMongoByUniqueId(classInformation.focusAgreementId, 'focusAgreements')
        // setLastUpdate(Date.now())
        const {output: finalOutput, oneModule} = await updateChecklistActions(e, itemId, approved, setPending, mongoEntry, false, preservedEvent)
            if(!finalOutput || !oneModule) {
                console.log('returned during updateChecklistActions ---')
                return
            }
            // await updateMongo({modules: [...finalOutput.modules]}, focusAgreements._id, 'focusAgreements')
            console.log('mongo updated ---')
            console.log('focusAgreements updated - adashentry')
            socket.emit("send_updateProgressChecklist", { oneModule: {...oneModule}, room: classInformation.period });
            setLocalFocusAgreements(prev => {return {...prev, ...finalOutput}})
            setLastUpdate(Date.now())
            // setFocusAgreements(prev => {return {...prev, ...finalOutput}})

        } catch(error) {
            console.error(error);
        }
    }

    async function updateChecklistActions(e, itemId, approved, setPending, editEntry, initial, preservedEvent) {
        e.preventDefault()

        // const editEntry = await getMongoByUniqueId(classInformation.focusAgreementId, 'focusAgreements')
        // var editEntry = {...focusAgreements}

        var index0 = studentList.findIndex(entry => entry.studentId === preservedEvent.studentId)
        // console.log('1', item, item.completeEvent, preservedEvent)
        console.log('1',initial)
        if(index0 === -1) return {oneModule: false, output: false}
        var matchedStudent = studentList[index0]
        // console.log(item,'item', matchedStudent)
        console.log('2',initial)
        var groupNumber = preservedEvent.groupNumber ? preservedEvent.groupNumber : matchedStudent.groupNumber
        var index = editEntry.modules.findIndex(entry => entry.title === item.module)
            if(index === -1) return {oneModule: false, output: false}
            console.log('3',initial)
        var index1 = editEntry.modules[index].checklists.findIndex(entry => entry.groupNumber === groupNumber)
            if(index1 === -1) return {oneModule: false, output: false}
            console.log('4',initial)
            // console.log(editEntry.modules[index],'editEntry.modules[index]')
        var index2 = editEntry.modules[index].checklists[index1].checklist.findIndex(entry => entry.itemId == itemId)

        editEntry.modules[index].checklists[index1].checklist[index2].completeAdmin = approved
        
        if(setPending) {

            editEntry.modules[index].checklists[index1].checklist.map(entry => {
                entry.inProgress = false
            })

            editEntry.modules[index].checklists[index1].checklist[index2].inProgress = true
            editEntry.modules[index].checklists[index1].checklist[index2].complete = false
            editEntry.modules[index].checklists[index1].checklist[index2].completeEvent = false
            editEntry.modules[index].checklists[index1].checklist[index2].completeAdmin = false
            
        } else if(!approved) {
            editEntry.modules[index].checklists[index1].checklist[index2].complete = false
            editEntry.modules[index].checklists[index1].checklist[index2].completeEvent = false
        }

        if(approved && !setPending && initial) {
            studentList.map(entry => {
                // if(entry.period !== classInformation.period) return

                // console.log(item.completeEvent, 'item.completeEvent.matchingStudents.')
                var filter = preservedEvent.matchingStudents.filter(entry2 => entry2 == entry.studentId)
                if(filter.length === 0) return {oneModule: false, output: false}
                console.log(filter, 'filter', preservedEvent.matchingStudents)

                if(entry.moduleCompletions) {
                    entry.moduleCompletions = entry.moduleCompletions.filter(item2 => item2.itemId !== item.itemId)
                }
                
                entry.moduleCompletions = entry.moduleCompletions && typeof entry.moduleCompletions === 'object' ? [...entry.moduleCompletions, item] : [item]
                console.log(entry.moduleCompletions,'entry.moduleCompletions')

                updateMongo({moduleCompletions: entry.moduleCompletions}, entry.dailyListId, 'dailyList')
            })
        }

        return {oneModule: editEntry.modules[index], output: editEntry}
        // updateMongo({modules: [...editEntry.modules]}, focusAgreements._id, 'focusAgreements')
        // socket.emit("send_updateProgressChecklist", { oneModule: {...editEntry.modules[index]}, room: classInformation.period });
        // setFocusAgreements(prev => {return {...prev, ...editEntry}})
    }

    if(item.completeEvent.period !== classInformation.period) return

    // console.log(periodBg,'periodBg')
    var indexBg = periodBg.findIndex(entry => entry.period === item.completeEvent.period)
    if(indexBg === -1) return
        var bg = periodBg[indexBg].bg

    var timeStamp = new Date(item.timeStamp)
        var dayEntry = timeStamp.getDate().toString()
        var monthEntry = (timeStamp.getMonth() + 1).toString()
        var weekDayEntry = timeStamp.getDay().toString()
            const weekDays = ['Sunday','Mon','Tue','Wed','Thu','Fri', 'Sat']
            const weekDay = weekDays[weekDayEntry]

    var typeBg = item.type === 'Test' ? 'bg-black' :
                 item.type === 'Activity' ? 'bg-purple' :
                 item.type === 'Design Brief' ? 'bg-primary' : 'bg-secondary'

    var today = new Date()
    var timestampDate = new Date(item.completeEvent.date)
    // console.log(today.getDate(), 'today.getDate()', timestampDate.getDate(), 'timestampDate.getDate()')
    var dateString = (today.getMonth() === timestampDate.getMonth() && today.getDate() === timestampDate.getDate()) ? 
        `Today ${prettyDate(item.completeEvent.date, false)}` : prettyDate(item.completeEvent.date, true)

    function updateFocusAg(e) {
        e.preventDefault()
        classInformationArray.map(entry => {
            updateMongo({period: entry.period}, entry.focusAgreementId, 'focusAgreements')
        })
    }

    function handleLocalActiveEvent() {
        setActiveEntry(item)
        handleShow()
    }

    async function handleDateLocal(e, changeVal) {
        e.preventDefault()
        const importEntry = (lastUpdate && (Date.now() - lastUpdate < 5000) || updating) ? localFocusAgreements : await getMongoByUniqueId(classInformation.focusAgreementId, 'focusAgreements')
        const newEntry = await handleDate(e, changeVal, classInformation.period, true, item, false, false, importEntry)
        console.log(newEntry,'newEntry')
        setLocalFocusAgreements(prev => {return {...prev, ...newEntry}})
        setLastUpdate(Date.now())
        setFocusAgreements(prev => {return {...prev, ...newEntry}})
    }

    return (
        <ErrorBoundary>
                <tr>
                    <td className="" style={{verticalAlign:"top"}}>
                      <div className="d-flex justify-content-start">
                        {/* {dateString.includes('Today') ?  */}
                        <div>
                            <div className="d-flex">
                                <div className={`badge bg-${dateString.includes('Today') ? typeBg.split('-')[1] : 'secondary'} d-flex align-items-center pt-0 pb-0`}>{dateString}</div>
                                <div className="d-flex align-items-center me-2 ms-1">
                                    {/* <div className="badge bg-secondary me-1">{prettyDate(eventLocal.date)}</div> */}
                                    <FontAwesomeIcon icon={faArrowUp} onClick={e => handleDateLocal(e, 1)} className="fa-lg m-1" role="button" />
                                    <FontAwesomeIcon icon={faArrowDown} onClick={e => handleDateLocal(e, -1)} className="fa-lg m-1" role="button" />
                                </div>
                            </div>
                            <div className="text-wrap">{item.module}</div>
                        </div>
                      </div>
                    </td>
                    <td className="text-center" style={{verticalAlign:"top"}}>
                        <span className="text">
                            {item.completeEvent.name.substring(0, 15)}
                        </span>
                    </td>
                    {/* <td className="text-center">
                        <span className="text">
                            
                        </span>
                    </td> */}
                    <td className="" style={{verticalAlign:"top"}}>
                        <div onClick={handleLocalActiveEvent} className={`me-2 badge ${typeBg ? typeBg : 'bg-secondary'} word-wrap`} role="button">{item.day}: {item.type}</div>
                        {/* <CompleteEvent event={item.completeEvent} item={item} typeBg={typeBg} /> */}
                        <span className="text">
                            {item.item}
                            {/* {item.item.substring(0, 30)} */}
                        </span>    
                        {item.action && item.action.length > 0 && <span className="text fst-italic fw-lighter ms-1">
                            - {item.action}
                        </span>}  
                    </td>
                    <td className="text-center" style={{verticalAlign:"top"}}
                    // style={{width:"90px"}}
                    >
                        {item.complete && !item.completeAdmin && 
                        <div role="group" className="btn-group-sm btn-group"
                        // style={{minWidth:"100px", maxWidth:"100px"}}
                        >
                            {updating ? 
                            // <></> :
                            <button className={`btn btn-light text-${variant} fw-bold`} disabled>Reloading...</button> : 
                            <><button 
                            value="approved"
                            onClick={e => updateChecklist(e, item.itemId, true)} 
                            className={`btn-shadow btn btn-${variant} text-white`}>
                            <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button 
                            value="approved"
                            onClick={e => updateChecklist(e, item.itemId, true, true)} 
                            className={`btn-shadow btn btn-light text-purple`}>
                            <FontAwesomeIcon icon={faRotate} />
                            </button>
                            <button 
                            value="approved"
                            onClick={e => {
                                // handleUpdateCheckout(item, {}, true)
                                updateChecklist(e, item.itemId, false)
                            }} 
                            className={`btn-shadow btn btn-secondary text-white`}>
                            <FontAwesomeIcon icon={faXmark} />
                            </button>
                            </>}
                        </div>}
                        {item.complete && item.completeAdmin && 
                        <div role="group" className="btn-group-sm btn-group d-flex justify-content-center"
                        // style={{minWidth:"70px", maxWidth:"90px"}}
                        >
                            <div className={`badge bg-light text-${typeBg.split('-')[1]} p-2`}
                            // style={{fontSize:"16px"}}
                            >
                            <FontAwesomeIcon icon={faCheck}/>
                            </div>
                        </div>}
                        {/* {!item.complete && <div role="group" className="btn-group-sm btn-group">
                            <FontAwesomeIcon style={{ marginRight: 5}} icon={faRightLeft} />
                        </div>}
                        {!item.checkedOut && !item.complete && <div role="group" className="btn-group-sm btn-group">
                            <FontAwesomeIcon style={{ marginRight: 5}} icon={faX} />
                        </div>} */}
                    </td>
                </tr>
                </ErrorBoundary>
    )

}




function prettyDate(dateInput, fullString) {
    var date = new Date(dateInput)
    // console.log(date, 'inside---')
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var hours = ((date.getHours() + 11) % 12 + 1);
    var minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    if(fullString) {
        return `${months[date.getMonth()]} ${date.getDate()} ${hours}:${minutes}`
    } else {
        return `${hours}:${minutes}`
    }

}

