import React, { useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { SiteContext } from '../../App.tsx';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './dailyEvent.css'

export default function ChipsTooltip({addChips}) {

    const { 
        classInformation, focusAgreements,
        studentDataFunctions: {}
     } = useContext(SiteContext)

    function handleClick(e) {
        e.preventDefault()
    }

    const chipsPeriod = focusAgreements.chipsDaily
    // addChips.filter(entry => entry.period === classInformation.period)[0]
    if(!chipsPeriod || chipsPeriod.length === 0) return

    return (
        
        <OverlayTrigger
            placement="right"
            // trigger={triggerVal}
            // onClick={(e) => handleClick(e)}
            style={{width:"300px"}}
            overlay= {
                <Tooltip className="dailytooltip" >
                    <div>
                        <span className={`badge rounded-pill bg-${chipsPeriod[0].variant} dailyPill`}>{chipsPeriod[0].timeStamp}</span>
                        <span className={`badge rounded-pill bg-${chipsPeriod[1].variant} dailyPill`}>{chipsPeriod[1].timeStamp}</span>
                        <span className={`badge rounded-pill bg-${chipsPeriod[2].variant} dailyPill`}>{chipsPeriod[2].timeStamp}</span>
                        <span className={`badge rounded-pill bg-secondary dailyPill`}>{classInformation.period}</span>
                    </div>
                </Tooltip>
            }
        >   
            <span>Add Chips</span>
            {/* <button
                type="button"
                style={{color:"white", fontSize:"10px"}}
                className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-${variant} active`}
                onClick={(e) => e.preventDefault()}
            >
                {title}
            </button> */}
        </OverlayTrigger>         
    )
}
