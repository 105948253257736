import { baseUrl } from "../App.tsx";
import axios from "axios";
import { School, SchoolCycle } from "./school";  // ✅ Import School type

// ✅ Fetch Schools
export const fetchSchools = async () => {
    try {
        const response = await axios.get<School[]>(`${baseUrl}/api/schools`);
        return response.data;
    } catch (error) {
        console.error("Error fetching schools:", error);
        return [];
    }
};

// ✅ Handle District Change
export const handleDistrictChange = (selectedDistrict: string, setNewSchool: Function) => {
    console.log(selectedDistrict, 'selectedDistrict');

    setNewSchool((prev: School) => ({
        ...prev,
        metadata: {
            ...prev.metadata,
            districtName: selectedDistrict,
        }
    }));
};

// // ✅ Handle Input Change
// export const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, setNewSchool: Function) => {
//     const { name, value } = e.target;

//     setNewSchool((prevState: School) => {
//         if (["schoolNickname", "address"].includes(name)) {
//             return {
//                 ...prevState,
//                 metadata: {
//                     ...prevState.metadata,
//                     [name]: value,
//                 },
//             };
//         }
//         return {
//             ...prevState,
//             [name]: value,
//         };
//     });
// };

// ✅ Add New School
export const addSchool = async (newSchool: Partial<School>, fetchSchools: Function) => {
    try {
        console.log("📤 Sending school data:", JSON.stringify(newSchool, null, 2));

        const cleanedSchool = { ...newSchool };
        delete cleanedSchool._id;  // 🔹 Ensure MongoDB generates `_id`

        const response = await axios.post(`${baseUrl}/api/schools`, cleanedSchool);
        console.log("✅ School added successfully:", response.data);

        fetchSchools(); // Refresh school list after adding
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.error("❌ Axios Error:", error.message);
            if (error.response) {
                console.error("🔍 Response Data:", error.response.data);
                console.error("🔍 Response Status:", error.response.status);
                console.error("🔍 Response Headers:", error.response.headers);
            } else if (error.request) {
                console.error("📡 No Response Received:", error.request);
            } else {
                console.error("💥 Request Setup Error:", error.message);
            }
        } else {
            console.error("⚠️ Non-Axios Error:", error);
        }
    }
};


export const fetchAllowedRoles = async () => {
    const response = await fetch(`${baseUrl}/api/enums/allowedRoles`);
    const data = await response.json();
    return data;
};

export const fetchAllowedSpecialGroups = async () => {
    const response = await fetch(`${baseUrl}/api/enums/allowedSpecialGroups`);
    const data = await response.json();
    return data;
};

export const fetchAllowedSubjects = async () => {
    const response = await fetch(`${baseUrl}/api/enums/allowedSubjects`);
    const data = await response.json();
    return data;
};

export const fetchAllowedGrades = async () => {
    const response = await fetch(`${baseUrl}/api/enums/allowedGrades`);
    const data = await response.json();
    return data;
};

export const fetchHexCodes = async () => {
    const response = await fetch(`${baseUrl}/api/enums/hexCodes`);
    const data = await response.json();
    return data;
};




export const fetchLookFors = async () => {
    try {
        // console.log('before------')
        const response = await axios.get(`${baseUrl}/api/lookFors`);
        // console.log(response.data,'response.data')
        return response.data;
    } catch (error) {
        console.error("Error fetching LookFors:", error);
        return [];
    }
};

export const createLookFor = async (lookFor) => {
    try {
        const response = await axios.post(`${baseUrl}/api/lookFors`, lookFor);
        return response.data;
    } catch (error) {
        console.error("Error creating LookFor:", error);
    }
};

export const updateLookFor = async (id, lookFor) => {
    try {
        const response = await axios.put(`${baseUrl}/api/lookFors/${id}`, lookFor);
        return response.data;
    } catch (error) {
        console.error("Error updating LookFor:", error);
    }
};

export const deleteLookFor = async (id) => {
    try {
        await axios.delete(`${baseUrl}/api/lookFors/${id}`);
    } catch (error) {
        console.error("Error deleting LookFor:", error);
    }
};


export const createCycle = async (schoolId: string, cycleData: Partial<SchoolCycle>) => {
  try {
    console.log('here------', schoolId,'schoolId')
    const response = await axios.post(`/api/cycles/${schoolId}`, cycleData);
    return response.data;
  } catch (error) {
    console.error("API Error: Failed to create cycle", error);
    throw error;
  }
};
