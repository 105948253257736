import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import SchoolLookFors from "./SchoolLookFors.tsx";
import PairLookFors from "./PairLookFors.tsx";
import { LookFors, LookForRubric } from "../../../types/lookFors.ts"; // ✅ Import the TypeScript type
import { SchoolCycle } from "../../../types/school";
import { createCycle, fetchLookFors } from "../../../types/api.tsx"; // Ensure this API function exists

interface CyclesProps {
  schoolId: string;
  cycles: SchoolCycle[];
  setCycles: React.Dispatch<React.SetStateAction<SchoolCycle[]>>;
}

const Cycles: React.FC<CyclesProps> = ({ schoolId, cycles, setCycles, staff }) => {
  const [loading, setLoading] = useState(false);
  const [lookFors, setLookFors] = useState<LookFors[]>([]);

      useEffect(() => {
        const getLookFors = async () => {
          const data = await fetchLookFors();
          // console.log(data, 'data------')
          setLookFors(data);
        };
    
        getLookFors();
      }, []);
  // ✅ Handle Create New Cycle
  const handleCreateCycle = async () => {
    setLoading(true);
    try {
      const newCycle: Partial<SchoolCycle> = {
        cycleNum: cycles.length + 1, // Auto-increment
        ipgIndicator: "",
        actionPlanURL: "",
        lookFors: [],
      };

      const addedCycle = await createCycle(schoolId, newCycle);
      if (addedCycle) {
        setCycles((prev) => [...prev, addedCycle]);
      }
    } catch (error) {
      console.error("Error creating cycle:", error);
    } finally {
      setLoading(false);
    }
};

function DisplayLookFors(entryImport) {
    const entry = entryImport.entryImport
    console.log(entry,'entry.lookFors',entryImport)
        return (
            <>
            {entry.lookFors && entry.lookFors.length > 0 && lookFors.length > 0 ? (
                <div className="mt-3">
                    <h5 className="fw-bold">🔍 Look Fors:</h5>
                    {entry.lookFors.map((lookForEntry, index) => {
                        
                        const matchedLookFor = lookFors.find(lf => lf._id === lookForEntry.lookForID);
                        const matchedList = []
                        lookForEntry.teacherIDs.map(id => {
                                var nameFind = staff.find(s => s.staffID === id)?.name || `Unknown (${id})`;
                                matchedList.push(nameFind);
                        })

                        const chosenByList = []
                        lookForEntry.chosenBy.map(id => {
                                if (id === "Coach" || id === "Admin") {
                                    chosenByList.push(id);
                                } else {
                                    var nameFind = staff.find(s => s.staffID === id)?.name || `Unknown (${id})`;
                                    chosenByList.push(nameFind);
                                }
                            })

                        return (
                            <div key={index} className="border rounded p-2 mb-2 bg-light">
                                {/* LookFor Content */}
                                {matchedLookFor ? (
                                    <div className="p-2 m-1">
                                    <Row className="text-center d-flex align-items-stretch">
                                        <Col md={1} className="rounded p-0 m-0 d-flex">
                                            <div className={`m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-center text-center bg-opacity-50 bg-${matchedLookFor.studentFacing ? "danger" : "warning"}`}>
                                            <h2 className="fw-bold">{matchedLookFor.studentFacing ? "🍎" : "✏️"}{index+1}</h2>
                                            </div>
                                        </Col>     
                                        <Col md={10} className="rounded p-0 m-0 d-flex">
                                            <div className="m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-between text-start bg-secondary text-white">
                                                <div className="">
                                                    <span className="h4 fw-bold">{matchedLookFor.topic}</span>
                                                    <span className="ms-4 h5">
                                                        {chosenByList.includes("Coach") ? "📝 " : chosenByList.includes("Admin") ? "🎓 " : "🍎 "} Metric
                                                    </span>
                                                </div>                                                    
                                                <div className="flex-grow-1 mb-1">
                                                    <div className="flex-grow-1 mb-1" dangerouslySetInnerHTML={{ __html: matchedLookFor.description }} />
                                                </div>
                                            </div>
                                        </Col>   
                                        <Col md={1} className="rounded p-0 m-0 d-flex">
                                            <div className={`m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-center text-center bg-primary`}>
                                            <h4 className="fw-bold text-white">Edit</h4>
                                            </div>
                                        </Col>                  
                                    </Row>
                                    </div>
                                ) : (
                                    <p className="text-muted">Look For not found</p>
                                )}
                                <div className="p-2 pt-0 m-1"> 
                                <Row className="text-center d-flex align-items-stretch">
                                    <Col md={6} className="rounded p-0 m-0 d-flex">
                                      <div className={`m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-start text-start bg-white`}>
                                        {/* Teacher Names */}
                                        <p className="mb-1">
                                            <strong>{matchedList.length > 1 ? 'Teachers' : 'Teacher'} Using Look For:</strong> 
                                            {matchedList.map(item => {return <div>{item}</div>})}
                                        </p>
            
                                        {/* Chosen By */}
                                        <p className="mb-1">
                                            <strong>
                                                {chosenByList.includes("Coach") && "📝 "} 
                                                {chosenByList.includes("Admin") && "🎓 "}
                                                {!chosenByList.includes("Coach") && !chosenByList.includes("Admin") && "🍎 "}
                                                Chosen By:
                                            </strong>
                                            <div>

                                            </div>
                                            {chosenByList.map(item => {return <div>{item}</div>})}
                                        </p>
                                        
                                        {/* Active Status */}
                                        <p className="mb-1"><strong>{lookForEntry.active ? "✅ Active" : "⏸️ Disabled"}</strong></p>
                                      </div>
                                    </Col>
                                    <Col md={6} className="rounded p-0 m-0 d-flex">
                                      <div className={`m-1 rounded pt-2 pb-1 p-2 w-100 d-flex flex-column justify-content-start text-start bg-white`}>
                                        <p className="mb-1"><strong>Scores:</strong></p>
                                        {/* Scores */}
                                        {lookForEntry.scores && lookForEntry.scores.length > 0 ? (
                                            <div className="mt-2">
                                                <h6 className="fw-bold">📊 Scores:</h6>
                                                {lookForEntry.scores.map((score, scoreIndex) => (
                                                    <div key={scoreIndex} className="border rounded p-2 mb-1">
                                                        <p className="mb-1"><strong>Date:</strong> {score.date}</p>
                                                        <p className="mb-1"><strong>Score:</strong> {score.score}</p>
                                                        <p className="mb-1"><strong>Staff ID:</strong> {score.staffID}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="text-muted">No scores recorded</p>
                                        )}
                                      </div>
                                    </Col>              
                                </Row>
                                </div>  
                            </div>
                        );
                    })}
                </div>
            ) : (
                <p className="text-muted">No Look Fors assigned yet.</p>
            )}
        </>
    )
}
  return (
    <div className="mt-3 p-3 rounded" style={{backgroundColor:"#e9e9e9"}}>
      <div className="p-2 d-flex justify-content-between align-items-center">
        <h4 className="fw-bold">📆 Coaching Cycle</h4>
        <Button variant="secondary" onClick={handleCreateCycle} disabled={loading}>
          ➕ Create New Cycle
        </Button>
      </div>
      <div className="p-2">
        {cycles.map((entry) => (            
        <div key={entry._id} className="mt-3 p-3 rounded" style={{backgroundColor:"#ffffff"}}>
            <h4 className="fw-bold">Cycle {entry.cycleNum}</h4>
            <div className="card-body">
                <p className="card-text"><strong>IPG Indicator:</strong> {entry.ipgIndicator}</p>
                <p className="card-text"><strong>Action Plan URL:</strong> {entry.actionPlanURL}</p>
                <p className="card-text"><strong># Look Fors:</strong> {entry.lookFors.length}</p>
            </div>
            <SchoolLookFors entry={entry} staff={staff} lookFors={lookFors} schoolId={schoolId} cycleNum={entry.cycleNum} />
            <PairLookFors schoolId={schoolId} cycleNum={entry.cycleNum} staff={staff} />
        </div>
        ))}
      </div>
    </div>
  );
};

export default Cycles;
