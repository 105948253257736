import React, { useContext } from 'react'
import { AdminContext } from '../../../pages/AdminContainer';
import { SiteContext } from '../../../App.tsx';

import CategoryEntries from './CategoryEntries';
import Accordion from 'react-bootstrap/Accordion';

export default function Body({classPeriod}) {

  const { period, classStartNormal, classStartMinimum, agreements, focusSchedule, classRoles } = classPeriod

  const {schedules} = useContext(SiteContext)

  const arrays = []
  const information = []

  const arraysTitles = [
    {code: 'agreements', text: 'Agreements'}, 
    {code: 'classRoles', text: 'Class Roles'}, 
    {code: 'focusSchedule', text: 'Focus Schedule'}, 
    // {code: 'information', text: 'Information'}
  ]

  for (var key in classPeriod) {
    if (!classPeriod.hasOwnProperty(key)) return
    // console.log(key,'key', classPeriod.period)
    if(typeof classPeriod[key] === 'object') {
      var index = arraysTitles.findIndex(textEntry => textEntry.code === key)
      if(index > -1) {
        arrays.push({key: key, value: classPeriod[key], text: arraysTitles[index].text })
      }
      // arrays.push({key: key, value: classPeriod[key], text: arraysTitles[index].text })
    }
    else {information.push({key: key, value: classPeriod[key]})}
  }

  var count = 0
  return (
    <>
    <div className="mb-2">
        <div className={`mb-2 me-2 badge bg-secondary`}>Class Start</div>
        {schedules.map(entry => {
          var index = entry.periods.findIndex(item => item.class === classPeriod.period)
          if(index === -1 || !entry.periods[index].startTime) return
          return <div key={entry.item + entry.periods[index].startTime} className={`mb-2 me-2 badge bg-light`}>{entry.periods[index].startTime} ({entry.item})</div>
        })}
    </div>
    <Accordion> 
        {arrays.map(entry => {
            count++
            return <AccordionEntry key={entry.key} heading={entry} count={count} />
        })}
    </Accordion>
    </>
  )
}

function AccordionEntry({heading, count}) {
  return (
    <Accordion.Item eventKey={count} key={count}>
      <Accordion.Header>{heading.text}</Accordion.Header>
      <Accordion.Body>
      {heading.value.map((category) => {
        return <AccordionEntryBody key={category.id} category={category} arrayCategory={heading.text} />
      })}
      </Accordion.Body>
    </Accordion.Item>
  )
  
}

function AccordionEntryBody({category, arrayCategory}) {
  return (
    <>
      <div className={`mb-2 me-2 badge bg-secondary`}>{category.category}</div>
      <div className="recipe__row" style={{marginBottom:"25px"}}>
      <div className="recipe__value recipe__value--indented2">
        <div className="ingredient-gridClass">
            <CategoryEntries key={category.id} category={category} arrayCategory={arrayCategory} />
        </div> 
      </div>  
      </div>
    </>
  )

}