import React, { useContext, useState, useEffect } from 'react'
import { SiteContext } from '../App.tsx'

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ColorReminder() {

    const {
        classInformation, student, colorMatch,
        studentDataFunctions: {
            setStudent, updateMongo, stringtoJSDate,
        }
    } = useContext(SiteContext)

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const modalTriggers = useModalTriggers()

    function handleCloseLocal() {
      handleClose()

      // updateMongo({reminderRejection: Date.now()}, student.dailyListId, 'dailyList')
      const editStudent = {...student, reminderRejection: Date.now()}
      setStudent(editStudent)
    }

    useEffect(() => {

        const interval = setInterval(async () => {

          var nowMilli = new Date().getTime()

          // console.log(schoolEnd,'schoolEnd', classInformation.dayType, focusAgreements, classInformation)
          var {date,  milli: milliEnd} = stringtoJSDate(classInformation.classEnd)
          var {dateStart, milli: milliStart} = stringtoJSDate(classInformation.classStart)

          if(milliStart > nowMilli || milliEnd < nowMilli || classInformation.dayType === 'No School') return

          let lastElement = student.behaviorEvents.length > 0 ? student.behaviorEvents[student.behaviorEvents.length - 1] : false

          if(!lastElement && !student.reminderRejection) {
            updateMongo({reminderRejection: Date.now()}, student.dailyListId, 'dailyList')

            const editStudent = {...student, reminderRejection: Date.now()}
            setStudent(editStudent)
            return
          }

          if(lastElement && (Date.now() - lastElement.timeStamp > 900000) ) {
            if(student.color.current === 'Black' || student.color.current === 'Gold') return
            if(student.reminderRejection && Date.now() - student.reminderRejection < 900000) return
            handleShow()
          }
         
       }, 4000);
 
       return () => {
          clearInterval(interval);
       };

    }, [student, classInformation]);

    const match = colorMatch.filter(item => item.color === student.color.current)
    const variant = match ? match[0].variant : 'secondary'
    const colorString = match ? match[0].color : false

    return (
        <>
          {/* <div onClick={handleShow} className={`me-2 badge ${typeBg ? typeBg : 'bg-secondary'} word-wrap`} role="button">{item.day}: {item.type}</div> */}
    
          <Modal show={show} onHide={handleCloseLocal}>
            <Modal.Header closeButton>
              <Modal.Title>
                  <span className="fw-bold">Agreements Reminder!</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center p-1">
                 Current Color:
                 <div className={`ms-2 badge bg-${variant}`}>{colorString}</div>
               </div>
               <div className="p-1">
                If you have been following a class agreement, raise your color!
               </div> 
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </Modal>
        </>
    );
}

function useModalTriggers() {

    const {classInformationArray, classInformation} = useContext(SiteContext)

    var modalTriggers = []
    classInformationArray.map(entry => {
        // if(entry.dayType !== 'Normal Day' && entry.dayType !== 'Minimum Day') return
        var classStart = entry.classStart 
        // === 'Normal' ? entry.classStartNormal : entry.classStartMinimum

        if(!classStart) return modalTriggers
        
        var hours = parseInt(classStart.split(':')[0])
        var min = parseInt(classStart.split(':')[1])
            if(hours < 8) {hours = hours + 12}
    
        var classStartDate = new Date()
          classStartDate.setHours(hours, min, 0)
        
          modalTriggers.push(futureTime(classStartDate, 15, entry.period))
          modalTriggers.push(futureTime(classStartDate, 30, entry.period))
          modalTriggers.push(futureTime(classStartDate, 45, entry.period))
        //   if(entry.classLength == 50) {modalTriggers.push(futureTime(classStartDate, 45, entry.period))}            
    })

    // console.log(modalTriggers,'modalTriggers')
    return modalTriggers
}

function futureTime(existingDate, minAdded, period) {
  return {
      date: new Date(existingDate.getTime() + minAdded * 60000), 
      milli: new Date(existingDate.getTime() + minAdded * 60000).getTime(),
      min: minAdded,
      period
  }
}







          // student.behaviorEvents.map(entry => {
          //   if(Date.now() - entry.timeStamp > 900000 && (student.reminderRejection && Date.now() - student.reminderRejection > 900000)) {
          //     showModal = true
          //   }
          // })

          // if(showModal) {
          //   handleShow()
          // }





          // if(student.)
          // console.log('running')
          //   var nowMilli = new Date().getTime()
    
          //   var {date, milli} = stringtoJSDate(classInformation.classEnd)
          //       if(milli < nowMilli || classInformation.dayType === 'No School') return
    
          //   modalTriggers.map(entry => {
          //       if(nowMilli < entry.milli || entry.period !== classInformation.period) return
          //       // if(entry.period !== classInformation.period) return
                
          //       console.log(student,'student')
          //       var index = focusAgreements.chips.findIndex(item => item.timeStamp === entry.min.toString())
          //       // console.log(focusAgreements.chips,'focusAgreements.chips', focusAgreements.chips[index])
          //       if(index === -1 || focusAgreements.chips[index].variant !== 'light') return
    
          //       console.log('showing')
          //       handleShow()
          //   })