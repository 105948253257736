import React, {useContext, useEffect, useState} from 'react'
import { SiteContext } from '../../App.tsx'

export function useModules({}) {

    const {
        focusAgreements, modules, socket, room, classInformation, classInformationArray,
        studentDataFunctions: {setFocusAgreements, updateMongo, getMongoByUniqueId}
    } = useContext(SiteContext)

    function handleModifyModuleGroup(itemId, mode) {
        
        const editModules = [...modules]
        var index = editModules.findIndex(entry => entry.itemId === itemId)

        const editFocusAgreements = {...focusAgreements}
        var index2 = editFocusAgreements.modules.findIndex(entry => entry.itemId === itemId)

        console.log(index, 'index', index2, 'index2')
        if(mode === 'add') {
            var length = editFocusAgreements.modules[index2].checklists.length
            editFocusAgreements.modules[index2].checklists.push({groupNumber: length + 1, checklist: editModules[index].checklist})
        } else if(mode === 'reset') {
            editFocusAgreements.modules[index2].checklists = [{groupNumber: 1, checklist: editModules[index].checklist}]
        }

        setFocusAgreements(editFocusAgreements)
        updateMongo({modules: editFocusAgreements.modules}, editFocusAgreements._id, 'focusAgreements')
        socket.emit("send_updateProgressChecklist", { focusAgreements: editFocusAgreements, admin: true, room });

        console.log(editFocusAgreements.modules[index2].checklists,'editFocusAgreements.modules[index2].checklists')
    }

    async function handleDate(e, dayChange, period, eventsPage, item, setItemLocal, setEventLocal, importEntry) {
        e.preventDefault()
  
        var period = classInformation.period
        var index = classInformationArray.findIndex(entry => entry.period === (period ? period : ''))
        var localLoad = (period && !importEntry) ? await getMongoByUniqueId(classInformationArray[index].focusAgreementId, 'focusAgreements') : false
  
        var editEntry = importEntry ? importEntry : !period ? {...focusAgreements} : {...localLoad}
  
        // var editEntry = {...focusAgreements}
  
        var groupNumber = item.completeEvent.groupNumber
        var index = editEntry.modules.findIndex(entry => entry.title === item.module)
            if(index === -1) return
        var index1 = editEntry.modules[index].checklists.findIndex(entry => entry.groupNumber === groupNumber)
            if(index1 === -1) return
            // console.log(editEntry.modules[index],'editEntry.modules[index]')
        var index2 = editEntry.modules[index].checklists[index1].checklist.findIndex(entry => entry.itemId == item.itemId)
  
        editEntry.modules[index].checklists[index1].checklist[index2].completeEvent.date = editEntry.modules[index].checklists[index1].checklist[index2].completeEvent.date + (dayChange)*86400000
  
        // console.log(editEntry.modules[index].checklists[index1].checklist[index2],'editEntry.modules[index].checklists[index1].checklist[index2]', item, period)
        if(!eventsPage) {
            await updateMongo({modules: [...editEntry.modules]}, editEntry._id, 'focusAgreements')
        }
        
        socket.emit("send_updateProgressChecklist", { oneModule: {...editEntry.modules[index]}, room: period ? period : classInformation.period });

        if((!period || period === classInformation.period) && !eventsPage) {
          setFocusAgreements(prev => {return {...prev, ...editEntry}})
          console.log('setting')
        } 

        if(!eventsPage) {
            setItemLocal({...editEntry.modules[index].checklists[index1].checklist[index2]})
            setEventLocal({...editEntry.modules[index].checklists[index1].checklist[index2].completeEvent})
        }

        if(eventsPage) return editEntry
        // console.log(editEntry.modules[index].checklists[index1].checklist[index2].completeEvent.date,'editEntry.modules[index].checklists[index1].checklist[index2].completeEvent.date')
    }

    return {
        handleModifyModuleGroup, handleDate
    }
}