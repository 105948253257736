import React, { useContext } from 'react'
import { SiteContext } from '../../App.tsx';

export default function Explain({ title, subtitle, colorValue, sizeClass }) {

    const { student } = useContext(SiteContext)

    return (

      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
                {/* <div className="widget-numbers mt-0 fsize-3 text-white"> */}
                  <div className="widget-numbers mt-0 fsize-3">
                      How to Earn
                  </div>
                </div>
              </div>
              <div className="">
                <div style={{marginBottom:"10px"}}><span className="bold">Every 15 minutes...</span></div>
                        <div className="fsize-1">
                            
                            <div className="ingredient-grid2 text-muted">
                                <span className="">Color</span>
                                <span className="">Chips</span>
                                <span className="bold">⬛  Black</span><span className="bold">+4</span>
                                <span className="bold">🏅  Gold</span><span className="bold">+2</span>
                                <span className="bold">🟪  Purple</span><span className="bold">+1</span>
                            </div>
                        </div>
                    </div>  
              </div>
            </div>
          </div>
        </div>
    //   </div>

    )
}