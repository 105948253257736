import React, { useContext } from 'react'

import { SiteContext } from '../App.tsx';

import Accordion from 'react-bootstrap/Accordion';

import ScheduleAccordion from '../other/schedule/ScheduleAccordion';
import RZAccordion from '../other/schedule/RZAccordion';
import GradesAccordion from '../other/schedule/GradesAccordion';
import CalendarAccordion from '../other/schedule/CalendarAccordion';
import LoginAccordion from '../other/schedule/LoginAccordion';

export default function Schedule() {

    const {
        student,
        loginState,
        studentDataFunctions: {
        }
    } = useContext(SiteContext)
    
    return (
        <div className="row">
            <div className="d-none d-md-block col-6">
              <ScheduleAccordion sizeClass={'col-12'} title={'7th Grade'} grade={7} />
              <RZAccordion sizeClass={'col-12'} title={'Reading Zone Schedule'} />
              <LoginAccordion sizeClass={'col-12'} title={'Pax Pat Login'} />
              <BracketAccordion sizeClass={'col-12'} title={'Bracket Login'} />
            </div>
            <div className="d-none d-md-block col-6">
              <ScheduleAccordion sizeClass={'col-12'} title={'8th Grade'} grade={8} />
              <GradesAccordion sizeClass={'col-12'} title={'Grades'} />
              <CalendarAccordion sizeClass={'col-12'} title={'Calendar'} />
            </div>
            <div className="d-block d-md-none">
              <ScheduleAccordion sizeClass={'col-12'} title={'7th Grade'} grade={7} />
              <ScheduleAccordion sizeClass={'col-12'} title={'8th Grade'} grade={8} />
              <RZAccordion sizeClass={'col-12'} title={'Reading Zone & Clubs Schedule'} />
              <GradesAccordion sizeClass={'col-12'} title={'Grades'} />
              <CalendarAccordion sizeClass={'col-12'} title={'Calendar'} />
              <LoginAccordion sizeClass={'col-sm-12 col-md-6'} title={'Pax Pat Login'} />
              <BracketAccordion sizeClass={'col-sm-12 col-md-6'} title={'Bracket Login'} />
            </div>
            
        </div>                   
    )
}

function BracketAccordion({sizeClass, title}) {

  const { 
    student, loginState,
    classInformation
  } = useContext(SiteContext)

  console.log(classInformation,'classInfo loginacc')
  const code = classInformation.informationStrings ? classInformation.informationStrings.paxpatCourseCode : false

    return (
      <div className={sizeClass}>
        <div className="main-card mb-3 card">
          <div className="card-header">
            {/* <FontAwesomeIcon style={{ marginRight: 10 }} icon={faCalendar} /> */}
            <a href="https://www.pooltracker.com/join.asp?poolid=230554" target="_blank">{title}</a>
          </div>
          <div className="card-body">
            <Accordion>
                  <Accordion.Item eventKey={'sem1'}>
                    <Accordion.Header>Login</Accordion.Header>
                    <Accordion.Body>
                    <div className="recipe__row">
                        <div className="recipe__value recipe__value--indented">
                            <div className="ingredient-grid2">
                                <span className="bold">Email</span>
                                <span className="">{student.email}</span>
                                <span className="bold">Password</span>
                                <span className="" style={{}}>{student.studentId}</span>
                                <span className="bold">Screen Name</span>
                                <span className="" style={{}}>{student.name}</span>
                                {/* <span className="bold">Name</span>
                                <span className="bold">Course / Product Code</span>
                                <span className="">{student.name}</span>
                                {code && <span className="">{code}</span>} */}
                            </div>
                        </div>
                    </div>  
                    {/* <div className="recipe__row">
                        <div className="recipe__value recipe__value--indented">
                            <div className="ingredient-grid2">
                                <span className="bold">Name</span>
                                <span className="bold">Course Code</span>
                                <span className="">{student.name}</span>
                                <span className="">{code}</span>
                            </div>
                        </div>
                    </div>   */}
                    </Accordion.Body>
                  </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    )
}