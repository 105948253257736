import React, { useContext } from 'react';
import Search from './Search';

import { SiteContext } from "../App.tsx";

export default function MainRecords() {

  const {
    studentDataFunctions: {
      ///
    },
    records, studentList,
  } = useContext(SiteContext)

  var recordsSort = [...studentList]
  var alphaRecords = recordsSort.sort((a, b) => a.name.localeCompare(b.name))

  return (
    <div className="">
      <Search students={alphaRecords}/>
    </div>
  );
}