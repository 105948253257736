import React, { useContext, useEffect, useState } from 'react'

import { SiteContext, baseUrl } from '../../App.tsx';

import AgreementString from './AgreementString';

export default function PQuiz() {

    const [string, setString] = useState('')

    const {
        socket,
        studentDataFunctions: {
        }
    } = useContext(SiteContext)

    useEffect(() => {

        socket.on("receive_agreement", (data) => {
            console.log(data, "receive_agreement")   

            setString(data.string)
        })

        return () => {
            socket.off('receive_agreement');
        };

    }, [socket])

    function handleChange(stringChange) {
        setString(stringChange)
        socket.emit("send_agreement", { string: stringChange, room: 'admin' });
    }
    
    return (
        <>
          <AgreementString handleChange={handleChange} string={string} setString={setString} />
        </>
    )
}