import React, { useState, useEffect, useContext } from "react";

import { SiteContext } from "../../../App.tsx";

export default function SubmitButton({steps, step, handleCloseLocal, form, checkList, setShowInventory }) {

    const {socket, student, room, studentDataFunctions: {updateMongo, setStudent}} = useContext(SiteContext)
    const [warning, setWarning] = useState(false)

    // var filter = step === 1 ? checkList.filter(entry => !entry.complete).length : filterForm()
    // console.log(filter,'filter',step, 'step', form)

    if(steps[step-1]) {
      var {buttonText, warningText, filter} = steps[step-1]
    } else return
     
    
    // var buttonText = (step < 3) ? 'Continue to Step 2' : 'Complete Exit Ticket'
    // var warningText = (step < 3) ? 'Complete checklist first' : 'Complete reflection first'
    var warningTriggered = filter > 0 ? true : false

    filter = filter === 0 && step === 1 ? checkList[0].item === 'Return to White Bin:' && checkList[0].secondary.filter(item => !item.complete).length : filter

    function handleCheckCompletion(e, previous) {

      e.preventDefault()

      if(warningTriggered && !previous) {
        setWarning(true)
        return
      }

      var difference = previous ? -1 : 1

      if(step < steps.length || previous) {
        const newExitTicket = {...student.dayReview, display: step + difference}
        updateMongo({dayReview: newExitTicket}, student.dailyListId, 'dailyList')
        setShowInventory(false)
        setStudent({...student, dayReview: newExitTicket})
        socket.emit("send_exitTicket", { dayReview: newExitTicket, studentId: student.studentId, room });
        return
      } else if(step >= steps.length) {
        const newExitTicket = {...form, timeStamp: form.timeStamp ? form.timeStamp : Date.now(), display: 4}
        updateMongo({dayReview: newExitTicket}, student.dailyListId, 'dailyList')
        handleCloseLocal()
        setStudent({...student, dayReview: newExitTicket})
        socket.emit("send_exitTicket", { dayReview: newExitTicket, studentId: student.studentId, room });
      }

    }

    return (
        <div className="d-flex align-content-center">
        {step > 1 && <button className={`btn btn-light me-2`} onClick={e => handleCheckCompletion(e, true)}>Previous Step</button>}
        {(warning && warningTriggered) && <span className="ms-2 ps-2 pe-2 pt-1 pb-1 text-center" role="none">{warningText}</span>}
        <button className={`btn btn-info ${filter > 0 ? 'incompleteButton' : ''}`} onClick={e => handleCheckCompletion(e)}>{buttonText}</button>
        </div>
    )
}
