import React, { useContext, useState } from 'react'

import { SiteContext } from '../../App.tsx';
import { AdminContext } from '../../pages/AdminContainer';
import { isBrowser, isMobile } from 'react-device-detect';

import MenuModal from './overlays/MenuModal';
import useLongPress from './utils/useLongPress';
import TimerCountUp from '../../other/utils/TimerCountUp';
import CompleteCard from '../../modals/assignments/admin/ACompleteCard';

// import DropdownWarning from './DropdownWarning'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary';

import './utils/gridContainer.css'
import '../../behavior/dashboard/d_behavior.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faBookmark, faX, faRotate, faShare, faPlus, faCheck, faBarsProgress, faSpinner } from '@fortawesome/free-solid-svg-icons';

import birthdayFunction from '../../other/utils/birthday.js'

export default function GridEntry({student, alphaView, setActiveStudent, gridMode, handleAddWarning, handleClick, activeStudent, useSingleAndDoubleClick, editMode, chipMode, module, handleGroupNumber, showSeats}) {

    const { 
        colorMatch, focusAgreements, studentList, goalQuestions, socket,
        studentDataFunctions: {
            setStudentList,
        }
     } = useContext(SiteContext)
     
    const {handleColorClick, mode, behaviorEvents} = useContext(AdminContext)
    
    const [show, setShow] = useState(false)
    const [currentClasses, setCurrentClasses] = useState([])
    
    const {
        color: {
            current, warningsGiven,
        },
    } = student

    // console.log(student,'student')
    const handleLongPress = useLongPress(currentClasses, longPressCallBack, 500, singleClickCallBack, doubleClickCallBack, tripleClickCallBack);
    // const click = useSingleAndDoubleClick(useSingleClick, useDoubleClick);

    function singleClickCallBack() {
      // console.log('here')
      if(editMode === 'Pending') return
      console.log(currentClasses, 'currentClasses singleClickCallBack')
      if(currentClasses.length > 0 && currentClasses.includes('attendanceStop')) return
      handleClick(student)
    }

    function longPressCallBack() {
      setShow(prev => {return !prev})
    }

    function doubleClickCallBack() {
      if(isMobile) return
      handleAddWarning(undefined, student, 'removeWarning')
    }

    function tripleClickCallBack() {
      if(isMobile) return
      handleColorClick(undefined, student, 'raise')
    }

    if(!student || (gridMode === 'Assignments' && !student.assignments)) return
    
    var variant = ''
    if(current) {
        var match = colorMatch.filter(item => item.color === current)
            variant = match[0].variant
    }
    
    var textColor = (variant === 'light') ? 'textChoice' : ''

    var colorBg = !student.loggedIn && variant !== 'flag' ? `stripes ${variant}` : `bg-${variant}`
        colorBg = !student.loggedIn && variant === 'flag' ? `stripes flagVariant` : colorBg
        colorBg = !student.loggedIn && variant === 'light' ? `stripes lightVariant` : colorBg

        // console.log(student.attendance, 'attendance', student.name)
        if(gridMode === 'Attendance') {
            colorBg = student.attendance === 'Absent' ? 'bg-danger' : student.attendance === 'Present' ? 'bg-success' : 'bg-warning'
            colorBg = student.loggedIn && student.attendance === 'Absent' ? 'stripes danger' : colorBg
            colorBg = !student.loggedIn && student.attendance === 'Tardy' ? 'stripes warningAttendance' : colorBg
            colorBg = !student.loggedIn && student.attendance === 'Present' ? 'stripes successAttendance' : colorBg
        }

        if(gridMode === 'Assignments') {

          var index = !student.assignments ? -1 : student.assignments.findIndex(entry => entry.title === focusAgreements.activeAssignment) 
          var completionStatus = index === -1 ? 'none' : student.assignments[index].complete
          // if(student.name.includes('Natalia')) {
          //   console.log(student,student.assignments,'student.assignments', student.assignments[index], index)
          // }
          colorBg = completionStatus === true ? 'pending stripes' : 
                    completionStatus === 1 ? 'bg-warning' : 
                    completionStatus === false ? 'bg-danger' : student.attendance === 'Absent' ? 'bg-light' : 'bg-secondary'

          colorBg = (index !== -1 && student.assignments[index].complete && student.assignments[index].completeAdmin) ? 'bg-success' : colorBg
          // textColor = student.attendance === 'Absent' ? `fw-normal` : textColor
          // colorBg = student.attendance === 'Absent' ? 'bg-light' : colorBg
        }

        if(gridMode === 'Edit') {
            colorBg = student.attendance === 'Absent' ? 'bg-purple text-white' : 'bg-secondary'
            colorBg = activeStudent.studentId === student.studentId ? 'bg-primary' : colorBg

            // console.log(editMode,'editMode')
            colorBg = editMode === 'Group' ? `text-white bg-${colorMatch[student.groupNumber + 1].variant}` : colorBg
            // textColor = editMode === 'Group' ? `text-${colorMatch[student.groupNumber + 1].variant}` : textColor
        }

        if(gridMode === 'Chips') {
          // console.log(mode,'mode', student[mode])
          if(chipMode.mode === 'Auction') {
            colorBg = (typeof student.auctionBalance !== 'undefined') ? 'bg-primary' : 'bg-purple'
          }
          if(chipMode.mode === 'Chips') {
            // console.log(student.chips.balance,'student.chips.balance')
            colorBg = student.chips && student.chips.balance > 0 ? 'bg-purple' : 'bg-primary'
          }
        }

        if(gridMode === 'Modal Completion') {
          // console.log(mode,'mode', student[mode])
          if(!mode || !student[mode]) return
          colorBg = student[mode].display === 1 && mode === 'dayReview' ? 'bg-flag text-white' : 
                    (student[mode].display > 1 && student[mode].display < 4) && mode === 'dayReview' ? 'bg-primary' :
                    
                    (mode === 'goalSetting' && student[mode].display === 1) ? 'bg-flag text-white' : (mode === 'goalSetting' && student[mode].display <= goalQuestions.length) ? 'bg-info text-white' : 'bg-white text-success'

        }

        if(gridMode === 'Reading Zone') {

          console.log(student.readingZoneMin,'student.readingZoneMin', student.name)
          var newDate = student.readingZoneDate ? new Date(student.readingZoneDate).getTime() : 0
          student.weeksSince = student.readingZoneMin > -1 && student.readingZoneDate ? Math.floor(datediff(newDate, Date.now())/7) : false

          var totalMin = parseInt(student.readingZoneMin) + (student.weeksSince ? parseInt(student.weeksSince) : 0)
          // console.log(totalMin,'totalMin', student.name, student.readingZoneMin, student.weeksSince)
          var colorBg = totalMin === 0 ? 'bg-black' :
                        totalMin < 5 ? 'bg-danger' :
                        totalMin < 10 ? 'bg-flag' :
                        totalMin < 15 ? 'bg-success' :
                        totalMin >= 15 ? 'bg-primary' : 'bg-black'
          textColor = 'text-'+colorBg.split('-')[1]
        }

        function datediff(first, second) {        
          return Math.round((second - first) / (1000 * 60 * 60 * 24));
        }

    var borderColor = student.attendance === 'Tardy' && gridMode === 'Colors' ? '#fd7e14' : birthdayFunction(student.birthday) ? '#3f6ad8' : 'white'

    if((gridMode === 'Modal Completion' || gridMode === 'Reading Zone') && student.attendance === 'Absent') return
    // if(student.attendance === 'Absent') return

    const exportFunctions = {student, textColor, gridMode, editMode, module, handleClick, showSeats, chipMode, setActiveStudent}

    function filterBehaviorEvents() {
      // console.log(behaviorEvents,'behaviorEvents')
      const nominateEvents = behaviorEvents.filter(event => event.type !== 'self' && event.studentNominatedId == student.studentId && event.activelyPending)
      const selfEvents = behaviorEvents.filter(event => event.type === 'self' && event.studentInitiatingId == student.studentId && event.activelyPending)
      const combined = [...nominateEvents, ...selfEvents]

      return combined.length > 0 ? true : false
    }
    
    function sortAttendanceData() {
      if(student.attendancePercent.percent < 30) return [false, false]

      // console.log(student.attendancePercent.percent,'student.attendancePercent.percent')

      return [
        student.attendancePercent.tPercent > 0 ? `${student.attendancePercent.tPercent}%` : false, 
        student.attendancePercent.aPercent > 0 ? `${student.attendancePercent.aPercent}%` : false, 
      ]
    }

    function handleLocalClass(e) {
      e.preventDefault()
      console.log(e.target.classList,'e.target.classList', [...e.target.classList])
      setCurrentClasses([...e.target.classList])
    }

    function WarningsComponent({}) {
      return (
        <>
        {warningsGiven > 0 && <Warnings num={1} handleAddWarning={handleAddWarning} student={student} variant={gridMode === 'Attendance' ? variant : false} />}
        {warningsGiven > 1 && <Warnings num={2} handleAddWarning={handleAddWarning} student={student} variant={gridMode === 'Attendance' ? variant : false} />}
        {warningsGiven > 2 && <Warnings num={3} handleAddWarning={handleAddWarning} student={student} variant={gridMode === 'Attendance' ? variant : false} />}
        </>
      )
    }

    return ( 
    <ErrorBoundary>
        <div className={'d-inline-block p-1 pt-0 pb-1'} role={(gridMode !== 'Edit' || editMode !== 'Group') ? 'button' : ''}>
        <MenuModal student={student} show={show} setShow={setShow} />
         <div className="d-flex justify-content-between" style={{height:"3px"}}>
            <div>
              {gridMode === 'Colors' && <WarningsComponent/>}
              {gridMode === 'Attendance' && (student.attendancePercent.percent >= 30 ? 
                <GenericBadge student={student} showBadge={student.attendancePercent.percent >= 30} variant={'white'} type={'attendance'} value={sortAttendanceData()} /> : 
                <WarningsComponent/>)}
            </div>
            <div style={{height:"3px"}} className="">
              {gridMode === 'Attendance' && <AttendanceBadge student={student} />}
              {gridMode === 'Colors' && <GenericBadge student={student} showBadge={filterBehaviorEvents()} variant={'info'} value={student.color.lastUpdate} type={'timer'} />}
            </div>
         </div>
         <div className={`d-inline-block boxesGrid boxLeft ${colorBg} p-1 pb-0 pt-0`} onClick={e => handleLocalClass(e)} {...handleLongPress} 
           style={{color: (current === 'White' && gridMode === 'Colors' && student.loggedIn) ? '#343a40' : 'white', border: `3px solid ${borderColor}`, borderRadius: "5px"}} >
            <StudentBox exportFunctions={exportFunctions} />
          </div>
        </div>  
    </ErrorBoundary>
    )
}

function StudentBox({exportFunctions}) {

    const {student, textColor, gridMode, editMode, module, handleClick, showSeats, chipMode, setActiveStudent} = exportFunctions

    const {focusAgreements, goalQuestions, socket, studentList, studentDataFunctions: {updateMongo, setStudentList}} = useContext(SiteContext)
    const {handleAttendanceClick, mode} = useContext(AdminContext)

    const outcome = birthdayFunction(student.birthday)
    var classes = gridMode === 'Colors' ? `ms-0 me-0 p-1 mb-0 ${student.color.warningsGiven > 1 ? 'mt-0' : 'mt-0'}` :
                  gridMode === 'Attendance' ? 'ms-0 me-0 p-1' : 'ms-0 me-0 p-1'

    if(gridMode === 'Modal Completion') {
      if(!mode || !student[mode]) return
      var boxBg = student[mode].display === 1 && mode === 'dayReview' ? 'bg-white text-flag' : 
                (student[mode].display > 1 && student[mode].display < 4) && mode === 'dayReview' ? 'bg-white text-primary' :

                (mode === 'goalSetting' && student[mode].display === 1) ? 'bg-white text-flag' : (mode === 'goalSetting' && student[mode].display <= goalQuestions.length) ? 'bg-white text-info' : 'bg-success text-white'

    }

    function handleClickLocal(e) {
      e.preventDefault()

      var index = focusAgreements.modules.findIndex(moduleEntry => moduleEntry.title === student.module)
      var numberGroups = focusAgreements.modules[index].checklists.length
      var newGroupNumber = parseInt(student.groupNumber) + 1 > numberGroups ? 1 : parseInt(student.groupNumber) + 1

      console.log(newGroupNumber,'newGroupNumber')
      socket.emit("send_updateSingleStudent", { student: {...student, groupNumber: newGroupNumber}, room: student.period });
    //   socket.emit("send_updateSingleStudent", { student: {...student, groupNumber: newGroupNumber}, room: 'admin' });

      const editStudentList = [...studentList]
      var index1 = editStudentList.findIndex(studentEntry => studentEntry.studentId === student.studentId)
      editStudentList[index1].groupNumber = newGroupNumber

      updateMongo({groupNumber: newGroupNumber}, editStudentList[index1].dailyListId, 'dailyList')
      updateMongo({groupNumber: newGroupNumber}, editStudentList[index1]._id, 'records')
      // updateMongo({groupNumber: newGroupNumber}, editStudentList[index1]._id, 'records')
      console.log(editStudentList[index1],'editStudentList[index1]')
      setStudentList(editStudentList)
    }
    
    if(student.chips) {
      student.chips.balance = student && student.chips && parseInt(student.chips.balance)
    }
    
    var checklistsLength = editMode === 'Group' ? handleChecklistsLength() : false

    function handleChecklistsLength() {
      var index = focusAgreements.modules.findIndex(item => item.title === student.module)
      if(index === -1) return
      var index1 = focusAgreements.modules[index].checklists.findIndex(item => item.groupNumber === student.groupNumber)
      if(index1 === -1) return

      return focusAgreements.modules[index].checklists.length
    }
    // console.log('before')

    function handlePendingCost(e) {
      e.preventDefault()

      const editStudentList = [...studentList]
      var index = editStudentList.findIndex(entry => entry._id === student._id)
      if(index === -1) return
      editStudentList[index].pendingAuction = e.target.value
      setStudentList(editStudentList)

      // setActiveStudent({})
      updateMongo({pendingAuction: e.target.value}, student.dailyListId, 'dailyList')
    }

    function handlePendingClick(e) {
      // console.log(e.target,'e.target', e.target.classList)
      if(e && e.target && e.target.classList && [...e.target.classList].includes('auctionBalance') || editMode !== 'Pending') return
      handleClick(student)
    }

    //  console.log('after')
    if(!mode) return

    // console.log(student.auctionBalance,'acutonBalance', student.name)

    return (
        <div className={`d-inline-block ${classes}`} style={{minWidth:"50px", whiteSpace:"nowrap", textAlign:"center"}} onClick={e => handlePendingClick(e)}>
            {outcome} 
            <span className={`${gridMode === 'Assignments' && textColor}`} style={{fontWeight:"bold"}}>{student.name.split(' ')[0] ? student.name.split(' ')[0] : student.name}</span>
            <span className={`${gridMode === 'Assignments' && textColor}`}>{` ${student.name.split(' ')[1] ? student.name.split(' ')[1].substring(0, 1) : ''}`}</span>
            {/* {gridMode === 'Edit' && editMode === 'Group' && module.checklists && module.checklists.length > 1 && <div onClick={e => handleGroupNumber(e, student)} className={`badge d-inline bg-white text-secondary ms-1`} role={focusAgreements.modulesGroups.length > 1 ? 'button' : ''}>{student.groupNumber}</div>} */}
            {/* {gridMode === 'Attendance' && student.attendance !== 'Present' && <span className="ms-1">{student.attendancePercent.percent > 30 && (student.attendance === 'Tardy' && student.attendancePercent.tPercent > 0) ? `${student.attendancePercent.tPercent}` : (student.attendance === 'Absent' && student.attendancePercent.aPercent > 0) ? `${student.attendancePercent.aPercent}` : ``}</span>} */}
            {gridMode === 'Attendance' && <div className="mt-0 p-0">
              {student.attendance !== 'Present' && <div className="badge bg-success m-0 p-1 me-1 attendanceStop" style={{height:"15px"}} onClick={e => handleAttendanceClick(e, student, 'Present')}>P</div>}
              {student.attendance !== 'Tardy' && <div className="badge bg-warning text-white m-0 p-1 me-1 attendanceStop" style={{height:"15px"}} onClick={e => handleAttendanceClick(e, student, 'Tardy')}>T </div>}
              {student.attendance !== 'Absent' && <div className="badge bg-danger m-0 p-1 attendanceStop" style={{height:"15px"}} onClick={e => handleAttendanceClick(e, student, 'Absent')}>A 
              {/* {student.attendancePercent.percent > 30 && student.attendancePercent.aPercent > 0 &&  `${student.attendancePercent.aPercent}`} */}
              </div>}
            </div>}
            {gridMode === 'Reading Zone' && student.readingZoneMin && <div className="mt-0 p-0">
              {student.readingZoneMin > -1 && <div className={`badge bg-white ${textColor} m-0 p-1 me-1`} style={{height:"15px"}}>{student.readingZoneMin} + {student.weeksSince ? student.weeksSince : 0}</div>}
            </div>}
            {gridMode === 'Chips' && <div className="mt-0 p-0">
              {chipMode.mode === 'Auction' ? <div className={`badge bg-white text-${(typeof student.auctionBalance !== 'undefined') ? 'primary' : 'purple'} m-0 p-1 me-1`} style={{height:"15px"}}>${typeof student.auctionBalance !== 'undefined' ? parseInt(student.auctionBalance) : 200}</div> : 
              <div className={`badge bg-white text-${student.chips && student.chips.balance > 0 ? 'purple' : 'primary'} m-0 p-1 me-1`} style={{height:"15px"}}>{student.chips && student.chips.balance ? student.chips.balance : 0}€</div>}
            </div>}
            {gridMode === 'Modal Completion' && <div className="mt-0 p-0">
              <div className={`badge ${boxBg} m-0 p-1 me-1`} style={{height:"15px"}}>Step {student[mode].display}</div>
            </div>}
            {gridMode === 'Edit' && <div className="mt-0 p-0">
              {editMode === 'Seat' && student.seat && student.seat !== student.module && showSeats && <div className="badge bg-light m-0 p-1 me-1" style={{height:"15px"}}>{student.module.substring(0, 7)}</div>}
              {editMode === 'Group' && focusAgreements.modules && checklistsLength > 1 && <div className={`badge d-inline bg-white text-secondary ms-1`} onClick={e => handleClickLocal(e)} role={checklistsLength > 1 ? 'button' : ''}>{student.groupNumber}</div>}
              {editMode === 'Pending' && student.pending && student.pending !== 'No Group' && <input type="number" className={`d-inline auctionBalance`} min={0} max={200} style={{fontSize:"8px"}}
                value={student.pendingAuction} onChange={e => handlePendingCost(e)}/>}
              {/* onClick={e => handleGroupNumber(e, student)} */}
              {editMode === 'Pending' && (!student.pending || student.pending === 'No Group') && <span>{typeof student.auctionBalance !== 'undefined' ? parseInt(student.auctionBalance) : 200}</span>}
              {/* onClick={e => handleGroupNumber(e, student)} */}
            </div>}
            {gridMode === 'Assignments' && <AssignmentCompletion student={student} textColor={textColor} />}
      </div>
    )
}

function Warnings({num, handleAddWarning, student, variant}) {
    return <div className={`warningsSeat warnings${num}Seat bg-${variant ? variant : 'flag'}`} onClick={e => handleAddWarning(e, student, 'removeWarning')}/>
}

function GenericBadge({student, showBadge, variant, value, type}) {
  const {colorMatch} = useContext(SiteContext)
  if(!showBadge) return

  // console.log(value,'value', student)
  const match = colorMatch.filter(item => item.color == student.color.current)[0]
  // console.log(match,'match')
    const emoji = match.string.split(' ') ? match.string.split(' ')[0] : ''
    const variant1 = match.variant

  return (
    <div className={`genericBadge bg-${variant} text-white fw-bold`}>
      {type === 'timer' && value ? <TimerCountUp startTime={value} showBadge={showBadge} /> : type === 'timer' && 'New'}
      {type === 'attendance' && <>
        <span className="text-warning">{value[0]}</span>
        <span className="text-danger">{value[1]}</span>
        {student.color.warningsGiven > 0 && <span className={`ms-1 text-${variant1}`}>
          {student.color.warningsGiven}
          <span className="" style={{fontSize:"6px"}}>{emoji}</span>
        </span>}
      </>}
    </div>
  )
}

function AttendanceBadge({student}) {

  // var attendanceType = student.attendance.substring(0, 1)
  const reversed = [...student.attendanceString].reverse()
  var count = 0

  for(let i=0; i < reversed.length;i++) {
    if(reversed[i].letter === 'A') {count++} else {break}
  }

  if(count === 0) return

  return <div className={`attendanceBadge bg-white d-flex align-items-center justify-content-center`} style={{fontSize:"8px"}}>
    <div className="text-danger fw-bold">{count}</div>
  </div>
}

function AssignmentCompletion({student, textColor}) {

  const {focusAgreements, studentList, socket, studentDataFunctions: {setStudentList, updateMongo}} = useContext(SiteContext)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleCompletion(e, value) {
    e.preventDefault()

    console.log(value, 'value')

    const editStudentList = [...studentList]
    var index1 = editStudentList.findIndex(entry => entry._id === student._id)
    if(index1 === -1) return
    var index = editStudentList[index1].assignments.findIndex(entry => entry.title === focusAgreements.activeAssignment)
    if(index === -1) return

    editStudentList[index1].assignments[index].complete = value
    editStudentList[index1].assignments[index].completeAdmin = value
    editStudentList[index1].assignments[index].timestamp = Date.now()

    setStudentList(editStudentList)
    updateMongo({assignments: editStudentList[index1].assignments}, student.dailyListId, 'dailyList')

    // console.log(editStudentList[index1].assignments, 'editStudentList[index1].assignments')
    socket.emit("send_updateSingleStudent", { student: editStudentList[index1], room: editStudentList[index1].period });
  }

  var index = student.assignments.findIndex(entry => entry.title === focusAgreements.activeAssignment) 
  // if(student.studentId == "376967") {
  //   console.log(student.assignments, 'assignements', student.assignments[index])
  // }

  var completionStatus = index === -1 ? 'none' : student.assignments[index].completeAdmin

  // console.log(student,'student', student.assignments, 'completionStatus', completionStatus)

  const statuses = [
    {text: 'C', value: true, icon: faCheck, variant: 'success', width: "35px"},
    {text: 'I', value: false, icon: faX, variant: 'danger'},
    // {text: 'P', value: 1, icon: faSpinner, variant: 'warning'},
  ]

  const exportFunctions = {show, setShow, handleClose, handleShow, activeModal: false, setActiveModal: false}

  return (
  <div className={`mt-0 p-0`}>
    {statuses.map(entry => {
      if(focusAgreements.activeAssignment === 'none') return
      // if(entry.value) return
      if(entry.text === 'P') return
      if(entry.text === 'C' && student.assignments[index] && student.assignments[index].completeAdmin) return
      return (
        <div key={entry.text} className={`badge bg-${entry.variant} text-white m-0 p-1 me-1 h-auto ${student.attendance !== 'Absent' && `border border-1 border-white`}`} style={{height:"15px", fontSize:"12px", width: entry.width ? entry.width : ''}} onClick={e => handleCompletion(e, entry.value)}>
          <FontAwesomeIcon icon={entry.icon} className="" />
        </div>
      )
    })}
    {index !== -1 && student.assignments[index].complete && student.assignments[index].completeAdmin && <CompleteCard activeAssignment={student.assignments[index]} name={student.name} studentId={student.studentId} exportFunctions={exportFunctions} />}
  </div>
  )
}
