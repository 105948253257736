import React, { Component } from "react";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

import { default as ReactSelect } from "react-select";
import { components } from "react-select";

export const colourOptions = [
    { value: "ocean1", label: "Ocean" },
    { value: "blue", label: "Blue" },
    { value: "purple", label: "Purple" },
    { value: "red", label: "Red" },
    { value: "orange", label: "Orange" },
    { value: "yellow", label: "Yellow" },
    { value: "green", label: "Green" },
    { value: "forest", label: "Forest" },
    { value: "slate", label: "Slate" },
    { value: "silver", label: "Silver" }
];

const Option = (props) => {
  // console.log(props, 'props inside')
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{fontWeight:"normal", fontSize:"0.8rem"}}
        />{" "}
        <label style={{fontWeight:"normal", fontSize:"12px", fontFamily:"sans-serif", paddingLeft:"3px"}}>{props.label}</label>
      </components.Option>
    </div>
  );
};

export default class Example extends Component {
  
  componentDidUpdate(prevProps) {
    if(prevProps.focusOptions !== this.props.focusOptions) {
      this.setState({optionSelected: this.props.focusOptions.filter(entry => entry.isSelected)});
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      optionSelected: this.props.focusOptions.filter(entry => entry.isSelected)
    };
  }

  handleChange = (selected) => {
    this.setState({
      optionSelected: selected
    });
    this.props.updateFocusSelection(selected[0])
  };

  render() {
    // console.log(this.props)
    return (
      <ErrorBoundary>
      <span
        className="d-inline-block mb-1"
        data-toggle="popover"
        data-trigger="focus"
        data-content="No Focus"
      >
        <ReactSelect
          options={this.props.focusOptions}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option
          }}
          key={this.props.focusOptions}
          onChange={this.handleChange}
          allowSelectAll={true}
          value={this.state.optionSelected}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: '2px solid black',
              maxWidth:"30rem",
              minWidth:"180px",
              fontFamily:"inherit",
              // fontSize:"11px"
              fontSize:"0.7rem"
              // fontWeight:"normal"
            }),
          }}
          // value={this.focusOptions.optionSelected}
        />
      </span>
      </ErrorBoundary>
    );
  }
}